import { UserInterface } from "../interfaces/UserInterface";
import useApi from "./useApi";

interface UseCreateUserInterface {
  create: (user: UserInterface) => Promise<UserInterface | null>;
}

const useCreateUser = (): UseCreateUserInterface => {
  const { post } = useApi();

  const create = async (user: UserInterface): Promise<UserInterface | null> => {
    const { status, data: createdUser } = await post(`/user`, {
      ...user,
    });

    if (status === 200) {
      return createdUser;
    }

    return null;
  };

  return { create };
};

export default useCreateUser;
