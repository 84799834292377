import { FormGroupInterface } from "../interfaces/FormGroupInterface";
import { ProjectCategoryInterface } from "../interfaces/ProjectCategoryInterface";

const getProjectCategoriesFromFormGroup = (
  formGroup: FormGroupInterface
): ProjectCategoryInterface[] => {
  return formGroup.projectCategories ?? [];
};

export default getProjectCategoriesFromFormGroup;
