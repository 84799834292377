import useApi from "../useApi";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";

interface UseGetProjectCapabilityInterface {
  getById: (id: string) => Promise<ProjectCapabilityInterface | null>;
}

const useGetProjectCapability = (): UseGetProjectCapabilityInterface => {
  const { get } = useApi();

  const getById = async (
    id: string
  ): Promise<ProjectCapabilityInterface | null> => {
    const { data, status } = await get(`/project-capability/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetProjectCapability;
