import React, { ReactElement, useState } from "react";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import { Button, Card, Form, TableControlBar } from "../../components";
import { CloseIcon } from "../../icons";
import PlusIcon from "../../icons/PlusIcon";
import { AnimatePresence } from "framer-motion";
import Right from "../../components/Right";
import CheckIcon from "../../icons/CheckIcon";
import { useTranslation } from "react-i18next";
import {
  FormGroupRequiredType,
  FormGroupRequiredTypeEnum,
} from "../../interfaces/FormGroupRequiredType";
import useFormGroups from "../../api/formGroups/useFormGroups";

interface AddFormGroupProps {
  projectCategoryFilter: ProjectCategoryInterface | null;
}
const AddFormGroup = ({
  projectCategoryFilter,
}: AddFormGroupProps): ReactElement => {
  const { t } = useTranslation();

  const { create } = useFormGroups();

  const [isShowAddForm, setIsShowAddForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowAddForm(!isShowAddForm)}
      />
      {isShowAddForm && (
        <AnimatePresence>
          <Card
            initial={{ y: "-2rem" }}
            animate={{ y: 0 }}
            exit={{ opacity: 0 }}
          >
            <Form<{
              isMandatory: boolean;
              name: string;
              requiredType: FormGroupRequiredType;
            }>
              fields={[
                {
                  id: "name",
                  type: "text",
                  label: t("FORMS.GROUPS.name"),
                },
                {
                  id: "isMandatory",
                  type: "boolean",
                  label: t("FORMS.GROUPS.is-mandatory"),
                },
                {
                  id: "requiredType",
                  type: "select",
                  label: t("FORMS.GROUPS.required-type"),
                  options: Object.keys(FormGroupRequiredTypeEnum).map(
                    (requiredType) => ({
                      value: requiredType,
                      label: t(`REQUIRED_TYPES.${requiredType}`),
                    })
                  ),
                },
              ]}
              initialValues={{
                isMandatory: false,
                name: "",
                requiredType: FormGroupRequiredTypeEnum.ONE_OR_MORE,
              }}
              handleSubmit={async (formGroupToCreate) => {
                setIsSubmitting(true);
                await create({
                  name: formGroupToCreate.name,
                  isMandatory: formGroupToCreate.isMandatory,
                  requiredType: formGroupToCreate.requiredType,
                });
                setIsSubmitting(false);
                setIsShowAddForm(false);
              }}
            >
              <Right>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant={isSubmitting ? "success" : "default"}
                  whileTap={{
                    scale: 0.8,
                  }}
                >
                  {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                </Button>
              </Right>
            </Form>
          </Card>
        </AnimatePresence>
      )}
    </>
  );
};

export default AddFormGroup;
