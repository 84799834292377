import useApi from "../useApi";
import { FrontendInterface } from "../../interfaces/FrontendInterface";

interface UseCreateFrontendInterface {
  create: (project: FrontendInterface) => Promise<FrontendInterface>;
}

const useCreateFrontend = (): UseCreateFrontendInterface => {
  const { post } = useApi();

  const create = async (
    frontend: FrontendInterface
  ): Promise<FrontendInterface> => {
    const { status, data: createdFrontend } = await post(`/frontend`, {
      ...frontend,
    });

    if (status === 200) {
      return createdFrontend;
    }

    throw new Error("Frontend creation error");
  };

  return { create };
};

export default useCreateFrontend;
