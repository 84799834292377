import useApi from "../useApi";
import { FrontendInterface } from "../../interfaces/FrontendInterface";

interface UseGetFrontendsInterface {
  getAll: () => Promise<FrontendInterface[]>;
}

const useGetFrontends = (): UseGetFrontendsInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<FrontendInterface[]> => {
    const { data, status } = await get(`/frontend`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetFrontends;
