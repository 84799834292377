import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseUpdateFormGroupInterface {
  update: ({
    formGroup,
    projectCategoriesToAdd,
    projectCategoriesToRemove,
  }: {
    formGroup: FormGroupInterface;
    projectCategoriesToRemove: string[];
    projectCategoriesToAdd: string[];
  }) => Promise<FormGroupInterface | null>;
}

const useUpdateFormGroupWithProjectCategories =
  (): UseUpdateFormGroupInterface => {
    const { put } = useApi();

    const update = async ({
      formGroup,
      projectCategoriesToAdd,
      projectCategoriesToRemove,
    }: {
      formGroup: FormGroupInterface;
      projectCategoriesToRemove: string[];
      projectCategoriesToAdd: string[];
    }): Promise<FormGroupInterface | null> => {
      if (formGroup.id === undefined) {
        return null;
      }

      const { data, status } = await put(`/form-group/${formGroup.id}`, {
        ...formGroup,
        projectCategoriesToAdd,
        projectCategoriesToRemove,
      });

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { update };
  };

export default useUpdateFormGroupWithProjectCategories;
