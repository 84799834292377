import React, { ReactElement, useEffect, useState } from "react";
import { CircledPlusIcon } from "../../icons";
import styled from "styled-components";
import GenericPopover from "../../components/GenericPopover";
import StyledPopover from "../../components/styles/StyledPopover";
import { CompanySelection } from "../index";
import useProjectContracts from "../../api/projectContracts/useProjectContracts";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { SearchableDataInterface } from "../../interfaces/SearchableDataInterface";
import useCompanies from "../../api/companies/useCompanies";
import { Button, Clickable, Column, Row, Space } from "../../components";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import _ from "lodash";
import { ContractStateType } from "../../interfaces/ContractStateType";
import ContractList from "./ProjectContractList";
import { useTranslation } from "react-i18next";
import EditContractStateModal from "./modals/EditContractStateModal";
import SendEmailModal from "./modals/SendEmailModal";

const StyledPopoverFixedWidth = styled(StyledPopover)`
  width: 50rem;
`;

const StyledPaddingDiv = styled.div`
  padding: 1rem;
`;

const getDifferences = (
  arrayA: string[],
  arrayB: string[]
): { removed: string[]; added: string[] } => {
  const removed = _.difference(arrayA, arrayB);
  const added = _.difference(arrayB, arrayA);

  return { removed, added };
};

export interface CompanySelectionInterface {
  company: CompanyInterface;
  contractId: string | null;
  contractState: ContractStateType;
}

interface AddProjectContractProps {
  projectId?: string;
}
const ManageProjectContracts = ({
  projectId,
}: AddProjectContractProps): ReactElement => {
  const { t } = useTranslation();
  const { getByProjectId, create, deleteById, update } = useProjectContracts();
  const { getById } = useCompanies();

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [initialContracts, setInitialContracts] = useState<
    ProjectContractInterface[]
  >([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedCompaniesAndStates, setSelectedCompaniesAndStates] = useState<
    CompanySelectionInterface[]
  >([]);
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    id: string | null;
  }>({ isOpen: false, id: null });
  const [sendEmailModalConfig, setSendEmailModalConfig] = useState<{
    isOpen: boolean;
    id: string | null;
  }>({ isOpen: false, id: null });

  useEffect(() => {
    if (projectId === undefined || !isPopoverOpen) {
      return;
    }
    void getByProjectId(projectId).then((projectContractData) =>
      setInitialContracts(projectContractData ?? [])
    );
  }, [projectId, isPopoverOpen]);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const companies: CompanySelectionInterface[] = [];

      for (const initialContract of initialContracts) {
        const company = await getById(initialContract.companyId);
        if (
          company === null ||
          companies.find(
            (existingState) => existingState.company.id === company.id
          ) !== undefined
        ) {
          continue;
        }

        companies.push({
          company,
          contractState: initialContract.contractState ?? "OPEN",
          contractId: initialContract.id ?? null,
        });
      }

      setSelectedCompaniesAndStates(companies);
    };

    void initialize().then();
  }, [initialContracts]);

  useEffect(() => {
    const initialCompanyIds = initialContracts.map(
      (initialContract) => initialContract.companyId
    );
    const selectedCompanyIds = selectedCompaniesAndStates.map(
      (selectedCompany) => selectedCompany.company.id ?? ""
    );

    const { added, removed } = getDifferences(
      initialCompanyIds,
      selectedCompanyIds
    );

    if (removed.length === 0 && added.length === 0) {
      let isChanged = false;
      initialContracts.forEach((initialContract) => {
        const found = selectedCompaniesAndStates.find(
          (selected) => selected.contractId === initialContract.id
        );
        if (found === undefined) {
          return;
        }
        if (found.contractState !== initialContract.contractState) {
          isChanged = true;
        }
      });
      setIsChanged(isChanged);
      return;
    }

    setIsChanged(true);
  }, [initialContracts, selectedCompaniesAndStates]);

  useEffect(() => {
    if (isPopoverOpen) {
      return;
    }

    setSelectedCompaniesAndStates([]);
  }, [isPopoverOpen]);

  const selectCompany = async (
    selected: SearchableDataInterface | null
  ): Promise<void> => {
    if (
      selected === null ||
      selectedCompaniesAndStates.find(
        (selectedCompany) => selectedCompany.company.id === selected.value
      ) !== undefined
    ) {
      return;
    }

    const company = await getById(selected.value);
    if (company === null) {
      return;
    }
    setSelectedCompaniesAndStates([
      ...selectedCompaniesAndStates,
      { company, contractState: "OPEN", contractId: company.id ?? "" },
    ]);
  };

  const deselectCompany = (id?: string): void => {
    if (id === undefined) {
      return;
    }
    setSelectedCompaniesAndStates(
      selectedCompaniesAndStates.filter(
        (selectedCompany) => selectedCompany.company.id !== id
      )
    );
  };

  const handleClosePopover = (): void => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleSave = async (): Promise<void> => {
    const initialCompanyIds = initialContracts.map(
      (initialContract) => initialContract.companyId
    );
    const selectedCompanyIds = selectedCompaniesAndStates.map(
      (selectedCompany) => selectedCompany.company.id ?? ""
    );

    const contractsToChange: ProjectContractInterface[] = [];
    selectedCompaniesAndStates.forEach((selected) => {
      const found = initialContracts.find(
        (initialContract) => initialContract.id === selected.contractId
      );
      if (found === undefined) {
        return;
      }
      if (found.contractState !== selected.contractState) {
        contractsToChange.push({
          ...found,
          contractState: selected.contractState,
        });
      }
    });
    for (const contractToChange of contractsToChange) {
      await update(contractToChange);
    }

    const { added, removed } = getDifferences(
      initialCompanyIds,
      selectedCompanyIds
    );

    for (const companyId of added) {
      if (projectId === undefined) {
        continue;
      }

      const state = selectedCompaniesAndStates.find(
        (selected) => selected.company.id === companyId
      );

      await create({
        projectId,
        companyId,
        contractState: state !== undefined ? state.contractState : "OPEN",
      });
    }

    const contractsToDelete: ProjectContractInterface[] = [];
    removed.forEach((removedCompanyId) => {
      const found = initialContracts.find(
        (initialContract) => initialContract.companyId === removedCompanyId
      );
      if (found === undefined) {
        return;
      }
      contractsToDelete.push(found);
    });
    for (const contractToDelete of contractsToDelete) {
      if (contractToDelete.id === undefined) {
        continue;
      }
      await deleteById(contractToDelete.id);
    }

    setIsPopoverOpen(false);
  };

  const updateSelectedCompany = (
    id: string,
    updatedContractState: ContractStateType
  ): void => {
    setSelectedCompaniesAndStates(
      selectedCompaniesAndStates.map((selectedCompany) => {
        if (selectedCompany.contractId !== id) {
          return selectedCompany;
        }
        return {
          ...selectedCompany,
          contractState: updatedContractState,
        };
      })
    );
  };

  if (projectId === undefined) {
    return <></>;
  }

  return (
    <>
      {modalConfig.isOpen && modalConfig.id !== null && (
        <EditContractStateModal
          isOpen={modalConfig.isOpen}
          handleStateChange={(updatedState) => {
            if (modalConfig.id === null) {
              return;
            }
            updateSelectedCompany(modalConfig.id, updatedState);
            setModalConfig({
              id: null,
              isOpen: false,
            });
          }}
          handleOpenChange={(isOpen) => {
            if (isOpen) {
              return;
            }
            setModalConfig({
              isOpen: false,
              id: null,
            });
          }}
        />
      )}
      {sendEmailModalConfig.isOpen && sendEmailModalConfig.id !== null && (
        <SendEmailModal
          contractId={sendEmailModalConfig.id}
          isOpen={sendEmailModalConfig.isOpen}
          handleOpenChange={(isOpen) => {
            if (isOpen) {
              return;
            }
            setSendEmailModalConfig({
              isOpen: false,
              id: null,
            });
          }}
        />
      )}
      <GenericPopover
        isPopoverOpen={isPopoverOpen}
        closePopover={() => {}}
        positions={["bottom"]}
        align="center"
        triggerElement={
          <>
            <Clickable onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              <CircledPlusIcon />
            </Clickable>
          </>
        }
      >
        <StyledPopoverFixedWidth>
          <StyledPaddingDiv>
            <ContractList
              handleRemove={deselectCompany}
              handleEdit={(id) => {
                setModalConfig({
                  id,
                  isOpen: true,
                });
              }}
              handleSendEmail={(id) => {
                setSendEmailModalConfig({
                  id,
                  isOpen: true,
                });
              }}
              projectId={projectId}
              selectionState={selectedCompaniesAndStates}
            />
            {selectedCompaniesAndStates.length > 0 && <Space size={2} />}
            <CompanySelection
              skipSelection
              initialValue={null}
              filter="default"
              handleSelected={async (selected) => {
                await selectCompany(selected);
              }}
            />
            <Row mb="0">
              <Column>
                <Button
                  fullWidth
                  type="button"
                  handleClick={() => handleClosePopover()}
                >
                  {t("BUTTONS.close")}
                </Button>
              </Column>
              <Column>
                {isChanged && (
                  <Button fullWidth type="button" handleClick={handleSave}>
                    {t("BUTTONS.save")}
                  </Button>
                )}
              </Column>
            </Row>
          </StyledPaddingDiv>
        </StyledPopoverFixedWidth>
      </GenericPopover>
    </>
  );
};

export default ManageProjectContracts;
