import { create } from "zustand";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { ProjectContractInterface } from "../interfaces/ProjectContractInterface";

export interface ProjectContractsState
  extends CrudStateInterface<ProjectContractInterface> {}

const useProjectContractsStore = create<ProjectContractsState>((set, get) => ({
  ...defaultStateCrud<ProjectContractInterface>(set, get),
}));

export default useProjectContractsStore;
