import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useProjects from "../../api/projects/useProjects";
import CompanyUserSelection, {
  CompanyUserSelectionConfigurationInterface,
} from "../Projects/CompanyUserSelection";
import { ProjectMasterDataConfigurationInterface } from "../Projects/ProjectMasterDataForm";
import { Button, Card, Headline, Space } from "../../components";
import SetEntityCapabilities from "./SetEntityCapabilities";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { FormikValues } from "formik";
import useFormToCapabilityAdapter from "./hooks/useFormToCapabilityAdapter";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import { useNavigate } from "react-router-dom";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";
import { ProjectTypeInterface } from "../../interfaces/ProjectTypeInterface";
import EntityMasterDataForm from "../Projects/EntityMasterDataForm";

export interface CreateEntityConfigurationInterface
  extends CompanyUserSelectionConfigurationInterface,
    ProjectMasterDataConfigurationInterface {
  projectCapabilities: ProjectCapabilityInterface[];
}

interface CreateEntityProps {
  type: ProjectTypeInterface;
}

const CreateEntity = ({ type }: CreateEntityProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [configuration, setConfiguration] =
    useState<CreateEntityConfigurationInterface>({
      companyId: null,
      userId: null,
      name: "",
      description: "",
      projectCapabilities: [],
      image: "",
      year: new Date().getFullYear(),
      price: 0,
    });

  const { adapt } = useFormToCapabilityAdapter();
  const { create: createProject } = useProjects();

  const handleNext = (
    updatedConfiguration:
      | CompanyUserSelectionConfigurationInterface
      | ProjectMasterDataConfigurationInterface
  ): void => {
    setConfiguration({
      ...configuration,
      ...updatedConfiguration,
    });
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = (): void => setActiveStep(activeStep - 1);

  const handleSaveEntity = async ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }): Promise<void> => {
    const projectCapabilities = await adapt({
      formData,
      enabledCategories,
      files,
    });

    setConfiguration({
      ...configuration,
      projectCapabilities,
    });

    if (configuration.companyId === null) {
      return;
    }

    await createProject({
      companyId: configuration.companyId,
      mandate: ProjectMandateEnum.ENTITY,
      userId: configuration.userId ?? undefined,
      name: configuration.name,
      description: configuration.description,
      categories: enabledCategories.map((category) => ({
        name: category.name,
      })),
      types: [type],
      projectCapabilities,
      projectMasterData: {
        price: configuration.price,
        year: configuration.year,
        image: configuration.image,
        video: configuration.video,
      },
    });

    setActiveStep(4);
  };

  const handleFinishClick = (): void => {
    switch (type.name) {
      case "default":
        navigate("/packing-machine/machines");
        break;
      case "usedMachine":
        navigate("/used-machine/machines");
        break;
      case "contractManufacturer":
        navigate("/contract-manufacturer/machines");
        break;
      case "packaging":
        navigate("/packaging/entities");
        break;
      case "client":
      default:
        break;
    }
  };

  return (
    <>
      {activeStep === 1 && (
        <>
          <Headline>{t("CREATE_ENTITY.step-1")}</Headline>
          <CompanyUserSelection
            // @ts-expect-error @ts-ignore todo: fix
            filter={type.name}
            handleNext={handleNext}
            values={{
              companyId: configuration.companyId,
              userId: configuration.userId,
            }}
          />
        </>
      )}
      {activeStep === 2 && (
        <>
          <Headline>{t("CREATE_ENTITY.step-2")}</Headline>
          <EntityMasterDataForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            values={{
              name: configuration.name,
              description: configuration.description,
              price: configuration.price ?? 0,
              image: configuration.image ?? "",
              video: configuration.video ?? "",
              year: configuration.year ?? new Date().getFullYear(),
            }}
            handleChange={(updatedConfiguration) => {
              setConfiguration({
                ...configuration,
                ...updatedConfiguration,
              });
            }}
          />
        </>
      )}
      {activeStep === 3 && (
        <>
          <Headline>{t("CREATE_ENTITY.step-3")}</Headline>
          <SetEntityCapabilities
            action="create"
            handleSaveEntity={handleSaveEntity}
          />
        </>
      )}
      {activeStep === 4 && (
        <>
          <Card color="success">{t("CREATE_ENTITY.success")}</Card>
          <Space size={2} />
          <Button type="button" handleClick={handleFinishClick}>
            {t("CREATE_ENTITY.back")}
          </Button>
        </>
      )}
    </>
  );
};

export default CreateEntity;
