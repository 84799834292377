import React, { ReactElement } from "react";
import { Tag } from "../../components";
import { useTranslation } from "react-i18next";
import { ContractStateType } from "../../interfaces/ContractStateType";

const ContractStateTag = ({
  state,
}: {
  state: ContractStateType;
}): ReactElement => {
  const { t } = useTranslation();

  switch (state) {
    case "OPEN":
      return <Tag variant="danger">{t("CONTRACT_STATES.OPEN")}</Tag>;
    case "ON_HOLD":
      return <Tag variant="default">{t("CONTRACT_STATES.ON_HOLD")}</Tag>;
    case "CLOSED":
      return <Tag variant="success">{t("CONTRACT_STATES.CLOSED")}</Tag>;
  }

  return <></>;
};

export default ContractStateTag;
