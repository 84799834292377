import useApi from "../useApi";
import { CompanyInterface } from "../../interfaces/CompanyInterface";

interface UseGetCompaniesInterface {
  getAll: () => Promise<CompanyInterface[]>;
}

const useGetCompanies = (): UseGetCompaniesInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<CompanyInterface[]> => {
    const { data, status } = await get(`/company`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetCompanies;
