import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useProjectCategories from "../../api/projectCategories/useProjectCategories";
import useSearchConfiguration from "../Matching/hooks/useSearchConfiguration";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { FormikValues } from "formik";
import { Card, Checkbox, Column, Row, Space } from "../../components";
import StyledGroup from "../../components/styles/StyledGroup";
import MatchingForm from "../Matching/MatchingForm";
import { SearchResultsWrapper } from "../index";
import useHandleSearch from "../Matching/hooks/useHandleSearch";

interface SetEntityCapabilitiesProps {
  handleSaveEntity: ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }) => Promise<void> | void;
  initialValues?: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    enabledFieldIds: string[];
  };
  action: "edit" | "create";
  isSearch?: boolean;
  projectId?: string;
}

const SetEntityCapabilities = ({
  handleSaveEntity,
  initialValues,
  action,
  isSearch,
  projectId,
}: SetEntityCapabilitiesProps): ReactElement => {
  const { t } = useTranslation();
  const { getAll } = useProjectCategories();
  const { isEnabled, toggle, enableMany } = useSearchConfiguration();

  const [projectCategories, setProjectCategories] = useState<
    ProjectCategoriesColumn[]
  >([]);

  const { searchResults, values, files, handleSearch } = useHandleSearch();

  useEffect(() => {
    if (initialValues === undefined) {
      return;
    }
    enableMany(
      initialValues.enabledCategories.map(
        (enabledCategory) => enabledCategory.id ?? ""
      )
    );
  }, [initialValues]);

  useEffect(() => {
    const getProjectCategories = async (): Promise<void> => {
      const projectCategoriesData = await getAll();
      setProjectCategories(
        projectCategoriesData.map((projectCategory) => ({
          ...projectCategory,
          edit: true,
        }))
      );
    };

    void getProjectCategories();
  }, []);

  const handleSaveSearchWithParams = async ({
    values: updatedValues,
    files: updatedFiles,
  }: {
    values: FormikValues;
    files: Record<string, File | null>;
  }): Promise<void> => {
    if (handleSaveEntity === undefined) {
      return;
    }

    const enabledCategories: ProjectCategoriesColumn[] = [];
    projectCategories.forEach((projectCategory) => {
      if (!isEnabled(projectCategory.id)) {
        return;
      }
      enabledCategories.push(projectCategory);
    });

    await handleSaveEntity({
      enabledCategories,
      formData: updatedValues,
      files: updatedFiles,
    });
  };

  const handleSaveSearch = async (): Promise<void> => {
    if (handleSaveEntity === undefined) {
      return;
    }

    const enabledCategories: ProjectCategoriesColumn[] = [];
    projectCategories.forEach((projectCategory) => {
      if (!isEnabled(projectCategory.id)) {
        return;
      }
      enabledCategories.push(projectCategory);
    });

    await handleSaveEntity({ enabledCategories, formData: values, files });
  };

  const handleSave = async ({
    values: updatedValues,
    files: updatedFiles,
  }: {
    values: FormikValues;
    files?: Record<string, File | null>;
  }): Promise<void> => {
    const { values: parsedValues, files: parsedFiles } = await handleSearch({
      values: updatedValues,
      files: updatedFiles,
    });
    if (parsedFiles === undefined) {
      return;
    }
    await handleSaveSearchWithParams({
      values: parsedValues,
      files: parsedFiles,
    });
  };

  return (
    <>
      <Card>
        <StyledGroup>
          {projectCategories.map(({ id, name }) => (
            <React.Fragment key={JSON.stringify(id)}>
              <Checkbox
                name={name}
                checked={isEnabled(id)}
                label={t(`PROJECT_CATEGORIES.${name}`)}
                handleChange={() => toggle(id)}
              />
            </React.Fragment>
          ))}
        </StyledGroup>
      </Card>
      <Space size={2} />
      <Row>
        <Column>
          {projectCategories.length > 0 && (
            <MatchingForm
              isSearch={isSearch !== undefined ? isSearch : false}
              buttonText={
                action === "create"
                  ? t("BUTTONS.create-entity")
                  : isSearch !== undefined && isSearch
                  ? t("BUTTONS.redo-search")
                  : t("BUTTONS.save-changes")
              }
              buttonRedoText={
                action === "create"
                  ? t("BUTTONS.create-entity")
                  : isSearch !== undefined && isSearch
                  ? t("BUTTONS.redo-search")
                  : t("BUTTONS.save-changes")
              }
              toggle={toggle}
              isEnabled={isEnabled}
              projectCategories={projectCategories}
              handleSearch={
                isSearch === undefined || !isSearch ? handleSave : handleSearch
              }
              hasSearched={searchResults.searched}
              initialValues={initialValues?.formData}
            />
          )}
        </Column>
        {isSearch !== undefined && isSearch && (
          <>
            <SearchResultsWrapper
              searchResults={searchResults}
              handleSaveSearch={handleSaveSearch}
              isShowSaveProject={true}
              projectId={projectId}
            />
          </>
        )}
      </Row>
    </>
  );
};

export default SetEntityCapabilities;
