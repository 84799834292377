import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";
import useCompanies from "../../api/companies/useCompanies";
import usePublicProfileBlocks from "../../api/publicProfileBlocks/usePublicProfileBlocks";
import {
  Column,
  ContentBlock,
  Headline,
  Row,
  Space,
  Table,
} from "../../components";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";
import { useTranslation } from "react-i18next";
import useUsers from "../../api/users/useUsers";
import { UserInterface } from "../../interfaces/UserInterface";
import useUserListColumns from "./hooks/useUserListColumns";
import useProjects from "../../api/projects/useProjects";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import useEntityListColumns from "./hooks/useEntityListColumns";
import EntityModal from "../EntityModal/EntityModal";

const PublicProfile = (): ReactElement => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const { columns: userColumns } = useUserListColumns();
  const { columns: entityColumns } = useEntityListColumns();

  const { getById: getCompanyById } = useCompanies();
  const { getByCompany: getUsersByCompany } = useUsers();
  const { getByCompanyId: getPublicProfileBlocksByCompanyId } =
    usePublicProfileBlocks();
  const { getEntitiesByCompanyId } = useProjects();

  const [company, setCompany] = useState<CompanyInterface | null>(null);
  const [profileBlocks, setProfileBlocks] = useState<
    PublicProfileBlockInterface[]
  >([]);
  const [users, setUsers] = useState<UserInterface[] | null>(null);
  const [entities, setEntities] = useState<ProjectInterface[] | null>(null);
  const [entityModalConfiguration, setEntityModalConfiguration] = useState<{
    isOpen: boolean;
    id?: string;
  }>({ isOpen: false });

  // todo Ansprechpartner, Leistungsspektrum
  useEffect(() => {
    if (companyId === undefined) {
      return;
    }
    void getCompanyById(companyId).then((companyData) => {
      setCompany(companyData);
    });
    void getPublicProfileBlocksByCompanyId(companyId).then(
      (profileBlockData) => {
        setProfileBlocks(profileBlockData);
      }
    );
    void getUsersByCompany(companyId).then((userData) => {
      setUsers(userData);
    });
    void getEntitiesByCompanyId(companyId).then((projectData) => {
      setEntities(projectData);
    });
  }, [companyId]);

  if (companyId === undefined) {
    return <></>;
  }

  return (
    <>
      {entityModalConfiguration.id !== undefined && (
        <EntityModal
          isOpen={entityModalConfiguration.isOpen}
          handleClose={() => setEntityModalConfiguration({ isOpen: false })}
          id={entityModalConfiguration.id}
        />
      )}
      {company?.name !== undefined && (
        <Headline type="h1" mb="3rem">
          {company.name}
        </Headline>
      )}
      <Headline>{t("COMPANY.data")}</Headline>
      {company?.address !== undefined && (
        <>
          <Row mb="0">
            <Column size={1}>{t("COMPANY.address")}</Column>
            <Column>{company?.address}</Column>
          </Row>
        </>
      )}
      {company?.country !== undefined && (
        <>
          <Row mb="0">
            <Column size={1}>{t("COMPANY.country")}</Column>
            <Column>{company?.country}</Column>
          </Row>
        </>
      )}
      {company?.phone !== undefined && company.phone !== "" && (
        <>
          <Row mb="0">
            <Column size={1}>{t("COMPANY.phone")}</Column>
            <Column>{company?.phone}</Column>
          </Row>
        </>
      )}
      <Space size={2} />
      {profileBlocks.map((profileBlock) => (
        <React.Fragment key={profileBlock.id}>
          <Headline mb="1rem">{profileBlock.name}</Headline>
          <ContentBlock
            content={profileBlock.content}
            isShowImageAs="img"
            type={profileBlock.type ?? PublicProfileBlockTypeEnum.HTML}
          />
          <Space size={2} />
        </React.Fragment>
      ))}
      <Space size={2} />
      <Headline>{t("COMPANY.contact-person")}</Headline>
      <Table<UserInterface>
        data={users ?? []}
        columns={userColumns}
        isLoading={users === null}
      />
      <Space size={2} />
      <Headline>{t("ENTITIES.list")}</Headline>
      <Table<ProjectInterface>
        data={entities ?? []}
        columns={entityColumns}
        isLoading={entities === null}
        rowButton={{
          label: "Details",
          handleClick: (data) => {
            setEntityModalConfiguration({
              isOpen: true,
              id: data.id,
            });
          },
        }}
      />
    </>
  );
};

export default PublicProfile;
