import React, { ReactElement, ReactNode, useState } from "react";
import { Column, Headline, Row } from "../components";
import { ChevronDownIcon, ChevronRightIcon } from "../icons";
import styled from "styled-components";

const ToggleLink = styled.a`
  display: inline-block;
  cursor: pointer;
  margin-left: 1rem;
`;
const ToggleIcon = styled.div`
  margin-right: 1rem;
  display: inline-block;
`;

interface HeadlineToggleProps {
  children: ReactNode;
  headline: ReactNode;
  isInitiallyOpened?: boolean;
}
const HeadlineToggle = ({
  children,
  headline,
  isInitiallyOpened,
}: HeadlineToggleProps): ReactElement => {
  const [isOpened, setIsOpened] = useState<boolean>(isInitiallyOpened ?? false);

  return (
    <>
      {" "}
      <Headline mb={isOpened ? "2rem" : "0"}>
        <ToggleLink onClick={() => setIsOpened(!isOpened)}>
          <Row mb="0">
            <Column size="shrink" padding="0">
              <ToggleIcon>
                {isOpened && <ChevronDownIcon />}
                {!isOpened && <ChevronRightIcon />}
              </ToggleIcon>
            </Column>
            <Column padding="0">{headline}</Column>
          </Row>
        </ToggleLink>
      </Headline>
      {isOpened && <>{children}</>}
    </>
  );
};

export default HeadlineToggle;
