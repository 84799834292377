import React, { ReactElement, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { Button, Column, Headline, Row, Space, Text } from "../../components";
import { useTranslation } from "react-i18next";
import Scrollable from "../../components/Scrollable";
import useProjects from "../../api/projects/useProjects";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import useProjectCategories from "../../api/projectCategories/useProjectCategories";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import useFormGroups from "../../api/formGroups/useFormGroups";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import isFormGroupHasCapability from "../../helpers/isFormGroupHasCapability";
import useCapabilityValues from "../../hooks/useCapabilityValues";

const FormGroupDisplay = ({
  id,
  projectCapabilities,
}: {
  id: string;
  projectCapabilities: ProjectCapabilityInterface[];
}): ReactElement => {
  const { getById } = useFormGroups();
  const { getCapabilityValue } = useCapabilityValues();

  const [formGroup, setFormGroup] = useState<ProjectCategoryInterface | null>(
    null
  );
  const [filteredProjectCapabilities, setFilteredProjectCapabilities] =
    useState<ProjectCapabilityInterface[] | null>(null);

  useEffect(() => {
    void getById(id).then((formGroupData) => {
      if (
        formGroupData === undefined ||
        formGroupData?.capabilities === undefined ||
        !isFormGroupHasCapability({
          formGroup: formGroupData,
          projectCapabilities,
        })
      ) {
        setFormGroup(null);
        return;
      }

      setFormGroup(formGroupData);
      setFilteredProjectCapabilities(
        projectCapabilities.filter(
          (projectCapability) =>
            formGroupData.capabilities?.find(
              (formGroupCapability) =>
                formGroupCapability.id === projectCapability.capabilityId
            ) !== undefined
        )
      );
    });
  }, [id, projectCapabilities]);

  if (formGroup === null || filteredProjectCapabilities === null) {
    return <></>;
  }
  // todo: Add capabilityGroup??
  return (
    <>
      {formGroup.name}
      <br />
      {filteredProjectCapabilities.map((filteredProjectCapability) => (
        <React.Fragment key={filteredProjectCapability.id}>
          {getCapabilityValue(filteredProjectCapability) !== null && (
            <>
              {filteredProjectCapability.capability?.name}:{" "}
              {typeof filteredProjectCapability.value === "string" && (
                <>
                  {getCapabilityValue(filteredProjectCapability)}
                  <br />
                </>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const ProjectCategoryHeadline = ({
  id,
  projectCapabilities,
}: {
  id: string;
  projectCapabilities: ProjectCapabilityInterface[];
}): ReactElement => {
  const { getById } = useProjectCategories();
  const { t } = useTranslation();

  const [projectCategory, setProjectCategory] =
    useState<ProjectCategoryInterface | null>(null);

  useEffect(() => {
    void getById(id).then((projectCategoryData) => {
      if (projectCategoryData === undefined) {
        setProjectCategory(null);
        return;
      }

      setProjectCategory(projectCategoryData);
    });
  }, [id]);

  if (projectCategory === null) {
    return <></>;
  }

  return (
    <>
      <Headline>{t(`PROJECT_CATEGORIES.${projectCategory.name}`)}</Headline>
      {projectCategory.formGroups?.map((formGroup) => (
        <React.Fragment key={formGroup.id}>
          {formGroup.id !== undefined && (
            <FormGroupDisplay
              id={formGroup.id}
              projectCapabilities={projectCapabilities}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

interface EntityModalProps {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
}
const EntityModal = ({
  isOpen,
  handleClose,
  id,
}: EntityModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getById } = useProjects();

  const [project, setProject] = useState<ProjectInterface | null>(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    void getById(id).then((projectData) => {
      console.log(2, projectData);
      setProject(projectData);
    });
  }, [id, isOpen]);

  if (project === null) {
    return <></>;
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        {project?.name !== undefined && (
          <Headline type="h1" mb="3rem">
            {project.name}
          </Headline>
        )}
        <Scrollable height={"50vh"}>
          {project.description !== undefined && (
            <>
              <Headline>{t("PROJECT.description")}</Headline>
              <Text>{project.description}</Text>
              <Space size={2} />
            </>
          )}
          {project.categories?.map((category) => (
            <>
              {category.id !== undefined &&
                project.projectCapabilities !== undefined && (
                  <ProjectCategoryHeadline
                    id={category.id}
                    projectCapabilities={project.projectCapabilities}
                  />
                )}
            </>
          ))}
        </Scrollable>
        <Space size={2} />
        <Row mb="0">
          <Column>
            <Button
              fullWidth
              variant="light"
              type="button"
              handleClick={handleClose}
            >
              {t("BUTTONS.close")}
            </Button>
          </Column>
        </Row>
      </Modal>
    </>
  );
};

export default EntityModal;
