import useApi from "../useApi";
import { CapabilityConfigurationInterface } from "../../interfaces/CapabilityConfigurationInterface";

interface UseGetCapabilityConfigurationsInterface {
  getAll: () => Promise<CapabilityConfigurationInterface[]>;
}

const useGetCapabilityConfigurations =
  (): UseGetCapabilityConfigurationsInterface => {
    const { get } = useApi();

    const getAll = async (): Promise<CapabilityConfigurationInterface[]> => {
      const { data, status } = await get(`/capability-configuration`);

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { getAll };
  };

export default useGetCapabilityConfigurations;
