import { UserInterface } from "../interfaces/UserInterface";
import useApi from "./useApi";

interface UseUpdateUserInterface {
  update: (user: UserInterface) => Promise<UserInterface | null>;
}

const useUpdateUser = (): UseUpdateUserInterface => {
  const { put } = useApi();

  const update = async (user: UserInterface): Promise<UserInterface | null> => {
    if (user.id === undefined) {
      return null;
    }
    const { status, data: updatedUser } = await put(`/user/${user.id}`, {
      ...user,
    });

    if (status === 200) {
      return updatedUser;
    }

    return null;
  };

  return { update };
};

export default useUpdateUser;
