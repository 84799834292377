import React, { ReactElement, useEffect, useState } from "react";
import useProjectCategories from "../../api/projectCategories/useProjectCategories";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import { Button } from "../../components";
import { useTranslation } from "react-i18next";

interface ProjectCategoryFilterProps {
  handleFilterChange: (
    filter: ProjectCategoryInterface | null
  ) => void | Promise<void>;
  activeFilter: ProjectCategoryInterface | null;
}
const ProjectCategoryFilter = ({
  handleFilterChange,
  activeFilter,
}: ProjectCategoryFilterProps): ReactElement => {
  const { t } = useTranslation();

  const { getAll, projectCategories } = useProjectCategories();
  const [status, setStatus] = useState<RequestStatusType>("initial");

  useEffect(() => {
    if (status !== "initial") {
      return;
    }
    void getAll().then(() => {
      setStatus("success");
    });

    setStatus("loading");
  }, [status]);

  return (
    <>
      <Button
        type="button"
        variant={activeFilter === null ? "default" : "light"}
        handleClick={() => handleFilterChange(null)}
      >
        {t(`PROJECT_CATEGORIES.none`)}
      </Button>{" "}
      {projectCategories.map((projectCategory) => (
        <React.Fragment
          key={
            projectCategory.id !== undefined
              ? projectCategory.id
              : projectCategory.name
          }
        >
          <Button
            type="button"
            variant={
              activeFilter?.id === projectCategory.id ? "default" : "light"
            }
            handleClick={() => handleFilterChange(projectCategory)}
          >
            {t(`PROJECT_CATEGORIES.${projectCategory.name ?? ""}`)}
          </Button>{" "}
        </React.Fragment>
      ))}
    </>
  );
};

export default ProjectCategoryFilter;
