export enum FormGroupRequiredTypeEnum {
  ALL = "ALL",
  EXCLUSIVE_ONE = "EXCLUSIVE_ONE",
  ONE_OR_MORE = "ONE_OR_MORE",
}

export type FormGroupRequiredType =
  | FormGroupRequiredTypeEnum.ALL
  | FormGroupRequiredTypeEnum.EXCLUSIVE_ONE
  | FormGroupRequiredTypeEnum.ONE_OR_MORE;
