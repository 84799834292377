import { useEffect, useState } from "react";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseFilterFormGroupsProps {
  projectCategoryFilter: ProjectCategoryInterface | null;
  formGroups: FormGroupInterface[];
}

interface UseFilterFormGroupsInterface {
  filteredFormGroups: FormGroupInterface[];
}

const sortForMandatory = (
  groups: FormGroupInterface[]
): FormGroupInterface[] => {
  const sorted = groups.map((formGroup, position) => ({ position, formGroup }));
  sorted.sort((formGroupA, formGroupB) => {
    if (!formGroupA.formGroup.isMandatory) {
      return 1;
    }
    return formGroupA.position - formGroupB.position;
  });

  return sorted.map(({ formGroup }) => formGroup);
};

const useFilterFormGroups = ({
  projectCategoryFilter,
  formGroups,
}: UseFilterFormGroupsProps): UseFilterFormGroupsInterface => {
  const [filteredFormGroups, setFilteredFormGroups] = useState<
    FormGroupInterface[]
  >([]);

  useEffect(() => {
    if (projectCategoryFilter === null) {
      setFilteredFormGroups(sortForMandatory(formGroups));
      return;
    }
    setFilteredFormGroups(
      sortForMandatory(
        formGroups.filter((formGroup) => {
          return (
            formGroup.projectCategories?.find(
              (projectCategory) =>
                projectCategory.id === projectCategoryFilter.id
            ) !== undefined
          );
        })
      )
    );
  }, [formGroups, projectCategoryFilter]);

  return { filteredFormGroups };
};

export default useFilterFormGroups;
