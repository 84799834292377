import { CapabilityInterface } from "../../interfaces/CapabilityInterface";
import useGetCapabilities from "./useGetCapabilities";
import useCapabilityStore from "../../stores/useCapabilityStore";
import useGetCapability from "./useGetCapability";
import useCreateCapability from "./useCreateCapability";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseCapabilitiesInterface {
  getAll: () => Promise<CapabilityInterface[]>;
  getById: (id: string) => Promise<CapabilityInterface | null>;
  getByName: (name: string) => Promise<CapabilityInterface | null>;
  create: (capability: CapabilityInterface) => Promise<CapabilityInterface>;
  addFormGroup: ({
    capabilityId,
    formGroup,
  }: {
    capabilityId: string;
    formGroup: FormGroupInterface;
  }) => Promise<void>;
  removeFormGroup: ({
    capabilityId,
    formGroup,
  }: {
    capabilityId: string;
    formGroup: FormGroupInterface;
  }) => Promise<void>;
  capabilities: CapabilityInterface[];
}

const useCapabilities = (): UseCapabilitiesInterface => {
  const add = useCapabilityStore((state) => state.add);
  const set = useCapabilityStore((state) => state.set);
  const gotAll = useCapabilityStore((state) => state.gotAll);
  const stateGetAll = useCapabilityStore((state) => state.getAll);
  const stateUpdate = useCapabilityStore((state) => state.update);
  const stateGetById = useCapabilityStore((state) => state.getById);
  const stateGetByName = useCapabilityStore((state) => state.getByName);
  const setGotAll = useCapabilityStore((state) => state.setGotAll);
  const capabilities = useCapabilityStore((state) => state.data);

  const { getAll: apiGetAll } = useGetCapabilities();
  const { create: apiCreate } = useCreateCapability();
  const { getById: apiGetById } = useGetCapability();

  const getAll = async (): Promise<CapabilityInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    setGotAll();
    const capabilities = await apiGetAll();

    set(capabilities);

    return capabilities;
  };

  const getById = async (id: string): Promise<CapabilityInterface | null> => {
    let capability = stateGetById(id);

    if (capability !== null) {
      return capability;
    }

    capability = await apiGetById(id);

    if (capability === null) {
      return null;
    }

    add(capability);
    return capability;
  };

  const addFormGroup = async ({
    capabilityId,
    formGroup,
  }: {
    capabilityId: string;
    formGroup: FormGroupInterface;
  }): Promise<void> => {
    if (formGroup.id === undefined) {
      return;
    }

    const capability = stateGetById(capabilityId);
    if (capability === null) {
      return;
    }
    stateUpdate({
      id: capabilityId,
      updatedDataSet: {
        ...capability,
        formGroups: [...(capability.formGroups ?? []), formGroup],
      },
    });
  };

  const removeFormGroup = async ({
    capabilityId,
    formGroup,
  }: {
    capabilityId: string;
    formGroup: FormGroupInterface;
  }): Promise<void> => {
    if (formGroup.id === undefined) {
      return;
    }

    const capability = stateGetById(capabilityId);
    if (capability === null) {
      return;
    }
    stateUpdate({
      id: capabilityId,
      updatedDataSet: {
        ...capability,
        formGroups:
          capability.formGroups === undefined
            ? []
            : capability.formGroups.filter(
                (stateFormGroup) => stateFormGroup.id !== formGroup.id
              ),
      },
    });
  };

  const getByName = async (
    name: string
  ): Promise<CapabilityInterface | null> => {
    if (stateGetByName(name) !== undefined) {
      return stateGetByName(name);
    }
    await getAll();
    return stateGetByName(name);
  };

  const create = async (
    capability: CapabilityInterface
  ): Promise<CapabilityInterface> => {
    const created = await apiCreate(capability);
    if (created !== null) {
      add(created);
    }

    return created;
  };

  return {
    getAll,
    capabilities,
    getById,
    getByName,
    create,
    addFormGroup,
    removeFormGroup,
  };
};

export default useCapabilities;
