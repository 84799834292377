import React, { ReactNode } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledCard = styled.div<{ variant: ColorType; floating: boolean }>`
  border-radius: 2rem;
  background: ${({ variant, theme }) => theme.colors[variant]};
  ${({ theme, variant }) =>
    variant === "success" ? `color: ${theme.colors.white};` : ""}
  padding: 3rem;
  ${({ floating }) => (floating ? "height: 100%;" : "")}
`;

type ColorType = "light" | "blue" | "purple" | "success";

interface CardProps {
  children: ReactNode;
  color?: ColorType;
  floating?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, color = "light", floating = true }, ref) => {
    return (
      <StyledCard variant={color} floating={floating} ref={ref}>
        {children}
      </StyledCard>
    );
  }
);
Card.displayName = "Card";

export default motion(Card);
