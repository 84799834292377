import styled from "styled-components";

const StyledTag = styled.span<{ variant?: "success" | "danger" | "default" }>`
  border-radius: 1rem;
  background: ${({ theme, variant }) =>
    theme.colors[
      variant !== undefined && variant !== "default" ? variant : "blue"
    ]};
  padding: 0 1rem;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  color: ${({ theme, variant }) =>
    variant !== undefined && (variant === "danger" || variant === "success")
      ? theme.colors.white
      : "inherit"};

  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
`;

export default StyledTag;
