import React, { ReactElement } from "react";
import { FormFieldInterface } from "../../components";
import { useTranslation } from "react-i18next";
import MasterDataForm from "./MasterDataForm";

export interface ProjectMasterDataConfigurationInterface {
  name: string;
  description: string;
  image?: string;
  video?: string;
  year?: number;
  price?: number;
}

interface ProjectMasterDataFormProps {
  handleNext: ({
    name,
    description,
  }: ProjectMasterDataConfigurationInterface) => void;
  handleChange: ({
    name,
    description,
  }: ProjectMasterDataConfigurationInterface) => void;
  handlePrevious: () => void;
  values: ProjectMasterDataConfigurationInterface;
}
const ProjectMasterDataForm = ({
  handleNext,
  handleChange,
  handlePrevious,
  values,
}: ProjectMasterDataFormProps): ReactElement => {
  const { t } = useTranslation();

  const fields: FormFieldInterface[] = [
    {
      id: "name",
      label: t("CREATE_PROJECT.name"),
      type: "text",
    },
    {
      id: "description",
      label: t("CREATE_PROJECT.description"),
      type: "text",
    },
  ];

  return (
    <>
      <MasterDataForm<{ name: string; description: string }>
        fields={fields}
        initialValues={{
          name: values.name,
          description: values.description,
        }}
        handleNext={async ({ name, description }): Promise<void> => {
          await handleNext({ name, description });
        }}
        handleChange={(formValues) => {
          handleChange(formValues);
        }}
        handlePrevious={handlePrevious}
      />
    </>
  );
};

export default ProjectMasterDataForm;
