import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableDataInterface } from "../../interfaces/SearchableDataInterface";
import CompanySelection from "../CompanySelection";
import UserSelection from "../UserSelection";
import { Button, Right } from "../../components";
import { CompanyTypeType } from "../../interfaces/CompanyTypeType";

interface CompanyUserSelectionProps {
  handleNext: (
    configuration: CompanyUserSelectionConfigurationInterface
  ) => void;
  values: CompanyUserSelectionConfigurationInterface;
  filter?: CompanyTypeType;
}

export interface CompanyUserSelectionConfigurationInterface {
  userId: string | null;
  companyId: string | null;
}
const CompanyUserSelection = ({
  handleNext,
  values,
  filter = "client",
}: CompanyUserSelectionProps): ReactElement => {
  const { t } = useTranslation();

  const [configuration, setConfiguration] =
    useState<CompanyUserSelectionConfigurationInterface>({
      companyId: values.companyId,
      userId: values.userId,
    });

  useEffect(() => {
    if (values.companyId === null && values.userId === null) {
      return;
    }
    setConfiguration(values);
  }, [values]);

  const handleCompanySelection = (
    selected: SearchableDataInterface | null
  ): void => {
    if (selected === null || selected.value === "createNew") {
      setConfiguration({
        ...configuration,
        companyId: null,
      });
      return;
    }

    setConfiguration({
      ...configuration,
      companyId: selected.value,
    });
  };

  const handleUserSelection = (
    selected: SearchableDataInterface | null
  ): void => {
    if (selected === null || selected.value === "createNew") {
      setConfiguration({
        ...configuration,
        userId: null,
      });
      return;
    }

    setConfiguration({
      ...configuration,
      userId: selected.value,
    });
  };

  const handleNextButtonClick = (): void => {
    handleNext(configuration);
  };

  return (
    <>
      <CompanySelection
        handleSelected={handleCompanySelection}
        initialValue={configuration.companyId}
        filter={filter}
      />
      {configuration.companyId !== null && (
        <UserSelection
          companyId={configuration.companyId}
          handleSelected={handleUserSelection}
          initialValue={configuration.userId}
        />
      )}
      {configuration.companyId !== null && configuration.userId === null && (
        <Right>
          <Button handleClick={handleNextButtonClick}>
            {t("CREATE_PROJECT.continue-without-user")}
          </Button>
        </Right>
      )}
      {configuration.companyId !== null && configuration.userId !== null && (
        <Right>
          <Button handleClick={handleNextButtonClick}>
            {t("FORMS.continue")}
          </Button>
        </Right>
      )}
    </>
  );
};

export default CompanyUserSelection;
