import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import useProjectContractsStore from "../../stores/useProjectContractsStore";
import useCreateProjectContract from "./useCreateProjectContract";
import useGetProjectContract from "./useGetProjectContract";
import useUpdateProjectContract from "./useUpdateProjectContract";
import useGetProjectContractsByProjectId from "./useGetProjectContractsByProjectId";
import useDeleteProjectContract from "./useDeleteProjectContract";
import useProjects from "../projects/useProjects";

interface UseProjectContractsInterface {
  getById: (id: string) => Promise<ProjectContractInterface | null>;
  getByProjectId: (
    projectId: string
  ) => Promise<ProjectContractInterface[] | null>;
  create: (
    projectContract: ProjectContractInterface
  ) => Promise<ProjectContractInterface>;
  update: (
    projectContract: ProjectContractInterface
  ) => Promise<ProjectContractInterface | null>;
  projectContracts: ProjectContractInterface[];
  deleteById: (id: string) => Promise<boolean>;
}

const useProjectContracts = (): UseProjectContractsInterface => {
  const { removeContract, addContract, updateContract } = useProjects();
  const add = useProjectContractsStore((state) => state.add);
  const remove = useProjectContractsStore((state) => state.remove);
  const stateUpdate = useProjectContractsStore((state) => state.update);
  const stateGetById = useProjectContractsStore((state) => state.getById);
  const projectContracts = useProjectContractsStore((state) => state.data);
  const { create: apiCreate } = useCreateProjectContract();
  const { deleteById: apiDeleteById } = useDeleteProjectContract();
  const { update: apiUpdate } = useUpdateProjectContract();
  const { getById: apiGetById } = useGetProjectContract();
  const { getByProjectId: apiGetByProjectId } =
    useGetProjectContractsByProjectId();

  const getByProjectId = async (
    projectId: string
  ): Promise<ProjectContractInterface[] | null> => {
    const projectContract = await apiGetByProjectId(projectId);

    if (projectContract === null) {
      return null;
    }

    add(projectContract);
    return projectContract;
  };

  const deleteById = async (id: string): Promise<boolean> => {
    const projectContract = stateGetById(id);
    const removed = await apiDeleteById(id);

    remove(id);
    if (projectContract !== null) {
      await removeContract(projectContract);
    }

    return removed;
  };

  const getById = async (
    id: string
  ): Promise<ProjectContractInterface | null> => {
    let projectContract = stateGetById(id);

    if (projectContract !== null) {
      return projectContract;
    }

    projectContract = await apiGetById(id);

    if (projectContract === null) {
      return null;
    }

    add(projectContract);
    return projectContract;
  };

  const create = async (
    projectContract: ProjectContractInterface
  ): Promise<ProjectContractInterface> => {
    const created = await apiCreate(projectContract);

    if (created === null) {
      return created;
    }

    add(created);

    await addContract(created);

    return created;
  };

  const update = async (
    projectContract: ProjectContractInterface
  ): Promise<ProjectContractInterface | null> => {
    const updated = await apiUpdate(projectContract);
    if (updated !== null && projectContract.id !== undefined) {
      await updateContract(updated);
      stateUpdate({ id: projectContract.id, updatedDataSet: updated });
    }

    return updated;
  };

  return {
    getById,
    getByProjectId,
    create,
    deleteById,
    update,
    projectContracts,
  };
};
export default useProjectContracts;
