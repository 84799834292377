import React, { ReactElement, useState } from "react";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { useTranslation } from "react-i18next";
import { Button, Card, Column, Space } from "../../components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useCreateSpecificationInvitationAndContract from "../../api/specificationInvitations/useCreateSpecificationInvitationAndContract";
import { RequestStatusType } from "../../interfaces/RequestStatusType";

const StyledFullSizeDiv = styled.div`
  display: flex;

  > * {
    flex: 1;
  }
`;

interface SendButtonInterface {
  projectId: string;
  companyId?: string;
}
const SendButton = ({
  projectId,
  companyId,
}: SendButtonInterface): ReactElement => {
  const { create } = useCreateSpecificationInvitationAndContract();
  const { t } = useTranslation();

  const [status, setStatus] = useState<RequestStatusType>("initial");

  const sendSpecificationSheet = async (companyId: string): Promise<void> => {
    if (companyId === undefined || projectId === undefined) {
      return;
    }

    setStatus("loading");
    await create({
      projectId,
      companyId,
    });
    setStatus("success");
  };

  if (companyId === undefined) {
    return <></>;
  }

  return (
    <Button
      fullWidth
      handleClick={async () => {
        await sendSpecificationSheet(companyId);
      }}
      variant={status === "success" ? "success" : "default"}
      disabled={status !== "initial"}
    >
      {status === "initial" && (
        <>{t("CREATE_PROJECT.RESULTS.send-specification-sheet")}</>
      )}
      {status === "loading" && <>{t("BUTTONS.updating")}</>}
      {status === "success" && <>{t("PROJECT.SEND_MAIL.successfully-sent")}</>}
    </Button>
  );
};

interface SearchResultsWrapperProps {
  searchResults: {
    searched: boolean;
    results: Array<{ match: number; project: ProjectInterface }>;
  };
  handleSaveSearch: () => void | Promise<void>;
  isShowSaveProject: boolean;
  projectId?: string;
}

const SearchResultsWrapper = ({
  searchResults,
  handleSaveSearch,
  isShowSaveProject,
  projectId,
}: SearchResultsWrapperProps): ReactElement => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSaveClick = async (): Promise<void> => {
    setIsSubmitting(true);
    await handleSaveSearch();
    setIsSubmitting(false);
  };

  if (!searchResults.searched) {
    return <></>;
  }

  return (
    <Column>
      <StyledFullSizeDiv>
        {isShowSaveProject ? (
          <Button
            disabled={isSubmitting}
            type="button"
            handleClick={() => {
              void handleSaveClick();
            }}
          >
            {!isSubmitting
              ? t("CREATE_PROJECT.save-search")
              : t("BUTTONS.updating")}
          </Button>
        ) : (
          <Button
            disabled
            type="button"
            variant="success"
            handleClick={() => {}}
          >
            {t("MESSAGES.successfully-saved")}
          </Button>
        )}
      </StyledFullSizeDiv>
      <Space size={2} />
      {searchResults.results.map((result) => (
        <React.Fragment key={JSON.stringify(result)}>
          <Card floating={false} color="blue">
            {t("CREATE_PROJECT.RESULTS.company-name")}:{" "}
            {result.project.company?.name}
            <br />
            {result.project.name !== "" && (
              <>
                {t("CREATE_PROJECT.RESULTS.name")}: {result.project.name}
                <br />
              </>
            )}
            {result.project.description !== "" && (
              <>
                {t("CREATE_PROJECT.RESULTS.description")}:{" "}
                {result.project.description}
                <br />
              </>
            )}
            {t("CREATE_PROJECT.RESULTS.matching-value")}: {result.match * 100}%
            <Space size={2} />
            <Link
              to={`/specifications/${result.project.id ?? ""}`}
              target="_blank"
            >
              <Button fullWidth variant="light" handleClick={() => {}}>
                {t("CREATE_PROJECT.RESULTS.open-specifications")}
              </Button>
            </Link>
            <Space />
            {projectId !== undefined && (
              <SendButton
                projectId={projectId}
                companyId={result.project.companyId}
              />
            )}
          </Card>
          <Space size={2} />
        </React.Fragment>
      ))}
    </Column>
  );
};

export default SearchResultsWrapper;
