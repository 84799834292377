import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProjects from "../../api/projects/useProjects";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { Table, TableControlBar } from "../../components";
import PlusIcon from "../../icons/PlusIcon";
import { useTranslation } from "react-i18next";
import useProjectsListColumns from "./hooks/useProjectsListColumns";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import ProjectStatusModal from "./ProjectStatusModal";
import { ProjectStatusTypeEnum } from "../../interfaces/ProjectStateType";
import useProjectContracts from "../../api/projectContracts/useProjectContracts";
import useUnlockProject from "../Company/hooks/useUnlockProject";
import UnlockModal from "../Company/UnlockModal";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import useUsers from "../../api/users/useUsers";
import hasUserRole from "../../helpers/hasUserRole";

const ProjectList = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientProjects, getAllClientProjects, deleteById } = useProjects();
  const { getByProjectId } = useProjectContracts();
  const { columns } = useProjectsListColumns();
  const { currentUser } = useUsers();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [modalConfiguration, setModalConfiguration] = useState<{
    isModalOpen: boolean;
    projectId: string | null;
  }>({ isModalOpen: false, projectId: null });
  const [unlockModalConfiguration, setUnlockModalConfiguration] = useState<{
    isModalOpen: boolean;
    projectId: string | null;
    projectContracts: ProjectContractInterface[];
  }>({ isModalOpen: false, projectId: null, projectContracts: [] });
  const { handleAccept } = useUnlockProject();

  useEffect(() => {
    if (status !== "initial") {
      return;
    }

    void getAllClientProjects().then(() => {
      setStatus("success");
    });
    setStatus("loading");
  }, [status]);

  const checkSpecificationSheet = async (projectId: string): Promise<void> => {
    const contracts = await getByProjectId(projectId);

    if (
      currentUser === null ||
      !hasUserRole({ user: currentUser, role: "ADMIN" })
    ) {
      if (contracts === null) {
        return;
      }
      if (
        contracts.find((contract) => contract.contractState !== "OPEN") ===
        undefined
      ) {
        setUnlockModalConfiguration({
          isModalOpen: true,
          projectId,
          projectContracts: contracts,
        });
        return;
      }
    }

    window.open(`/specifications/${projectId}`, "_blank");
  };

  const handleUnlockModalClose = (): void => {
    setUnlockModalConfiguration({
      isModalOpen: false,
      projectId: null,
      projectContracts: [],
    });
  };

  return (
    <>
      <UnlockModal
        isModalOpen={unlockModalConfiguration.isModalOpen}
        handleModalOpenChange={handleUnlockModalClose}
        handleAccept={async (acceptedCompany) => {
          await handleAccept({
            acceptedCompany,
            projectContracts: unlockModalConfiguration.projectContracts,
          });
          const projectId = unlockModalConfiguration.projectId;
          if (projectId === null) {
            return;
          }
          await checkSpecificationSheet(projectId);
          await handleUnlockModalClose();
        }}
        projectContracts={unlockModalConfiguration.projectContracts}
      />
      <ProjectStatusModal
        projectId={modalConfiguration.projectId}
        isOpen={modalConfiguration.isModalOpen}
        handleClose={() =>
          setModalConfiguration({ isModalOpen: false, projectId: null })
        }
      />
      <TableControlBar
        controls={[
          {
            action: "add",
            element: PlusIcon,
          },
        ]}
        handleAction={() => navigate("/create-project")}
      />
      <Table<ProjectInterface>
        handleExpandedChange={([project]) => {
          setModalConfiguration({
            projectId: project.id ?? null,
            isModalOpen: true,
          });
        }}
        collapse
        data={clientProjects.map((clientProject) => ({
          ...clientProject,
          projectState:
            clientProject.projectState ?? ProjectStatusTypeEnum.OPEN,
        }))}
        isShowMore
        getRowCanExpand={() => true}
        options={[
          {
            label: t("BUTTONS.edit"),
            onClick: (id) => {
              navigate(`/project/edit/${id}`);
            },
          },
          {
            label: t("BUTTONS.delete"),
            onClick: async (id) => {
              await deleteById(id);
            },
          },
          {
            label: t("PROJECT.open-specification-sheet"),
            onClick: checkSpecificationSheet,
          },
        ]}
        columns={columns}
        isLoading={status === "loading"}
      />
    </>
  );
};

export default ProjectList;
