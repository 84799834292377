import { create } from "zustand";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { FrontendInterface } from "../interfaces/FrontendInterface";

export interface FrontendsState extends CrudStateInterface<FrontendInterface> {}

const useFrontendsStore = create<FrontendsState>((set, get) => ({
  ...defaultStateCrud<FrontendInterface>(set, get),
}));

export default useFrontendsStore;
