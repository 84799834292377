import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface HeadlineProps {
  children: ReactNode;
  pl?: string;
  mb?: string;
  type?: "h1" | "h2";
}

const StyledHeadline = styled.h2<{ pl: string; mb?: string }>`
  font-weight: 600;
  font-size: 1.75rem;
  margin: 0 0 ${({ mb }) => (mb !== undefined ? mb : "2rem")} 0;
  padding-left: ${({ pl }) => pl};
`;

const StyledHeadline1 = styled.h1<{ pl: string; mb?: string }>`
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 0 ${({ mb }) => (mb !== undefined ? mb : "2rem")} 0;
  padding-left: ${({ pl }) => pl};
`;

const Headline = ({
  children,
  pl = "0",
  mb,
  type = "h2",
}: HeadlineProps): ReactElement => {
  if (type === "h2") {
    return (
      <StyledHeadline pl={pl} mb={mb}>
        {children}
      </StyledHeadline>
    );
  }

  return (
    <StyledHeadline1 pl={pl} mb={mb}>
      {children}
    </StyledHeadline1>
  );
};

export default Headline;
