import useApi from "../useApi";
import { SpecificationInviteInterface } from "../../interfaces/SpecificationInviteInterface";

interface UseCreateSpecificationInvitationInterface {
  create: ({
    contractId,
    recipients,
  }: SpecificationInviteInterface) => Promise<boolean>;
}

const useCreateSpecificationInvitation =
  (): UseCreateSpecificationInvitationInterface => {
    const { post } = useApi();

    const create = async ({
      contractId,
      recipients,
    }: SpecificationInviteInterface): Promise<boolean> => {
      const { status } = await post(`/specification/invite/${contractId}`, {
        recipients,
      });

      if (status === 200) {
        return true;
      }

      throw new Error("Specification Invitation creation error");
    };

    return { create };
  };

export default useCreateSpecificationInvitation;
