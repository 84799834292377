import React, { ReactElement } from "react";
import styled from "styled-components";
import { DeleteIcon } from "../../icons";

const StyledImage = styled.img`
  max-width: 40rem;
  border-radius: 1rem;
`;

const StyledRemoveButton = styled.a`
  cursor: pointer;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
`;

interface ImageProps {
  src: string;
  alt?: string;
  handleRemove?: () => Promise<void>;
}
const Image = ({ src, alt = "", handleRemove }: ImageProps): ReactElement => {
  return (
    <>
      {handleRemove !== undefined && (
        <>
          <StyledRemoveButton
            onClick={() => {
              void handleRemove();
            }}
          >
            <DeleteIcon />
          </StyledRemoveButton>
        </>
      )}
      <StyledImage alt={alt} src={src} />
    </>
  );
};

export default Image;
