import useApi from "../useApi";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";

interface UseGetProjectContractInterface {
  getById: (id: string) => Promise<ProjectContractInterface | null>;
}

const useGetProjectContract = (): UseGetProjectContractInterface => {
  const { get } = useApi();

  const getById = async (
    id: string
  ): Promise<ProjectContractInterface | null> => {
    const { data, status } = await get(`/project-contract/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetProjectContract;
