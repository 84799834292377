import { create } from "zustand";
import { CapabilityInterface } from "../interfaces/CapabilityInterface";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { TranslationInterface } from "../interfaces/TranslationInterface";

export interface CapabilitiesState
  extends CrudStateInterface<CapabilityInterface> {
  getByName: (name: string) => CapabilityInterface | null;
  addTranslation: ({
    capabilityId,
    translation,
  }: {
    capabilityId: string;
    translation: TranslationInterface;
  }) => void;
  updateTranslation: ({
    capabilityId,
    translation,
  }: {
    capabilityId: string;
    translation: TranslationInterface;
  }) => void;
}

const useCapabilityStore = create<CapabilitiesState>((set, get) => ({
  ...defaultStateCrud<CapabilityInterface>(set, get),
  getByName: (name) =>
    get().data.find(({ name: stateName }) => name === stateName) ?? null,
  addTranslation: ({ capabilityId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== capabilityId) {
              return dataSet;
            }
            return {
              ...dataSet,
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation,
                      {
                        ...translation,
                      },
                    ],
            };
          }),
        ],
      };
    }),
  updateTranslation: ({ capabilityId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== capabilityId) {
              return dataSet;
            }
            return {
              ...dataSet,
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation.map((stateTranslation) => {
                        if (stateTranslation.id !== translation.id) {
                          return stateTranslation;
                        }
                        return translation;
                      }),
                    ],
            };
          }),
        ],
      };
    }),
}));

export default useCapabilityStore;
