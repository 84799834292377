import { useEffect, useState } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import useApi from "./useApi";

interface UseGetUsersInterface {
  users: UserInterface[];
  status: RequestStatusType;
  removeUser: ({ id }: { id: string }) => void;
  updateUser: (user: UserInterface) => void;
  createUser: (user: UserInterface) => void;
}

const useGetUsers = (): UseGetUsersInterface => {
  const { get } = useApi();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [users, setUsers] = useState<UserInterface[]>([]);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }

    const getUsers = async (): Promise<void> => {
      const { data, status: responseStatus } = await get(`/user`);
      if (responseStatus !== 200) {
        // todo
        console.log("error");
        return;
      }

      setUsers(data);
      setStatus("success");
    };

    getUsers().catch((error) => {
      // todo
      console.log("error", error);
    });

    setStatus("loading");
  }, [status]);

  const removeUser = ({ id }: { id: string }): void => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const updateUser = (user: UserInterface): void => {
    setUsers(
      users.map((existingUser) => {
        if (existingUser.id !== user.id) {
          return existingUser;
        }

        return { ...existingUser, ...user };
      })
    );
  };

  const createUser = (user: UserInterface): void => {
    setUsers([
      ...users,
      {
        ...user,
      },
    ]);
  };

  return { users, status, removeUser, updateUser, createUser };
};

export default useGetUsers;
