import React, { ReactElement, useEffect, useState } from "react";
import { EditablePublicProfileBlockInterface } from "./hooks/usePublicProfileListColumns";
import useProfileBlockListColumns from "../../hooks/useProfileBlockListColumns";
import { Button, Card, Space, Table } from "../../components";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { useTranslation } from "react-i18next";
import ContentBlock from "../../components/ContentBlock";
import Right from "../../components/Right";
import CheckIcon from "../../icons/CheckIcon";
import ProfileBlockForm from "./ProfileBlockForm";
import usePublicProfileBlocks from "../../api/publicProfileBlocks/usePublicProfileBlocks";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";

interface ProfileBlockListProps {
  companyId: string;
}

const ProfileBlockList = ({
  companyId,
}: ProfileBlockListProps): ReactElement => {
  const { getByCompanyId, deleteBlock, update } = usePublicProfileBlocks();
  const { columns } = useProfileBlockListColumns();
  const { t } = useTranslation();

  const [data, setData] = useState<EditablePublicProfileBlockInterface[]>([]);
  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }
    setStatus("loading");
    void getByCompanyId(companyId).then((blocks) => {
      setData(
        blocks.map((block) => {
          return {
            name: block.name,
            companyId: block.companyId ?? "",
            type: block.type ?? PublicProfileBlockTypeEnum.HTML,
            id: block.id,
            company: block.company,
            content: (
              <ContentBlock
                content={block.content}
                type={block.type ?? PublicProfileBlockTypeEnum.HTML}
              />
            ),
            originalContent: block.content,
            edit: true,
          };
        })
      );
      setStatus("success");
    });
  }, [companyId, status]);

  return (
    <Table
      options={[
        {
          label: t("BUTTONS.edit"),
          // todo: implement edit
        },
        {
          label: t("BUTTONS.delete"),
          onClick: async (id): Promise<void> => {
            await deleteBlock(id);
            setStatus("initial");
          },
        },
      ]}
      columns={columns}
      data={data}
      isLoading={status !== "success"}
      getRowCanExpand={() => true}
      renderSubComponent={({ row }) => {
        if (row.original.id === undefined) {
          return <></>;
        }
        return (
          <>
            <Space />
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <ProfileBlockForm
                initialValues={{
                  id: row.original.id,
                  name: row.original.name,
                  content: row.original.originalContent,
                  companyId: [
                    {
                      id: row.original.companyId,
                      label:
                        // todo get company
                        row.original.company === undefined
                          ? "none"
                          : row.original.company.name ?? "",
                    },
                  ],
                  type: [],
                }}
                handleSubmit={async (block) => {
                  setIsSubmitting(true);
                  await update({
                    id: block.id as string,
                    name: block.name as string,
                    content: block.content as string,
                    companyId: block.companyId[0].id as string,
                    type: block.type[0]?.id ?? PublicProfileBlockTypeEnum.HTML,
                  });
                  row.getToggleExpandedHandler()();
                  setStatus("initial");
                  setIsSubmitting(false);
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </ProfileBlockForm>
            </Card>
            <Space size={3} />
          </>
        );
      }}
    />
  );
};

export default ProfileBlockList;
