import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../components/Clickable";
import { useTranslation } from "react-i18next";
import { CapabilityInterface } from "../interfaces/CapabilityInterface";
import CapabilityTranslation from "../modules/Translation/CapabilityTranslation";

export interface CapabilityColumn extends CapabilityInterface {
  edit: boolean;
}

const columnHelper = createColumnHelper<CapabilityColumn>();

interface UseCapabilitiesListColumnsInterface {
  columns: Array<ColumnDef<CapabilityColumn, any>>;
}
const useCapabilitiesListColumns = (): UseCapabilitiesListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<CapabilityColumn, any>> = [
    columnHelper.accessor("name", {
      header: `${t("CAPABILITIES.name")}`,
      cell: (info) => {
        return (
          <>
            {t([
              `CAPABILITIES.labels.${info.getValue() as string}${
                info.row.original.type === "SELECT" ? ".label" : ""
              }`,
              `${info.getValue() as string}`,
            ])}{" "}
            <CapabilityTranslation capability={info.row.original} />
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("type", {
      header: `${t("CAPABILITIES.type")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {t(`CAPABILITIES.typeLabels.${info.getValue() as string}`)}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useCapabilitiesListColumns;
