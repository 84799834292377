import { CompanyInterface } from "../../../interfaces/CompanyInterface";
import useProjectContracts from "../../../api/projectContracts/useProjectContracts";
import { ProjectContractInterface } from "../../../interfaces/ProjectContractInterface";

interface UseUnlockProjectInterface {
  handleAccept: ({
    acceptedCompany,
    projectContracts,
  }: {
    acceptedCompany: CompanyInterface;
    projectContracts?: ProjectContractInterface[];
  }) => Promise<boolean>;
}

const useUnlockProject = (): UseUnlockProjectInterface => {
  const { update: updateProjectContract } = useProjectContracts();

  const handleAccept = async ({
    acceptedCompany,
    projectContracts,
  }: {
    acceptedCompany: CompanyInterface;
    projectContracts?: ProjectContractInterface[];
  }): Promise<boolean> => {
    const contract = projectContracts?.find(
      (projectContracts) => projectContracts.companyId === acceptedCompany.id
    );

    if (contract === undefined) {
      return false;
    }

    await updateProjectContract({
      ...contract,
      contractState: "ON_HOLD",
    });

    return true;
  };

  return { handleAccept };
};

export default useUnlockProject;
