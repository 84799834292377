import React, { ReactElement, useEffect, useState } from "react";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import useTranslations from "../../api/translations/useTranslations";
import { TranslationInterface } from "../../interfaces/TranslationInterface";
import { useTranslation } from "react-i18next";
import { AddTranslationFormValuesInterface } from "./AddTranslationForm";
import Translation from "./Translation";

interface FormGroupTranslationProps {
  formGroup: FormGroupInterface;
}
const FormGroupTranslation = ({
  formGroup,
}: FormGroupTranslationProps): ReactElement => {
  const { t } = useTranslation();
  const { getByFormGroupId, create } = useTranslations();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [translations, setTranslations] = useState<
    TranslationInterface[] | null
  >(null);

  useEffect(() => {
    if (!isPopoverOpen || formGroup.id === undefined) {
      return;
    }

    void getByFormGroupId(formGroup.id).then((data) => {
      setTranslations(data ?? []);
    });
  }, [formGroup, isPopoverOpen]);

  const handleAddTranslation = async (
    translation: AddTranslationFormValuesInterface
  ): Promise<void> => {
    if (translation.mandate === "") {
      return;
    }
    await create({
      language: translation.language,
      formGroupId: formGroup.id,
      translationType: "FORM_GROUP",
      mandate: translation.mandate,
      value: translation.value,
    });
  };

  return (
    <Translation
      handleAddTranslation={handleAddTranslation}
      translations={translations}
      headline={t([`FORM_GROUPS.${formGroup.name}`, formGroup.name])}
      isPopoverOpen={isPopoverOpen}
      handlePopoverOpenChange={setIsPopoverOpen}
    />
  );
};

export default FormGroupTranslation;
