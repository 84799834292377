import React, { ReactElement, ReactNode } from "react";
import StyledTag from "./styles/StyledTag";

const Tag = ({
  children,
  variant,
  handleClick,
}: {
  children: ReactNode;
  variant?: "success" | "danger" | "default";
  handleClick?: () => void;
}): ReactElement => {
  return (
    <>
      <StyledTag onClick={handleClick} variant={variant}>
        {children}
      </StyledTag>
    </>
  );
};

export default Tag;
