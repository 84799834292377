import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Formik } from "formik";
import InputField from "./InputField";
import RelationField from "./RelationField";
import { RelationInterface } from "../../interfaces/RelationInterface";
import { FormFieldType } from "../../interfaces/FormFieldType";
import {
  AddOptionsInputFieldProps,
  AddOptionsValueType,
  FileInputFieldProps,
  SelectInputFieldProps,
  TextInputFieldProps,
} from "../../interfaces/InputFieldProps";
import { FormikErrors } from "formik/dist/types";
import FileInputField from "./FileInputField";
import SelectField from "./SelectField";
import { Button, Column, Headline } from "../index";
import StyledGroupDiv from "../styles/StyledGroupDiv";
import CircledPlusIcon from "../../icons/CircledPlusIcon";
import { useTranslation } from "react-i18next";
import Row from "../Row";
import InputCheckbox from "./InputCheckbox";
import RenderFileUploadField from "./FileUpload";

export type RelationType = RelationInterface[];

type FormValuesValueType =
  | string
  | RelationType
  | File
  | AddOptionsValueType
  | boolean
  | number;
export type FormValuesType = Record<string, FormValuesValueType>;

interface FormProps<FormValues extends FormValuesType> {
  fields: FormFieldInterface[];
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => Promise<void> | void;
  children: ReactNode;
  populate?: Record<string, RelationType>;
  validate?: (
    values: FormValues
  ) => object | Promise<FormikErrors<FormValues>> | void;
  handleChange?: (values: FormValues) => void;
}

export interface FormFieldInterface {
  id: string;
  type: FormFieldType;
  label: string;
  options?: Array<{
    value: string;
    label: string;
  }>;
}

const RenderFileField = ({
  handleChange,
  handleBlur,
  value,
  name,
  label,
}: FileInputFieldProps): ReactElement => {
  return (
    <FileInputField
      handleChange={handleChange}
      handleBlur={handleBlur}
      name={name}
      label={label}
      value={value}
    />
  );
};

const RenderSelectField = ({
  handleChange,
  handleBlur,
  value,
  name,
  label,
  options,
}: SelectInputFieldProps): ReactElement => {
  return (
    <SelectField
      handleChange={handleChange}
      handleBlur={handleBlur}
      name={name}
      label={label}
      value={value}
    >
      {options.map((option) => (
        <option key={JSON.stringify(option)} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectField>
  );
};

const RenderFormField = ({
  type,
  handleChange,
  handleBlur,
  value,
  name,
  label,
  handleRelationChange,
  populate,
}: TextInputFieldProps): ReactElement => {
  const handleRelationFieldChange = ({ value }: { value: any }): void => {
    if (handleRelationChange === undefined) {
      return;
    }
    if (populate === undefined || populate[name] === undefined) {
      handleRelationChange([{ id: "new", label: value }]);
      return;
    }
    const found = populate[name].find((pop) => {
      return pop.id === value;
    });

    if (found === undefined) {
      handleRelationChange([{ id: "new", label: value }]);
      return;
    }
    handleRelationChange([{ id: value, label: found.label }]);
  };

  switch (type) {
    case "email":
    case "phone":
    case "text":
    case "password":
    case "code":
    case "number":
      return (
        <InputField
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value === null ? "" : value}
          name={name}
          type={type}
          label={label}
        />
      );

    case "boolean":
      return (
        <InputCheckbox
          label={label}
          name={name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value}
        />
      );

    case "relation":
      return (
        <RelationField
          handleChange={({ target: { value } }) => {
            handleRelationFieldChange({ value });
          }}
          populate={populate}
          handleBlur={handleBlur}
          value={value}
          name={name}
          type={type}
          label={label}
        />
      );
  }

  return <></>;
};

const RenderAddOptionsField = ({
  handleChange,
  value,
  label,
}: AddOptionsInputFieldProps): ReactElement => {
  const { t } = useTranslation();

  const [updatedValue, setUpdatedValue] = useState<Record<number, string>>({});

  const objectFromValues = { ...value.map((singleValue) => singleValue.value) };

  const handleValueUpdate = (values: Record<number, string>): void => {
    setUpdatedValue(values);
  };

  const adaptValue = (): AddOptionsValueType => {
    const keys = Object.keys(updatedValue);
    return keys.map((key) => ({
      value: updatedValue[+key],
    }));
  };

  const handleAddNewClick = (): void => {
    const newValue: AddOptionsValueType = [
      ...adaptValue(),
      {
        value: "new",
      },
    ];
    handleChange(newValue);
  };

  return (
    <StyledGroupDiv>
      <Headline>{label}</Headline>
      <Formik<Record<number, string>>
        initialValues={objectFromValues}
        onSubmit={(e) => console.log(e)}
      >
        {({ handleChange: handleInternalChange, values }) => {
          useEffect(() => {
            handleValueUpdate(values);
          }, [values]);
          return (
            <>
              {value.map((option, index) => (
                <React.Fragment key={`${option.value}${index.toString()}`}>
                  <InputField
                    type="text"
                    handleChange={handleInternalChange}
                    value={values[index] !== undefined ? values[index] : ""}
                    label={`Option ${(index + 1).toString()}`}
                    name={`${index.toString()}`}
                  />
                </React.Fragment>
              ))}
              <Button
                variant="light"
                type="button"
                handleClick={handleAddNewClick}
              >
                <Row mb="0">
                  <Column size="shrink" padding="0 0 0 2rem">
                    <CircledPlusIcon />
                  </Column>
                  <Column padding="0 2rem 0 0">{t("FORMS.add-new")}</Column>
                </Row>
              </Button>
            </>
          );
        }}
      </Formik>
    </StyledGroupDiv>
  );
};

const Form = <FormValues extends FormValuesType>({
  fields,
  initialValues,
  handleSubmit,
  children,
  populate,
  validate,
  handleChange: handleFormChange,
}: FormProps<FormValues>): ReactElement => {
  return (
    <>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          await handleSubmit(values);
        }}
        validate={validate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setValues,
        }) => {
          useEffect(() => {
            if (handleFormChange === undefined) {
              return;
            }
            handleFormChange(values);
          }, [values]);

          const formValues = values as FormValuesType;
          return (
            <form onSubmit={handleSubmit}>
              {fields.map(({ id, type, label, options }) => (
                <React.Fragment key={id}>
                  {type === "file" && (
                    <RenderFileField
                      handleChange={(value) => {
                        if (value.currentTarget.files === null) {
                          handleChange(value);
                          return;
                        }

                        const [updatedFile] = Array.from(
                          value.currentTarget.files
                        );
                        setValues({ ...values, [id]: updatedFile });
                      }}
                      handleBlur={handleBlur}
                      value={formValues[id] as File}
                      name={id}
                      label={label}
                    />
                  )}
                  {type === "select" && options !== undefined && (
                    <RenderSelectField
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={formValues[id] as string}
                      name={id}
                      label={label}
                      options={options}
                    />
                  )}
                  {type === "addOptions" && (
                    <RenderAddOptionsField
                      handleChange={(data) => {
                        setValues({ ...values, [id]: data });
                      }}
                      value={formValues[id] as AddOptionsValueType}
                      name={id}
                      label={label}
                    />
                  )}
                  {[
                    "hidden",
                    "email",
                    "code",
                    "password",
                    "text",
                    "phone",
                    "relation",
                    "number",
                    "boolean",
                  ].includes(type) && (
                    <RenderFormField
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={formValues[id] as string | RelationType}
                      name={id}
                      type={type}
                      label={label}
                      handleRelationChange={(value) => {
                        setValues({ ...values, [id]: value });
                      }}
                      populate={populate}
                    />
                  )}
                  {type === "fileUpload" && (
                    <RenderFileUploadField
                      handleChange={(value) => {
                        setValues({ ...values, [id]: value });
                      }}
                      name={id}
                      label={label}
                      value={formValues[id] as string}
                    />
                  )}
                </React.Fragment>
              ))}
              {children}
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export default Form;
