import React, { ReactElement, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { UserInterface } from "../../interfaces/UserInterface";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { useTranslation } from "react-i18next";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import useFilterFormGroups from "../../modules/Forms/useFilterFormGroups";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import useGetCapabilityLabel from "../../hooks/useGetCapabilityLabel";

interface CapabilityGroupCollectionInterface {
  id?: string;
  name: string;
  capabilities: ProjectCapabilityInterface[];
}

const getCapabilityGroups = (
  formGroup: FormGroupInterfaceWithCapabilities
): CapabilityGroupCollectionInterface[] => {
  const groups: CapabilityGroupCollectionInterface[] = [
    {
      name: "ungrouped",
      capabilities: [],
    },
  ];

  formGroup.capabilities?.forEach((capability) => {
    const group = groups.find((searchGroup) =>
      capability.capability?.capabilityGroup !== undefined &&
      capability.capability.capabilityGroup !== null
        ? searchGroup.id === capability.capability.capabilityGroup.id
        : searchGroup.name === "ungrouped"
    );

    if (
      group === undefined &&
      capability.capability?.capabilityGroup !== undefined &&
      capability.capability.capabilityGroup !== null
    ) {
      groups.push({
        name: capability.capability.capabilityGroup.name,
        id: capability.capability.capabilityGroup.id,
        capabilities: [{ ...capability }],
      });
      return;
    }

    if (group === undefined) {
      return;
    }

    group.capabilities.push({
      ...capability,
    });
  });

  return groups;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 70,
    fontSize: 12,
  },
  headSection: {
    flexDirection: "row",
    marginBottom: 70,
  },
  rowSection: {
    flexDirection: "row",
  },
  rowTitleSection: {
    flexGrow: 1,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "black",
    backgroundColor: "#76fbef",
    paddingTop: 10,
    paddingBottom: 10,
  },
  columnTitleSection: {
    flexGrow: 1,
    width: "30%",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "black",
  },
  columnContentSection: {
    flexGrow: 1,
    width: "70%",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "black",
  },
  section: {
    flexGrow: 1,
    width: "50%",
  },
  centerSection: {
    textAlign: "center",
    marginBottom: 70,
  },
  summarySection: {
    marginBottom: 70,
  },
  linkStyle: {
    color: "black",
    textDecoration: "none",
  },
  linkStyleSummaryHeading: {
    fontWeight: "bold",
    color: "black",
  },
});

interface FormGroupInterfaceWithCapabilities extends FormGroupInterface {
  capabilities: ProjectCapabilityInterface[];
}

const FilterFormGroups = ({
  formGroups,
  projectCategoryFilter,
  projectCapabilities,
  RenderElement,
}: {
  formGroups: FormGroupInterface[];
  projectCategoryFilter: ProjectCategoryInterface;
  projectCapabilities?: ProjectCapabilityInterface[];
  RenderElement: ({
    formGroups,
  }: {
    formGroups: FormGroupInterfaceWithCapabilities[];
  }) => ReactElement;
}): ReactElement => {
  const { filteredFormGroups } = useFilterFormGroups({
    formGroups,
    projectCategoryFilter,
  });
  const [activatedFormGroups, setActivatedFormGroups] = useState<
    FormGroupInterfaceWithCapabilities[]
  >([]);

  useEffect(() => {
    const activatedFormGroups: FormGroupInterfaceWithCapabilities[] = [];
    projectCapabilities?.forEach((projectCapability) => {
      const found = filteredFormGroups.find((filteredFormGroup) => {
        const foundCapability = filteredFormGroup.capabilities?.find(
          (capability) => capability.id === projectCapability.capabilityId
        );
        return foundCapability !== undefined;
      });

      if (found === undefined) {
        return;
      }
      const foundActivated = activatedFormGroups.find(
        (activatedFormGroup) => activatedFormGroup.id === found.id
      );
      if (foundActivated === undefined) {
        activatedFormGroups.push({
          ...found,
          capabilities: [projectCapability],
        });
        return;
      }
      foundActivated.capabilities?.push(projectCapability);
    });
    setActivatedFormGroups(activatedFormGroups);
  }, [projectCapabilities, filteredFormGroups]);

  return <>{<RenderElement formGroups={activatedFormGroups} />}</>;
};

const RenderCapabilities = ({
  capabilities,
  capabilityConfigurationValues,
  isUngrouped,
}: {
  capabilities: ProjectCapabilityInterface[];
  capabilityConfigurationValues: Array<{
    capabilityConfigurationId: string;
    value: string | Buffer;
  }>;
  isUngrouped: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const { getShortLabel } = useGetCapabilityLabel();

  // todo replace with useCapabilityValues();
  const getValue = (projectCapability: ProjectCapabilityInterface): string => {
    if (projectCapability.capability === undefined) {
      return typeof projectCapability.value === "string"
        ? projectCapability.value
        : projectCapability.value.name;
    }

    if (projectCapability.value instanceof File) {
      return projectCapability.value.name;
    }
    switch (projectCapability.capability.type) {
      case "FLOAT": {
        const value = JSON.parse(projectCapability.value);
        if (typeof value === "number") {
          return projectCapability.value;
        }
        if ("min" in value && "max" in value) {
          const minValue = value.min;
          const maxValue = value.max;
          return `Von: ${typeof minValue === "string" ? minValue : ""}, bis: ${
            typeof maxValue === "string" ? maxValue : ""
          }`;
        }
        break;
      }
      case "BOOLEAN": {
        return projectCapability.value === "true"
          ? t("CAPABILITY.true")
          : t("CAPABILITY.false");
      }
      case "SELECT": {
        const foundValue = capabilityConfigurationValues.find(
          (capabilityConfigurationValue) =>
            capabilityConfigurationValue.capabilityConfigurationId ===
            projectCapability.value
        );
        if (foundValue !== undefined && typeof foundValue.value === "string") {
          return foundValue.value;
        }
        break;
      }
      case "FILE": {
        const foundValue = capabilityConfigurationValues.find(
          (capabilityConfigurationValue) =>
            capabilityConfigurationValue.capabilityConfigurationId ===
            projectCapability.value
        );
        if (foundValue === undefined) {
          return projectCapability.value;
        }
        const blob = new Blob([foundValue.value]);
        return window.URL.createObjectURL(blob);
      }
    }

    return projectCapability.value;
  };

  const RenderCapability = ({
    capability,
  }: {
    capability: ProjectCapabilityInterface;
  }): ReactElement => {
    switch (capability.capability?.type) {
      case "BOOLEAN": {
        return (
          <>
            {!isUngrouped ? (
              <>
                <View style={styles.section}>
                  <Text> </Text>
                </View>
                <View style={styles.section}>
                  <Text>
                    {capability.capability !== undefined &&
                      getShortLabel(capability.capability)}
                  </Text>
                </View>
              </>
            ) : (
              <>
                <View>
                  <Text>
                    {capability.capability !== undefined &&
                      getShortLabel(capability.capability)}
                  </Text>
                </View>
              </>
            )}
          </>
        );
      }
      case "FLOAT":
      case "STRING":
      case "SELECT":
        return (
          <>
            <View style={styles.section}>
              <Text>
                {capability.capability !== undefined &&
                  getShortLabel(capability.capability)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text>{getValue(capability)}</Text>
            </View>
          </>
        );
      case "FILE":
        return <></>;
    }

    return <></>;
  };

  return (
    <>
      {capabilities.map((capability) => {
        return (
          <React.Fragment key={JSON.stringify(capability)}>
            <View style={styles.rowSection}>
              <RenderCapability capability={capability} />
            </View>
          </React.Fragment>
        );
      })}
    </>
  );
};

const RenderFormGroup = ({
  formGroup,
  index,
  capabilityConfigurationValues,
}: {
  formGroup: FormGroupInterfaceWithCapabilities;
  index: string;
  capabilityConfigurationValues: Array<{
    capabilityConfigurationId: string;
    value: string | Buffer;
  }>;
}): ReactElement => {
  const { t } = useTranslation();

  const capabilityGroups = getCapabilityGroups(formGroup);

  if (capabilityGroups.length === 0) {
    return <></>;
  }

  return (
    <>
      <View style={styles.rowSection}>
        <View style={styles.columnTitleSection}>
          <Text id={`${index}`}>
            {index} {t(`FORM_GROUPS.${formGroup.name}`)}
          </Text>
        </View>
        <View style={styles.columnContentSection}>
          {capabilityGroups.map((capabilityGroup) => (
            <React.Fragment key={JSON.stringify(capabilityGroup)}>
              {capabilityGroup.name !== "ungrouped" && (
                <Text>{t(`CAPABILITY_GROUPS.${capabilityGroup.name}`)}</Text>
              )}
              <RenderCapabilities
                isUngrouped={capabilityGroup.name === "ungrouped"}
                capabilityConfigurationValues={capabilityConfigurationValues}
                capabilities={capabilityGroup.capabilities}
              />
            </React.Fragment>
          ))}
        </View>
      </View>
    </>
  );
};

const Summary = ({
  categories,
  formGroups,
  projectCapabilities,
}: {
  categories: ProjectCategoryInterface[];
  formGroups: FormGroupInterface[];
  projectCapabilities: ProjectCapabilityInterface[];
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <View style={styles.summarySection}>
      {categories.map((category, index) => (
        <React.Fragment key={category.id}>
          <Link
            style={styles.linkStyleSummaryHeading}
            src={`#${category.name}`}
          >
            {index + 1} {t(`PROJECT_CATEGORIES.${category.name}`)}
          </Link>
          <FilterFormGroups
            formGroups={formGroups}
            projectCategoryFilter={category}
            projectCapabilities={projectCapabilities}
            RenderElement={({ formGroups }) => {
              return (
                <>
                  {formGroups.map((formGroup, formGroupIndex) => (
                    <React.Fragment key={formGroup.id}>
                      <Link
                        src={`#${index + 1}.${formGroupIndex + 1}`}
                        style={styles.linkStyle}
                      >
                        {index + 1}.{formGroupIndex + 1}{" "}
                        {t(`FORM_GROUPS.${formGroup.name}`)}
                      </Link>
                    </React.Fragment>
                  ))}
                </>
              );
            }}
          />
        </React.Fragment>
      ))}
    </View>
  );
};

interface SpecificationSheetPdfProps {
  company: CompanyInterface;
  user?: UserInterface;
  project: ProjectInterface;
  formGroups: FormGroupInterface[];
  capabilityConfigurationValues: Array<{
    capabilityConfigurationId: string;
    value: string | Buffer;
  }>;
}
const SpecificationSheetPdf = ({
  company,
  user,
  project,
  formGroups,
  capabilityConfigurationValues,
}: SpecificationSheetPdfProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headSection}>
          <View style={styles.section}>
            <Text style={{ fontWeight: "bold" }}>Firma:</Text>
            <Text style={{ fontWeight: "bold" }}>Ansprechpartner:</Text>
            <Text style={{ fontWeight: "bold" }}>Telefonnummer:</Text>
            <Text style={{ fontWeight: "bold" }}>E-Mail-Adresse:</Text>
          </View>
          <View style={styles.section}>
            <Text>{company.name}</Text>
            {user !== undefined && (
              <>
                <Text>
                  {user.profile?.firstName ?? ""} {user.profile?.lastName ?? ""}
                </Text>
                <Text>{user.profile?.phone ?? company.phone ?? ""}</Text>
                <Text>{user.email ?? company.email ?? ""}</Text>
              </>
            )}
          </View>
        </View>
        <View style={styles.centerSection}>
          <Text>{project.name}</Text>
        </View>
        <View>
          <Summary
            projectCapabilities={project.projectCapabilities ?? []}
            formGroups={formGroups}
            categories={project.categories ?? []}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {project.categories?.map((category, index) => (
          <View key={category.id}>
            <View style={styles.rowTitleSection}>
              <Text id={`${category.name}`}>
                {index + 1} {t(`PROJECT_CATEGORIES.${category.name}`)}
              </Text>
            </View>
            <FilterFormGroups
              formGroups={formGroups}
              projectCategoryFilter={category}
              projectCapabilities={project.projectCapabilities}
              RenderElement={({ formGroups }) => {
                return (
                  <>
                    {formGroups.map((formGroup, formGroupIndex) => (
                      <React.Fragment key={formGroup.id}>
                        <RenderFormGroup
                          capabilityConfigurationValues={
                            capabilityConfigurationValues
                          }
                          formGroup={formGroup}
                          index={`${index + 1}.${formGroupIndex + 1}`}
                        />
                      </React.Fragment>
                    ))}
                  </>
                );
              }}
            />
          </View>
        ))}
      </Page>
    </Document>
  );
};
export default SpecificationSheetPdf;
