import React, { ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import useCompanies from "../../api/useCompanies";
import { Form, FormValuesType, RelationType } from "../../components";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";

interface CompanyFormProps {
  initialValues: {
    id: string;
    companyId: RelationType;
    name: string;
    content: string;
    // todo: add SearchInput as RelationType for 1on1 relations
    type: RelationType;
  };
  handleSubmit: (values: Record<string, any>) => void | Promise<void>;
  children: ReactNode;
}

interface ProfileBlockFormFormInterface extends FormValuesType {
  companyId: RelationType;
  id: string;
  name: string;
  content: string;
  type: RelationType;
}

const ProfileBlockForm = ({
  initialValues,
  handleSubmit,
  children,
}: CompanyFormProps): ReactElement => {
  const { t } = useTranslation();
  const { companies } = useCompanies();

  const [initialType] = initialValues.type;
  const [type, setType] = useState<PublicProfileBlockTypeEnum>(
    (initialType?.label as PublicProfileBlockTypeEnum) ??
      PublicProfileBlockTypeEnum.HTML
  );

  const handleTypeChange = (
    changedValues: ProfileBlockFormFormInterface
  ): void => {
    const [changedType] = changedValues.type;
    if (changedType === undefined || changedType.label === type) {
      return;
    }

    setType(changedType.label as PublicProfileBlockTypeEnum);
  };

  return (
    <Form<ProfileBlockFormFormInterface>
      handleChange={handleTypeChange}
      fields={[
        {
          id: "companyId",
          label: t("PUBLIC_PROFILE.BLOCKS.company"),
          type: "relation",
        },
        {
          id: "id",
          label: t("PUBLIC_PROFILE.BLOCKS.name"),
          type: "hidden",
        },
        {
          id: "name",
          label: t("PUBLIC_PROFILE.BLOCKS.name"),
          type: "text",
        },
        {
          id: "content",
          label: t("PUBLIC_PROFILE.BLOCKS.content"),
          type: type === PublicProfileBlockTypeEnum.HTML ? "text" : "file",
        },
        {
          id: "type",
          label: t("PUBLIC_PROFILE.BLOCKS.type"),
          type: "relation",
        },
      ]}
      populate={{
        companyId: companies.map(({ name, id }) => ({
          id: id ?? "",
          label: name ?? "",
        })),
        type: [
          {
            id: "html",
            label: PublicProfileBlockTypeEnum.HTML,
          },
          {
            id: "image",
            label: PublicProfileBlockTypeEnum.IMAGE,
          },
        ],
      }}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      {children}
    </Form>
  );
};

export default ProfileBlockForm;
