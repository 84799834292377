import { TranslationInterface } from "../../interfaces/TranslationInterface";
import useGetTranslations from "./useGetTranslations";
import useTranslationStore from "../../stores/useTranslationStore";
import useGetTranslation from "./useGetTranslation";
import useCreateTranslation from "./useCreateTranslation";
import useGetTranslationByRelationId from "./useGetTranslationByRelationId";
import useFormGroupStore from "../../stores/useFormGroupStore";
import useUpdateTranslation from "./useUpdateTranslation";
import useCapabilityStore from "../../stores/useCapabilityStore";
import useCapabilityGroupStore from "../../stores/useCapabilityGroupStore";

interface UseTranslationsInterface {
  getAll: () => Promise<TranslationInterface[]>;
  getById: (id: string) => Promise<TranslationInterface | null>;
  getByFormGroupId: (
    formGroupId: string
  ) => Promise<TranslationInterface[] | null>;
  getByCapabilityId: (
    capabilityId: string
  ) => Promise<TranslationInterface[] | null>;
  getByCapabilityGroupId: (
    capabilityGroupId: string
  ) => Promise<TranslationInterface[] | null>;
  create: (translation: TranslationInterface) => Promise<TranslationInterface>;
  update: (
    translation: TranslationInterface
  ) => Promise<TranslationInterface | null>;
}

const useTranslations = (): UseTranslationsInterface => {
  const add = useTranslationStore((state) => state.add);
  const set = useTranslationStore((state) => state.set);
  const gotAll = useTranslationStore((state) => state.gotAll);
  const stateGetAll = useTranslationStore((state) => state.getAll);
  const stateUpdate = useTranslationStore((state) => state.update);
  const stateFormGroupAddTranslation = useFormGroupStore(
    (state) => state.addTranslation
  );
  const stateFormGroupUpdateTranslation = useFormGroupStore(
    (state) => state.updateTranslation
  );
  const stateCapabilityAddTranslation = useCapabilityStore(
    (state) => state.addTranslation
  );
  const stateCapabilityUpdateTranslation = useCapabilityStore(
    (state) => state.updateTranslation
  );
  const stateCapabilityGroupAddTranslation = useCapabilityGroupStore(
    (state) => state.addTranslation
  );
  const stateCapabilityGroupUpdateTranslation = useCapabilityGroupStore(
    (state) => state.updateTranslation
  );
  const stateGetById = useTranslationStore((state) => state.getById);
  const stateGetByFormGroupId = useTranslationStore(
    (state) => state.getByFormGroupId
  );
  const stateGetByCapabilityId = useTranslationStore(
    (state) => state.getByCapabilityIdId
  );
  const stateGetByCapabilityGroupId = useTranslationStore(
    (state) => state.getByCapabilityGroupIdId
  );
  const setGotAll = useTranslationStore((state) => state.setGotAll);
  const { getAll: apiGetAll } = useGetTranslations();
  const { create: apiCreate } = useCreateTranslation();
  const { update: apiUpdate } = useUpdateTranslation();
  const { getById: apiGetById } = useGetTranslation();
  const { getByRelationId: apiGetByRelationId } =
    useGetTranslationByRelationId();

  const getAll = async (): Promise<TranslationInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const translations = await apiGetAll();

    set(translations);
    setGotAll();

    return translations;
  };

  const getById = async (id: string): Promise<TranslationInterface | null> => {
    let translation = stateGetById(id);

    if (translation !== null) {
      return translation;
    }

    translation = await apiGetById(id);

    if (translation === null) {
      return null;
    }

    add(translation);
    return translation;
  };

  const getByRelationId = async (
    relationId: string
  ): Promise<TranslationInterface[] | null> => {
    const translations = await apiGetByRelationId(relationId);

    if (translations === null) {
      return null;
    }

    add(translations);
    return translations;
  };

  const getByFormGroupId = async (
    formGroupId: string
  ): Promise<TranslationInterface[] | null> => {
    const translations = stateGetByFormGroupId(formGroupId);

    if (translations !== null && translations.length > 0) {
      return translations;
    }

    return await getByRelationId(formGroupId);
  };

  const getByCapabilityId = async (
    capabilityId: string
  ): Promise<TranslationInterface[] | null> => {
    const translations = stateGetByCapabilityId(capabilityId);

    if (translations !== null && translations.length > 0) {
      return translations;
    }

    return await getByRelationId(capabilityId);
  };

  const getByCapabilityGroupId = async (
    capabilityGroupId: string
  ): Promise<TranslationInterface[] | null> => {
    const translations = stateGetByCapabilityGroupId(capabilityGroupId);

    if (translations !== null && translations.length > 0) {
      return translations;
    }

    return await getByRelationId(capabilityGroupId);
  };

  const create = async (
    translation: TranslationInterface
  ): Promise<TranslationInterface> => {
    const created = await apiCreate(translation);

    add(created);

    if (translation.formGroupId !== undefined) {
      stateFormGroupAddTranslation({
        formGroupId: translation.formGroupId,
        translation,
      });
    }
    if (translation.capabilityId !== undefined) {
      stateCapabilityAddTranslation({
        capabilityId: translation.capabilityId,
        translation,
      });
      stateFormGroupAddTranslation({
        capabilityId: translation.capabilityId,
        translation,
      });
    }
    if (translation.capabilityGroupId !== undefined) {
      stateCapabilityGroupAddTranslation({
        capabilityGroupId: translation.capabilityGroupId,
        translation,
      });
      stateFormGroupAddTranslation({
        capabilityGroupId: translation.capabilityGroupId,
        translation,
      });
    }

    return created;
  };

  const update = async (
    translation: TranslationInterface
  ): Promise<TranslationInterface | null> => {
    const updatedTranslation = await apiUpdate(translation);

    if (updatedTranslation === null || updatedTranslation.id === undefined) {
      return null;
    }

    stateUpdate({
      id: updatedTranslation.id,
      updatedDataSet: updatedTranslation,
    });

    if (translation.formGroupId !== undefined) {
      stateFormGroupUpdateTranslation({
        formGroupId: translation.formGroupId,
        translation,
      });
    }
    if (translation.capabilityId !== undefined) {
      stateCapabilityUpdateTranslation({
        capabilityId: translation.capabilityId,
        translation,
      });
      stateFormGroupUpdateTranslation({
        capabilityId: translation.capabilityId,
        translation,
      });
    }
    if (translation.capabilityGroupId !== undefined) {
      stateCapabilityGroupUpdateTranslation({
        capabilityGroupId: translation.capabilityGroupId,
        translation,
      });
      stateFormGroupUpdateTranslation({
        capabilityGroupId: translation.capabilityGroupId,
        translation,
      });
    }

    return updatedTranslation;
  };
  return {
    getAll,
    getById,
    getByFormGroupId,
    getByCapabilityId,
    getByCapabilityGroupId,
    create,
    update,
  };
};

export default useTranslations;
