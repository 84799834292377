import { CapabilityGroupCollectionInterface } from "../interfaces/CapabilityGroupCollectionInterface";
import { FormGroupInterface } from "../interfaces/FormGroupInterface";

const getCapabilityGroups = (
  formGroup: FormGroupInterface
): CapabilityGroupCollectionInterface[] => {
  const groups: CapabilityGroupCollectionInterface[] = [
    {
      name: "ungrouped",
      capabilities: [],
    },
  ];

  formGroup.capabilities?.forEach((capability) => {
    const group = groups.find((searchGroup) =>
      capability.capabilityGroup !== undefined &&
      capability.capabilityGroup !== null
        ? searchGroup.id === capability.capabilityGroup.id
        : searchGroup.name === "ungrouped"
    );

    if (
      group === undefined &&
      capability.capabilityGroup !== undefined &&
      capability.capabilityGroup !== null
    ) {
      groups.push({
        name: capability.capabilityGroup.name,
        id: capability.capabilityGroup.id,
        capabilities: [{ ...capability, edit: true }],
        translation: capability.capabilityGroup.translation,
      });
      return;
    }

    if (group === undefined) {
      return;
    }

    group.capabilities.push({
      ...capability,
      edit: true,
    });
  });

  return groups;
};

export default getCapabilityGroups;
