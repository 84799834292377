import useApi from "../useApi";
import { FrontendInterface } from "../../interfaces/FrontendInterface";

interface UseUpdateFrontendInterface {
  update: (frontend: FrontendInterface) => Promise<FrontendInterface | null>;
}

const useUpdateFrontend = (): UseUpdateFrontendInterface => {
  const { put } = useApi();

  const update = async (
    frontend: FrontendInterface
  ): Promise<FrontendInterface | null> => {
    if (frontend.id === undefined) {
      return null;
    }

    const { data, status } = await put(`/frontend/${frontend.id}`, frontend);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateFrontend;
