import { TranslationInterface } from "../../interfaces/TranslationInterface";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useTranslations from "../../api/translations/useTranslations";
import { Button, Column, Form, Row } from "../../components";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

const StyledHeightDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: stretch;
  padding-bottom: 2rem;
`;

interface EditTranslationFormProps {
  translation: TranslationInterface;
}
const EditTranslationForm = ({
  translation,
}: EditTranslationFormProps): ReactElement => {
  const { t } = useTranslation();
  const { update } = useTranslations();

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [value, setValue] = useState<string>(translation.value);

  const handleSubmit = async (): Promise<void> => {
    await update({
      ...translation,
      value,
    });
    setIsTouched(false);
  };

  return (
    <Row mb="0">
      <Column>
        <Form<{
          value: string;
        }>
          handleChange={({ value: updatedValue }) => {
            setValue(updatedValue);
            if (updatedValue === translation.value) {
              setIsTouched(false);
              return;
            }
            setIsTouched(true);
          }}
          fields={[
            {
              type: "text",
              label: t(`PROJECT_MANDATE.${translation.mandate}`),
              id: "value",
            },
          ]}
          initialValues={{ value: translation.value }}
          handleSubmit={() => {}}
        >
          {""}
        </Form>
      </Column>
      <AnimatePresence>
        {isTouched && (
          <Column size="shrink" padding="0 1.75rem 0 0">
            <StyledHeightDiv>
              <Button
                initial={{ x: "-2rem" }}
                animate={{ x: 0 }}
                handleClick={handleSubmit}
                variant="success"
              >
                {t("BUTTONS.save")}
              </Button>
            </StyledHeightDiv>
          </Column>
        )}
      </AnimatePresence>
    </Row>
  );
};

export default EditTranslationForm;
