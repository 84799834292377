import { create } from "zustand";
import { ProjectCategoryInterface } from "../interfaces/ProjectCategoryInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";

interface ProjectCategoryState
  extends CrudStateInterface<ProjectCategoryInterface> {}

const useProjectCategoryStore = create<ProjectCategoryState>((set, get) => ({
  ...defaultStateCrud<ProjectCategoryInterface>(set, get),
}));

export default useProjectCategoryStore;
