import useGetCompanies from "./useGetCompanies";
import useDeleteCompany from "./useDeleteCompany";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import useUpdateCompany from "./useUpdateCompany";
import useCreateCompany from "./companies/useCreateCompany";

interface UseCompaniesInterface {
  status: RequestStatusType;
  companies: CompanyInterface[];
  deleteById: ({ id }: { id: string }) => Promise<void>;
  update: (companyInterface: CompanyInterface) => Promise<void>;
  create: (company: CompanyInterface) => Promise<void>;
}

const useCompanies = (): UseCompaniesInterface => {
  const { companies, status, removeCompany, updateCompany, createCompany } =
    useGetCompanies();
  const { create: apiCreate } = useCreateCompany();
  const { deleteById: apiDeleteById } = useDeleteCompany();
  const { update: apiUpdate } = useUpdateCompany();

  const deleteById = async ({ id }: { id: string }): Promise<void> => {
    const deleted = await apiDeleteById({ id });
    if (deleted) {
      removeCompany({ id });
    }
  };

  const create = async (company: CompanyInterface): Promise<void> => {
    const created = await apiCreate(company);
    if (created !== null) {
      createCompany(created);
    }
  };

  const update = async (company: CompanyInterface): Promise<void> => {
    const updated = await apiUpdate(company);
    if (updated !== null) {
      updateCompany(updated);
    }
  };
  return {
    status,
    companies,
    deleteById,
    update,
    create,
  };
};
export default useCompanies;
