import { useState } from "react";

export type SearchConfigurationSettingType = Array<{
  id: string;
  enabled: boolean;
}>;

interface UseSearchConfigurationInterface {
  toggle: (id?: string) => void;
  enable: (id?: string) => void;
  enableMany: (ids: string[]) => void;
  isEnabled: (id?: string) => boolean;
  searchConfiguration: SearchConfigurationSettingType;
}
const useSearchConfiguration = (): UseSearchConfigurationInterface => {
  const [searchConfiguration, setSearchConfiguration] =
    useState<SearchConfigurationSettingType>([]);

  const toggle = (id?: string): void => {
    if (id === undefined) {
      return;
    }

    const currentSearchConfig = [...searchConfiguration];

    const found = currentSearchConfig.find(
      (projectCategory) => projectCategory.id === id
    );

    if (found !== undefined) {
      found.enabled = !found.enabled;
    } else {
      currentSearchConfig.push({
        id,
        enabled: true,
      });
    }

    setSearchConfiguration(currentSearchConfig);
  };

  const enableMany = (ids: string[]): void => {
    const currentSearchConfig = [...searchConfiguration];

    ids.forEach((id) => {
      const found = currentSearchConfig.find(
        (projectCategory) => projectCategory.id === id
      );

      if (found !== undefined) {
        found.enabled = true;
      } else {
        currentSearchConfig.push({
          id,
          enabled: true,
        });
      }
    });

    setSearchConfiguration(currentSearchConfig);
  };

  const enable = (id?: string): void => {
    if (id === undefined) {
      return;
    }

    const currentSearchConfig = [...searchConfiguration];

    const found = currentSearchConfig.find(
      (projectCategory) => projectCategory.id === id
    );

    if (found !== undefined) {
      found.enabled = true;
    } else {
      currentSearchConfig.push({
        id,
        enabled: true,
      });
    }

    setSearchConfiguration(currentSearchConfig);
  };

  const isEnabled = (id?: string): boolean => {
    if (id === undefined) {
      return false;
    }
    const foundCategory = searchConfiguration.find((group) => group.id === id);

    if (foundCategory === undefined) {
      return false;
    }

    return foundCategory.enabled;
  };

  return { searchConfiguration, toggle, isEnabled, enable, enableMany };
};

export default useSearchConfiguration;
