import React, { ReactElement, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import UnlockModalContent from "./UnlockModalContent";
import { Button, Space, Text } from "../../components";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import useCompanies from "../../api/companies/useCompanies";
import { useTranslation } from "react-i18next";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";

interface UnlockModalProps {
  isModalOpen: boolean;
  handleModalOpenChange: (isOpen: boolean) => void;
  handleAccept: (company: CompanyInterface) => Promise<void> | void;
  projectContracts?: ProjectContractInterface[];
}
const UnlockModal = ({
  isModalOpen,
  handleModalOpenChange,
  handleAccept,
  projectContracts,
}: UnlockModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getCurrentUsersCompanies } = useCompanies();

  const [chooseCompany, setChooseCompany] = useState<CompanyInterface[] | null>(
    null
  );

  useEffect(() => {
    if (isModalOpen) {
      return;
    }

    setChooseCompany(null);
  }, [isModalOpen]);

  const handleUnlock = async (): Promise<void> => {
    if (projectContracts === undefined) {
      return;
    }
    const userCompanies = await getCurrentUsersCompanies();
    const companies = userCompanies.filter((userCompany) => {
      return (
        projectContracts?.find(
          (projectContract) => projectContract.companyId === userCompany.id
        ) !== undefined
      );
    });

    if (companies.length === 0) {
      return;
    }

    if (companies.length > 1) {
      setChooseCompany(companies);
      return;
    }

    await handleAccept(companies[0]);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={() => handleModalOpenChange(false)}
    >
      <UnlockModalContent
        handleCancelClick={() => handleModalOpenChange(false)}
        handleAcceptClick={handleUnlock}
      />
      {chooseCompany !== null && (
        <>
          <Space size={2} />
          <Text>{t("PROJECT.UNLOCK.choose-company")}</Text>
          <Space size={2} />
          {chooseCompany.map((company, index) => (
            <React.Fragment key={company.id}>
              <Button
                handleClick={async () => {
                  await handleAccept(company);
                }}
                fullWidth
                type="button"
              >
                {company.name}
              </Button>
              {index < chooseCompany.length - 1 && <Space />}
            </React.Fragment>
          ))}
        </>
      )}
    </Modal>
  );
};

export default UnlockModal;
