import React, { ReactElement, useEffect, useState } from "react";
import { CapabilityInterface } from "../../interfaces/CapabilityInterface";
import useTranslations from "../../api/translations/useTranslations";
import { TranslationInterface } from "../../interfaces/TranslationInterface";
import { AddTranslationFormValuesInterface } from "./AddTranslationForm";
import Translation from "./Translation";
import useGetCapabilityLabel from "../../hooks/useGetCapabilityLabel";

interface CapabilityTranslationProps {
  capability: CapabilityInterface;
}
const CapabilityTranslation = ({
  capability,
}: CapabilityTranslationProps): ReactElement => {
  const { getByCapabilityId, create } = useTranslations();
  const { getShortLabel } = useGetCapabilityLabel();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [translations, setTranslations] = useState<
    TranslationInterface[] | null
  >(null);

  useEffect(() => {
    if (!isPopoverOpen || capability.id === undefined) {
      return;
    }

    void getByCapabilityId(capability.id).then((data) => {
      setTranslations(data ?? []);
    });
  }, [capability, isPopoverOpen]);

  const handleAddTranslation = async (
    translation: AddTranslationFormValuesInterface
  ): Promise<void> => {
    if (translation.mandate === "") {
      return;
    }
    await create({
      language: translation.language,
      capabilityId: capability.id,
      translationType: "CAPABILITY",
      mandate: translation.mandate,
      value: translation.value,
    });
  };

  return (
    <Translation
      handleAddTranslation={handleAddTranslation}
      translations={translations}
      headline={getShortLabel(capability)}
      isPopoverOpen={isPopoverOpen}
      handlePopoverOpenChange={setIsPopoverOpen}
    />
  );
};

export default CapabilityTranslation;
