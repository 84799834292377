import styled from "styled-components";
import StyledPopover from "../../components/styles/StyledPopover";
import { TranslationInterface } from "../../interfaces/TranslationInterface";
import AddTranslationForm, {
  AddTranslationFormValuesInterface,
} from "./AddTranslationForm";
import React, {
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";
import GenericPopover from "../../components/GenericPopover";
import TranslationIcon from "../../icons/TranslationIcon";
import {
  Clickable,
  Column,
  Headline,
  LoadingSkeleton,
  Row,
} from "../../components";
import CircledPlusIcon from "../../icons/CircledPlusIcon";
import { CircledMinusIcon } from "../../icons";
import EditTranslationForm from "./EditTranslationForm";

const StyledTranslationLink = styled.a`
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledTranslationPopover = styled(StyledPopover)`
  width: 50rem;
`;

interface TranslationProps {
  headline: string;
  isPopoverOpen: boolean;
  handlePopoverOpenChange: (isPopoverOpen: boolean) => void;
  translations: TranslationInterface[] | null;
  handleAddTranslation: (
    translation: AddTranslationFormValuesInterface
  ) => Promise<void>;
}
const Translation = ({
  headline,
  isPopoverOpen,
  handlePopoverOpenChange,
  translations,
  handleAddTranslation: executeAddTranslation,
}: TranslationProps): ReactElement => {
  const { t } = useTranslation();

  const [isShowAddForm, setIsShowAddForm] = useState<boolean>(false);

  const toggleAddForm: MouseEventHandler<HTMLAnchorElement> = (
    e: MouseEvent<HTMLAnchorElement>
  ): void => {
    setIsShowAddForm(!isShowAddForm);
    e.stopPropagation();
  };

  const handleAddTranslation = async (
    translation: AddTranslationFormValuesInterface
  ): Promise<void> => {
    await executeAddTranslation(translation);
    setIsShowAddForm(false);
  };

  const filteredMandates = useMemo(
    () =>
      (Object.keys(ProjectMandateEnum) as ProjectMandateEnum[]).filter(
        (mandate: ProjectMandateEnum) => {
          return (
            translations?.find(
              (translation) => translation.mandate === mandate
            ) === undefined
          );
        }
      ),
    [translations]
  );
  const isAddFormAvailable = filteredMandates.length > 0;

  return (
    <GenericPopover
      isPopoverOpen={isPopoverOpen}
      closePopover={() => handlePopoverOpenChange(false)}
      align="center"
      triggerElement={
        <>
          <StyledTranslationLink
            onClick={() => handlePopoverOpenChange(!isPopoverOpen)}
          >
            <TranslationIcon />
          </StyledTranslationLink>
        </>
      }
    >
      <StyledTranslationPopover>
        <Row mb="0">
          <Column>
            <Headline>
              {t("TRANSLATION.headline")} &quot;
              {headline}&quot;
            </Headline>
          </Column>
          <Column size="shrink">
            {isAddFormAvailable && (
              <Clickable onClick={toggleAddForm}>
                {!isShowAddForm && <CircledPlusIcon />}
                {isShowAddForm && <CircledMinusIcon />}
              </Clickable>
            )}
          </Column>
        </Row>
        {isShowAddForm && isAddFormAvailable && (
          <AddTranslationForm
            handleSubmit={handleAddTranslation}
            mandates={filteredMandates}
          />
        )}
        {translations === null ?? <LoadingSkeleton />}
        {translations !== null && (
          <>
            {translations.length === 0 && <>{t("TRANSLATION.none")}</>}
            {translations.length > 0 && (
              <>
                {translations.map((translation) => {
                  return (
                    <React.Fragment key={translation.id}>
                      <EditTranslationForm translation={translation} />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </>
        )}
      </StyledTranslationPopover>
    </GenericPopover>
  );
};

export default Translation;
