import React, { ReactElement, useState } from "react";
import {
  Button,
  Column,
  Form,
  FormFieldInterface,
  Row,
} from "../../components";
import CheckIcon from "../../icons/CheckIcon";
import { useTranslation } from "react-i18next";

interface MasterDataFormProps<
  MasterDataFields extends Record<string, string | number>
> {
  fields: FormFieldInterface[];
  handleNext: (data: MasterDataFields) => Promise<void>;
  handleChange: (values: MasterDataFields) => void;
  handlePrevious: () => void;
  initialValues: MasterDataFields;
}

const MasterDataForm = <
  MasterDataFields extends Record<string, string | number>
>({
  fields,
  handleChange,
  handleNext,
  handlePrevious,
  initialValues,
}: MasterDataFormProps<MasterDataFields>): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <Form<MasterDataFields>
        fields={fields}
        initialValues={initialValues}
        handleSubmit={async (data) => {
          setIsSubmitting(true);
          await handleNext(data);
          setIsSubmitting(false);
        }}
        handleChange={(formValues) => {
          handleChange(formValues);
        }}
      >
        <Row>
          <Column>
            <Button type="button" handleClick={handlePrevious}>
              {t("FORMS.previous")}
            </Button>
          </Column>
          <Column align="right">
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={isSubmitting ? "success" : "default"}
              whileTap={{
                scale: 0.8,
              }}
            >
              {!isSubmitting ? t("FORMS.continue") : <CheckIcon />}
            </Button>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default MasterDataForm;
