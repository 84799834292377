import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { ProjectMandateType } from "../../interfaces/ProjectMandateType";

interface UseGetProjectsByMandateInterface {
  getByMandate: ({
    mandate,
  }: {
    mandate: ProjectMandateType;
  }) => Promise<ProjectInterface[]>;
}

const useGetProjectsByMandate = (): UseGetProjectsByMandateInterface => {
  const { get } = useApi();

  const getByMandate = async ({
    mandate,
  }: {
    mandate: ProjectMandateType;
  }): Promise<ProjectInterface[]> => {
    const { data, status } = await get(`/project/by-mandate/${mandate}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByMandate };
};

export default useGetProjectsByMandate;
