import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseGetFormGroupInterface {
  getById: (id: string) => Promise<FormGroupInterface | null>;
}

const useGetFormGroup = (): UseGetFormGroupInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<FormGroupInterface | null> => {
    const { data, status } = await get(`/form-group/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetFormGroup;
