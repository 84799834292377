import useApi from "../useApi";
import { TranslationInterface } from "../../interfaces/TranslationInterface";

interface UseGetTranslationsInterface {
  getAll: () => Promise<TranslationInterface[]>;
}

const useGetTranslations = (): UseGetTranslationsInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<TranslationInterface[]> => {
    const { data, status } = await get(`/translation`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetTranslations;
