import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";

interface UseCreateProjectInterface {
  create: (project: ProjectInterface) => Promise<ProjectInterface>;
}

const useCreateProject = (): UseCreateProjectInterface => {
  const { postForm } = useApi();

  const create = async (
    project: ProjectInterface
  ): Promise<ProjectInterface> => {
    const files: Array<{ file: File; name: string }> = [];
    project.projectCapabilities?.forEach((projectCapability) => {
      if (!(projectCapability.value instanceof File)) {
        return;
      }
      files.push({
        file: projectCapability.value,
        name: projectCapability.capabilityId,
      });
    });
    const { status, data: createdProject } = await postForm(
      `/project`,
      {
        ...project,
      },
      files
    );

    if (status === 200) {
      return createdProject;
    }

    throw new Error("Project creation error");
  };

  return { create };
};

export default useCreateProject;
