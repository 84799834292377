import React, { ReactElement, useEffect, useState } from "react";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import TableControlBar from "../components/TableControlBar";
import { CloseIcon } from "../icons";
import PlusIcon from "../icons/PlusIcon";
import { AnimatePresence } from "framer-motion";
import { Button, Card, Space } from "../components";
import Right from "../components/Right";
import CheckIcon from "../icons/CheckIcon";
import Table from "../components/Table";
import { Row } from "@tanstack/react-table";
import CompanyForm from "./CompanyForm";
import useCompanies from "../api/useCompanies";
import { CompanyTypeType } from "../interfaces/CompanyTypeType";
import useCompanyListColumns, {
  CompanyColumn,
} from "../hooks/useCompanyListColumns";
import { useTranslation } from "react-i18next";

interface CompanyListProps {
  filter?: CompanyTypeType;
}
const CompanyList = ({ filter }: CompanyListProps): ReactElement => {
  const { companies, status, deleteById, update, create } = useCompanies();
  const { columns } = useCompanyListColumns();
  const { t } = useTranslation();

  const [data, setData] = useState<CompanyColumn[]>([]);
  const [isShowCompanyAddForm, setIsShowCompanyAddForm] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setData(
      companies.map((company) => {
        return { ...company, edit: true };
      })
    );
  }, [companies]);

  const activeFilter =
    filter === undefined
      ? []
      : [
          {
            id: "types",
            value: {
              filter: (rowData: Row<CompanyInterface>) => {
                const type = rowData.original.types?.find(
                  (companyType) => companyType.name === filter
                );
                return type !== undefined;
              },
            },
          },
        ];

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowCompanyAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowCompanyAddForm(!isShowCompanyAddForm)}
      />
      <AnimatePresence>
        {isShowCompanyAddForm && (
          <React.Fragment key={1}>
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <CompanyForm
                initialValues={{
                  id: "",
                  name: "",
                  address: "",
                  country: "",
                  users: [],
                }}
                handleSubmit={(company) => {
                  setIsSubmitting(true);
                  void create({
                    name: company.name,
                    address: company.address,
                    country: company.country,
                    types:
                      filter !== undefined
                        ? [
                            {
                              name: filter,
                            },
                          ]
                        : [],
                  }).then(() => {
                    setIsSubmitting(false);
                    setIsShowCompanyAddForm(false);
                  });
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </CompanyForm>
            </Card>
            <Space />
          </React.Fragment>
        )}

        <Table
          data={data}
          columns={columns}
          isLoading={status !== "success"}
          isShowMore
          options={[
            {
              label: t("BUTTONS.edit"),
            },
            {
              label: t("BUTTONS.delete"),
              onClick: async (id): Promise<void> => {
                await deleteById({ id }).catch();
              },
            },
          ]}
          filter={activeFilter}
          getRowCanExpand={() => true}
          renderSubComponent={({ row }) => {
            if (row.original.id === undefined) {
              return <></>;
            }
            return (
              <>
                <Space />
                <Card
                  initial={{ y: "-2rem" }}
                  animate={{ y: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <CompanyForm
                    initialValues={{
                      id: row.original.id,
                      name: row.original.name ?? "",
                      address: row.original.address ?? "",
                      country: row.original.country ?? "",
                      users:
                        row.original.users !== undefined
                          ? row.original.users.map((user) => {
                              return {
                                id: user.id ?? "",
                                label: user.email,
                              };
                            })
                          : [],
                    }}
                    handleSubmit={(company) => {
                      setIsSubmitting(true);
                      void update({
                        id: company.id,
                        name: company.name,
                        address: company.address,
                        country: company.country,
                      }).then(() => {
                        setIsSubmitting(false);
                        row.getToggleExpandedHandler()();
                      });
                    }}
                  >
                    <Right>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant={isSubmitting ? "success" : "default"}
                        whileTap={{
                          scale: 0.8,
                        }}
                      >
                        {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                      </Button>
                    </Right>
                  </CompanyForm>
                </Card>
                <Space size={3} />
              </>
            );
          }}
        />
      </AnimatePresence>
    </>
  );
};

export default CompanyList;
