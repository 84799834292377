import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import React, { ReactElement, useState } from "react";
import FormGroupForm from "./FormGroupForm";
import FormGroupCapabilities from "./FormGroupCapabilities";

interface FormGroupDetailsProps {
  formGroup: FormGroupInterface;
}

const FormGroupDetails = ({
  formGroup,
}: FormGroupDetailsProps): ReactElement => {
  const [showCapabilities, setShowCapabilities] = useState<boolean>(false);

  const handleBack = (): void => {
    setShowCapabilities(false);
  };

  return (
    <>
      {!showCapabilities ? (
        <FormGroupForm
          formGroup={formGroup}
          handleCheckCapabilitiesClick={() => setShowCapabilities(true)}
        />
      ) : (
        <>
          <FormGroupCapabilities
            formGroup={formGroup}
            handleBack={handleBack}
          />
        </>
      )}
    </>
  );
};

export default FormGroupDetails;
