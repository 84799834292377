import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { ShowMoreOptionInterface } from "../../interfaces/ShowMoreOptionInterface";
import StyledPopover from "../styles/StyledPopover";
import GenericPopover from "../GenericPopover";
import StyledEditSpan from "./StyledEditSpan";
import { MoreIcon } from "../../icons";

const StyledPopoverUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 20rem;
`;

const StyledPopoverLi = styled.li`
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.light};
  }
`;

const ShowMore = ({
  options,
  id,
  handleExpand,
}: {
  options: ShowMoreOptionInterface[];
  id: string;
  handleExpand: () => void;
}): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleMoreClick = (): void => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <GenericPopover
      isPopoverOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      triggerElement={
        <>
          <StyledEditSpan onClick={handleMoreClick}>
            <MoreIcon />
          </StyledEditSpan>
        </>
      }
    >
      <StyledPopover>
        <StyledPopoverUl>
          {options.map((option) => (
            <StyledPopoverLi
              key={option.label}
              onClick={() => {
                setIsPopoverOpen(false);
                if (option.onClick === undefined) {
                  handleExpand();
                  return;
                }
                void option.onClick(id);
              }}
            >
              {option.label}
            </StyledPopoverLi>
          ))}
        </StyledPopoverUl>
      </StyledPopover>
    </GenericPopover>
  );
};

export default ShowMore;
