import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseGetFormGroupsInterface {
  getAll: () => Promise<FormGroupInterface[]>;
}

const useGetFormGroups = (): UseGetFormGroupsInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<FormGroupInterface[]> => {
    const { data, status } = await get(`/form-group`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetFormGroups;
