import React, { ReactElement, useState } from "react";
import CompanyUserSelection, {
  CompanyUserSelectionConfigurationInterface,
} from "./CompanyUserSelection";
import ProjectMasterDataForm, {
  ProjectMasterDataConfigurationInterface,
} from "./ProjectMasterDataForm";
import { Matching } from "../index";
import useProjectMatching, {
  RelevantCapability,
} from "../Matching/hooks/useProjectMatching";
import useProjects from "../../api/projects/useProjects";
import { Headline } from "../../components";
import { useTranslation } from "react-i18next";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { FormikValues } from "formik";
import useFormToCapabilityAdapter from "../Entities/hooks/useFormToCapabilityAdapter";
import useCompanies from "../../api/companies/useCompanies";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";

export interface CreateProjectConfigurationInterface
  extends CompanyUserSelectionConfigurationInterface,
    ProjectMasterDataConfigurationInterface {
  capabilities: RelevantCapability[];
}

const CreateProject = (): ReactElement => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(1);
  const [configuration, setConfiguration] =
    useState<CreateProjectConfigurationInterface>({
      companyId: null,
      userId: null,
      name: "",
      description: "",
      capabilities: [],
    });
  const [isShowSaveProject, setIsShowSaveProject] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string | undefined>();

  const { create: createProject } = useProjects();
  const { getCurrentUsersCompanies } = useCompanies();
  const { getRelevantCapabilities } = useProjectMatching();
  const { adapt } = useFormToCapabilityAdapter();

  const handleNext = (
    updatedConfiguration:
      | CompanyUserSelectionConfigurationInterface
      | ProjectMasterDataConfigurationInterface
  ): void => {
    setConfiguration({
      ...configuration,
      ...updatedConfiguration,
    });
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = (): void => setActiveStep(activeStep - 1);

  const handleSaveProject = async ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }): Promise<void> => {
    const projectCapabilities = await adapt({
      formData,
      enabledCategories,
      files,
    });
    const relevantCapabilities = getRelevantCapabilities({
      projectCategories: enabledCategories,
      values: formData,
    });

    setConfiguration({
      ...configuration,
      capabilities: relevantCapabilities,
    });

    if (configuration.companyId === null) {
      return;
    }

    const categoryList: string[] = [];
    relevantCapabilities.forEach((capability) => {
      if (categoryList.includes(capability.projectCategoryName)) {
        return;
      }
      categoryList.push(capability.projectCategoryName);
    });

    const usersCompanies = await getCurrentUsersCompanies();

    const projectContracts: ProjectContractInterface[] = [];
    usersCompanies.forEach((usersCompany) => {
      if (usersCompany.id === undefined) {
        return;
      }
      projectContracts.push({ companyId: usersCompany.id });
    });

    const project = await createProject({
      companyId: configuration.companyId,
      // todo add more types
      types: [
        {
          name: "client",
        },
      ],
      mandate: ProjectMandateEnum.REQUEST,
      userId: configuration.userId ?? undefined,
      name: configuration.name,
      description: configuration.description,
      categories: categoryList.map((category) => ({
        name: category,
      })),
      projectCapabilities,
      projectContracts,
    });
    setProjectId(project.id);
    setIsShowSaveProject(false);
  };

  return (
    <>
      {activeStep === 1 && (
        <>
          <Headline>{t("CREATE_PROJECT.step-1")}</Headline>
          <CompanyUserSelection
            handleNext={handleNext}
            values={{
              companyId: configuration.companyId,
              userId: configuration.userId,
            }}
          />
        </>
      )}
      {activeStep === 2 && (
        <>
          <Headline>{t("CREATE_PROJECT.step-2")}</Headline>
          <ProjectMasterDataForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            values={{
              name: configuration.name,
              description: configuration.description,
            }}
            handleChange={(updatedConfiguration) =>
              setConfiguration({
                ...configuration,
                ...updatedConfiguration,
              })
            }
          />
        </>
      )}
      {activeStep === 3 && (
        <>
          <Headline>{t("CREATE_PROJECT.step-3")}</Headline>
          <Matching
            handleSaveProject={handleSaveProject}
            isShowSaveProject={isShowSaveProject}
            projectId={projectId}
          />
        </>
      )}
    </>
  );
};

export default CreateProject;
