import React, { ReactElement, useEffect, useState } from "react";
import useCompanies from "../../api/companies/useCompanies";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import _ from "lodash";

const CompanyById = ({
  id,
  attribute,
}: {
  id: string;
  attribute: keyof CompanyInterface;
}): ReactElement => {
  const { getById } = useCompanies();

  const [company, setCompany] = useState<CompanyInterface | null>(null);

  useEffect(() => {
    void getById(id).then((companyById) => {
      if (_.isEqual(company, companyById)) {
        return;
      }
      setCompany(companyById);
    });
  }, [id]);

  if (company === null || company[attribute] === undefined) {
    return <></>;
  }

  return <>{company[attribute]}</>;
};

export default CompanyById;
