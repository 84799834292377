import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../components/Clickable";
import { useTranslation } from "react-i18next";
import { EditablePublicProfileBlockInterface } from "../modules/PublicProfiles/hooks/usePublicProfileListColumns";
import { PublicProfileBlockTypeEnum } from "../interfaces/PublicProfileBlockTypeEnum";
import getStaticFileUrl from "../helpers/getStaticFileUrl";

const columnHelper = createColumnHelper<EditablePublicProfileBlockInterface>();

interface UseProfileBlockListColumnsInterface {
  columns: Array<ColumnDef<EditablePublicProfileBlockInterface, any>>;
}
const useProfileBlockListColumns = (): UseProfileBlockListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<EditablePublicProfileBlockInterface, any>> = [
    columnHelper.accessor((row) => row.name, {
      header: `${t("PUBLIC_PROFILE.BLOCKS.name")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.content, {
      header: `${t("PUBLIC_PROFILE.BLOCKS.content")}`,
      cell: (info) => {
        return info.getValue();
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "script",
      header: `${t("PUBLIC_PROFILE.BLOCKS.script")}`,
      cell: (info) => {
        // todo: outsource
        return (
          <>
            <code>
              {info.row.original.type === PublicProfileBlockTypeEnum.HTML
                ? `
          <div class="pp-snippet" data-block="${
            info.getValue() as string
          }"></div>`
                : `
                <img src="${getStaticFileUrl(
                  info.row.original.originalContent
                )}" alt="${info.getValue() as string}">
              `}
            </code>
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useProfileBlockListColumns;
