import React, { ReactElement } from "react";
import { TextInputFieldProps } from "../../interfaces/InputFieldProps";
import StyledField from "../styles/StyledField";
import StyledInputField from "../styles/StyledInputField";
import StyledLabel from "../styles/StyledLabel";

const InputField = ({
  label,
  type,
  name,
  handleChange,
  handleBlur,
  value,
  disabled,
}: TextInputFieldProps): ReactElement => {
  if (
    typeof value !== "string" &&
    typeof value !== "number" &&
    value !== undefined
  ) {
    throw new Error("Invalid input field data");
  }
  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      <StyledInputField
        disabled={disabled}
        type={type}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value ?? ""}
      />
    </StyledField>
  );
};

export default InputField;
