import { ProjectMandateType } from "../../interfaces/ProjectMandateType";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, FormValuesType, Space } from "../../components";

export interface AddTranslationFormValuesInterface extends FormValuesType {
  language: "DE" | "EN";
  mandate: ProjectMandateType | "";
  value: string;
}

interface AddTranslationFormProps {
  handleSubmit: (
    values: AddTranslationFormValuesInterface
  ) => void | Promise<void>;
  mandates: ProjectMandateType[];
}

const AddTranslationForm = ({
  handleSubmit,
  mandates,
}: AddTranslationFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form<AddTranslationFormValuesInterface>
      validate={({ mandate, value }) => {
        if (mandate === "" || value === "") {
          return {
            mandate: "mandatory",
          };
        }
      }}
      fields={[
        {
          id: "language",
          label: "language",
          type: "hidden",
        },
        {
          id: "mandate",
          label: t("TRANSLATION.mandate"),
          type: "select",
          options: [
            {
              label: t("FORMS.select"),
              value: "",
            },
            ...mandates.map((mandate) => ({
              label: t(`PROJECT_MANDATE.${mandate}`),
              value: mandate,
            })),
          ],
        },
        {
          id: "value",
          label: t("TRANSLATION.value"),
          type: "text",
        },
      ]}
      initialValues={{
        language: "DE",
        mandate: "",
        value: "",
      }}
      handleSubmit={handleSubmit}
    >
      <Button fullWidth>{t("FORMS.add-new")}</Button>
      <Space size={2} />
    </Form>
  );
};

export default AddTranslationForm;
