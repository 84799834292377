import useApi from "./useApi";

interface UseDeleteUserInterface {
  deleteById: ({ id }: { id: string }) => Promise<boolean>;
}
const useDeleteUser = (): UseDeleteUserInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async ({ id }: { id: string }): Promise<boolean> => {
    const { status } = await deleteRequest(`/user/${id}`);

    return status === 200;
  };

  return { deleteById };
};

export default useDeleteUser;
