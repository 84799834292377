import { create } from "zustand";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";

interface CompanyState extends CrudStateInterface<CompanyInterface> {}
const useCompanyStore = create<CompanyState>((set, get) => ({
  ...defaultStateCrud<CompanyInterface>(set, get),
}));

export default useCompanyStore;
