import useApi from "../useApi";

interface UseCreateSpecificationInvitationAndContractInterface {
  create: ({
    projectId,
    companyId,
  }: {
    projectId: string;
    companyId: string;
  }) => Promise<boolean>;
}

const useCreateSpecificationInvitationAndContract =
  (): UseCreateSpecificationInvitationAndContractInterface => {
    const { post } = useApi();

    const create = async ({
      projectId,
      companyId,
    }: {
      projectId: string;
      companyId: string;
    }): Promise<boolean> => {
      const { status } = await post(
        `/specification/invite/company/${projectId}`,
        {
          companyId,
        }
      );

      if (status === 200) {
        return true;
      }

      throw new Error("Specification Invitation Contract creation error");
    };

    return { create };
  };

export default useCreateSpecificationInvitationAndContract;
