import useApi from "../useApi";

interface DeleteFileInterface {
  deleteById: (fileId: string) => Promise<boolean>;
}
const useDeleteFile = (): DeleteFileInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async (fileId: string): Promise<boolean> => {
    const { status } = await deleteRequest(`/file/${fileId}`);

    return status === 200;
  };

  return { deleteById };
};

export default useDeleteFile;
