import React, { ReactElement } from "react";

const ImageIcon = ({ height }: { height?: string }): ReactElement => {
  return (
    <svg
      width="26"
      height={height !== undefined ? height : "22"}
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 2 0 1.17157 0.585787 0.585787C0.585787 0.585787 1.17157 0 2 0H24C24 0 24.8284 0 25.4142 0.585786C25.4142 0.585786 26 1.17157 26 2V20C26 20 26 20.8284 25.4142 21.4142C25.4142 21.4142 24.8284 22 24 22H2C2 22 1.17157 22 0.585786 21.4142C0.585786 21.4142 0 20.8284 0 20V2ZM2 2V20H24V2H2Z"
        fill="#1C1C1C"
      />
      <path
        d="M19.0054 13.4195L24.2929 18.707C24.4804 18.8945 24.7348 18.9999 25 18.9999C25.2652 18.9999 25.5196 18.8945 25.7071 18.707C25.8946 18.5195 26 18.2651 26 17.9999C26 17.7347 25.8946 17.4803 25.7071 17.2928L20.4196 12.0053C20.1431 11.7244 19.7724 11.5692 19.7724 11.5692C19.4018 11.4141 19 11.4141 19 11.4141C18.5982 11.4141 18.2276 11.5692 18.2276 11.5692C17.8569 11.7244 17.575 12.0107 17.575 12.0107L15.0054 14.5803L15 14.5857L9.41961 9.00528C9.14306 8.72443 8.77243 8.56925 8.77243 8.56925C8.40181 8.41406 8 8.41406 8 8.41406C7.59819 8.41406 7.22757 8.56925 7.22757 8.56925C6.85694 8.72443 6.575 9.01072 6.575 9.01072L0.293271 15.2924C0.105734 15.4799 0 15.7347 0 15.9999C5.96046e-08 16.0159 0.000384986 16.0319 0.00115448 16.0479C0.0130965 16.2962 0.117104 16.5312 0.292893 16.707C0.48043 16.8945 0.734783 16.9999 1 16.9999C1.26522 16.9999 1.51957 16.8945 1.70711 16.707L8 10.4141L8.00539 10.4195L13.575 15.9891C13.8569 16.2753 14.2276 16.4305 14.2276 16.4305C14.5982 16.5857 15 16.5857 15 16.5857C15.4018 16.5857 15.7724 16.4305 15.7724 16.4305C16.1431 16.2753 16.4196 15.9945 16.4196 15.9945L19 13.4141L19.0054 13.4195Z"
        fill="#1C1C1C"
      />
      <path
        d="M18 7.5C18 8.32843 17.3284 9 16.5 9C15.6716 9 15 8.32843 15 7.5C15 6.67157 15.6716 6 16.5 6C17.3284 6 18 6.67157 18 7.5Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default ImageIcon;
