import React, { ReactElement } from "react";
import { RelationType, Tag } from "./index";

const RelationTags = ({
  relationsItems,
}: {
  relationsItems: RelationType;
}): ReactElement => {
  return (
    <>
      {relationsItems.map(({ id, label }) => {
        return (
          <React.Fragment key={id}>
            <Tag>{label}</Tag>{" "}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RelationTags;
