import useApi from "../useApi";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";

interface UseGetProjectContractsByProjectIdInterface {
  getByProjectId: (
    projectId: string
  ) => Promise<ProjectContractInterface[] | null>;
}

const useGetProjectContractsByProjectId =
  (): UseGetProjectContractsByProjectIdInterface => {
    const { get } = useApi();

    const getByProjectId = async (
      projectId: string
    ): Promise<ProjectContractInterface[] | null> => {
      const { data, status } = await get(
        `/project-contract/by-project/${projectId}`
      );

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { getByProjectId };
  };

export default useGetProjectContractsByProjectId;
