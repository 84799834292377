import { FormikValues } from "formik";
import { ProjectInterface } from "../../../interfaces/ProjectInterface";
import useProjects from "../../../api/projects/useProjects";
import useCompanies from "../../../api/companies/useCompanies";
import useProjectMatching from "./useProjectMatching";
import { useEffect, useState } from "react";
import { ProjectCategoriesColumn } from "../../../hooks/useProjectCategoriesListColumns";
import useProjectCategories from "../../../api/projectCategories/useProjectCategories";

interface UseHandleSearchInterface {
  values: FormikValues;
  files: Record<string, File | null>;
  searchResults: {
    searched: boolean;
    results: Array<{ match: number; project: ProjectInterface }>;
  };
  handleSearch: ({
    values,
    files,
  }: {
    values: FormikValues;
    files?: Record<string, File | null>;
  }) => Promise<{
    values: FormikValues;
    files?: Record<string, File | null>;
  }>;
}

const useHandleSearch = (): UseHandleSearchInterface => {
  const { getById: getProjectById } = useProjects();
  const { getById: getCompanyById } = useCompanies();
  const { matchProjects } = useProjectMatching();

  const [values, setValues] = useState<FormikValues>({});
  const [files, setFiles] = useState<Record<string, File | null>>({});
  const { getAll } = useProjectCategories();

  const [searchResults, setSearchResults] = useState<{
    searched: boolean;
    results: Array<{ match: number; project: ProjectInterface }>;
  }>({ searched: false, results: [] });

  const [projectCategories, setProjectCategories] = useState<
    ProjectCategoriesColumn[]
  >([]);

  useEffect(() => {
    const getProjectCategories = async (): Promise<void> => {
      const projectCategoriesData = await getAll();

      setProjectCategories(
        projectCategoriesData.map((projectCategory) => ({
          ...projectCategory,
          edit: true,
        }))
      );
    };

    void getProjectCategories();
  }, []);

  const handleSearch = async ({
    values: searchValues,
    files,
  }: {
    values: FormikValues;
    files?: Record<string, File | null>;
  }): Promise<{
    values: FormikValues;
    files?: Record<string, File | null>;
  }> => {
    setFiles(files ?? {});
    const { relevantProjects, relevantCapabilities } = await matchProjects({
      projectCategories,
      values: searchValues,
    });

    const results: Array<{ match: number; project: ProjectInterface }> = [];

    for (const searchResultItem of relevantProjects) {
      const project = await getProjectById(searchResultItem.projectId);

      if (
        project === null ||
        project.companyId === undefined ||
        project.mandate !== "ENTITY"
      ) {
        continue;
      }

      const company = await getCompanyById(project.companyId);
      if (company === null) {
        continue;
      }

      results.push({
        match: Number(
          (
            searchResultItem.matchingCapabilities.length /
            relevantCapabilities.length
          ).toFixed(2)
        ),
        project,
      });
    }

    setValues(searchValues);
    setSearchResults({
      searched: true,
      results: results.sort(
        (resultA, resultB) => resultB.match - resultA.match
      ),
    });

    return {
      files,
      values: searchValues,
    };
  };

  return { values, files, searchResults, handleSearch };
};

export default useHandleSearch;
