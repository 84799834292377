import React, { ReactElement } from "react";
import LoadingSkeleton from "./LoadingSkeleton";
import { Table } from "@tanstack/react-table";
import StyledTr from "./styles/StyledTr";
import StyledTd from "./styles/StyledTd";

interface LoadingTableSkeletonProps {
  table: Table<any>;
}
const LoadingTableSkeleton = ({
  table,
}: LoadingTableSkeletonProps): ReactElement => {
  const indices = [1, 2, 3, 4];

  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <React.Fragment key={headerGroup.id}>
          {indices.map((index) => (
            <StyledTr key={index}>
              {headerGroup.headers.map((header) => (
                <StyledTd key={header.id}>
                  <LoadingSkeleton />
                </StyledTd>
              ))}
            </StyledTr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default LoadingTableSkeleton;
