import useCompanies from "../api/companies/useCompanies";
import { useEffect, useState } from "react";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import { ProjectContractInterface } from "../interfaces/ProjectContractInterface";
import useUsers from "../api/users/useUsers";
import hasUserRole from "../helpers/hasUserRole";

interface UseHasCurrentUserAccessToCompanyInterface {
  status: RequestStatusType;
  company: CompanyInterface | null;
}
interface UseHasCurrentUserAccessToCompanyProps {
  companyId?: string;
  filterContractState: boolean;
  projectContracts?: ProjectContractInterface[];
}

const useHasCurrentUserAccessToCompany = ({
  companyId,
  filterContractState,
  projectContracts,
}: UseHasCurrentUserAccessToCompanyProps): UseHasCurrentUserAccessToCompanyInterface => {
  const { getCurrentUsersCompanies, getById } = useCompanies();
  const { currentUser } = useUsers();

  const [currentUsersCompanies, setCurrentUsersCompanies] = useState<
    CompanyInterface[] | null
  >(null);
  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [company, setCompany] = useState<CompanyInterface | null>(null);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }
    void getCurrentUsersCompanies().then((companies) => {
      setCurrentUsersCompanies(companies);
      setStatus("success");
    });
    setStatus("loading");
  }, [status]);

  useEffect(() => {
    if (
      currentUser !== null &&
      companyId !== undefined &&
      hasUserRole({ user: currentUser, role: "ADMIN" })
    ) {
      void getById(companyId).then((foundCompany) => {
        setCompany(foundCompany);
      });
      return;
    }
    if (
      status !== "success" ||
      currentUsersCompanies === null ||
      companyId === undefined
    ) {
      return;
    }

    if (
      filterContractState &&
      currentUsersCompanies.find(
        (currentUsersCompany) => currentUsersCompany.id === companyId
      ) === undefined
    ) {
      if (projectContracts === undefined) {
        return;
      }

      const relevantContracts = projectContracts.filter((projectContract) => {
        return (
          currentUsersCompanies.find(
            (currentUsersCompany) =>
              currentUsersCompany.id === projectContract.companyId
          ) !== undefined
        );
      });
      if (
        relevantContracts.find(
          (relevantContract) => relevantContract.contractState !== "OPEN"
        ) === undefined
      ) {
        return;
      }
    }

    if (
      !filterContractState &&
      currentUsersCompanies.find(
        (currentUsersCompany) => currentUsersCompany.id === companyId
      ) === undefined
    ) {
      return;
    }

    void getById(companyId).then((foundCompany) => {
      setCompany(foundCompany);
    });
  }, [
    status,
    currentUser,
    currentUsersCompanies,
    companyId,
    projectContracts,
    filterContractState,
  ]);

  return { status, company };
};

export default useHasCurrentUserAccessToCompany;
