import React, { ReactElement } from "react";
import Table from "../../components/Table";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import useCapabilitiesListColumns, {
  CapabilityColumn,
} from "../../hooks/useCapabilitiesListColumns";

interface CapabilitiesTableProps {
  data: {
    status: RequestStatusType;
    capabilities: CapabilityColumn[];
  };
}

const CapabilitiesTable = ({ data }: CapabilitiesTableProps): ReactElement => {
  const { columns } = useCapabilitiesListColumns();

  return (
    <Table
      data={data.capabilities}
      columns={columns}
      isLoading={data.status !== "success"}
      isShowMore
      getRowCanExpand={() => true}
    />
  );
};

export default CapabilitiesTable;
