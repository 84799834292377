import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { TextInputFieldProps } from "../../interfaces/InputFieldProps";
import StyledField from "../styles/StyledField";
import StyledInputField from "../styles/StyledInputField";
import StyledLabel from "../styles/StyledLabel";
import styled from "styled-components";

const StyledRangeInput = styled(StyledInputField)`
  width: auto;
`;

const RangeField = ({
  label,
  name,
  handleChange,
  handleBlur,
  value,
}: TextInputFieldProps): ReactElement => {
  if (Array.isArray(value)) {
    throw new Error("Invalid Range field data");
  }

  let minValue: number = 0;
  let maxValue: number = 0;

  const originalType = typeof value;

  switch (typeof value) {
    case "string": {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === "number") {
        minValue = parsedValue;
        maxValue = parsedValue;
      }
      if (parsedValue.min !== undefined) {
        minValue = parsedValue.min;
      }
      if (parsedValue.max !== undefined) {
        maxValue = parsedValue.max;
      }
      break;
    }
    case "number": {
      minValue = value;
      maxValue = value;
      break;
    }
    case "object": {
      if (value.min !== undefined) {
        minValue = +value.min;
      }
      if (value.max !== undefined) {
        maxValue = +value.max;
      }
      break;
    }
  }

  const [originalValue, setOriginalValue] = useState<{
    min: number;
    max: number;
  }>({
    min: minValue,
    max: maxValue,
  });

  useEffect(() => {
    setOriginalValue({
      min: minValue,
      max: maxValue,
    });
  }, [value]);

  const change = (
    e: ChangeEvent<HTMLInputElement>,
    minOrMax: "min" | "max"
  ): void => {
    if (originalType === "string") {
      e.preventDefault();
      e.stopPropagation();
      const newValue = { ...originalValue, [minOrMax]: e.target.value };
      handleChange({
        ...e,
        target: { ...e.target, name, value: JSON.stringify(newValue) },
      });
    }
  };

  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      Von:{" "}
      <StyledRangeInput
        type="number"
        name={`${name}.min`}
        onChange={(e) => change(e, "min")}
        onBlur={handleBlur}
        value={Number.isNaN(minValue) ? originalValue.min : minValue}
      />
      Bis:{" "}
      <StyledRangeInput
        type="number"
        name={`${name}.max`}
        onChange={(e) => change(e, "max")}
        onBlur={handleBlur}
        value={Number.isNaN(maxValue) ? originalValue.max : maxValue}
      />
    </StyledField>
  );
};

export default RangeField;
