import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseGetUsersInterface {
  getAll: () => Promise<UserInterface[]>;
}

const useGetUsers = (): UseGetUsersInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<UserInterface[]> => {
    const { data, status } = await get(`/user`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetUsers;
