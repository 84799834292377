import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import useGetProjectCapability from "./useGetProjectCapability";
import useGetProjectCapabilityFile from "./useGetProjectCapabilityFile";

interface UseProjectCapabilitiesInterface {
  getById: (id: string) => Promise<ProjectCapabilityInterface | null>;
  getFileById: (id: string) => Promise<Buffer | null>;
}

const useProjectCapabilities = (): UseProjectCapabilitiesInterface => {
  const { getById: apiGetById } = useGetProjectCapability();
  const { getById: apiGetFileById } = useGetProjectCapabilityFile();

  const getById = async (
    id: string
  ): Promise<ProjectCapabilityInterface | null> => {
    return await apiGetById(id);
  };

  const getFileById = async (id: string): Promise<Buffer | null> => {
    return await apiGetFileById(id);
  };

  return {
    getById,
    getFileById,
  };
};

export default useProjectCapabilities;
