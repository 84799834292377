import React, { ReactElement, useState } from "react";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import FilteredForms from "./FilteredForms";
import ProjectCategoryFilter from "./ProjectCategoryFilter";
import { Card, Space } from "../../components";
import FormGroupDetails from "./FormGroupDetails";
import AddFormGroup from "./AddFormGroup";

const Forms = (): ReactElement => {
  const [projectCategoryFilter, setProjectCategoryFilter] =
    useState<ProjectCategoryInterface | null>(null);

  const handleFilterChange = (
    filter: ProjectCategoryInterface | null
  ): void => {
    if (filter?.id === projectCategoryFilter?.id) {
      setProjectCategoryFilter(null);
      return;
    }

    setProjectCategoryFilter(filter);
  };

  return (
    <>
      <ProjectCategoryFilter
        handleFilterChange={handleFilterChange}
        activeFilter={projectCategoryFilter}
      />
      <Space size={2} />
      <AddFormGroup projectCategoryFilter={projectCategoryFilter} />
      <Space size={2} />
      <FilteredForms
        projectCategoryFilter={projectCategoryFilter}
        RenderElement={({ formGroup }) => (
          <>
            <Card>
              <FormGroupDetails formGroup={formGroup} />
            </Card>
            <Space size={2} />
          </>
        )}
      />
    </>
  );
};

export default Forms;
