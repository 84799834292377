import useApi from "../useApi";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";

interface UseUpdateCapabilityGroupInterface {
  update: (
    capabilityGroup: CapabilityGroupInterface
  ) => Promise<CapabilityGroupInterface | null>;
}

const useUpdateCapabilityGroup = (): UseUpdateCapabilityGroupInterface => {
  const { put } = useApi();

  const update = async (
    capabilityGroup: CapabilityGroupInterface
  ): Promise<CapabilityGroupInterface | null> => {
    if (capabilityGroup.id === undefined) {
      return null;
    }

    const { data, status } = await put(
      `/capability-group/${capabilityGroup.id}`,
      capabilityGroup
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateCapabilityGroup;
