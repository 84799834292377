import { CapabilityGroupCollectionInterface } from "../../interfaces/CapabilityGroupCollectionInterface";
import { RangeValue } from "../../interfaces/InputFieldProps";
import React, { ChangeEvent, ReactElement } from "react";
import CapabilityInput from "./CapabilityInput";
import GroupWrapper from "./GroupWrapper";

interface DisplayCapabilityGroupProps {
  capabilityGroup: CapabilityGroupCollectionInterface;
  values: Record<
    string,
    Record<string, string | number | RangeValue | File | boolean>
  >;
  isSearch?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  name: string;
  priority: number;
}

const DisplayCapabilityGroup = ({
  capabilityGroup,
  isSearch,
  values,
  handleChange,
  name,
  priority,
}: DisplayCapabilityGroupProps): ReactElement => {
  return (
    <>
      <GroupWrapper capabilityGroup={capabilityGroup} isSearch={isSearch}>
        {capabilityGroup.capabilities.map(({ id, name: capabilityName }) => (
          <React.Fragment key={id}>
            {id !== undefined && (
              <CapabilityInput
                priority={priority}
                isSearch={isSearch}
                value={values[name][`${capabilityName ?? ""}.${priority}`]}
                id={id}
                groupName={name}
                handleChange={handleChange}
              />
            )}
          </React.Fragment>
        ))}
      </GroupWrapper>
    </>
  );
};

export default DisplayCapabilityGroup;
