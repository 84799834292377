import { CompanyInterface } from "../../interfaces/CompanyInterface";
import useApi from "../useApi";

interface UseCreateCompanyInterface {
  create: (company: CompanyInterface) => Promise<CompanyInterface>;
}

const useCreateCompany = (): UseCreateCompanyInterface => {
  const { post } = useApi();

  const create = async (
    company: CompanyInterface
  ): Promise<CompanyInterface> => {
    const { status, data: createdCompany } = await post(`/company`, {
      ...company,
    });

    if (status === 200) {
      return createdCompany;
    }

    throw new Error("Company creation error");
  };

  return { create };
};

export default useCreateCompany;
