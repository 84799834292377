import useApi from "../useApi";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";

interface UseGetCapabilityGroupsInterface {
  getAll: () => Promise<CapabilityGroupInterface[]>;
}

const useGetCapabilityGroups = (): UseGetCapabilityGroupsInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<CapabilityGroupInterface[]> => {
    const { data, status } = await get(`/capability-group`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetCapabilityGroups;
