import React, { ReactElement, useEffect, useState } from "react";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";
import useTranslations from "../../api/translations/useTranslations";
import { TranslationInterface } from "../../interfaces/TranslationInterface";
import { AddTranslationFormValuesInterface } from "./AddTranslationForm";
import Translation from "./Translation";
import useGetCapabilityGroupLabel from "../../hooks/useGetCapabilityGroupLabel";

interface CapabilityGroupTranslationProps {
  capabilityGroup: CapabilityGroupInterface;
}
const CapabilityGroupTranslation = ({
  capabilityGroup,
}: CapabilityGroupTranslationProps): ReactElement => {
  const { getByCapabilityGroupId, create } = useTranslations();
  const { getShortLabel } = useGetCapabilityGroupLabel();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [translations, setTranslations] = useState<
    TranslationInterface[] | null
  >(null);

  useEffect(() => {
    if (!isPopoverOpen || capabilityGroup.id === undefined) {
      return;
    }

    void getByCapabilityGroupId(capabilityGroup.id).then((data) => {
      setTranslations(data ?? []);
    });
  }, [capabilityGroup, isPopoverOpen]);

  const handleAddTranslation = async (
    translation: AddTranslationFormValuesInterface
  ): Promise<void> => {
    if (translation.mandate === "") {
      return;
    }
    await create({
      language: translation.language,
      capabilityGroupId: capabilityGroup.id,
      translationType: "CAPABILITY_GROUP",
      mandate: translation.mandate,
      value: translation.value,
    });
  };

  return (
    <Translation
      handleAddTranslation={handleAddTranslation}
      translations={translations}
      headline={getShortLabel(capabilityGroup)}
      isPopoverOpen={isPopoverOpen}
      handlePopoverOpenChange={setIsPopoverOpen}
    />
  );
};

export default CapabilityGroupTranslation;
