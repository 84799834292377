import React, { MouseEventHandler, ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface ClickableProps {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  title?: string;
}

const StyledA = styled.a`
  cursor: pointer;
`;

const Clickable = ({
  children,
  onClick,
  title,
}: ClickableProps): ReactElement => {
  return (
    <StyledA title={title} onClick={onClick}>
      {children}
    </StyledA>
  );
};

export default Clickable;
