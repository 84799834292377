import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseUpdateUserInterface {
  update: (user: UserInterface) => Promise<UserInterface | null>;
}

const useUpdateUser = (): UseUpdateUserInterface => {
  const { put } = useApi();

  const update = async (user: UserInterface): Promise<UserInterface | null> => {
    if (user.id === undefined) {
      return null;
    }

    const { data, status } = await put(`/user/${user.id}`, user);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateUser;
