import { FormGroupInterface } from "../interfaces/FormGroupInterface";
import { ProjectCapabilityInterface } from "../interfaces/ProjectCapabilityInterface";

const isFormGroupHasCapability = ({
  formGroup,
  projectCapabilities,
}: {
  formGroup: FormGroupInterface;
  projectCapabilities: ProjectCapabilityInterface[];
}): boolean => {
  return (
    formGroup.capabilities?.find((formGroupCapability) => {
      return (
        projectCapabilities.find(
          (projectCapability) =>
            projectCapability.capabilityId === formGroupCapability.id
        ) !== undefined
      );
    }) !== undefined
  );
};

export default isFormGroupHasCapability;
