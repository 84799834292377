import { create } from "zustand";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { FormGroupInterface } from "../interfaces/FormGroupInterface";
import { TranslationInterface } from "../interfaces/TranslationInterface";

interface FormGroupState extends CrudStateInterface<FormGroupInterface> {
  addTranslation: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
    translation,
  }: {
    formGroupId?: string;
    capabilityGroupId?: string;
    capabilityId?: string;
    translation: TranslationInterface;
  }) => void;
  updateTranslation: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
    translation,
  }: {
    formGroupId?: string;
    capabilityId?: string;
    capabilityGroupId?: string;
    translation: TranslationInterface;
  }) => void;
}
const useFormGroupStore = create<FormGroupState>((set, get) => ({
  ...defaultStateCrud<FormGroupInterface>(set, get),
  addTranslation: ({ formGroupId, capabilityId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== formGroupId) {
              return dataSet;
            }
            return {
              ...dataSet,
              capabilities:
                dataSet.capabilities === undefined
                  ? undefined
                  : [
                      ...dataSet.capabilities.map((capability) => {
                        if (capability.id !== capabilityId) {
                          return capability;
                        }
                        return {
                          ...capability,
                          translation:
                            capability.translation === undefined
                              ? [translation]
                              : [
                                  ...capability.translation.map(
                                    (capabilityTranslation) => {
                                      if (
                                        capabilityTranslation.id !==
                                        translation.id
                                      ) {
                                        return capabilityTranslation;
                                      }
                                      return translation;
                                    }
                                  ),
                                ],
                        };
                      }),
                    ],
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation,
                      {
                        ...translation,
                      },
                    ],
            };
          }),
        ],
      };
    }),
  updateTranslation: ({ formGroupId, capabilityId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== formGroupId) {
              return dataSet;
            }
            return {
              ...dataSet,
              capabilities:
                dataSet.capabilities === undefined
                  ? undefined
                  : [
                      ...dataSet.capabilities.map((capability) => {
                        if (capability.id !== capabilityId) {
                          return capability;
                        }
                        return {
                          ...capability,
                          translation:
                            capability.translation === undefined
                              ? [translation]
                              : [
                                  ...capability.translation.map(
                                    (capabilityTranslation) => {
                                      if (
                                        capabilityTranslation.id !==
                                        translation.id
                                      ) {
                                        return capabilityTranslation;
                                      }
                                      return translation;
                                    }
                                  ),
                                ],
                        };
                      }),
                    ],
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation.map((stateTranslation) => {
                        if (stateTranslation.id !== translation.id) {
                          return stateTranslation;
                        }
                        return translation;
                      }),
                    ],
            };
          }),
        ],
      };
    }),
}));

export default useFormGroupStore;
