import { ProjectCategoriesColumn } from "../../../hooks/useProjectCategoriesListColumns";
import { ProjectCapabilityInterface } from "../../../interfaces/ProjectCapabilityInterface";
import useCapabilities from "../../../api/capabilities/useCapabilities";
import { RangeValue } from "../../../interfaces/InputFieldProps";

interface UseFormToCapabilityAdapterInterface {
  adapt: ({
    formData,
    enabledCategories,
    files,
  }: {
    formData: Record<string, Record<string, string | number | boolean>>;
    enabledCategories: ProjectCategoriesColumn[];
    files: Record<string, File | null>;
  }) => Promise<ProjectCapabilityInterface[]>;
}
const useFormToCapabilityAdapter = (): UseFormToCapabilityAdapterInterface => {
  const { getByName } = useCapabilities();

  // todo: pretty much the same as src/modules/Matching/useProjectMatching.ts
  const adapt = async ({
    formData,
    enabledCategories,
    files,
  }: {
    formData: Record<
      string,
      Record<string, string | number | RangeValue | File | boolean>
    >;
    enabledCategories: ProjectCategoriesColumn[];
    files: Record<string, File | null>;
  }): Promise<ProjectCapabilityInterface[]> => {
    const projectCapabilities: ProjectCapabilityInterface[] = [];

    for (const projectCategoryName of Object.keys(formData)) {
      const foundProjectCategory = enabledCategories.find(
        (enabledCategory) => enabledCategory.name === projectCategoryName
      );
      if (foundProjectCategory === undefined) {
        continue;
      }

      for (const capabilityName of Object.keys(formData[projectCategoryName])) {
        // todo: this is found multiple times, fix
        const [, cleanedValueKey, priority] =
          capabilityName.match(/([\w|.|\s]+)\.(\d+)$/) ?? [];
        const capability = await getByName(cleanedValueKey ?? capabilityName);

        if (capability === null || capability.id === undefined) {
          continue;
        }

        const uncastValue = formData[projectCategoryName][capabilityName];
        if (uncastValue === "" || uncastValue === false) {
          continue;
        }

        let value: string | File = "";
        switch (typeof uncastValue) {
          case "boolean":
            value = uncastValue ? "true" : "false";
            break;
          case "object":
            value = `${JSON.stringify(uncastValue)}`;
            break;
          case "number":
          case "string":
            value = `${uncastValue}`;
            break;
        }

        if (capability.type === "FILE" && capability.name !== undefined) {
          const file =
            files[`${projectCategoryName}.['${capability.name}.${priority}']`];
          if (file === null) {
            continue;
          }
          value = file;
        }

        projectCapabilities.push({
          capabilityId: capability.id,
          projectCategoryId: foundProjectCategory.id,
          value,
          priority: +priority ?? 0,
        });
      }
    }
    return projectCapabilities;
  };

  return { adapt };
};

export default useFormToCapabilityAdapter;
