import useApi from "../useApi";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";

interface UseGetCapabilityGroupInterface {
  getById: (id: string) => Promise<CapabilityGroupInterface | null>;
}

const useGetCapabilityGroup = (): UseGetCapabilityGroupInterface => {
  const { get } = useApi();

  const getById = async (
    id: string
  ): Promise<CapabilityGroupInterface | null> => {
    const { data, status } = await get(`/capability-group/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetCapabilityGroup;
