import useApi from "../useApi";
import { CompanyInterface } from "../../interfaces/CompanyInterface";

interface UseUpdateCompanyInterface {
  update: (company: CompanyInterface) => Promise<CompanyInterface | null>;
}

const useUpdateCompany = (): UseUpdateCompanyInterface => {
  const { put } = useApi();

  const update = async (
    company: CompanyInterface
  ): Promise<CompanyInterface | null> => {
    if (company.id === undefined) {
      return null;
    }

    const { data, status } = await put(`/company/${company.id}`, company);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateCompany;
