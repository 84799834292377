import useApi from "../useApi";

interface GetFileInterface {
  getById: (fileId: string) => Promise<Buffer | null>;
}
const useGetFile = (): GetFileInterface => {
  const { get } = useApi();

  const getById = async (fileId: string): Promise<Buffer | null> => {
    const { status, data: file } = await get(`/file/${fileId}`);

    if (status === 200) {
      return file;
    }

    return null;
  };

  return { getById };
};

export default useGetFile;
