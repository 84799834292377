import { create } from "zustand";
import { TranslationInterface } from "../interfaces/TranslationInterface";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";

export interface TranslationsState
  extends CrudStateInterface<TranslationInterface> {
  getByFormGroupId: (formGroupId: string) => TranslationInterface[] | null;
  getByCapabilityIdId: (capabilityId: string) => TranslationInterface[] | null;
  getByCapabilityGroupIdId: (
    capabilityGroupId: string
  ) => TranslationInterface[] | null;
}

const useTranslationStore = create<TranslationsState>((set, get) => ({
  ...defaultStateCrud<TranslationInterface>(set, get),
  getByFormGroupId: (formGroupId) => {
    return get().data.filter((translation) => {
      return (
        translation.formGroupId === formGroupId ||
        translation.formGroup?.id === formGroupId
      );
    });
  },
  getByCapabilityIdId: (capabilityId) => {
    return get().data.filter((translation) => {
      return (
        translation.capabilityId === capabilityId ||
        translation.capability?.id === capabilityId
      );
    });
  },
  getByCapabilityGroupIdId: (capabilityGroupId) => {
    return get().data.filter((translation) => {
      return (
        translation.capabilityGroupId === capabilityGroupId ||
        translation.capabilityGroup?.id === capabilityGroupId
      );
    });
  },
}));

export default useTranslationStore;
