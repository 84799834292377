import useApi from "../useApi";
import { TranslationInterface } from "../../interfaces/TranslationInterface";

interface UseUpdateTranslationInterface {
  update: (
    translation: TranslationInterface
  ) => Promise<TranslationInterface | null>;
}

const useUpdateTranslation = (): UseUpdateTranslationInterface => {
  const { put } = useApi();

  const update = async (
    translation: TranslationInterface
  ): Promise<TranslationInterface | null> => {
    if (translation.id === undefined) {
      return null;
    }

    const { data, status } = await put(
      `/translation/${translation.id}`,
      translation
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateTranslation;
