import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseGetUserInterface {
  getById: (id: string) => Promise<UserInterface>;
}

const useGetUser = (): UseGetUserInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<UserInterface> => {
    const { data, status } = await get(`/user/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetUser;
