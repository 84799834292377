import usePostLogin from "./usePostLogin";
import { TokenInterface } from "../interfaces/TokenInterface";
import useStorage from "../hooks/useStorage";
import { useState } from "react";

interface UseAuthenticationInterface {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<TokenInterface | null>;
  isLoggedIn: boolean;
  token: TokenInterface | null;
  logout: () => void;
}

const loginStorageKey = "loginDetails";

const useAuthentication = (): UseAuthenticationInterface => {
  const { getItem, setItem, removeItem } = useStorage();
  const { login: apiLogin } = usePostLogin();

  const storedToken = getItem(loginStorageKey);
  const [token] = useState<TokenInterface | null>(
    storedToken !== null ? JSON.parse(storedToken) : null
  );

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<TokenInterface | null> => {
    const loginDetails = await apiLogin({ email, password });

    if (loginDetails === null) {
      return null;
    }
    setItem(loginStorageKey, JSON.stringify(loginDetails));

    return loginDetails;
  };

  const logout = (): void => {
    removeItem(loginStorageKey);
  };

  return { login, isLoggedIn: token !== null, token, logout };
};

export default useAuthentication;
