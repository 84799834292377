import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";

interface UseGetProjectsByCompanyIdInterface {
  getByCompanyId: ({
    companyId,
    mandate,
  }: {
    companyId: string;
    mandate: ProjectMandateEnum;
  }) => Promise<ProjectInterface[]>;
}

const useGetProjectsByCompanyId = (): UseGetProjectsByCompanyIdInterface => {
  const { get } = useApi();

  const getByCompanyId = async ({
    companyId,
    mandate,
  }: {
    companyId: string;
    mandate: ProjectMandateEnum;
  }): Promise<ProjectInterface[]> => {
    const { data, status } = await get(
      `/project/by-company/${companyId}?mandate=${mandate}`
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByCompanyId };
};

export default useGetProjectsByCompanyId;
