import client from "./client";
import getApiUrl from "../helpers/getApiUrl";
import { AxiosResponse } from "axios";
import useAuthentication from "./useAuthentication";
import { useNavigate } from "react-router-dom";

interface UseApiInterface {
  get: <R = AxiosResponse>(route: string) => Promise<R>;
  post: <R = AxiosResponse>(route: string, data: any) => Promise<R>;
  postForm: <R = AxiosResponse>(
    route: string,
    data: any,
    files?: Array<{ file: File; name: string }>
  ) => Promise<R>;
  putForm: <R = AxiosResponse>(route: string, data: any) => Promise<R>;
  put: <R = AxiosResponse>(route: string, data: any) => Promise<R>;
  deleteRequest: <R = AxiosResponse>(route: string) => Promise<R>;
}

const useApi = (): UseApiInterface => {
  const { token } = useAuthentication();
  const navigate = useNavigate();

  const headers =
    token === null
      ? {}
      : {
          Authorization: `Bearer ${token.token}`,
        };

  const get = async <R = AxiosResponse>(route: string): Promise<R> => {
    return await client
      .get(`${getApiUrl()}${route}`, {
        headers,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          return;
        }
        return error;
      });
  };
  const post = async <R = AxiosResponse>(
    route: string,
    data: any
  ): Promise<R> => {
    return await client.post(`${getApiUrl()}${route}`, data, {
      headers,
    });
  };

  const postForm = async <R = AxiosResponse>(
    route: string,
    data: any,
    files?: Array<{ file: File; name: string }>
  ): Promise<R> => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (typeof data[key] !== "object") {
        formData.append(key, data[key]);
        return;
      }
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
        return;
      }
      formData.append(key, JSON.stringify(data[key]));
    });

    files?.forEach(({ file, name }) => {
      formData.append("files", new File([file], name, { type: file.type }));
    });

    return await client.post(`${getApiUrl()}${route}`, formData, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const putForm = async <R = AxiosResponse>(
    route: string,
    data: any
  ): Promise<R> => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await client.put(`${getApiUrl()}${route}`, formData, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const put = async <R = AxiosResponse>(
    route: string,
    data: any
  ): Promise<R> => {
    return await client.put(`${getApiUrl()}${route}`, data, {
      headers,
    });
  };
  const deleteRequest = async <R = AxiosResponse>(
    route: string
  ): Promise<R> => {
    return await client.delete(`${getApiUrl()}${route}`, {
      headers,
    });
  };

  return { get, post, postForm, putForm, put, deleteRequest };
};

export default useApi;
