import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCompanies from "../api/companies/useCompanies";
import { SearchableDataType } from "../interfaces/SearchableDataType";
import { SearchableDataInterface } from "../interfaces/SearchableDataInterface";
import SearchInput from "../components/Form/SearchInput";
import { Button, Form } from "../components";
import CheckIcon from "../icons/CheckIcon";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import { CompanyTypeType } from "../interfaces/CompanyTypeType";

interface CompanySelectionProps {
  handleSelected: (
    selected: SearchableDataInterface | null
  ) => Promise<void> | void;
  initialValue?: string | null;
  filter?: CompanyTypeType;
  skipSelection?: boolean;
  label?: string;
}

const CompanySelection = ({
  handleSelected,
  initialValue,
  filter = "client",
  skipSelection,
  label,
}: CompanySelectionProps): ReactElement => {
  const { t } = useTranslation();
  const { create, getByType } = useCompanies();

  const [companies, setCompanies] = useState<CompanyInterface[] | null>(null);
  const [searchData, setSearchData] = useState<SearchableDataType>([]);
  const [selected, setSelected] = useState<SearchableDataInterface | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (companies === null) {
      return;
    }
    setSearchData(
      companies.map((company) => ({
        label: company.name ?? "",
        value: company.id ?? "",
      }))
    );

    if (initialValue === undefined || initialValue === null) {
      return;
    }
    const foundCompany = companies.find(
      (company) => company.id === initialValue
    );

    if (
      foundCompany === undefined ||
      foundCompany.name === undefined ||
      foundCompany.id === undefined
    ) {
      return;
    }
    setSelected({
      label: foundCompany.name,
      value: foundCompany.id,
    });
  }, [companies, initialValue]);

  useEffect(() => {
    void handleSelected(selected);
    if (skipSelection === undefined || !skipSelection || selected === null) {
      return;
    }
    setSelected(null);
  }, [selected, skipSelection]);

  useEffect(() => {
    void getByType(filter).then((companiesData) => {
      setCompanies(companiesData);
    });
  }, [filter]);

  return (
    <>
      <SearchInput
        label={label ?? t("COMPANY.company")}
        searchData={searchData}
        handleCreate={(value) => {
          setSelected({
            label: value,
            value: "createNew",
          });
        }}
        handleSelect={setSelected}
        selected={selected}
        createText="COMPANY.create"
      />
      {selected !== null && selected.value === "createNew" && (
        <>
          <Form
            fields={[
              {
                id: "address",
                label: t("COMPANY.address"),
                type: "text",
              },
              {
                id: "country",
                label: t("COMPANY.country"),
                type: "text",
              },
            ]}
            initialValues={{
              address: "",
              country: "",
            }}
            handleSubmit={async ({ address, country }) => {
              setIsSubmitting(true);
              const company = await create({
                address,
                country,
                name: selected.label,
              });

              if (company.id === undefined) {
                setIsSubmitting(false);
                return;
              }

              setSelected({
                label: company.name ?? selected.label,
                value: company.id,
              });
              setIsSubmitting(false);
            }}
          >
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={isSubmitting ? "success" : "default"}
              whileTap={{
                scale: 0.8,
              }}
            >
              {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default CompanySelection;
