import React, { ReactElement } from "react";

interface CloseIconProps {
  size?: string;
  fill?: string;
}

const CloseIcon = ({ size, fill }: CloseIconProps): ReactElement => (
  <svg
    width={size ?? "28"}
    height={size ?? "28"}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4644 9.58055C10.2204 9.33647 9.82463 9.33647 9.58055 9.58055C9.33647 9.82463 9.33647 10.2204 9.58055 10.4644L13.1161 14L9.5806 17.5355C9.33652 17.7796 9.33652 18.1753 9.5806 18.4194C9.82467 18.6635 10.2204 18.6635 10.4645 18.4194L14 14.8839L17.5355 18.4194C17.7796 18.6635 18.1753 18.6635 18.4194 18.4194C18.6635 18.1753 18.6635 17.7796 18.4194 17.5355L14.8839 14L18.4194 10.4644C18.6635 10.2204 18.6635 9.82463 18.4194 9.58055C18.1754 9.33647 17.7796 9.33647 17.5355 9.58055L14 13.1161L10.4644 9.58055Z"
      fill={fill ?? "#1C1C1C"}
    />
  </svg>
);

export default CloseIcon;
