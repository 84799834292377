import { ProjectCategoriesColumn } from "../../../hooks/useProjectCategoriesListColumns";
import { ProjectCapabilityInterface } from "../../../interfaces/ProjectCapabilityInterface";
import useProjectCategories from "../../../api/projectCategories/useProjectCategories";
import { RangeValue } from "../../../interfaces/InputFieldProps";
import useCapabilities from "../../../api/capabilities/useCapabilities";

interface UseCapabilityToFormAdapterInterface {
  adapt: ({
    projectCapabilities,
  }: {
    projectCapabilities: ProjectCapabilityInterface[];
  }) => Promise<{
    formData: Record<
      string,
      Record<string, string | number | RangeValue | File | boolean>
    >;
    enabledCategories: ProjectCategoriesColumn[];
    enabledFieldIds: string[];
  }>;
}
const useCapabilityToFormAdapter = (): UseCapabilityToFormAdapterInterface => {
  const { getById: getProjectCategoryById } = useProjectCategories();
  const { getById: getCapabilityById } = useCapabilities();

  const adapt = async ({
    projectCapabilities,
  }: {
    projectCapabilities: ProjectCapabilityInterface[];
  }): Promise<{
    formData: Record<
      string,
      Record<string, string | number | RangeValue | File | boolean>
    >;
    enabledCategories: ProjectCategoriesColumn[];
    enabledFieldIds: string[];
  }> => {
    const formData: Record<
      string,
      Record<string, string | number | RangeValue | File | boolean>
    > = {};
    const enabledCategories: ProjectCategoriesColumn[] = [];
    const enabledFieldIds: string[] = [];

    for (const projectCapability of projectCapabilities) {
      if (
        projectCapability.id !== undefined &&
        !enabledFieldIds.includes(projectCapability.id)
      ) {
        enabledFieldIds.push(projectCapability.id);
      }
      const projectCategory =
        projectCapability.projectCategoryId !== undefined
          ? await getProjectCategoryById(projectCapability.projectCategoryId)
          : null;

      if (projectCategory === null || projectCategory.name === undefined) {
        continue;
      }

      if (
        enabledCategories.find(
          (enabledCategory) => enabledCategory.id === projectCategory.id
        ) === undefined
      ) {
        enabledCategories.push({ ...projectCategory, edit: true });
      }

      const capability = await getCapabilityById(
        projectCapability.capabilityId
      );

      if (capability === null || capability.name === undefined) {
        continue;
      }
      if (formData[projectCategory.name] === undefined) {
        formData[projectCategory.name] = {};
      }
      formData[projectCategory.name] = {
        ...formData[projectCategory.name],
        [`${capability.name}.${projectCapability.priority.toString()}`]:
          projectCapability.value,
      };
    }

    return {
      formData,
      enabledCategories,
      enabledFieldIds,
    };
  };

  return { adapt };
};

export default useCapabilityToFormAdapter;
