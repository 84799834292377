import { UserInterface } from "../interfaces/UserInterface";
import { UserRoleType } from "../interfaces/UserRoleType";

const hasUserRole = ({
  user,
  role,
}: {
  user?: UserInterface;
  role: UserRoleType;
}): boolean => {
  return user?.roles?.find((userRole) => userRole === role) !== undefined;
};

export default hasUserRole;
