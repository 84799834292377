import { CompanyInterface } from "../interfaces/CompanyInterface";
import useApi from "./useApi";

interface UseUpdateCompanyInterface {
  update: (company: CompanyInterface) => Promise<CompanyInterface | null>;
}

const useUpdateCompany = (): UseUpdateCompanyInterface => {
  const { put } = useApi();

  const update = async (
    company: CompanyInterface
  ): Promise<CompanyInterface | null> => {
    if (company.id === undefined) {
      return null;
    }
    const { status, data: updatedCompany } = await put(
      `/company/${company.id}`,
      {
        ...company,
      }
    );

    if (status === 200) {
      return updatedCompany;
    }

    return null;
  };

  return { update };
};

export default useUpdateCompany;
