import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseUpdateFormGroupInterface {
  update: (formGroup: FormGroupInterface) => Promise<FormGroupInterface | null>;
}

const useUpdateFormGroup = (): UseUpdateFormGroupInterface => {
  const { put } = useApi();

  const update = async (
    formGroup: FormGroupInterface
  ): Promise<FormGroupInterface | null> => {
    if (formGroup.id === undefined) {
      return null;
    }

    const { data, status } = await put(
      `/form-group/${formGroup.id}`,
      formGroup
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateFormGroup;
