import React, { ReactElement } from "react";
import { Card, Column, Row, Space } from "../../components";
import ProjectList from "../Projects/ProjectList";
import { useTranslation } from "react-i18next";
import HeadlineToggle from "../HeadlineToggle";
import styled from "styled-components";
import EntityList from "../Entities/EntityList";
import { UserList } from "../index";

const VScroll = styled.div`
  overflow: auto;
`;

const PartnerOverview = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Column size={2}>
          <Card floating={false}>
            <HeadlineToggle headline={<>{t("PROJECT.list")}</>}>
              <VScroll>
                <ProjectList />
              </VScroll>
            </HeadlineToggle>
          </Card>
          <Space size={2} />
          <Card floating={false}>
            <HeadlineToggle headline={t("USERS.list")}>
              <VScroll>
                <UserList />
              </VScroll>
            </HeadlineToggle>
          </Card>
        </Column>
        <Column size={2}>
          <Card floating={false}>
            <HeadlineToggle headline={t("ENTITIES.list")}>
              <EntityList />
            </HeadlineToggle>
          </Card>
        </Column>
      </Row>
    </>
  );
};

export default PartnerOverview;
