import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../components/Clickable";
import { useTranslation } from "react-i18next";
import { ProjectCategoryInterface } from "../interfaces/ProjectCategoryInterface";

export interface ProjectCategoriesColumn extends ProjectCategoryInterface {
  edit: boolean;
}

const columnHelper = createColumnHelper<ProjectCategoriesColumn>();

interface UseProjectsCategoriesListColumnsInterface {
  columns: Array<ColumnDef<ProjectCategoriesColumn, any>>;
}
const useProjectCategoriesListColumns =
  (): UseProjectsCategoriesListColumnsInterface => {
    const { t } = useTranslation();

    const columns: Array<ColumnDef<ProjectCategoriesColumn, any>> = [
      columnHelper.accessor("name", {
        header: `${t("PROJECT_CATEGORIES.name")}`,
        cell: (info) => {
          return (
            <Clickable onClick={info.row.getToggleExpandedHandler()}>
              {t(`PROJECT_CATEGORIES.label.${info.getValue() as string}`)}
            </Clickable>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("capabilities", {
        header: `${t("PROJECT_CATEGORIES.capabilities")}`,
        cell: (info) => {
          return (
            <Clickable onClick={info.row.getToggleExpandedHandler()}>
              {t(
                `PROJECT_CATEGORIES.capabilities.${info.getValue() as string}`
              )}
            </Clickable>
          );
        },
        footer: (info) => info.column.id,
      }),
    ];

    return { columns };
  };

export default useProjectCategoriesListColumns;
