import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FrontendRow } from "../FrontendList";
import { Clickable } from "../../../components";

const columnHelper = createColumnHelper<FrontendRow>();

interface UseFrontendListColumnsInterface {
  columns: Array<ColumnDef<FrontendRow, any>>;
}
const useFrontendListColumns = (): UseFrontendListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<FrontendRow, any>> = [
    columnHelper.accessor("name", {
      header: `${t("CAPABILITIES.name")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useFrontendListColumns;
