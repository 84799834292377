import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface ScrollableProps {
  height: string;
  children: ReactNode;
}

const StyledScrollableDiv = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  overflow: auto;
`;

const Scrollable = ({ height, children }: ScrollableProps): ReactElement => {
  return <StyledScrollableDiv height={height}>{children}</StyledScrollableDiv>;
};

export default Scrollable;
