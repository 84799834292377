import React, { ReactElement, ReactNode, useState } from "react";
import { CapabilityGroupCollectionInterface } from "../../interfaces/CapabilityGroupCollectionInterface";
import useGetCapabilityGroupLabel from "../../hooks/useGetCapabilityGroupLabel";
import StyledGroupDiv from "../../components/styles/StyledGroupDiv";
import { Column, Headline, Row } from "../../components";
import { ChevronDownIcon, ChevronRightIcon } from "../../icons";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";
import { ToggleIcon, ToggleLink } from "./MatchingCapabilitiesForm";

interface GroupWrapperProps {
  children: ReactNode;
  capabilityGroup: CapabilityGroupCollectionInterface;
  isSearch?: boolean;
}
const GroupWrapper = ({
  children,
  capabilityGroup,
  isSearch,
}: GroupWrapperProps): ReactElement => {
  const { getShortLabel } = useGetCapabilityGroupLabel();

  const [isActivated, setIsActivated] = useState<boolean>(false);

  return (
    <>
      {capabilityGroup.id === undefined ? (
        <>{children}</>
      ) : (
        <StyledGroupDiv>
          <Headline>
            <ToggleLink onClick={() => setIsActivated(!isActivated)}>
              <Row mb="0">
                <Column size="shrink" padding="0">
                  <ToggleIcon>
                    {isActivated && <ChevronDownIcon />}
                    {!isActivated && <ChevronRightIcon />}
                  </ToggleIcon>
                </Column>
                <Column padding="0">
                  {getShortLabel(
                    capabilityGroup,
                    isSearch !== undefined && isSearch
                      ? ProjectMandateEnum.REQUEST
                      : isSearch !== undefined
                      ? ProjectMandateEnum.ENTITY
                      : undefined
                  )}{" "}
                </Column>
              </Row>
            </ToggleLink>
          </Headline>
          {isActivated && <>{children}</>}
        </StyledGroupDiv>
      )}
    </>
  );
};

export default GroupWrapper;
