import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import { Button, Card, Headline, Right, Space } from "../../components";
import { MatchingCapabilitiesForm } from "../index";
import { useTranslation } from "react-i18next";
import adaptInitialMatchingValues from "../../helpers/adaptInitialMatchingValues";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { validateRequiredTypes } from "../../helpers/formValidation";
import _ from "lodash";
import { FormikErrors } from "formik/dist/types";
import { RangeValue } from "../../interfaces/InputFieldProps";
import useCapabilities from "../../api/capabilities/useCapabilities";

interface MatchingFormProps {
  projectCategories: ProjectCategoriesColumn[];
  handleSearch: ({
    values,
    files,
  }: {
    values: FormikValues;
    files: Record<string, File | null>;
  }) =>
    | void
    | Promise<void>
    | Promise<{
        values: FormikValues;
        files?: Record<string, File | null>;
      }>;
  isEnabled: (id?: string) => boolean;
  toggle: (id?: string) => void;
  hasSearched: boolean;
  buttonText: string;
  buttonRedoText: string;
  isSearch: boolean;
  initialValues?: FormikValues;
}

interface UseAdaptPrioritiesInterface {
  getPriorities: (values?: FormikValues) => Promise<
    Array<{
      formGroupId: string;
      priorityCount: number;
    }>
  >;
}
const useAdaptPriorities = (): UseAdaptPrioritiesInterface => {
  const { getByName } = useCapabilities();

  const getPriorities = async (
    values?: FormikValues
  ): Promise<
    Array<{
      formGroupId: string;
      priorityCount: number;
    }>
  > => {
    if (values === undefined) {
      return [];
    }
    const priorities: Array<{
      formGroupId: string;
      priorityCount: number;
    }> = [];

    const projectCategories = Object.keys(values);

    for (const projectCategory of projectCategories) {
      const inputFields = Object.keys(values[projectCategory]);

      for (const inputField of inputFields) {
        // todo: duplicate code
        const [, cleanedValueKey, priority] =
          inputField.match(/([\w|.]+)\.(\d+)$/) ?? [];

        // prio 0 enabled by default
        if (priority === "0") {
          continue;
        }

        const capability = await getByName(cleanedValueKey);
        if (capability === null) {
          continue;
        }
        const formGroupIds =
          capability.formGroups?.map((formGroup) => formGroup.id) ?? [];
        const priorityCount = +priority + 1;

        formGroupIds.forEach((formGroupId) => {
          if (formGroupId === undefined) {
            return;
          }
          const foundPriority = priorities.find(
            (priority) => priority.formGroupId === formGroupId
          );
          if (foundPriority === undefined) {
            priorities.push({
              formGroupId,
              priorityCount,
            });
            return;
          }

          if (
            foundPriority.priorityCount === priorityCount ||
            foundPriority.priorityCount > priorityCount
          ) {
            return;
          }

          foundPriority.priorityCount = +priority;
        });
      }
    }
    return priorities;
  };

  return { getPriorities };
};

const MatchingForm = ({
  projectCategories,
  handleSearch,
  isEnabled,
  toggle,
  hasSearched,
  buttonText,
  buttonRedoText,
  isSearch,
  initialValues,
}: MatchingFormProps): ReactElement => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<Record<string, File | null>>({});
  const { getPriorities } = useAdaptPriorities();

  const [priorities, setPriorities] = useState<
    Array<{
      formGroupId: string;
      priorityCount: number;
    }>
  >([]);
  const [updatedValues, setUpdatedValues] = useState<FormikValues | null>(null);

  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    void getPriorities(initialValues).then((adaptedPriorities) => {
      setPriorities(adaptedPriorities);
    });
  }, [initialValues]);

  const initialMatchingValues = _.merge(
    {},
    adaptInitialMatchingValues(projectCategories, priorities, isSearch),
    initialValues !== undefined ? initialValues : {},
    updatedValues ?? {}
  );
  const updatedPriorities = [...priorities];

  const handlePriorityChange = ({
    formGroupId,
    operation,
  }: {
    formGroupId?: string;
    operation: "add" | "remove";
  }): void => {
    if (!isSearch || formGroupId === undefined) {
      return;
    }

    const foundPriority = priorities.find(
      (priority) => priority.formGroupId === formGroupId
    );

    if (foundPriority === undefined) {
      if (operation === "remove") {
        return;
      }
      setPriorities([...priorities, { formGroupId, priorityCount: 2 }]);
      return;
    }

    if (operation === "add") {
      setPriorities(
        priorities.map((priority) => {
          if (priority.formGroupId !== formGroupId) {
            return priority;
          }

          return {
            ...priority,
            priorityCount: priority.priorityCount + 1,
          };
        })
      );
      return;
    }

    if (foundPriority.priorityCount <= 1) {
      return;
    }

    setPriorities(
      priorities.map((priority) => {
        if (priority.formGroupId !== formGroupId) {
          return priority;
        }

        return {
          ...priority,
          priorityCount: priority.priorityCount - 1,
        };
      })
    );
  };

  return (
    <Formik<
      Record<
        string,
        Record<string, string | number | RangeValue | File | boolean>
      >
    >
      enableReinitialize
      initialValues={initialMatchingValues}
      validate={(values) => {
        setUpdatedValues(values);
        setShowErrors(true);
        return validateRequiredTypes(values, projectCategories, isEnabled);
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        await handleSearch({ values, files });
        setSubmitting(false);
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const doChange = (
          e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
        ): void => {
          if (e.currentTarget.type !== "file") {
            handleChange(e);
            return;
          }

          if (
            "files" in e.currentTarget &&
            e.currentTarget.files !== undefined &&
            e.currentTarget.files !== null
          ) {
            setFiles({
              ...files,
              [e.currentTarget.name]: e.currentTarget.files.item(0),
            });
          }
        };

        return (
          <form onSubmit={handleSubmit} onChange={handleChange}>
            {projectCategories.map(({ id, name, formGroups }) => (
              <React.Fragment key={id}>
                {formGroups !== undefined && isEnabled(id) && (
                  <>
                    <Card>
                      <Headline>{t(`PROJECT_CATEGORIES.${name}`)}</Headline>
                      <MatchingCapabilitiesForm
                        formGroups={formGroups}
                        handlePriorityChange={handlePriorityChange}
                        errors={
                          showErrors
                            ? (errors[name] as FormikErrors<any>) ?? {}
                            : {}
                        }
                        handleBlur={handleBlur}
                        toggleGroup={(groupId) => toggle(groupId)}
                        isGroupEnabled={(groupId) => isEnabled(groupId)}
                        isSearch={isSearch}
                        name={name}
                        handleChange={doChange}
                        values={values}
                        priorities={updatedPriorities}
                      />
                    </Card>
                    <Space />
                  </>
                )}
              </React.Fragment>
            ))}
            <Right>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant={isSubmitting ? "success" : "default"}
                whileTap={{
                  scale: 0.8,
                }}
              >
                {!isSubmitting
                  ? !hasSearched
                    ? buttonText
                    : buttonRedoText
                  : t("BUTTONS.updating")}
              </Button>
            </Right>
          </form>
        );
      }}
    </Formik>
  );
};

export default MatchingForm;
