import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";

interface UseUpdateProjectInterface {
  update: (project: ProjectInterface) => Promise<ProjectInterface | null>;
}

const useUpdateProject = (): UseUpdateProjectInterface => {
  const { put } = useApi();

  const update = async (
    project: ProjectInterface
  ): Promise<ProjectInterface | null> => {
    if (project.id === undefined) {
      return null;
    }

    const { data, status } = await put(`/project/${project.id}`, project);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateProject;
