import useApi from "../useApi";
import { TranslationInterface } from "../../interfaces/TranslationInterface";

interface UseGetTranslationInterface {
  getByRelationId: (id: string) => Promise<TranslationInterface[] | null>;
}

const useGetTranslationByRelationId = (): UseGetTranslationInterface => {
  const { get } = useApi();

  const getByRelationId = async (
    id: string
  ): Promise<TranslationInterface[] | null> => {
    const { data, status } = await get(`/translation/byRelation/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByRelationId };
};

export default useGetTranslationByRelationId;
