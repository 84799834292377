import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";

interface UseGetProjectInterface {
  getById: (id: string) => Promise<ProjectInterface | null>;
}

const useGetProject = (): UseGetProjectInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<ProjectInterface | null> => {
    const { data, status } = await get(`/project/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetProject;
