import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Clickable,
  Column,
  Headline,
  Row,
  Text,
} from "../../components";
import { FormikErrors } from "formik/dist/types";
import StyledGroupDiv from "../../components/styles/StyledGroupDiv";
import getCapabilityGroups from "../../helpers/getCapabilityGroups";
import { CapabilityGroupCollectionInterface } from "../../interfaces/CapabilityGroupCollectionInterface";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CircledMinusIcon,
  CircledPlusIcon,
} from "../../icons";
import styled from "styled-components";
import { RangeValue } from "../../interfaces/InputFieldProps";
import DisplayCapabilityGroup from "./DisplayCapabilityGroup";

// todo: notes:
//  This can be used for both generation of search and creation! Find out how.
interface MatchingFormProps {
  values: Record<
    string,
    Record<string, string | number | RangeValue | File | boolean>
  >;
  name: string;
  formGroups: FormGroupInterface[];
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleBlur?: (values: Record<string, any>) => void;
  isSearch?: boolean;
  toggleGroup: (id?: string) => void;
  isGroupEnabled: (id?: string) => boolean;
  errors: FormikErrors<any>;
  handlePriorityChange: ({
    formGroupId,
    operation,
  }: {
    formGroupId?: string;
    operation: "add" | "remove";
  }) => void;
  priorities: Array<{
    formGroupId: string;
    priorityCount: number;
  }>;
}

export const ToggleLink = styled.a`
  display: inline-block;
  cursor: pointer;
`;
export const ToggleIcon = styled.div`
  margin-right: 1rem;
  display: inline-block;
`;

const StyledPriorityGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
`;
const StyledPriorityColumn = styled.div`
  flex: 1 0;
`;

interface DisplayFormGroupProps {
  formGroup: FormGroupInterface;
  errors: FormikErrors<any>;
  toggleGroup: (id?: string) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleBlur?: (values: Record<string, any>) => void;
  isGroupEnabled: (id?: string) => boolean;
  values: Record<
    string,
    Record<string, string | number | RangeValue | File | boolean>
  >;
  isSearch?: boolean;
  name: string;
  handlePriorityChange: ({
    formGroupId,
    operation,
  }: {
    formGroupId?: string;
    operation: "add" | "remove";
  }) => void;
  priorities: Array<{
    formGroupId: string;
    priorityCount: number;
  }>;
}
export const DisplayFormGroup = ({
  formGroup,
  errors,
  toggleGroup,
  handleBlur,
  isGroupEnabled,
  values,
  handleChange,
  isSearch,
  name,
  handlePriorityChange,
  priorities,
}: DisplayFormGroupProps): ReactElement => {
  const { t } = useTranslation();

  const [capabilityGroups, setCapabilityGroups] = useState<
    CapabilityGroupCollectionInterface[]
  >([]);

  useEffect(() => {
    setCapabilityGroups(getCapabilityGroups(formGroup));
  }, [formGroup]);

  // todo outsource
  const getLabel = (): string => {
    const label = t(`FORM_GROUPS.${formGroup.name}`);
    if (formGroup.translation === undefined) {
      return label;
    }
    if (isSearch !== undefined && isSearch) {
      const foundTranslation = formGroup.translation.find(
        (translation) => translation.mandate === "REQUEST"
      );
      return foundTranslation === undefined ? label : foundTranslation.value;
    }
    const foundTranslation = formGroup.translation.find(
      (translation) => translation.mandate === "ENTITY"
    );
    return foundTranslation === undefined ? label : foundTranslation.value;
  };

  // todo outsource
  const relevantPriorityCount = priorities.find((priority) => {
    return priority.formGroupId === formGroup.id;
  });
  const prioLoop: number[] = [];
  for (
    let iterator = 0;
    iterator < (relevantPriorityCount?.priorityCount ?? 1);
    iterator += 1
  ) {
    prioLoop.push(iterator);
  }

  return (
    <>
      <StyledGroupDiv isError={errors[formGroup.name] !== undefined}>
        <Headline>
          <Row mb="0">
            <Column size="shrink" padding="0 0 0 1.5rem">
              {!formGroup.isMandatory && (
                <>
                  <Checkbox
                    label={getLabel()}
                    name={formGroup.id ?? formGroup.name}
                    inline
                    handleChange={() => toggleGroup(formGroup.id)}
                    handleBlur={handleBlur}
                    value={isGroupEnabled(formGroup.id)}
                  />{" "}
                </>
              )}
              {formGroup.isMandatory && <>{getLabel()} (*)</>}
            </Column>
            <Column padding="0 0 0 1.5rem">
              {isSearch !== undefined && isSearch && (
                <Clickable
                  title={t("CREATE_PROJECT.priority.add")}
                  onClick={() =>
                    handlePriorityChange({
                      formGroupId: formGroup.id,
                      operation: "add",
                    })
                  }
                >
                  <CircledPlusIcon />
                </Clickable>
              )}
            </Column>
          </Row>
        </Headline>
        {(formGroup.isMandatory || isGroupEnabled(formGroup.id)) && (
          <StyledPriorityGroup>
            {prioLoop.map((priority) => (
              <React.Fragment key={priority}>
                <StyledPriorityColumn>
                  {prioLoop.length > 1 && (
                    <Row mb="0">
                      <Column size="shrink" padding="0 0 0 1.5rem">
                        <Headline>
                          {t("CREATE_PROJECT.priority.label", {
                            value: priority + 1,
                          })}
                        </Headline>
                      </Column>
                      <Column>
                        {priority + 1 === prioLoop.length && (
                          <Clickable
                            title={t("CREATE_PROJECT.priority.remove")}
                            onClick={() =>
                              handlePriorityChange({
                                formGroupId: formGroup.id,
                                operation: "remove",
                              })
                            }
                          >
                            <CircledMinusIcon />
                          </Clickable>
                        )}
                      </Column>
                    </Row>
                  )}
                  {capabilityGroups.map((capabilityGroup) => (
                    <React.Fragment
                      key={`${capabilityGroup.id ?? ""}${formGroup.id ?? ""}`}
                    >
                      <DisplayCapabilityGroup
                        capabilityGroup={capabilityGroup}
                        values={values}
                        handleChange={handleChange}
                        name={name}
                        isSearch={isSearch}
                        priority={priority}
                      />
                    </React.Fragment>
                  ))}
                </StyledPriorityColumn>
              </React.Fragment>
            ))}
          </StyledPriorityGroup>
        )}
        {errors[formGroup.name] !== undefined &&
          typeof errors[formGroup.name] === "string" && (
            <Text variant="danger">
              {t(`ERRORS.required-type.${errors[formGroup.name] as string}`)}
            </Text>
          )}
      </StyledGroupDiv>
    </>
  );
};

const MatchingCapabilitiesForm = ({
  name,
  values,
  formGroups,
  handleChange,
  handleBlur,
  isSearch,
  toggleGroup,
  isGroupEnabled,
  errors,
  handlePriorityChange,
  priorities,
}: MatchingFormProps): ReactElement => {
  const [isShowOptionalGroups, setIsShowOptionalGroups] =
    useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    formGroups.forEach((formGroup) => {
      if (formGroup.isMandatory && !isGroupEnabled(formGroup.id)) {
        toggleGroup(formGroup.id);
      }
    });
  }, [formGroups, toggleGroup, isGroupEnabled]);

  return (
    <>
      {formGroups.map((formGroup) => (
        <React.Fragment key={formGroup.name}>
          {formGroup.isMandatory && (
            <DisplayFormGroup
              handlePriorityChange={handlePriorityChange}
              formGroup={formGroup}
              errors={errors}
              toggleGroup={toggleGroup}
              handleChange={handleChange}
              isGroupEnabled={isGroupEnabled}
              values={values}
              name={name}
              handleBlur={handleBlur}
              isSearch={isSearch}
              priorities={priorities}
            />
          )}
        </React.Fragment>
      ))}

      <Headline>
        <ToggleLink
          onClick={() => setIsShowOptionalGroups(!isShowOptionalGroups)}
        >
          <Row mb="0">
            <Column size="shrink" padding="0">
              <ToggleIcon>
                {isShowOptionalGroups && <ChevronDownIcon />}
                {!isShowOptionalGroups && <ChevronRightIcon />}
              </ToggleIcon>
            </Column>
            <Column padding="0">{t("FORM_GROUPS.optional-groups")}</Column>
          </Row>
        </ToggleLink>
      </Headline>
      {isShowOptionalGroups &&
        formGroups.map((formGroup) => (
          <React.Fragment key={formGroup.name}>
            {!formGroup.isMandatory && (
              <DisplayFormGroup
                handlePriorityChange={handlePriorityChange}
                formGroup={formGroup}
                errors={errors}
                toggleGroup={toggleGroup}
                handleChange={handleChange}
                isGroupEnabled={isGroupEnabled}
                values={values}
                name={name}
                handleBlur={handleBlur}
                isSearch={isSearch}
                priorities={priorities}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
};
export default MatchingCapabilitiesForm;
