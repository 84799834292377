import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableDataType } from "../interfaces/SearchableDataType";
import { SearchableDataInterface } from "../interfaces/SearchableDataInterface";
import SearchInput from "../components/Form/SearchInput";
import { Button, Form } from "../components";
import CheckIcon from "../icons/CheckIcon";
import { UserInterface } from "../interfaces/UserInterface";
import useUsers from "../api/users/useUsers";

interface UserSelectionProps {
  handleSelected: (selected: SearchableDataInterface | null) => void;
  companyId: string;
  initialValue?: string | null;
}

const getUserLabel = (user: UserInterface): string => {
  return `${
    user.profile !== undefined && user.profile !== null
      ? `${user.profile.firstName ?? ""} ${user.profile.lastName ?? ""} `
      : ""
  }(${user.email})`;
};

const UserSelection = ({
  handleSelected,
  companyId,
  initialValue,
}: UserSelectionProps): ReactElement => {
  const { t } = useTranslation();
  const { getByCompany, create } = useUsers();

  const [searchData, setSearchData] = useState<SearchableDataType>([]);
  const [selected, setSelected] = useState<SearchableDataInterface | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    handleSelected(selected);
  }, [selected]);

  useEffect(() => {
    const loadUsers = async (): Promise<void> => {
      const users = await getByCompany(companyId);

      setSearchData(
        users.map((user) => ({
          label: getUserLabel(user),
          value: user.id ?? "",
        }))
      );

      if (initialValue === undefined || initialValue === null) {
        return;
      }
      const foundUser = users.find((user) => user.id === initialValue);

      if (foundUser === undefined || foundUser.id === undefined) {
        return;
      }
      setSelected({
        label: getUserLabel(foundUser),
        value: foundUser.id,
      });
    };

    void loadUsers();
  }, []);

  return (
    <>
      <SearchInput
        label={t("USER.user")}
        searchData={searchData}
        handleCreate={(value) => {
          setSelected({
            label: value,
            value: "createNew",
          });
        }}
        handleSelect={setSelected}
        selected={selected}
        createText="USER.create"
      />
      {selected !== null && selected.value === "createNew" && (
        <>
          <Form<{
            email: string;
            firstName: string;
            lastName: string;
            phone: string;
            position: string;
          }>
            fields={[
              {
                id: "email",
                label: t("USER.email"),
                type: "email",
              },
              {
                id: "firstName",
                label: t("USER.firstName"),
                type: "text",
              },
              {
                id: "lastName",
                label: t("USER.lastName"),
                type: "text",
              },
              {
                id: "phone",
                label: t("USER.phone"),
                type: "phone",
              },
              {
                id: "position",
                label: t("USER.position"),
                type: "text",
              },
            ]}
            initialValues={{
              email: selected.label,
              firstName: "",
              lastName: "",
              phone: "",
              position: "",
            }}
            handleSubmit={async ({
              firstName,
              email,
              lastName,
              phone,
              position,
            }) => {
              setIsSubmitting(true);

              const user = await create({
                email,
                profile: {
                  firstName,
                  lastName,
                  phone,
                  position,
                },
                companies: [
                  {
                    id: companyId,
                  },
                ],
              });

              if (user.id === undefined) {
                setIsSubmitting(false);
                return;
              }

              setSelected({
                label: getUserLabel(user),
                value: user.id,
              });
              setIsSubmitting(false);
            }}
          >
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={isSubmitting ? "success" : "default"}
              whileTap={{
                scale: 0.8,
              }}
            >
              {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default UserSelection;
