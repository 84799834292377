import useApi from "../useApi";
import { CompanyInterface } from "../../interfaces/CompanyInterface";

interface UseGetCompaniesByUserInterface {
  getByUser: ({ userId }: { userId: string }) => Promise<CompanyInterface[]>;
}

const useGetCompaniesByUser = (): UseGetCompaniesByUserInterface => {
  const { get } = useApi();

  const getByUser = async ({
    userId,
  }: {
    userId: string;
  }): Promise<CompanyInterface[]> => {
    const { data, status } = await get(`/company/by-user/${userId}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByUser };
};

export default useGetCompaniesByUser;
