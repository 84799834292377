import { create } from "zustand";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { ProjectInterface } from "../interfaces/ProjectInterface";

export interface ProjectsState extends CrudStateInterface<ProjectInterface> {}

const useProjectsStore = create<ProjectsState>((set, get) => ({
  ...defaultStateCrud<ProjectInterface>(set, get),
}));

export default useProjectsStore;
