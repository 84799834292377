import useGetFormGroups from "./useGetFormGroups";
import useGetFormGroup from "./useGetFormGroup";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import useFormGroupStore from "../../stores/useFormGroupStore";
import useRemoveCapabilityFromFormGroup from "./useRemoveCapabilityFromFormGroup";
import useAddCapabilityToFormGroup from "./useAddCapabilityToFormGroup";
import useUpdateFormGroup from "./useUpdateFormGroup";
import useDeleteFormGroup from "./useDeleteFormGroup";
import useCreateFormGroup from "./useCreateFormGroup";
import useUpdateFormGroupWithProjectCategories from "./useUpdateFormGroupWithProjectCategories";
import useCapabilities from "../capabilities/useCapabilities";

interface UseFormGroupsInterface {
  getAll: () => Promise<FormGroupInterface[]>;
  formGroups: FormGroupInterface[];
  getById: (id: string) => Promise<FormGroupInterface | null>;
  removeCapabilityFromFormGroup: ({
    formGroupId,
    capabilityId,
  }: {
    formGroupId: string;
    capabilityId: string;
  }) => Promise<FormGroupInterface | null>;
  addCapabilityToFormGroup: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId?: string;
  }) => Promise<FormGroupInterface | null>;
  addCapabilityToCapabilityGroup: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId: string;
  }) => Promise<FormGroupInterface | null>;
  create: (formGroup: FormGroupInterface) => Promise<FormGroupInterface>;
  update: (formGroup: FormGroupInterface) => Promise<FormGroupInterface | null>;
  deleteById: (id: string) => Promise<boolean>;
  updateWithProjectCategories: ({
    formGroup,
    projectCategoriesToAdd,
    projectCategoriesToRemove,
  }: {
    formGroup: FormGroupInterface;
    projectCategoriesToRemove: string[];
    projectCategoriesToAdd: string[];
  }) => Promise<FormGroupInterface | null>;
}

const useFormGroups = (): UseFormGroupsInterface => {
  const add = useFormGroupStore((state) => state.add);
  const remove = useFormGroupStore((state) => state.remove);
  const formGroups = useFormGroupStore((state) => state.data);
  const set = useFormGroupStore((state) => state.set);
  const stateUpdate = useFormGroupStore((state) => state.update);
  const gotAll = useFormGroupStore((state) => state.gotAll);
  const stateGetAll = useFormGroupStore((state) => state.getAll);
  const stateGetById = useFormGroupStore((state) => state.getById);
  const setGotAll = useFormGroupStore((state) => state.setGotAll);
  const { addFormGroup: capabilityAddFormGroup } = useCapabilities();
  const { removeFormGroup: capabilityRemoveFormGroup } = useCapabilities();
  const { getAll: apiGetAll } = useGetFormGroups();
  const { update: apiUpdate } = useUpdateFormGroup();
  const { update: apiUpdateWithProjectCategories } =
    useUpdateFormGroupWithProjectCategories();
  const { create: apiCreate } = useCreateFormGroup();
  const { getById: apiGetById } = useGetFormGroup();
  const { deleteById: apiDeleteById } = useDeleteFormGroup();
  const { removeCapabilityFromFormGroup: apiRemoveCapabilityFromFormGroup } =
    useRemoveCapabilityFromFormGroup();
  const {
    addCapabilityToFormGroup: apiAddCapabilityToFormGroup,
    addCapabilityToCapabilityGroup: apiAddCapabilityToCapabilityGroup,
  } = useAddCapabilityToFormGroup();

  const getAll = async (): Promise<FormGroupInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const formGroups = await apiGetAll();

    set(formGroups);
    setGotAll();

    return formGroups;
  };

  const create = async (
    formGroup: FormGroupInterface
  ): Promise<FormGroupInterface> => {
    const created = await apiCreate(formGroup);
    if (created !== null) {
      add(created);
    }

    return created;
  };

  const deleteById = async (id: string): Promise<boolean> => {
    const removed = await apiDeleteById(id);

    remove(id);

    return removed;
  };

  const getById = async (id: string): Promise<FormGroupInterface | null> => {
    let formGroup = stateGetById(id);

    if (formGroup !== null) {
      return formGroup;
    }

    formGroup = await apiGetById(id);

    if (formGroup === null) {
      return null;
    }

    add(formGroup);
    return formGroup;
  };

  const removeCapabilityFromFormGroup = async ({
    formGroupId,
    capabilityId,
  }: {
    formGroupId: string;
    capabilityId: string;
  }): Promise<FormGroupInterface | null> => {
    const updatedFormGroup = await apiRemoveCapabilityFromFormGroup({
      formGroupId,
      capabilityId,
    });

    if (updatedFormGroup === null) {
      return null;
    }

    await capabilityRemoveFormGroup({
      capabilityId,
      formGroup: updatedFormGroup,
    });
    stateUpdate({ id: formGroupId, updatedDataSet: updatedFormGroup });

    return updatedFormGroup;
  };

  const addCapabilityToFormGroup = async ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId?: string;
  }): Promise<FormGroupInterface | null> => {
    const updatedFormGroup = await apiAddCapabilityToFormGroup({
      formGroupId,
      capabilityId,
      capabilityGroupId,
    });

    if (updatedFormGroup === null) {
      return null;
    }

    await capabilityAddFormGroup({ capabilityId, formGroup: updatedFormGroup });
    stateUpdate({ id: formGroupId, updatedDataSet: updatedFormGroup });

    return updatedFormGroup;
  };

  const updateWithProjectCategories = async ({
    formGroup,
    projectCategoriesToAdd,
    projectCategoriesToRemove,
  }: {
    formGroup: FormGroupInterface;
    projectCategoriesToRemove: string[];
    projectCategoriesToAdd: string[];
  }): Promise<FormGroupInterface | null> => {
    if (formGroup.id === undefined) {
      return null;
    }

    const updatedFormGroup = await apiUpdateWithProjectCategories({
      formGroup,
      projectCategoriesToAdd,
      projectCategoriesToRemove,
    });

    if (updatedFormGroup === null || updatedFormGroup.id === undefined) {
      return null;
    }

    stateUpdate({ id: updatedFormGroup.id, updatedDataSet: updatedFormGroup });

    return updatedFormGroup;
  };

  const addCapabilityToCapabilityGroup = async ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId: string;
  }): Promise<FormGroupInterface | null> => {
    const updatedFormGroup = await apiAddCapabilityToCapabilityGroup({
      formGroupId,
      capabilityId,
      capabilityGroupId,
    });

    if (updatedFormGroup === null) {
      return null;
    }

    stateUpdate({ id: formGroupId, updatedDataSet: updatedFormGroup });

    return updatedFormGroup;
  };

  const update = async (
    formGroup: FormGroupInterface
  ): Promise<FormGroupInterface | null> => {
    const updatedFormGroup = await apiUpdate(formGroup);

    if (updatedFormGroup === null || updatedFormGroup.id === undefined) {
      return null;
    }

    stateUpdate({ id: updatedFormGroup.id, updatedDataSet: updatedFormGroup });

    return updatedFormGroup;
  };

  return {
    getAll,
    getById,
    deleteById,
    removeCapabilityFromFormGroup,
    addCapabilityToFormGroup,
    addCapabilityToCapabilityGroup,
    formGroups,
    updateWithProjectCategories,
    update,
    create,
  };
};

export default useFormGroups;
