import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseCreateUserInterface {
  create: (user: UserInterface) => Promise<UserInterface>;
}

const useCreateUser = (): UseCreateUserInterface => {
  const { post } = useApi();

  const create = async (user: UserInterface): Promise<UserInterface> => {
    const { status, data: createdUser } = await post(`/user`, {
      ...user,
    });

    if (status === 200) {
      return createdUser;
    }

    throw new Error("User creation error");
  };

  return { create };
};

export default useCreateUser;
