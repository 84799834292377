import { useTranslation } from "react-i18next";
import { NavigationItemInterface } from "../interfaces/NavigationItemInterface";
import useAuthentication from "../api/useAuthentication";
import { useNavigate } from "react-router-dom";

interface UseNavigationInterface {
  navigation: Array<{ label: string; items: NavigationItemInterface[] }>;
}
const useNavigation = (): UseNavigationInterface => {
  const { token, logout } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const processLogout = (): void => {
    logout();
    navigate(0);
  };

  const adminNavigation: Array<{
    label: string;
    items: NavigationItemInterface[];
  }> = [
    {
      label: t("NAVIGATION.dashboards"),
      items: [
        {
          label: t("NAVIGATION.overview"),
          route: "/",
        },
        {
          label: t("NAVIGATION.create-project"),
          route: "/create-project",
        },
      ],
    },
    {
      label: t("NAVIGATION.client"),
      items: [
        {
          label: t("NAVIGATION.companies"),
          route: "/client/companies",
        },
        {
          label: t("NAVIGATION.users"),
          route: "/client/users",
        },
        {
          label: t("NAVIGATION.projects"),
          route: "/client/projects",
        },
      ],
    },
    {
      label: t("NAVIGATION.packing-machine"),
      items: [
        {
          label: t("NAVIGATION.companies"),
          route: "/packing-machine/companies",
        },
        {
          label: t("NAVIGATION.users"),
          route: "/packing-machine/users",
        },
        {
          label: t("NAVIGATION.machines"),
          route: "/packing-machine/machines",
        },
      ],
    },
    {
      label: t("NAVIGATION.contract-manufacturer"),
      items: [
        {
          label: t("NAVIGATION.companies"),
          route: "/contract-manufacturer/companies",
        },
        {
          label: t("NAVIGATION.users"),
          route: "/contract-manufacturer/users",
        },
        {
          label: t("NAVIGATION.machines"),
          route: "/contract-manufacturer/machines",
        },
      ],
    },
    {
      label: t("NAVIGATION.used-machine"),
      items: [
        {
          label: t("NAVIGATION.companies"),
          route: "/used-machine/companies",
        },
        {
          label: t("NAVIGATION.users"),
          route: "/used-machine/users",
        },
        {
          label: t("NAVIGATION.machines"),
          route: "/used-machine/machines",
        },
      ],
    },
    {
      label: t("NAVIGATION.packaging"),
      items: [
        {
          label: t("NAVIGATION.companies"),
          route: "/packaging/companies",
        },
        {
          label: t("NAVIGATION.users"),
          route: "/packaging/users",
        },
        {
          label: t("NAVIGATION.packaging"),
          route: "/packaging/entities",
        },
      ],
    },
    {
      label: t("NAVIGATION.lists"),
      items: [
        {
          label: t("NAVIGATION.public-profiles"),
          route: "/public-profiles",
        },
        {
          label: t("NAVIGATION.frontends"),
          route: "/frontends",
        },
      ],
    },
    {
      label: t("NAVIGATION.capability-profiles"),
      items: [
        {
          label: t("NAVIGATION.capabilities"),
          route: "/capabilities",
        },
        {
          label: t("NAVIGATION.project-categories"),
          route: "/project-categories",
        },
        {
          label: t("NAVIGATION.forms"),
          route: "/forms",
        },
      ],
    },
    {
      label: t("NAVIGATION.account"),
      items: [
        {
          label: t("NAVIGATION.my-account"),
          route: "/account/my-account",
        },
        {
          label: t("NAVIGATION.my-company"),
          route: "/account/company",
        },
        {
          label: t("NAVIGATION.logout"),
          route: "/logout",
          handleClick: processLogout,
        },
      ],
    },
  ];

  const partnerNavigation: Array<{
    label: string;
    items: NavigationItemInterface[];
  }> = [
    {
      label: t("NAVIGATION.dashboards"),
      items: [
        {
          label: t("NAVIGATION.overview"),
          route: "/",
        },
        {
          label: t("NAVIGATION.machines"),
          route: "/machines",
        },
        {
          label: t("NAVIGATION.client-projects"),
          route: "/client/projects",
        },
        {
          label: t("NAVIGATION.partner-user-accounts"),
          route: "/users",
        },
      ],
    },
    {
      label: t("NAVIGATION.my-company"),
      items: [
        {
          label: t("NAVIGATION.my-company"),
          route: "/account/company",
        },
        {
          label: t("NAVIGATION.public-profiles"),
          route: "/public-profiles",
        },
      ],
    },
    {
      label: t("NAVIGATION.account"),
      items: [
        {
          label: t("NAVIGATION.my-account"),
          route: "/account/my-account",
        },
        {
          label: t("NAVIGATION.logout"),
          route: "/logout",
          handleClick: processLogout,
        },
      ],
    },
  ];

  const navigation =
    token?.user.roles?.find((role) => role === "ADMIN") !== undefined
      ? adminNavigation
      : partnerNavigation;

  return { navigation };
};
export default useNavigation;
