import useApi from "../useApi";

interface UseGetProjectCapabilityInterface {
  getById: (id: string) => Promise<Buffer | null>;
}

const useGetProjectCapabilityFile = (): UseGetProjectCapabilityInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<Buffer | null> => {
    const { data, status } = await get(`/project-capability/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetProjectCapabilityFile;
