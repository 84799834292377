import React, { ReactElement } from "react";

const TranslationIcon = (): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1059 26.5522C14.0364 26.6911 14 26.8447 14 27L14 27.0036C14.0004 27.1099 14.0177 27.2154 14.0513 27.3162C14.1352 27.5678 14.3156 27.7758 14.5528 27.8944C14.6916 27.9638 14.8448 28 15 28L15.0136 27.9999C15.3874 27.9948 15.7272 27.7816 15.8944 27.4472L22 15.236L28.1054 27.4468C28.2748 27.7856 28.6212 28 29 28C29.0123 28 29.0245 27.9997 29.0368 27.9993C29.1795 27.994 29.3195 27.9583 29.4472 27.8944C29.786 27.725 30 27.3787 30 27C30 26.9877 29.9998 26.9754 29.9993 26.9632C29.9941 26.8205 29.9583 26.6805 29.8944 26.5528L22.8944 12.5528C22.7977 12.3592 22.6407 12.2023 22.4472 12.1055C21.9532 11.8586 21.3526 12.0588 21.1056 12.5528L14.1059 26.5522Z"
        fill="#1C1C1C"
      />
      <path
        d="M17 24H27C27.5523 24 28 23.5523 28 23C28 22.4477 27.5523 22 27 22H17C16.4477 22 16 22.4477 16 23C16 23.5523 16.4477 24 17 24Z"
        fill="#1C1C1C"
      />
      <path
        d="M10 4V7C10 7.55228 10.4477 8 11 8C11.5523 8 12 7.55228 12 7V4C12 3.44772 11.5523 3 11 3C10.4477 3 10 3.44772 10 4Z"
        fill="#1C1C1C"
      />
      <path
        d="M3 8H19C19.5523 8 20 7.55228 20 7C20 6.44772 19.5523 6 19 6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8Z"
        fill="#1C1C1C"
      />
      <path
        d="M7.20952 17.1627C5.18804 18 3 18 3 18C2.44772 18 2 18.4477 2 19C2 19.5523 2.44772 20 3 20C5.58586 20 7.97488 19.0104 7.97488 19.0104C10.3639 18.0209 12.1924 16.1924 12.1924 16.1924C14.0209 14.3639 15.0104 11.9749 15.0104 11.9749C16 9.58586 16 7 16 7C16 6.44772 15.5523 6 15 6C14.4477 6 14 6.44772 14 7C14 9.18804 13.1627 11.2095 13.1627 11.2095C12.3253 13.231 10.7782 14.7782 10.7782 14.7782C9.231 16.3253 7.20952 17.1627 7.20952 17.1627Z"
        fill="#1C1C1C"
      />
      <path
        d="M8.63031 10.6667C8.62618 10.655 8.62184 10.6434 8.61729 10.6319C8.4663 10.2505 8.0977 10 7.6875 10C7.57395 10 7.46122 10.0193 7.35417 10.0572C7.3425 10.0613 7.33091 10.0657 7.31941 10.0702C7.08475 10.1631 6.8939 10.3413 6.78513 10.569L6.77723 10.586C6.71809 10.716 6.6875 10.8572 6.6875 11C6.6875 11.1136 6.70684 11.2263 6.74469 11.3333C8.1219 15.2282 11.4951 17.6141 11.4951 17.6141C14.8684 19.9996 18.9999 20 18.9999 20C19.061 20 19.1219 19.9944 19.1819 19.9833C19.6559 19.8956 20 19.4822 20 19.0001C20 18.939 19.9944 18.8781 19.9833 18.8181C19.8956 18.3441 19.4822 18 19.0001 18C15.5042 17.9996 12.6499 15.9811 12.6499 15.9811C9.79561 13.9626 8.63031 10.6667 8.63031 10.6667Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default TranslationIcon;
