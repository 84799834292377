import React, { ReactElement } from "react";

const DeleteIcon = (): ReactElement => {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 6H5C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H27C27.5523 8 28 7.55228 28 7C28 6.44772 27.5523 6 27 6Z"
        fill="#1C1C1C"
      />
      <path
        d="M12 13V21C12 21.5523 12.4477 22 13 22C13.5523 22 14 21.5523 14 21V13C14 12.4477 13.5523 12 13 12C12.4477 12 12 12.4477 12 13Z"
        fill="#1C1C1C"
      />
      <path
        d="M18 13V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13Z"
        fill="#1C1C1C"
      />
      <path
        d="M8 26V7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7V26C6 26.8284 6.58579 27.4142 6.58579 27.4142C7.17157 28 8 28 8 28H24C24.8284 28 25.4142 27.4142 25.4142 27.4142C26 26.8284 26 26 26 26V7C26 6.44772 25.5523 6 25 6C24.4477 6 24 6.44772 24 7V26H8Z"
        fill="#1C1C1C"
      />
      <path
        d="M10.8787 2.87868C10 3.75736 10 5 10 5V7C10 7.55228 10.4477 8 11 8C11.5523 8 12 7.55228 12 7V5C12 4.58579 12.2929 4.29289 12.2929 4.29289C12.5858 4 13 4 13 4H19C19.4142 4 19.7071 4.29289 19.7071 4.29289C20 4.58579 20 5 20 5V7C20 7.55228 20.4477 8 21 8C21.5523 8 22 7.55228 22 7V5C22 3.75736 21.1213 2.87868 21.1213 2.87868C20.2426 2 19 2 19 2H13C11.7574 2 10.8787 2.87868 10.8787 2.87868Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default DeleteIcon;
