import React, { useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { ProjectInterface } from "../../../interfaces/ProjectInterface";
import { Clickable, LoadingSkeleton } from "../../../components";
import ProjectStateTag from "../ProjectStateTag";
import CompanyById from "../../Company/CompanyById";
import ManageProjectContracts from "../ManageProjectContracts";
import { ProjectContractInterface } from "../../../interfaces/ProjectContractInterface";
import CompanyTag from "../../CompanyTag/CompanyTag";

export interface ProjectsListColumn extends ProjectInterface {}

const columnHelper = createColumnHelper<ProjectsListColumn>();

interface UseProjectsListColumnsInterface {
  columns: Array<ColumnDef<ProjectsListColumn, any>>;
}
const useGeneralProjectListColumns = (): UseProjectsListColumnsInterface => {
  const { t } = useTranslation();

  const [columns] = useState<Array<ColumnDef<ProjectsListColumn, any>>>([
    columnHelper.accessor("projectState", {
      header: `${t("PROJECT.state")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            <ProjectStateTag state={info.getValue()} />
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("name", {
      header: `${t("PROJECT.name")}`,
      cell: (info) => {
        return <>{info.getValue()}</>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("description", {
      header: `${t("PROJECT.description")}`,
      cell: (info) => {
        return <>{info.getValue()}</>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("companyId", {
      header: `${t("PROJECT.company")}`,
      cell: (info) => {
        return <CompanyById attribute="name" id={info.getValue()} />;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("projectContracts", {
      header: `${t("PROJECT.contracts")}`,
      cell: (
        info: CellContext<ProjectsListColumn, ProjectContractInterface[]>
      ) => {
        if (info.getValue() === undefined) {
          return (
            <>
              <LoadingSkeleton />
            </>
          );
        }
        return (
          <>
            {info.getValue().map((projectContract) => (
              <React.Fragment key={projectContract.id}>
                <CompanyTag id={projectContract.companyId} />
              </React.Fragment>
            ))}
            <ManageProjectContracts projectId={info.row.original.id} />
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
  ]);

  return { columns };
};

export default useGeneralProjectListColumns;
