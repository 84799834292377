import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import useProjects from "../../api/projects/useProjects";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { Button, Column, Headline, InputField, Row } from "../../components";
import { useTranslation } from "react-i18next";
import SelectField from "../../components/Form/SelectField";
import {
  ProjectStateType,
  ProjectStatusTypeEnum,
} from "../../interfaces/ProjectStateType";
import { Formik } from "formik";
import { CompanySelection } from "../index";
import useProjectContracts from "../../api/projectContracts/useProjectContracts";

interface ProjectStatusModalProps {
  handleClose: () => void;
  isOpen: boolean;
  projectId: string | null;
}
const ProjectStatusModal = ({
  handleClose,
  isOpen,
  projectId,
}: ProjectStatusModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getById, update: updateProject } = useProjects();
  const {
    getByProjectId: getProjectContractsByProjectId,
    create: createProjectContract,
    update: updateProjectContract,
  } = useProjectContracts();

  const [project, setProject] = useState<ProjectInterface | null>(null);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [contractPartnerId, setContractPartnerId] = useState<string | null>(
    null
  );
  const [isShowContractPartner, setIsShowContractPartner] =
    useState<boolean>(false);

  useEffect(() => {
    setIsTouched(false);
    if (projectId === null) {
      setProject(null);
      return;
    }

    void getById(projectId).then((projectData) => {
      setProject(projectData);
      let hasContractPartner = false;
      projectData?.projectContracts?.forEach((projectContract) => {
        if (projectContract.contractState !== "CLOSED") {
          return;
        }

        hasContractPartner = true;
        setContractPartnerId(projectContract.companyId);
      });

      if (hasContractPartner) {
        return;
      }
      setContractPartnerId(null);
    });
  }, [projectId]);

  if (project === null) {
    return <></>;
  }

  const availableStates = Object.keys(ProjectStatusTypeEnum);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <Headline>{t("PROJECT_STATES.edit.adjust")}</Headline>
        <Formik<{
          projectState: ProjectStateType;
          contractPartner: string | null;
        }>
          initialValues={{
            projectState: project.projectState ?? ProjectStatusTypeEnum.OPEN,
            contractPartner: contractPartnerId,
          }}
          validate={({ projectState }) => {
            if (projectState !== "CLOSED") {
              setIsShowContractPartner(false);
              return;
            }

            setIsShowContractPartner(true);
          }}
          onSubmit={async (updatedValues) => {
            if (project === null || projectId === null) {
              return;
            }
            await updateProject({
              id: projectId,
              name: project.name,
              description: project.description,
              projectState: updatedValues.projectState,
            });

            if (updatedValues.projectState !== "CLOSED") {
              handleClose();
              return;
            }

            // updated and initial project state is closed, so check if contract partner has been changed
            // contract partner stayed the same, do nothing
            if (
              contractPartnerId === updatedValues.contractPartner ||
              updatedValues.contractPartner === null
            ) {
              handleClose();
              return;
            }

            const contracts = await getProjectContractsByProjectId(projectId);

            if (contracts === null) {
              // no contracts found, so create a new contract.
              await createProjectContract({
                projectId,
                companyId: updatedValues.contractPartner,
                contractState: "CLOSED",
              });
              handleClose();
              return;
            }
            const foundContract = contracts.find(
              (contract) => contract.companyId === updatedValues.contractPartner
            );
            if (foundContract !== undefined) {
              // contract already existing, so update this contract's state
              await updateProjectContract({
                id: foundContract.id,
                projectId,
                companyId: updatedValues.contractPartner,
                contractState: "CLOSED",
              });
              handleClose();
              return;
            }

            // no contract existing, create a new contract.
            await createProjectContract({
              projectId,
              companyId: updatedValues.contractPartner,
              contractState: "CLOSED",
            });
            handleClose();
          }}
        >
          {({ handleChange, handleSubmit, values, setValues }) => {
            const touchedChange = (e?: ChangeEvent<any>): void => {
              setIsTouched(true);
              if (e === undefined) {
                return;
              }
              handleChange(e);
            };
            return (
              <>
                <InputField
                  disabled
                  handleChange={() => {}}
                  type="text"
                  value={project.name}
                  label={t("PROJECT_STATES.edit.project-name")}
                  name="name"
                />
                <InputField
                  type="text"
                  disabled
                  handleChange={() => {}}
                  value={project.description}
                  label={t("PROJECT_STATES.edit.description")}
                  name="description"
                />
                {project.company !== undefined && (
                  <InputField
                    type="text"
                    disabled
                    handleChange={() => {}}
                    value={project.company?.name ?? ""}
                    label={t("PROJECT_STATES.edit.company")}
                    name="company"
                  />
                )}
                <SelectField
                  label={t("PROJECT_STATES.edit.adjust")}
                  name="projectState"
                  handleChange={touchedChange}
                  value={values.projectState}
                >
                  {availableStates.map((stateName) => (
                    <React.Fragment key={stateName}>
                      <option value={stateName}>
                        {t(`PROJECT_STATES.${stateName}`)}
                      </option>
                    </React.Fragment>
                  ))}
                </SelectField>
                {isShowContractPartner && (
                  <CompanySelection
                    label={t<string>("PROJECT.contract-won")}
                    initialValue={contractPartnerId}
                    filter="default"
                    handleSelected={(e) => {
                      touchedChange();
                      setValues({
                        ...values,
                        contractPartner: e?.value ?? null,
                      });
                    }}
                  />
                )}
                <Row>
                  <Column>
                    <Button fullWidth type="button" handleClick={handleClose}>
                      {t("BUTTONS.close")}
                    </Button>
                  </Column>
                  <Column>
                    {isTouched && (
                      <Button fullWidth handleClick={handleSubmit}>
                        {t("BUTTONS.save-changes")}
                      </Button>
                    )}
                  </Column>
                </Row>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default ProjectStatusModal;
