import useApi from "../useApi";
import { TranslationInterface } from "../../interfaces/TranslationInterface";

interface UseCreateTranslationInterface {
  create: (translation: TranslationInterface) => Promise<TranslationInterface>;
}

const useCreateTranslation = (): UseCreateTranslationInterface => {
  const { post } = useApi();

  const create = async (
    translation: TranslationInterface
  ): Promise<TranslationInterface> => {
    const { status, data: createdTranslation } = await post(`/translation`, {
      ...translation,
    });

    if (status === 200) {
      return createdTranslation;
    }

    throw new Error("Translation creation error");
  };

  return { create };
};

export default useCreateTranslation;
