import { useTranslation } from "react-i18next";
import { CapabilityInterface } from "../interfaces/CapabilityInterface";

interface UseGetCapabilityLabelInterface {
  getLabel: (capability: CapabilityInterface) => string;
  getShortLabel: (capability: CapabilityInterface) => string;
}
const useGetCapabilityLabel = (): UseGetCapabilityLabelInterface => {
  const { t } = useTranslation();

  const getLabel = (capability: CapabilityInterface): string => {
    return capability.name === undefined
      ? t(`CAPABILITIES.labels.unknown`)
      : capability.type === "SELECT"
      ? `${t(`CAPABILITIES.labels.${capability.name}.label`)} (${
          capability.name
        })`
      : `${t(`CAPABILITIES.labels.${capability.name}`)} (${capability.name})`;
  };

  const getShortLabel = (capability: CapabilityInterface): string => {
    return capability.name === undefined
      ? t(`CAPABILITIES.labels.unknown`)
      : capability.type === "SELECT"
      ? `${t(`CAPABILITIES.labels.${capability.name}.label`)}`
      : `${t([`CAPABILITIES.labels.${capability.name}`, capability.name])}`;
  };

  return { getLabel, getShortLabel };
};

export default useGetCapabilityLabel;
