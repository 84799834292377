import React, { ReactElement, useEffect, useState } from "react";
import SpecificationSheet from "./SpecificationSheet";
import useProjectContracts from "../../api/projectContracts/useProjectContracts";
import { useParams } from "react-router-dom";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import UnlockModal from "../Company/UnlockModal";
import useUnlockProject from "../Company/hooks/useUnlockProject";

// todo: Gate for non logged in users / non project contract holders
const AccessSpecificationSheet = (): ReactElement => {
  const { projectId } = useParams();

  const { getByProjectId: getContractsByProjectId } = useProjectContracts();
  const { handleAccept } = useUnlockProject();

  const [unlockModalConfiguration, setUnlockModalConfiguration] = useState<{
    isModalOpen: boolean;
    projectId: string | null;
    projectContracts: ProjectContractInterface[];
  }>({ isModalOpen: false, projectId: null, projectContracts: [] });
  const [isAccessible, setIsAccessible] = useState<boolean>(false);

  useEffect(() => {
    if (projectId === undefined) {
      return;
    }

    void getContractsByProjectId(projectId).then((contracts) => {
      if (contracts === null) {
        // no access
        setIsAccessible(false);
        return;
      }

      if (
        contracts.find((contract) => contract.contractState !== "OPEN") ===
        undefined
      ) {
        setIsAccessible(false);
        setUnlockModalConfiguration({
          isModalOpen: true,
          projectId,
          projectContracts: contracts,
        });
      }
    });
  }, [projectId]);

  const handleUnlockModalClose = (): void => {
    setUnlockModalConfiguration({
      isModalOpen: false,
      projectId: null,
      projectContracts: [],
    });
  };

  return (
    <>
      <UnlockModal
        isModalOpen={unlockModalConfiguration.isModalOpen}
        handleModalOpenChange={handleUnlockModalClose}
        handleAccept={async (acceptedCompany) => {
          await handleAccept({
            acceptedCompany,
            projectContracts: unlockModalConfiguration.projectContracts,
          });
          const projectId = unlockModalConfiguration.projectId;
          if (projectId === null) {
            return;
          }
          setIsAccessible(true);
          await handleUnlockModalClose();
        }}
        projectContracts={unlockModalConfiguration.projectContracts}
      />
      {isAccessible && <SpecificationSheet />}
    </>
  );
};

export default AccessSpecificationSheet;
