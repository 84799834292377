import useApi from "../useApi";

interface UseDeleteProjectInterface {
  deleteById: (id: string) => Promise<boolean>;
}

const useDeleteProject = (): UseDeleteProjectInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async (id: string): Promise<boolean> => {
    const { status } = await deleteRequest(`/project/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return true;
  };

  return { deleteById };
};

export default useDeleteProject;
