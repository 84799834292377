import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../components/Clickable";
import RelationTags from "../components/RelationTags";
import { IndustryInterface } from "../interfaces/IndustryInterface";
import { CompanyTypeInterface } from "../interfaces/CompanyTypeInterface";
import { UserInterface } from "../interfaces/UserInterface";
import getName from "../helpers/getName";
import { CompanyInterface } from "../interfaces/CompanyInterface";

export interface CompanyColumn extends CompanyInterface {
  edit: boolean;
}

interface UseCompanyListColumnsInterface {
  columns: Array<ColumnDef<CompanyColumn, any>>;
}

const columnHelper = createColumnHelper<CompanyColumn>();

const useCompanyListColumns = (): UseCompanyListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<CompanyColumn, any>> = [
    columnHelper.accessor("name", {
      header: `${t("COMPANY.name")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.address, {
      header: `${t("COMPANY.address")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.country, {
      header: `${t("COMPANY.country")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.industries, {
      id: "industries",
      header: `${t("COMPANY.industries")}`,
      cell: (info) => {
        if (info.getValue() !== undefined) {
          return (
            <RelationTags
              relationsItems={info
                .getValue()
                .map((industry: IndustryInterface) => ({
                  id: industry.id,
                  label: t(`INDUSTRIES.${industry.name}`),
                }))}
            />
          );
        }
        return <></>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.types, {
      id: "types",
      header: `${t("COMPANY.types")}`,
      cell: (info) => {
        if (info.getValue() !== undefined) {
          return (
            <RelationTags
              relationsItems={info
                .getValue()
                .map((type: CompanyTypeInterface) => ({
                  id: type.id,
                  label: t(`CUSTOMER_TYPES.${type.name}`),
                }))}
            />
          );
        }
        return <></>;
      },
      filterFn: "fuzzy",
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.users, {
      id: "users",
      header: `${t("COMPANY.users")}`,
      cell: (info) => {
        if (info.getValue() !== undefined) {
          return (
            <RelationTags
              relationsItems={info.getValue().map((user: UserInterface) => ({
                id: user.id,
                label: getName(user.profile) ?? user.email,
              }))}
            />
          );
        }
        return <></>;
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useCompanyListColumns;
