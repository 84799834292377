import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";

interface UseGetProjectsInterface {
  getAll: () => Promise<ProjectInterface[]>;
}

const useGetProjects = (): UseGetProjectsInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<ProjectInterface[]> => {
    const { data, status } = await get(`/project`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetProjects;
