import React, { ReactElement, useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { UserInterface } from "../interfaces/UserInterface";
import Table from "../components/Table";
import TableControlBar from "../components/TableControlBar";
import PlusIcon from "../icons/PlusIcon";
import { Button, Card, Space } from "../components";
import { CloseIcon } from "../icons";
import { AnimatePresence } from "framer-motion";
import UserForm from "./UserForm";
import Right from "../components/Right";
import CheckIcon from "../icons/CheckIcon";
import useUsers from "../api/useUsers";
import { CompanyTypeType } from "../interfaces/CompanyTypeType";
import useUserListColumns, { PersonColumn } from "../hooks/useUserListColumns";
import { useTranslation } from "react-i18next";

interface UserListProps {
  filter?: CompanyTypeType;
}
const UserList = ({ filter }: UserListProps): ReactElement => {
  const { users, status, deleteById, update, create } = useUsers();
  const { columns } = useUserListColumns();
  const { t } = useTranslation();

  const [data, setData] = useState<PersonColumn[]>([]);
  const [isShowUserAddForm, setIsShowUserAddForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const activeFilter =
    filter === undefined
      ? []
      : [
          {
            id: "companies",
            value: {
              filter: (rowData: Row<UserInterface>) => {
                return (
                  rowData.original.companies?.find((company) => {
                    if (company.types === undefined) {
                      return false;
                    }
                    return (
                      company.types.find(
                        (companyType) => companyType.name === filter
                      ) !== undefined
                    );
                  }) !== undefined
                );
              },
            },
          },
        ];

  useEffect(() => {
    setData(
      users.map((user) => {
        return { ...user, edit: true };
      })
    );
  }, [users]);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowUserAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowUserAddForm(!isShowUserAddForm)}
      />
      <AnimatePresence>
        {isShowUserAddForm && (
          <React.Fragment key={1}>
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <UserForm
                initialValues={{
                  id: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  position: "",
                  role: "PARTNER",
                  companies: [],
                }}
                handleSubmit={(user) => {
                  setIsSubmitting(true);
                  void create({
                    email: user.email,
                    profile: {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      phone: user.phone,
                      position: user.position,
                    },
                    companies: user.companies.map(
                      ({ label }: { label: string }) => {
                        return { name: label };
                      }
                    ),
                  }).then(() => {
                    setIsSubmitting(false);
                    setIsShowUserAddForm(false);
                  });
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </UserForm>
            </Card>
            <Space />
          </React.Fragment>
        )}

        <Table
          data={data}
          columns={columns}
          isLoading={status !== "success"}
          isShowMore
          filter={activeFilter}
          options={[
            {
              label: t("BUTTONS.edit"),
            },
            {
              label: t("BUTTONS.delete"),
              onClick: async (id): Promise<void> => {
                await deleteById({ id }).catch();
              },
            },
          ]}
          getRowCanExpand={() => true}
          renderSubComponent={({ row }) => {
            if (row.original.id === undefined) {
              return <></>;
            }
            return (
              <>
                <Space />
                <Card
                  initial={{ y: "-2rem" }}
                  animate={{ y: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <UserForm
                    initialValues={{
                      id: row.original.id,
                      email: row.original.email,
                      firstName:
                        row.original.profile !== undefined
                          ? row.original.profile?.firstName ?? ""
                          : "",
                      lastName:
                        row.original.profile !== undefined
                          ? row.original.profile?.lastName ?? ""
                          : "",
                      phone:
                        row.original.profile !== undefined
                          ? row.original.profile?.phone ?? ""
                          : "",
                      position:
                        row.original.profile !== undefined
                          ? row.original.profile?.position ?? ""
                          : "",
                      role:
                        row.original.roles !== undefined
                          ? row.original.roles[0]
                          : "PARTNER",
                      companies:
                        row.original.companies !== undefined
                          ? row.original.companies.map((company) => {
                              return {
                                id: company.id ?? "",
                                label: company.name ?? "",
                              };
                            })
                          : [],
                    }}
                    handleSubmit={(user) => {
                      setIsSubmitting(true);
                      void update({
                        id: user.id,
                        email: user.email,
                        profile: {
                          userId: user.id,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          phone: user.phone,
                          position: user.position,
                        },
                        companies: user.companies.map(
                          ({ label }: { label: string }) => {
                            return { name: label };
                          }
                        ),
                      }).then(() => {
                        setIsSubmitting(false);
                        row.getToggleExpandedHandler()();
                      });
                    }}
                  >
                    <Right>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant={isSubmitting ? "success" : "default"}
                        whileTap={{
                          scale: 0.8,
                        }}
                      >
                        {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                      </Button>
                    </Right>
                  </UserForm>
                </Card>
                <Space size={3} />
              </>
            );
          }}
        />
      </AnimatePresence>
    </>
  );
};

export default UserList;
