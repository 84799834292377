import { CapabilityInterface } from "../interfaces/CapabilityInterface";
import { FormikErrors } from "formik";
import getGroupedCapabilitiesByFormGroup from "./getGroupedCapabilitiesByFormGroup";
import { ProjectCategoriesColumn } from "../hooks/useProjectCategoriesListColumns";

const validateRequiredOneOrMore = (
  values: Record<string, any>,
  groupCapabilities: CapabilityInterface[]
): boolean => {
  for (const { name } of groupCapabilities) {
    const fieldValue = values[name ?? ""];

    if (fieldValue !== "" && fieldValue !== false && fieldValue !== null) {
      return true;
    }
  }
  return false;
};

const validateRequiredAll = (
  values: Record<string, any>,
  groupCapabilities: CapabilityInterface[]
): boolean => {
  for (const { name } of groupCapabilities) {
    const fieldValue = values[name ?? ""];

    if (fieldValue === "" || fieldValue === false || fieldValue === null) {
      return false;
    }
  }
  return true;
};

const validateRequiredExclusiveOne = (
  values: Record<string, any>,
  groupCapabilities: CapabilityInterface[]
): boolean => {
  let givenValuesCount = 0;
  groupCapabilities.forEach(({ name }) => {
    const fieldValue = values[name ?? ""];

    if (fieldValue === "" || fieldValue === false || fieldValue === null) {
      return;
    }
    givenValuesCount++;
  });
  return givenValuesCount === 1;
};

const validateRequiredTypes = (
  values: Record<string, any>,
  projectCategories: ProjectCategoriesColumn[],
  isEnabled: (id?: string) => boolean
): FormikErrors<Record<string, Record<string, any>>> => {
  const validationErrors: FormikErrors<Record<string, Record<string, any>>> =
    {};

  for (const projectCategory of projectCategories) {
    if (!isEnabled(projectCategory.id)) {
      continue;
    }

    const { capabilities, name: projectCategoryName } = projectCategory;
    if (capabilities === undefined) {
      continue;
    }
    const groups = getGroupedCapabilitiesByFormGroup(capabilities);

    for (const {
      isMandatory,
      name,
      id,
      requiredType,
      capabilities: groupCapabilities,
    } of groups) {
      if (!isMandatory && !isEnabled(id)) {
        continue;
      }

      let isValid = false;
      switch (requiredType) {
        case "ONE_OR_MORE":
          isValid = validateRequiredOneOrMore(
            values[projectCategoryName],
            groupCapabilities ?? []
          );
          break;
        case "ALL":
          isValid = validateRequiredAll(
            values[projectCategoryName],
            groupCapabilities ?? []
          );
          break;
        case "EXCLUSIVE_ONE":
          isValid = validateRequiredExclusiveOne(
            values[projectCategoryName],
            groupCapabilities ?? []
          );
          break;
        default:
          // no check to make
          isValid = true;
          break;
      }

      if (isValid) {
        continue;
      }

      const validationError = validationErrors[projectCategoryName];

      if (validationError === undefined) {
        validationErrors[projectCategoryName] = {
          [name]: requiredType ?? "",
        };
        continue;
      }

      validationError[name] = requiredType ?? "";
    }
  }

  return validationErrors;
};

export {
  validateRequiredTypes,
  validateRequiredOneOrMore,
  validateRequiredExclusiveOne,
  validateRequiredAll,
};
