export type ProjectStateType =
  | ProjectStatusTypeEnum.OPEN
  | ProjectStatusTypeEnum.ON_HOLD
  | ProjectStatusTypeEnum.CLOSED;

export enum ProjectStatusTypeEnum {
  OPEN = "OPEN",
  ON_HOLD = "ON_HOLD",
  CLOSED = "CLOSED",
}
