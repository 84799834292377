import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface RowProps {
  children: ReactNode;
  mb?: string;
}

const StyledRow = styled.div<{ mb?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1.5rem ${({ mb }) => (mb !== undefined ? mb : "3rem")};
`;

const Row = ({ children, mb }: RowProps): ReactElement => {
  return <StyledRow mb={mb}>{children}</StyledRow>;
};

export default Row;
