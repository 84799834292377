import styled from "styled-components";

const StyledGroupDiv = styled.div<{ isError?: boolean; isDull?: boolean }>`
  border: 1px dashed
    ${({ theme, isError, isDull }) =>
      isError !== undefined && isError
        ? theme.colors.danger
        : isDull !== undefined && isDull
        ? theme.colors.highlight
        : theme.colors.gray};
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 3rem;
`;

export default StyledGroupDiv;
