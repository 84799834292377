import React, { ReactElement, useEffect, useState } from "react";
import Table from "../../components/Table";
import TableControlBar from "../../components/TableControlBar";
import PlusIcon from "../../icons/PlusIcon";
import { Button, Card, Space } from "../../components";
import { AnimatePresence } from "framer-motion";
import usePublicProfileListColumns, {
  EditablePublicProfileBlockInterface,
  PublicProfileColumn,
} from "./hooks/usePublicProfileListColumns";
import ProfileBlockList from "./ProfileBlockList";
import ContentBlock from "../../components/ContentBlock";
import { CloseIcon } from "../../icons";
import Right from "../../components/Right";
import CheckIcon from "../../icons/CheckIcon";
import ProfileBlockForm from "./ProfileBlockForm";
import { useTranslation } from "react-i18next";
import usePublicProfileBlocks from "../../api/publicProfileBlocks/usePublicProfileBlocks";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";

const PublicProfileList = (): ReactElement => {
  const { t } = useTranslation();
  const { columns } = usePublicProfileListColumns();
  const { blocks, getAll, create } = usePublicProfileBlocks();

  const [isShowBlockAddForm, setIsShowBlockAddForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<RequestStatusType>("initial");

  const [data, setData] = useState<PublicProfileColumn[]>([]);

  useEffect(() => {
    const profiles: PublicProfileColumn[] = [];
    blocks.forEach(({ companyId, company, id, name, content, type }) => {
      if (id === undefined || company === undefined) {
        return;
      }
      const foundProfile = profiles.find((profile) => profile.id === companyId);
      const block: EditablePublicProfileBlockInterface = {
        id,
        name,
        content: (
          <ContentBlock
            content={content}
            type={type ?? PublicProfileBlockTypeEnum.HTML}
          />
        ),
        originalContent: content,
        type: type ?? PublicProfileBlockTypeEnum.HTML,
        company,
        companyId: companyId ?? "",
        edit: true,
      };
      if (foundProfile === undefined) {
        profiles.push({
          id: companyId ?? "",
          name: company.name ?? "",
          blocks: [block],
        });
        return;
      }

      foundProfile.blocks.push(block);
    });
    setData(profiles);

    if (status !== "initial") {
      return;
    }
    setStatus("loading");
    void getAll().then(() => {
      setStatus("success");
    });
  }, [blocks, status]);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowBlockAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowBlockAddForm(!isShowBlockAddForm)}
      />
      <AnimatePresence>
        {isShowBlockAddForm && (
          <React.Fragment key={1}>
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <ProfileBlockForm
                initialValues={{
                  id: "",
                  companyId:
                    data[0] !== undefined
                      ? [
                          {
                            label: data[0].name,
                            id: data[0].id,
                          },
                        ]
                      : [],
                  name: "",
                  content: "",
                  type: [
                    {
                      label: "HTML",
                      id: "html",
                    },
                  ],
                }}
                handleSubmit={(block) => {
                  const [{ id }] = block.companyId;
                  const [{ label: type }] = block.type;
                  setIsSubmitting(true);
                  void create({
                    type,
                    companyId: id,
                    name: block.name,
                    content: block.content,
                  })
                    .then(() => {
                      setStatus("initial");
                      setIsSubmitting(false);
                      setIsShowBlockAddForm(false);
                    })
                    .catch(() => {
                      setIsSubmitting(false);
                    });
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </ProfileBlockForm>
            </Card>
            <Space />
          </React.Fragment>
        )}

        <Table
          data={data}
          columns={columns}
          isLoading={status !== "success"}
          isShowMore
          getRowCanExpand={() => true}
          renderSubComponent={({ row }) => {
            if (row.original.id === undefined) {
              return <></>;
            }
            return (
              <>
                <Space />
                <ProfileBlockList companyId={row.original.id} />
                <Space size={3} />
              </>
            );
          }}
        />
      </AnimatePresence>
    </>
  );
};

export default PublicProfileList;
