import useCompanyStore from "../../stores/useCompanyStore";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import useGetCompanies from "./useGetCompanies";
import useCreateCompany from "./useCreateCompany";
import useGetCompany from "./useGetCompany";
import useUsers from "../users/useUsers";
import useGetCompaniesByUser from "./useGetCompaniesByUser";
import useUpdateCompany from "./useUpdateCompany";
import { CompanyTypeType } from "../../interfaces/CompanyTypeType";
import useGetCompaniesByType from "./useGetCompaniesByType";

interface UseCompaniesInterface {
  getAll: () => Promise<CompanyInterface[]>;
  getById: (id: string) => Promise<CompanyInterface | null>;
  getByType: (type: CompanyTypeType) => Promise<CompanyInterface[] | null>;
  create: (company: CompanyInterface) => Promise<CompanyInterface>;
  getCurrentUsersCompanies: () => Promise<CompanyInterface[]>;
  update: (company: CompanyInterface) => Promise<CompanyInterface | null>;
}

const useCompanies = (): UseCompaniesInterface => {
  const set = useCompanyStore((state) => state.set);
  const add = useCompanyStore((state) => state.add);
  const gotAll = useCompanyStore((state) => state.gotAll);
  const stateGetAll = useCompanyStore((state) => state.getAll);
  const stateUpdate = useCompanyStore((state) => state.update);
  const setGotAll = useCompanyStore((state) => state.setGotAll);
  const stateGetById = useCompanyStore((state) => state.getById);
  const { create: apiCreate } = useCreateCompany();
  const { getAll: apiGetAll } = useGetCompanies();
  const { getByType: apiGetByType } = useGetCompaniesByType();
  const { getByUser: apiGetByUser } = useGetCompaniesByUser();
  const { getById: apiGetById } = useGetCompany();
  const { update: apiUpdate } = useUpdateCompany();
  const { getCurrentUser } = useUsers();

  const getByType = async (
    type: CompanyTypeType
  ): Promise<CompanyInterface[]> => {
    if (gotAll) {
      return stateGetAll().filter(
        (company) =>
          company.types?.find((companyType) => companyType.name === type) !==
          undefined
      );
    }
    const companies = await apiGetByType({ type });

    add(companies);

    return companies;
  };

  const getAll = async (): Promise<CompanyInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const companies = await apiGetAll();

    set(companies);
    setGotAll();

    return companies;
  };

  const create = async (
    company: CompanyInterface
  ): Promise<CompanyInterface> => {
    const created = await apiCreate(company);
    if (created !== null) {
      add(created);
    }

    return created;
  };

  const update = async (
    company: CompanyInterface
  ): Promise<CompanyInterface | null> => {
    const updatedCompany = await apiUpdate(company);

    if (updatedCompany === null || updatedCompany.id === undefined) {
      return null;
    }

    stateUpdate({ id: updatedCompany.id, updatedDataSet: updatedCompany });

    return updatedCompany;
  };

  const getCurrentUsersCompanies = async (): Promise<CompanyInterface[]> => {
    const currentUser = await getCurrentUser();
    if (currentUser === null) {
      throw new Error("No user set");
    }

    if (
      currentUser.companies !== undefined &&
      currentUser.companies.length !== 0
    ) {
      const companies: CompanyInterface[] = [];
      for (const company of currentUser.companies) {
        if (company.id === undefined) {
          continue;
        }
        const companyData = await getById(company.id);
        if (companyData === null) {
          continue;
        }
        companies.push(companyData);
      }
      return companies;
    }

    if (currentUser.id === undefined) {
      throw new Error("No user id set");
    }

    const companies = await apiGetByUser({ userId: currentUser.id });
    add(companies);

    return companies;
  };

  const getById = async (id: string): Promise<CompanyInterface | null> => {
    let company = stateGetById(id);

    if (company !== null) {
      return company;
    }

    company = await apiGetById(id);

    if (company === null) {
      return null;
    }

    add(company);
    return company;
  };

  return {
    getAll,
    create,
    getById,
    getByType,
    getCurrentUsersCompanies,
    update,
  };
};
export default useCompanies;
