import React, { ReactElement, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import {
  Button,
  Column,
  ContentBlock,
  Headline,
  Row,
  Space,
} from "../../components";
import { useTranslation } from "react-i18next";
import useCompanies from "../../api/companies/useCompanies";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import usePublicProfileBlocks from "../../api/publicProfileBlocks/usePublicProfileBlocks";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";
import Scrollable from "../../components/Scrollable";
import { useNavigate } from "react-router-dom";

interface CompanyModalProps {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
}
const CompanyModal = ({
  isOpen,
  handleClose,
  id,
}: CompanyModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getById } = useCompanies();
  const { getByCompanyId } = usePublicProfileBlocks();
  const navigate = useNavigate();

  const [company, setCompany] = useState<CompanyInterface | null>(null);
  const [profileBlocks, setProfileBlocks] = useState<
    PublicProfileBlockInterface[]
  >([]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    void getById(id).then((companyData) => {
      setCompany(companyData);
    });
    void getByCompanyId(id).then((profileBlockData) => {
      setProfileBlocks(profileBlockData);
    });
  }, [id, isOpen]);

  const handleOpenCompanyProfile = (): void => {
    navigate(`/profile/company/${id}`);
  };

  if (company === null) {
    return <></>;
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        {company?.name !== undefined && (
          <Headline type="h1" mb="3rem">
            {company.name}
          </Headline>
        )}
        <Scrollable height={"50vh"}>
          {profileBlocks.map((profileBlock) => (
            <React.Fragment key={profileBlock.id}>
              <Headline mb="1rem">{profileBlock.name}</Headline>
              <ContentBlock
                content={profileBlock.content}
                isShowImageAs="img"
                type={profileBlock.type ?? PublicProfileBlockTypeEnum.HTML}
              />
              <Space size={2} />
            </React.Fragment>
          ))}
        </Scrollable>
        <Space size={2} />
        <Row mb="0">
          <Column>
            <Button
              fullWidth
              variant="light"
              type="button"
              handleClick={handleClose}
            >
              {t("BUTTONS.close")}
            </Button>
          </Column>
          <Column>
            <Button
              fullWidth
              type="button"
              handleClick={handleOpenCompanyProfile}
            >
              {t("COMPANY.open-profile")}
            </Button>
          </Column>
        </Row>
      </Modal>
    </>
  );
};

export default CompanyModal;
