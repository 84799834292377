import useCreateSpecificationInvitation from "./useCreateSpecificationInvitation";
import { SpecificationInviteInterface } from "../../interfaces/SpecificationInviteInterface";

interface UseSpecificationInvitationsInterface {
  create: (
    specificationInvite: SpecificationInviteInterface
  ) => Promise<boolean>;
}

const useSpecificationInvitations =
  (): UseSpecificationInvitationsInterface => {
    const { create: apiCreate } = useCreateSpecificationInvitation();

    const create = async (
      specificationInvite: SpecificationInviteInterface
    ): Promise<boolean> => {
      return await apiCreate(specificationInvite);
    };

    return { create };
  };

export default useSpecificationInvitations;
