import React, { ReactElement } from "react";
import logo from "../assets/packpart-logo.png";

interface LogoProps {
  width?: string;
  height?: string;
}
const Logo = ({ width, height }: LogoProps): ReactElement => {
  return (
    <>
      <img style={{ width, height }} src={logo} alt="PackPart Dashboard" />
    </>
  );
};

export default Logo;
