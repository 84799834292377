import useApi from "../useApi";
import { CapabilityInterface } from "../../interfaces/CapabilityInterface";

interface UseGetCapabilitiesInterface {
  getAll: () => Promise<CapabilityInterface[]>;
}

const useGetCapabilities = (): UseGetCapabilitiesInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<CapabilityInterface[]> => {
    const { data, status } = await get(`/capability`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetCapabilities;
