import React, { ReactElement, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import useCapabilities from "../../api/capabilities/useCapabilities";
import { CapabilityInterface } from "../../interfaces/CapabilityInterface";
import CapabilityGroupSelection from "../CapabilityGroupSelection";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";
import useCapabilityGroups from "../../api/capabilityGroups/useCapabilityGroups";
import { Button, Headline, Space } from "../../components";
import { useTranslation } from "react-i18next";
import useGetCapabilityGroupLabel from "../../hooks/useGetCapabilityGroupLabel";
import useGetCapabilityLabel from "../../hooks/useGetCapabilityLabel";
import useFormGroups from "../../api/formGroups/useFormGroups";

export interface AddToFormGroupConfig {
  isActivated: boolean;
  capabilityId: string | null;
}

interface AddToFormGroupModalProps {
  config: AddToFormGroupConfig;
  formGroupId: string;
  handleClose: () => void;
}
const AddToCapabilityGroupModal = ({
  config,
  handleClose,
  formGroupId,
}: AddToFormGroupModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getLabel: getCapabilityGroupLabel } = useGetCapabilityGroupLabel();
  const { getLabel: getCapabilityLabel } = useGetCapabilityLabel();

  const { getById } = useCapabilities();
  const { getById: getCapabilityGroupById } = useCapabilityGroups();
  const { addCapabilityToCapabilityGroup } = useFormGroups();
  const [capability, setCapability] = useState<CapabilityInterface | null>(
    null
  );
  const [capabilityGroup, setCapabilityGroup] =
    useState<CapabilityGroupInterface | null>(null);

  useEffect(() => {
    if (config.capabilityId === null) {
      setCapability(null);
      return;
    }

    void getById(config.capabilityId).then((capability) => {
      setCapability(capability);
    });
  }, [config.capabilityId]);

  const handleConnect = async (): Promise<void> => {
    if (
      capability === null ||
      capabilityGroup === null ||
      capability.id === undefined ||
      capabilityGroup.id === undefined
    ) {
      return;
    }
    await addCapabilityToCapabilityGroup({
      formGroupId,
      capabilityId: capability.id,
      capabilityGroupId: capabilityGroup.id,
    });
    handleClose();
  };

  return (
    <Modal
      isOpen={config.isActivated && capability !== null}
      handleClose={handleClose}
    >
      {capability !== null && (
        <Headline>
          {t(`CAPABILITY_GROUP.connect`, {
            capability: getCapabilityLabel(capability),
          })}
        </Headline>
      )}
      <CapabilityGroupSelection
        handleSelected={async (result) => {
          if (result === null) {
            setCapabilityGroup(null);
            return;
          }
          const foundGroup = await getCapabilityGroupById(result.value);
          setCapabilityGroup(foundGroup);
        }}
      />
      {capabilityGroup !== null && capability !== null && (
        <Button fullWidth type="button" handleClick={handleConnect}>
          {t(`CAPABILITY_GROUP.connect-to`, {
            capability: getCapabilityLabel(capability),
            capabilityGroup: getCapabilityGroupLabel(capabilityGroup),
          })}
        </Button>
      )}
      <Space size={2} />
      <Button fullWidth variant="light" type="button" handleClick={handleClose}>
        Abbrechen
      </Button>
    </Modal>
  );
};

export default AddToCapabilityGroupModal;
