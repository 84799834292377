import React, { ChangeEvent, ReactElement, useState } from "react";
import FileInputField from "./FileInputField";
import useFiles from "../../api/files/useFiles";
import getApiUrl from "../../helpers/getApiUrl";
import Space from "../Space";
import Image from "../Image/Image";
import { LoadingSkeleton } from "../index";

interface RenderFileUploadFieldProps {
  value: string;
  handleChange: (fileName: string) => void | Promise<void>;
  name: string;
  label: string;
}

const RenderFileUploadField = ({
  label,
  handleChange,
  value,
  name,
}: RenderFileUploadFieldProps): ReactElement => {
  const { createFile, deleteById } = useFiles();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleFileUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setIsSubmitting(true);
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }
    const files = await createFile({ file: event.target.files[0] });
    const { fileName: persistedFileName } = files[0];

    await handleChange(persistedFileName);
    setIsSubmitting(false);
  };

  const handleFileRemove = async (): Promise<void> => {
    if (value === "") {
      return;
    }
    setIsSubmitting(true);
    await deleteById(value);
    await handleChange("");
    setIsSubmitting(false);
  };

  return (
    <>
      <FileInputField
        label={label}
        handleChange={(event) => {
          void handleFileUpload(event);
        }}
        value={null}
        name={name}
        disabled={isSubmitting || value !== ""}
      >
        {isSubmitting && (
          <>
            <Space size={2} />
            <LoadingSkeleton />
          </>
        )}
        {value !== "" && (
          <>
            <Space size={2} />
            <Image
              alt={value}
              src={`${getApiUrl()}/v1/file/${value}`}
              handleRemove={async () => {
                await handleFileRemove();
              }}
            />
            <Space size={2} />
          </>
        )}
      </FileInputField>
    </>
  );
};

export default RenderFileUploadField;
