import {
  ContentRenderer,
  Popover,
  PopoverAlign,
  PopoverPosition,
} from "react-tiny-popover";
import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: inline;
`;

const GenericPopover = ({
  isPopoverOpen,
  closePopover,
  children,
  triggerElement,
  positions,
  align,
}: {
  isPopoverOpen: boolean;
  closePopover: () => void;
  children: JSX.Element | ContentRenderer;
  triggerElement: ReactElement;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
}): ReactElement => {
  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        reposition
        positions={
          positions !== undefined
            ? positions
            : ["bottom", "right", "top", "left"]
        }
        align={align ?? "end"}
        onClickOutside={closePopover}
        content={children}
        containerStyle={{ zIndex: "99" }}
      >
        <StyledDiv>{triggerElement}</StyledDiv>
      </Popover>
    </>
  );
};

export default GenericPopover;
