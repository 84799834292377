import useApi from "./useApi";

interface UseDeleteCompanyInterface {
  deleteById: ({ id }: { id: string }) => Promise<boolean>;
}
const useDeleteCompany = (): UseDeleteCompanyInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async ({ id }: { id: string }): Promise<boolean> => {
    const { status } = await deleteRequest(`/company/${id}`);

    return status === 200;
  };

  return { deleteById };
};

export default useDeleteCompany;
