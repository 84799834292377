import useApi from "../useApi";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";

interface UseCreateProjectContractInterface {
  create: (
    project: ProjectContractInterface
  ) => Promise<ProjectContractInterface>;
}

const useCreateProjectContract = (): UseCreateProjectContractInterface => {
  const { post } = useApi();

  const create = async (
    projectContract: ProjectContractInterface
  ): Promise<ProjectContractInterface> => {
    if (projectContract.projectId === undefined) {
      throw new Error("Project Contract creation error: no projectId given.");
    }
    const { status, data: createdProjectContract } = await post(
      `/project-contract/${projectContract.projectId}`,
      {
        ...projectContract,
        contractState: projectContract.contractState ?? "OPEN",
      }
    );

    if (status === 200) {
      return createdProjectContract;
    }

    throw new Error("Project Contract creation error");
  };

  return { create };
};

export default useCreateProjectContract;
