import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Form, RelationType } from "../components";
import { UserRoleType } from "../interfaces/UserRoleType";

interface UserFormProps {
  initialValues: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    position: string;
    role: UserRoleType;
    companies: RelationType;
  };
  handleSubmit: (values: Record<string, any>) => void;
  children: ReactNode;
}

const UserForm = ({
  initialValues,
  handleSubmit,
  children,
}: UserFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form<{
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      position: string;
      role: UserRoleType;
      companies: RelationType;
    }>
      fields={[
        {
          id: "id",
          label: "id",
          type: "hidden",
        },
        {
          id: "email",
          label: t("USER.email"),
          type: "email",
        },
        {
          id: "firstName",
          label: t("USER.firstName"),
          type: "text",
        },
        {
          id: "lastName",
          label: t("USER.lastName"),
          type: "text",
        },
        {
          id: "phone",
          label: t("USER.phone"),
          type: "phone",
        },
        {
          id: "position",
          label: t("USER.position"),
          type: "text",
        },
        {
          id: "role",
          label: t("USER.role"),
          type: "select",
          options: [
            {
              value: "ADMIN",
              label: t("ROLE.ADMIN"),
            },
            {
              value: "PARTNER",
              label: t("ROLE.PARTNER"),
            },
            {
              value: "CLIENT",
              label: t("ROLE.CLIENT"),
            },
          ],
        },
        {
          id: "companies",
          label: t("USER.company"),
          type: "relation",
        },
      ]}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      {children}
    </Form>
  );
};

export default UserForm;
