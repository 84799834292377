import React, { ReactElement } from "react";
import { Table } from "../../components";
import { CompanySelectionInterface } from "./ManageProjectContracts";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import useProjectContractListColumns from "./hooks/useProjectContractListColumns";
import { useTranslation } from "react-i18next";

interface ContractListProps {
  handleRemove: (id: string) => void;
  handleEdit: (id: string) => void;
  handleSendEmail: (id: string) => void;
  selectionState: CompanySelectionInterface[];
  projectId: string;
}
const ContractList = ({
  handleRemove,
  handleEdit,
  handleSendEmail,
  selectionState,
  projectId,
}: ContractListProps): ReactElement => {
  const { t } = useTranslation();
  const { columns } = useProjectContractListColumns();

  const handleRemoveClick = (id?: string): void => {
    const selected = selectionState.find(
      (selection) => selection.contractId === id
    );

    if (selected === undefined || selected.company.id === undefined) {
      return;
    }

    handleRemove(selected.company.id);
  };

  return (
    <>
      <Table<ProjectContractInterface>
        data={selectionState.map((selection) => ({
          projectId,
          companyId: selection.company.id ?? "",
          contractState: selection.contractState,
          id: selection.contractId ?? undefined,
        }))}
        isShowMore
        options={[
          {
            label: t("BUTTONS.edit"),
            onClick: handleEdit,
          },
          {
            label: t("BUTTONS.delete"),
            onClick: handleRemoveClick,
          },
          {
            label: t("PROJECT.send-specifications-email"),
            onClick: handleSendEmail,
          },
        ]}
        getRowCanExpand={() => true}
        columns={columns}
        isLoading={false}
      />
    </>
  );
};

export default ContractList;
