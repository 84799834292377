import useApi from "../useApi";

interface UseGetProjectContractInterface {
  deleteById: (id: string) => Promise<boolean>;
}

const useDeleteProjectContract = (): UseGetProjectContractInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async (id: string): Promise<boolean> => {
    const { status } = await deleteRequest(`/project-contract/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return true;
  };

  return { deleteById };
};

export default useDeleteProjectContract;
