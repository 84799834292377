import React, { ReactElement, useEffect, useState } from "react";
import { Space, TableControlBar } from "../../components";
import { CloseIcon } from "../../icons";
import PlusIcon from "../../icons/PlusIcon";
import useFrontends from "../../api/frontends/useFrontends";
import Table from "../../components/Table";
import { FrontendInterface } from "../../interfaces/FrontendInterface";
import useFrontendListColumns from "./hooks/useFrontendListColumn";
import EditFrontendForm from "./EditFrontendForm";
import AddFrontendForm from "./AddFrontendForm";

export interface FrontendRow extends FrontendInterface {
  id: string;
}

const FrontendList = (): ReactElement => {
  const [isShowAddForm, setIsShowAddForm] = useState<boolean>(false);
  const [frontendList, setFrontendList] = useState<FrontendRow[] | null>(null);

  const { columns } = useFrontendListColumns();

  const { getAll, frontends } = useFrontends();

  useEffect(() => {
    void getAll().then((frontends) => {
      setFrontendList(
        frontends.map((frontend) => ({ ...frontend, id: frontend.id ?? "" }))
      );
    });
  }, [frontends]);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowAddForm(!isShowAddForm)}
      />
      {isShowAddForm && (
        <>
          <AddFrontendForm handleCreated={() => setIsShowAddForm(false)} />
        </>
      )}
      {frontendList !== null && (
        <Table<FrontendRow>
          getRowCanExpand={() => true}
          renderSubComponent={({ row }) => {
            return (
              <>
                <Space size={2} />
                <EditFrontendForm frontend={row.original} />
              </>
            );
          }}
          data={frontendList}
          columns={columns}
          isLoading={false}
        />
      )}
    </>
  );
};

export default FrontendList;
