import { FormGroupInterface } from "../interfaces/FormGroupInterface";
import { CapabilityInterface } from "../interfaces/CapabilityInterface";

const getGroupedCapabilitiesByFormGroup = (
  capabilities: CapabilityInterface[]
): FormGroupInterface[] => {
  const groups: FormGroupInterface[] = [];

  capabilities.forEach((capability) => {
    capability.formGroups?.forEach((formGroup) => {
      const groupToAdd = groups.find((group) => group.name === formGroup.name);
      if (groupToAdd !== undefined) {
        groupToAdd.capabilities?.push(capability);
        return;
      }

      groups.push({
        id: formGroup.id,
        name: formGroup.name,
        requiredType: formGroup.requiredType,
        isMandatory: formGroup.isMandatory,
        capabilities: [capability],
        translation: formGroup.translation,
      });
    });
  });

  return groups;
};

export default getGroupedCapabilitiesByFormGroup;
