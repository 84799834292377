import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableDataType } from "../interfaces/SearchableDataType";
import { SearchableDataInterface } from "../interfaces/SearchableDataInterface";
import SearchInput from "../components/Form/SearchInput";
import useCapabilityGroups from "../api/capabilityGroups/useCapabilityGroups";
import useCapabilityGroupStore from "../stores/useCapabilityGroupStore";
import useGetCapabilityGroupLabel from "../hooks/useGetCapabilityGroupLabel";

interface CapabilityFormSelectionProps {
  handleSelected: (
    selected: SearchableDataInterface | null
  ) => void | Promise<void>;
  initialValue?: string | null;
}

const CapabilityGroupSelection = ({
  handleSelected,
  initialValue,
}: CapabilityFormSelectionProps): ReactElement => {
  const { t } = useTranslation();
  const { getAll, create } = useCapabilityGroups();
  const { getLabel } = useGetCapabilityGroupLabel();
  const capabilityGroups = useCapabilityGroupStore((state) => state.data);

  const [searchData, setSearchData] = useState<SearchableDataType>([]);
  const [selected, setSelected] = useState<SearchableDataInterface | null>(
    null
  );

  useEffect(() => {
    setSearchData(
      capabilityGroups.map((capabilityGroup) => {
        const label = getLabel(capabilityGroup);
        return {
          label,
          value: capabilityGroup.id ?? "",
        };
      })
    );

    if (initialValue === undefined || initialValue === null) {
      return;
    }
    const foundCapability = capabilityGroups.find(
      (capability) => capability.id === initialValue
    );

    if (
      foundCapability === undefined ||
      foundCapability.name === undefined ||
      foundCapability.id === undefined
    ) {
      return;
    }
    setSelected({
      label: foundCapability.name,
      value: foundCapability.id,
    });
  }, [capabilityGroups]);

  useEffect(() => {
    void handleSelected(selected);
  }, [selected]);

  useEffect(() => {
    const loadCapabilityGroups = async (): Promise<void> => {
      await getAll();
    };

    void loadCapabilityGroups();
  }, []);

  return (
    <>
      <SearchInput
        label={t("CAPABILITY_GROUP.name")}
        searchData={searchData}
        handleCreate={async (value) => {
          const capabilityGroup = await create({
            name: value,
          });

          if (capabilityGroup.id === undefined) {
            return;
          }

          setSelected({
            label: getLabel(capabilityGroup),
            value: capabilityGroup.id,
          });
        }}
        handleSelect={setSelected}
        selected={selected}
        createText="CAPABILITY_GROUP.create"
      />
    </>
  );
};

export default CapabilityGroupSelection;
