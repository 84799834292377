import useApi from "../useApi";

interface UseGetFrontendInterface {
  deleteById: (id: string) => Promise<boolean>;
}

const useDeleteFrontend = (): UseGetFrontendInterface => {
  const { deleteRequest } = useApi();

  const deleteById = async (id: string): Promise<boolean> => {
    const { status } = await deleteRequest(`/frontend/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return true;
  };

  return { deleteById };
};

export default useDeleteFrontend;
