import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";
import useApi from "../useApi";

interface UseCreateCapabilityGroupInterface {
  create: (
    capabilityGroup: CapabilityGroupInterface
  ) => Promise<CapabilityGroupInterface>;
}

const useCreateCapabilityGroup = (): UseCreateCapabilityGroupInterface => {
  const { post } = useApi();

  const create = async (
    capabilityGroup: CapabilityGroupInterface
  ): Promise<CapabilityGroupInterface> => {
    const { status, data: createdCapabilityGroup } = await post(
      `/capability-group`,
      {
        ...capabilityGroup,
      }
    );

    if (status === 200) {
      return createdCapabilityGroup;
    }

    throw new Error("CapabilityGroup creation error");
  };

  return { create };
};

export default useCreateCapabilityGroup;
