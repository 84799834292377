import { CapabilityGroupInterface } from "../interfaces/CapabilityGroupInterface";
import { useTranslation } from "react-i18next";
import { ProjectMandateType } from "../interfaces/ProjectMandateType";

interface UseGetCapabilityGroupLabelInterface {
  getLabel: (
    capabilityGroup: CapabilityGroupInterface,
    mandate?: ProjectMandateType
  ) => string;
  getShortLabel: (
    capabilityGroup: CapabilityGroupInterface,
    mandate?: ProjectMandateType
  ) => string;
}
const useGetCapabilityGroupLabel = (): UseGetCapabilityGroupLabelInterface => {
  const { t } = useTranslation();

  const getLabel = (
    capabilityGroup: CapabilityGroupInterface,
    mandate?: ProjectMandateType
  ): string => {
    return capabilityGroup.name === undefined
      ? t(`CAPABILITY_GROUPS.labels.unknown`)
      : `${getShortLabel(capabilityGroup, mandate)} (${capabilityGroup.name})`;
  };

  const getShortLabel = (
    capabilityGroup: CapabilityGroupInterface,
    mandate?: ProjectMandateType
  ): string => {
    const label =
      capabilityGroup.name === undefined
        ? t(`CAPABILITY_GROUPS.labels.unknown`)
        : `${t([
            `CAPABILITY_GROUPS.${capabilityGroup.name}`,
            capabilityGroup.name,
          ])}`;

    if (mandate === undefined || capabilityGroup.translation === undefined) {
      return label;
    }

    const foundTranslation = capabilityGroup.translation.find(
      (translation) => translation.mandate === mandate
    );

    return foundTranslation !== undefined ? foundTranslation.value : label;
  };

  return { getLabel, getShortLabel };
};

export default useGetCapabilityGroupLabel;
