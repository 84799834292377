import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableDataType } from "../interfaces/SearchableDataType";
import { SearchableDataInterface } from "../interfaces/SearchableDataInterface";
import SearchInput from "../components/Form/SearchInput";
import { Button, Form } from "../components";
import CheckIcon from "../icons/CheckIcon";
import useCapabilities from "../api/capabilities/useCapabilities";
import {
  CapabilityType,
  CapabilityTypeEnum,
} from "../interfaces/CapabilityInterface";
import useGetCapabilityLabel from "../hooks/useGetCapabilityLabel";

interface CapabilitySelectionProps {
  handleSelected: (
    selected: SearchableDataInterface | null
  ) => void | Promise<void>;
  initialValue?: string | null;
}

type CapabilitySelectType = CapabilityType | "";

interface CapabilityFormValuesType {
  type: CapabilitySelectType;
  options: Array<{
    value: string;
  }>;
}

interface CapabilityFormInterface {
  handleSubmit: ({ type, options }: CapabilityFormValuesType) => Promise<void>;
  isSubmitting: boolean;
}
const CapabilityForm = ({
  handleSubmit,
  isSubmitting,
}: CapabilityFormInterface): ReactElement => {
  const { t } = useTranslation();

  const [isSelect, setIsSelect] = useState<boolean>(false);

  return (
    <>
      <Form<{
        type: CapabilitySelectType;
        options: Array<{
          value: string;
        }>;
      }>
        fields={[
          {
            id: "type",
            label: t("CAPABILITIES.type"),
            type: "select",
            options: [
              {
                label: t("FORMS.select"),
                value: "",
              },
              ...Object.keys(CapabilityTypeEnum).map((option) => ({
                label: t(`CAPABILITIES.typeLabels.${option}`),
                value: option,
              })),
            ],
          },
          {
            id: "options",
            label: t("CAPABILITIES.type"),
            type: isSelect ? "addOptions" : "hidden",
          },
        ]}
        initialValues={{
          type: "",
          options: [],
        }}
        handleChange={(change) => setIsSelect(change.type === "SELECT")}
        handleSubmit={handleSubmit}
      >
        <Button
          type="submit"
          disabled={isSubmitting}
          variant={isSubmitting ? "success" : "default"}
          whileTap={{
            scale: 0.8,
          }}
        >
          {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
        </Button>
      </Form>
    </>
  );
};

const CapabilitySelection = ({
  handleSelected,
  initialValue,
}: CapabilitySelectionProps): ReactElement => {
  const { t } = useTranslation();
  const { getAll, create, capabilities } = useCapabilities();
  const { getLabel } = useGetCapabilityLabel();

  const [searchData, setSearchData] = useState<SearchableDataType>([]);
  const [selected, setSelected] = useState<SearchableDataInterface | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setSearchData(
      capabilities
        .filter(
          (capability) =>
            capability.formGroups === undefined ||
            capability.formGroups.length === 0
        )
        .map((capability) => {
          const label = getLabel(capability);
          return {
            label,
            value: capability.id ?? "",
          };
        })
    );

    if (initialValue === undefined || initialValue === null) {
      return;
    }
    const foundCapability = capabilities.find(
      (capability) => capability.id === initialValue
    );

    if (
      foundCapability === undefined ||
      foundCapability.name === undefined ||
      foundCapability.id === undefined
    ) {
      return;
    }
    setSelected({
      label: foundCapability.name,
      value: foundCapability.id,
    });
  }, [capabilities]);

  useEffect(() => {
    void handleSelected(selected);
  }, [selected]);

  useEffect(() => {
    const loadCapabilities = async (): Promise<void> => {
      await getAll();
    };

    void loadCapabilities();
  }, []);

  return (
    <>
      <SearchInput
        label={t("CAPABILITIES.name")}
        searchData={searchData}
        handleCreate={(value) => {
          setSelected({
            label: value,
            value: "createNew",
          });
        }}
        handleSelect={setSelected}
        selected={selected}
        createText="CAPABILITIES.create"
      />
      {selected !== null && selected.value === "createNew" && (
        <>
          <CapabilityForm
            isSubmitting={isSubmitting}
            handleSubmit={async ({ type, options }) => {
              if (type === "") {
                return;
              }
              setIsSubmitting(true);
              const capability = await create({
                type,
                name: selected.label,
                capabilityConfigurations: options.map((option) => ({
                  type: "OPTION",
                  value: option.value,
                })),
              });

              if (capability.id === undefined) {
                setIsSubmitting(false);
                return;
              }

              setSelected({
                label: getLabel(capability),
                value: capability.id,
              });
              setIsSubmitting(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default CapabilitySelection;
