import useApi from "../useApi";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { CompanyTypeType } from "../../interfaces/CompanyTypeType";

interface UseGetCompaniesByTypeInterface {
  getByType: ({
    type,
  }: {
    type: CompanyTypeType;
  }) => Promise<CompanyInterface[]>;
}

const useGetCompaniesByType = (): UseGetCompaniesByTypeInterface => {
  const { get } = useApi();

  const getByType = async ({
    type,
  }: {
    type: CompanyTypeType;
  }): Promise<CompanyInterface[]> => {
    const { data, status } = await get(`/company/by-type/${type}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByType };
};

export default useGetCompaniesByType;
