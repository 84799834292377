import { ProjectCategoriesColumn } from "../hooks/useProjectCategoriesListColumns";
import { RangeValue } from "../interfaces/InputFieldProps";

const adaptInitialMatchingValues = (
  projectCategories: ProjectCategoriesColumn[],
  priorities: Array<{
    formGroupId: string;
    priorityCount: number;
  }>,
  isSearch?: boolean
): Record<
  string,
  Record<string, string | number | RangeValue | File | boolean>
> => {
  const values: Record<
    string,
    Record<string, string | number | RangeValue | File | boolean>
  > = {};
  projectCategories.forEach((projectCategory) => {
    values[projectCategory.name] = {};
    if (projectCategory.formGroups === undefined) {
      return;
    }
    projectCategory.formGroups.forEach((formGroup) => {
      const matchedPriority = priorities?.find((priority) => {
        return formGroup.id === priority.formGroupId;
      });
      const priorityCount = matchedPriority?.priorityCount ?? 1;

      formGroup.capabilities?.forEach((capability) => {
        for (let priority = 0; priority < priorityCount; priority += 1) {
          const capabilityName = `${
            (capability.name as string) ?? ""
          }.${priority}`;
          if (
            capability.type === "FLOAT" &&
            (isSearch === undefined || !isSearch)
          ) {
            values[projectCategory.name][capabilityName] = {
              min: "",
              max: "",
            };
            return;
          }
          if (capability.type === "FILE") {
            values[projectCategory.name][capabilityName] = new File([], "");
            return;
          }
          values[projectCategory.name][capabilityName] = "";
        }
      });
    });
  });

  return values;
};

export default adaptInitialMatchingValues;
