import React, { ReactElement, useState } from "react";
import { Tag } from "../../components";
import useHasCurrentUserAccessToCompany from "../../hooks/useHasCurrentUserAccessToCompany";
import CompanyModal from "../CompanyModal/CompanyModal";

interface CompanyTagInterface {
  id: string;
}
const CompanyTag = ({ id }: CompanyTagInterface): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { company } = useHasCurrentUserAccessToCompany({
    companyId: id,
    filterContractState: false,
  });

  if (company === null) {
    return <></>;
  }

  return (
    <>
      <CompanyModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        id={id}
      />
      <Tag handleClick={() => setIsOpen(true)}>{company.name}</Tag>
    </>
  );
};

export default CompanyTag;
