import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseGetUserInterface {
  getCurrent: () => Promise<UserInterface>;
}

const useGetCurrentUser = (): UseGetUserInterface => {
  const { get } = useApi();

  const getCurrent = async (): Promise<UserInterface> => {
    const { data, status } = await get(`/user/me`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getCurrent };
};

export default useGetCurrentUser;
