import { CapabilityConfigurationInterface } from "./CapabilityConfigurationInterface";
import { FormGroupInterface } from "./FormGroupInterface";
import { CapabilityGroupInterface } from "./CapabilityGroupInterface";
import { TranslationInterface } from "./TranslationInterface";

export type CapabilityType =
  | CapabilityTypeEnum.BOOLEAN
  | CapabilityTypeEnum.FLOAT
  | CapabilityTypeEnum.STRING
  | CapabilityTypeEnum.FILE
  | CapabilityTypeEnum.SELECT;

export enum CapabilityTypeEnum {
  BOOLEAN = "BOOLEAN",
  FLOAT = "FLOAT",
  STRING = "STRING",
  SELECT = "SELECT",
  FILE = "FILE",
}

export interface CapabilityInterface {
  id?: string;
  name?: string;
  type?: CapabilityType;
  capabilityConfigurations?: CapabilityConfigurationInterface[];
  formGroups?: FormGroupInterface[];
  capabilityGroup?: CapabilityGroupInterface | null;
  capabilityGroupId?: string | null;
  translation?: TranslationInterface[];
}
