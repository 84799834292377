import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseRemoveCapabilityFromFormGroupInterface {
  removeCapabilityFromFormGroup: ({
    formGroupId,
    capabilityId,
  }: {
    formGroupId: string;
    capabilityId: string;
  }) => Promise<FormGroupInterface | null>;
}

const useRemoveCapabilityFromFormGroup =
  (): UseRemoveCapabilityFromFormGroupInterface => {
    const { deleteRequest } = useApi();

    const removeCapabilityFromFormGroup = async ({
      formGroupId,
      capabilityId,
    }: {
      formGroupId: string;
      capabilityId: string;
    }): Promise<FormGroupInterface> => {
      const { data, status } = await deleteRequest(
        `/form-group/${formGroupId}/${capabilityId}`
      );

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { removeCapabilityFromFormGroup };
  };

export default useRemoveCapabilityFromFormGroup;
