import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useProjects from "../../api/projects/useProjects";
import CompanyUserSelection, {
  CompanyUserSelectionConfigurationInterface,
} from "../Projects/CompanyUserSelection";
import ProjectMasterDataForm, {
  ProjectMasterDataConfigurationInterface,
} from "../Projects/ProjectMasterDataForm";
import { Button, Card, Headline, Space } from "../../components";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { FormikValues } from "formik";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import { useNavigate, useParams } from "react-router-dom";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import useFormToCapabilityAdapter from "../Entities/hooks/useFormToCapabilityAdapter";
import useCapabilityToFormAdapter from "../Entities/hooks/useCapabilityToFormAdapter";
import SetEntityCapabilities from "../Entities/SetEntityCapabilities";

export interface EditEntityConfigurationInterface
  extends CompanyUserSelectionConfigurationInterface,
    ProjectMasterDataConfigurationInterface {
  projectCapabilities: ProjectCapabilityInterface[];
}

const EditProject = (): ReactElement => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { adapt: adaptToProjectCapabilities } = useFormToCapabilityAdapter();
  const { adapt: adaptToFormData } = useCapabilityToFormAdapter();
  const { getById: getProjectById, update: updateProject } = useProjects();
  const [status, setStatus] = useState<RequestStatusType>("initial");

  const [initialValues, setInitialValues] = useState<
    | {
        enabledCategories: ProjectCategoriesColumn[];
        formData: FormikValues;
        enabledFieldIds: string[];
      }
    | undefined
  >();
  const [activeStep, setActiveStep] = useState(1);
  const [configuration, setConfiguration] =
    useState<EditEntityConfigurationInterface>({
      companyId: null,
      userId: null,
      name: "",
      description: "",
      projectCapabilities: [],
    });

  useEffect(() => {
    if (projectId === undefined) {
      return;
    }
    void getProjectById(projectId).then(async (entity) => {
      if (entity === null) {
        setStatus("error");
        return;
      }
      setInitialValues(
        await adaptToFormData({
          projectCapabilities: entity.projectCapabilities ?? [],
        })
      );
      setConfiguration({
        companyId: entity.companyId ?? null,
        userId: entity.userId ?? null,
        name: entity.name,
        description: entity.description,
        projectCapabilities: entity.projectCapabilities ?? [],
      });
      setStatus("success");
    });
    setStatus("loading");
  }, [projectId]);

  const handleNext = (
    updatedConfiguration:
      | CompanyUserSelectionConfigurationInterface
      | ProjectMasterDataConfigurationInterface
  ): void => {
    setConfiguration({
      ...configuration,
      ...updatedConfiguration,
    });
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = (): void => setActiveStep(activeStep - 1);

  const handleSaveEntity = async ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }): Promise<void> => {
    if (projectId === undefined) {
      return;
    }
    const projectCapabilities = await adaptToProjectCapabilities({
      formData,
      enabledCategories,
      files,
    });
    setConfiguration({
      ...configuration,
      projectCapabilities,
    });

    if (configuration.companyId === null) {
      return;
    }

    await updateProject({
      id: projectId,
      companyId: configuration.companyId,
      userId: configuration.userId ?? undefined,
      name: configuration.name,
      description: configuration.description,
      categories: enabledCategories.map((category) => ({
        name: category.name,
      })),
      projectCapabilities,
    });

    setActiveStep(4);
  };

  return (
    <>
      {status === "success" && (
        <>
          {activeStep === 1 && (
            <>
              <Headline>{t("EDIT_PROJECT.step-1")}</Headline>
              <CompanyUserSelection
                handleNext={handleNext}
                values={{
                  companyId: configuration.companyId,
                  userId: configuration.userId,
                }}
              />
            </>
          )}
          {activeStep === 2 && (
            <>
              <Headline>{t("EDIT_PROJECT.step-2")}</Headline>
              <ProjectMasterDataForm
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                values={{
                  name: configuration.name,
                  description: configuration.description,
                }}
                handleChange={(updatedConfiguration) =>
                  setConfiguration({
                    ...configuration,
                    ...updatedConfiguration,
                  })
                }
              />
            </>
          )}
          {activeStep === 3 && projectId !== undefined && (
            <>
              <Headline>{t("EDIT_PROJECT.step-3")}</Headline>
              <SetEntityCapabilities
                action="edit"
                initialValues={initialValues}
                handleSaveEntity={handleSaveEntity}
                isSearch={true}
                projectId={projectId}
              />
            </>
          )}
          {activeStep === 4 && (
            <>
              <Card color="success">{t("EDIT_PROJECT.success")}</Card>
              <Space size={2} />
              <Button
                type="button"
                handleClick={() => navigate("/client/projects")}
              >
                {t("EDIT_PROJECT.back")}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditProject;
