import useApi from "../useApi";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";

interface UseCreateCompanyInterface {
  update: (
    publicProfileBlock: PublicProfileBlockInterface
  ) => Promise<PublicProfileBlockInterface>;
}

const useUpdatePublicProfileBlock = (): UseCreateCompanyInterface => {
  const { putForm } = useApi();

  const update = async (
    publicProfileBlock: PublicProfileBlockInterface
  ): Promise<PublicProfileBlockInterface> => {
    if (publicProfileBlock.id === undefined) {
      throw new Error("Missing public profile block id");
    }

    const { status, data: createdPublicProfileBlock } = await putForm(
      `/public-profile/${publicProfileBlock.id}`,
      {
        ...publicProfileBlock,
      }
    );

    if (status === 200) {
      return createdPublicProfileBlock;
    }

    throw new Error("Public Profile Block creation error");
  };

  return { update };
};

export default useUpdatePublicProfileBlock;
