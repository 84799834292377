import useGetCapabilityGroups from "./useGetCapabilityGroups";
import useGetCapabilityGroup from "./useGetCapabilityGroup";
import { CapabilityGroupInterface } from "../../interfaces/CapabilityGroupInterface";
import useUpdateCapabilityGroup from "./useUpdateCapabilityGroup";
import useCapabilityGroupStore from "../../stores/useCapabilityGroupStore";
import useCreateCapabilityGroup from "./useCreateCapabilityGroup";

interface UseCapabilityGroupsInterface {
  getAll: () => Promise<CapabilityGroupInterface[]>;
  capabilityGroups: CapabilityGroupInterface[];
  getById: (id: string) => Promise<CapabilityGroupInterface | null>;
  update: (
    capabilityGroup: CapabilityGroupInterface
  ) => Promise<CapabilityGroupInterface | null>;
  create: (
    capabilityGroup: CapabilityGroupInterface
  ) => Promise<CapabilityGroupInterface>;
}

const useCapabilityGroups = (): UseCapabilityGroupsInterface => {
  const add = useCapabilityGroupStore((state) => state.add);
  const capabilityGroups = useCapabilityGroupStore((state) => state.data);
  const set = useCapabilityGroupStore((state) => state.set);
  const stateUpdate = useCapabilityGroupStore((state) => state.update);
  const gotAll = useCapabilityGroupStore((state) => state.gotAll);
  const stateGetAll = useCapabilityGroupStore((state) => state.getAll);
  const stateGetById = useCapabilityGroupStore((state) => state.getById);
  const setGotAll = useCapabilityGroupStore((state) => state.setGotAll);
  const { getAll: apiGetAll } = useGetCapabilityGroups();
  const { update: apiUpdate } = useUpdateCapabilityGroup();
  const { create: apiCreate } = useCreateCapabilityGroup();
  const { getById: apiGetById } = useGetCapabilityGroup();

  const getAll = async (): Promise<CapabilityGroupInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const capabilityGroups = await apiGetAll();

    set(capabilityGroups);
    setGotAll();

    return capabilityGroups;
  };

  const getById = async (
    id: string
  ): Promise<CapabilityGroupInterface | null> => {
    let capabilityGroup = stateGetById(id);

    if (capabilityGroup !== null) {
      return capabilityGroup;
    }

    capabilityGroup = await apiGetById(id);

    if (capabilityGroup === null) {
      return null;
    }

    add(capabilityGroup);
    return capabilityGroup;
  };

  const update = async (
    capabilityGroup: CapabilityGroupInterface
  ): Promise<CapabilityGroupInterface | null> => {
    const updatedCapabilityGroup = await apiUpdate(capabilityGroup);

    if (
      updatedCapabilityGroup === null ||
      updatedCapabilityGroup.id === undefined
    ) {
      return null;
    }

    stateUpdate({
      id: updatedCapabilityGroup.id,
      updatedDataSet: updatedCapabilityGroup,
    });

    return updatedCapabilityGroup;
  };

  const create = async (
    capabilityGroup: CapabilityGroupInterface
  ): Promise<CapabilityGroupInterface> => {
    const created = await apiCreate(capabilityGroup);

    if (created !== null) {
      add(created);
    }

    return created;
  };

  return {
    getAll,
    getById,
    capabilityGroups,
    update,
    create,
  };
};

export default useCapabilityGroups;
