import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface TextProps {
  children: ReactNode;
  variant?: "default" | "light" | "danger";
}

const StyledLightText = styled.span`
  color: ${({ theme }) => theme.colors["black/40"]};
`;
const StyledDangerText = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

const Text = ({ children, variant = "default" }: TextProps): ReactElement => {
  switch (variant) {
    case "default":
      return <span>{children}</span>;
    case "light":
      return <StyledLightText>{children}</StyledLightText>;
    case "danger":
      return <StyledDangerText>{children}</StyledDangerText>;
  }
};

export default Text;
