import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import InputCheckbox from "../../components/Form/InputCheckbox";
import InputField from "../../components/Form/InputField";
import SelectField from "../../components/Form/SelectField";
import RangeField from "../../components/Form/RangeField";
import useCapabilityData from "./hooks/useCapabilityData";
import FileInputField from "../../components/Form/FileInputField";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";

interface CapabilityInputProps {
  id: string;
  groupName: string;
  value: any;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleBlur?: (values: Record<string, any>) => void;
  // TODO: CHANGE LOGIC
  isSearch?: boolean;
  priority: number;
}
const CapabilityInput = ({
  id,
  groupName,
  handleChange,
  handleBlur,
  value,
  isSearch,
  priority,
}: CapabilityInputProps): ReactElement => {
  const {
    data: { capability },
  } = useCapabilityData({ id });

  const { t } = useTranslation();

  if (capability === null) {
    return <></>;
  }

  const name = `${groupName}.['${capability.name ?? ""}.${priority}']`;

  // todo helper function
  const labelIdentifier =
    capability.type === "SELECT"
      ? `CAPABILITIES.labels.${capability.name ?? ""}`
      : `CAPABILITIES.labels.${capability.name ?? ""}`;

  const translation = capability.translation?.find((translation) => {
    if (
      isSearch !== undefined &&
      isSearch &&
      translation.mandate === ProjectMandateEnum.REQUEST
    ) {
      return true;
    }
    return translation.mandate === ProjectMandateEnum.ENTITY;
  });
  const label =
    translation !== undefined ? translation.value : t(labelIdentifier);

  switch (capability.type) {
    case "BOOLEAN": {
      return (
        <InputCheckbox
          value={value}
          name={name}
          label={label}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      );
    }
    case "STRING": {
      return (
        <InputField
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value}
          name={name}
          type="text"
          label={label}
        />
      );
    }

    case "SELECT": {
      const { capabilityConfigurations } = capability;
      return (
        <>
          <SelectField
            name={name}
            value={value}
            handleBlur={handleBlur}
            handleChange={handleChange}
            label={t(`${labelIdentifier}.label`)}
          >
            <option value="">{t("FORMS.select")}</option>
            {capabilityConfigurations?.map(({ id, value }) => {
              return (
                <option key={id} value={id}>
                  {t(`${labelIdentifier}.options.${value}`)}
                </option>
              );
            })}
          </SelectField>
        </>
      );
    }
    case "FLOAT": {
      if (isSearch === undefined || !isSearch) {
        return (
          <RangeField
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={value}
            name={name}
            type="text"
            label={label}
          />
        );
      }
      return (
        <InputField
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value}
          name={name}
          type="number"
          label={label}
        />
      );
    }

    case "FILE": {
      return (
        <FileInputField
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={value}
          name={name}
          label={label}
        />
      );
    }
    default:
      return <>Unknown capability type: {capability.type}</>;
  }
};

export default CapabilityInput;
