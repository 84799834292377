import useGetPublicProfileBlocks from "./useGetPublicProfileBlocks";
import useCreatePublicProfileBlock from "./useCreatePublicProfileBlock";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";
import usePublicProfileBlockStore from "../../stores/usePublicProfileBlockStore";
import useDeletePublicProfileBlock from "./useDeletePublicProfileBlock";
import useUpdatePublicProfileBlock from "./useUpdatePublicProfileBlock";
import useGetPublicProfileBlocksByCompanyId from "./useGetPublicProfileBlocksByCompanyId";

interface UsePublicProfileBlocksInterface {
  getAll: () => Promise<PublicProfileBlockInterface[]>;
  getByCompanyId: (companyId: string) => Promise<PublicProfileBlockInterface[]>;
  create: (
    publicProfileBlock: PublicProfileBlockInterface
  ) => Promise<PublicProfileBlockInterface>;
  update: (
    publicProfileBlock: PublicProfileBlockInterface
  ) => Promise<PublicProfileBlockInterface>;
  deleteBlock: (id: string) => Promise<void>;
  blocks: PublicProfileBlockInterface[];
}

const usePublicProfileBlocks = (): UsePublicProfileBlocksInterface => {
  const set = usePublicProfileBlockStore((state) => state.set);
  const blocks = usePublicProfileBlockStore((state) => state.data);
  const add = usePublicProfileBlockStore((state) => state.add);
  const stateUpdate = usePublicProfileBlockStore((state) => state.update);
  const stateDelete = usePublicProfileBlockStore((state) => state.remove);
  const gotAll = usePublicProfileBlockStore((state) => state.gotAll);
  const stateGetByCompanyId = usePublicProfileBlockStore(
    (state) => state.getByCompanyId
  );
  const stateGetAll = usePublicProfileBlockStore((state) => state.getAll);
  const setGotAll = usePublicProfileBlockStore((state) => state.setGotAll);
  const { create: apiCreate } = useCreatePublicProfileBlock();
  const { update: apiUpdate } = useUpdatePublicProfileBlock();
  const { deleteBlock: apiDeleteProfileBlock } = useDeletePublicProfileBlock();
  const { getAll: apiGetAll } = useGetPublicProfileBlocks();
  const { getByCompanyId: apiGetByCompanyId } =
    useGetPublicProfileBlocksByCompanyId();

  const getAll = async (): Promise<PublicProfileBlockInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const publicProfileBlocks = await apiGetAll();

    set(publicProfileBlocks);
    setGotAll();

    return publicProfileBlocks;
  };

  const getByCompanyId = async (
    companyId: string
  ): Promise<PublicProfileBlockInterface[]> => {
    if (gotAll) {
      return stateGetByCompanyId(companyId);
    }
    const publicProfileBlocks = await apiGetByCompanyId(companyId);

    add(publicProfileBlocks);

    return publicProfileBlocks;
  };

  const create = async (
    publicProfileBlock: PublicProfileBlockInterface
  ): Promise<PublicProfileBlockInterface> => {
    const created = await apiCreate(publicProfileBlock);
    if (created !== null) {
      add(created);
    }

    return created;
  };

  const update = async (
    publicProfileBlock: PublicProfileBlockInterface
  ): Promise<PublicProfileBlockInterface> => {
    const updated = await apiUpdate(publicProfileBlock);
    if (updated?.id !== undefined) {
      stateUpdate({ id: updated.id, updatedDataSet: updated });
    }

    return updated;
  };

  const deleteBlock = async (id: string): Promise<void> => {
    await apiDeleteProfileBlock(id);
    stateDelete(id);
  };

  return {
    getAll,
    getByCompanyId,
    create,
    update,
    deleteBlock,
    blocks,
  };
};
export default usePublicProfileBlocks;
