import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

const StyledCheckbox = styled.input`
  -webkit-appearance: none;

  &:checked + span:before {
    background-color: ${(props) => props.theme.colors.black};
  }
  &:checked + span:after {
    margin-left: 1.5rem;
    background-color: #fff;
  }
`;

const StyledCheckboxLabel = styled.span`
  position: relative;
  cursor: pointer;
  padding-left: 4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${(props) => props.theme.colors["black/40"]};

  &:before {
    width: calc(4rem - 4px);
    height: 2rem;
    border-radius: 2rem;
    border: 2px solid ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
    content: "";
    margin-right: 2rem;
    transition: all 0.5s linear;
    z-index: 5;
    position: absolute;
    left: 0;
  }

  &:after {
    width: calc(1.5rem - 2px);
    height: calc(1.5rem - 2px);
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.black};
    content: "";
    transition: all 0.1s linear;
    position: absolute;
    left: 3px;
    margin-top: 3px;
    z-index: 10;
  }
`;

const StyledLabel = styled.label<{ inline?: boolean }>`
  display: ${({ inline }) => (inline === true ? "inline" : "flex")};
`;

interface CheckboxProps {
  label: ReactNode;
  checked?: boolean | string;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
  name: string;
  value?: any;
  inline?: boolean;
}

const Checkbox = ({
  label,
  handleChange,
  // todo handleBlur doesn't work yet
  handleBlur,
  checked,
  name,
  value,
  inline = false,
}: CheckboxProps): ReactElement => {
  const valueToCheck = checked ?? value ?? false;
  const checkedValue =
    typeof valueToCheck === "boolean"
      ? valueToCheck
      : typeof valueToCheck === "string"
      ? valueToCheck === "true"
      : false;

  return (
    <StyledLabel inline={inline}>
      <StyledCheckbox
        value={value}
        name={name}
        checked={checkedValue ?? (value !== undefined && value)}
        onChange={handleChange}
        onBlur={handleBlur}
        type="checkbox"
      />{" "}
      <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
    </StyledLabel>
  );
};

export default Checkbox;
