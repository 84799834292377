import React, { ReactElement, useEffect, useState } from "react";
import { Column, Headline, Row } from "../../components";
import { ChevronRightIcon } from "../../icons";
import styled from "styled-components";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import { useTranslation } from "react-i18next";
import GroupedCapabilities from "./GroupedCapabilities";
import { CapabilityGroupCollectionInterface } from "../../interfaces/CapabilityGroupCollectionInterface";
import getCapabilityGroups from "../../helpers/getCapabilityGroups";

const RotateIconLink = styled.a`
  display: inline-block;
  transform: rotate(180deg);
  margin-right: 1rem;
  cursor: pointer;
`;

interface FormGroupCapabilitiesProps {
  formGroup: FormGroupInterface;
  handleBack: () => void;
}
const FormGroupCapabilities = ({
  formGroup,
  handleBack,
}: FormGroupCapabilitiesProps): ReactElement => {
  const { t } = useTranslation();

  const [data, setData] = useState<CapabilityGroupCollectionInterface[]>([]);

  useEffect(() => {
    setData(getCapabilityGroups(formGroup));
  }, [formGroup]);

  const isShowGroup = (group: CapabilityGroupCollectionInterface): boolean => {
    return data.length === 1 || group.capabilities.length > 0;
  };

  return (
    <>
      <Row mb="0">
        <Column size="shrink" padding="0">
          <RotateIconLink onClick={handleBack}>
            <ChevronRightIcon />
          </RotateIconLink>
        </Column>
        <Column padding="0">
          <Headline>{t(`FORM_GROUPS.${formGroup.name}`)}</Headline>
        </Column>
      </Row>
      {data.map((group) => (
        <React.Fragment
          key={`${group.name}-${group.id !== undefined ? group.id : ""}`}
        >
          {isShowGroup(group) && (
            <GroupedCapabilities
              capabilityGroup={group}
              formGroupId={formGroup.id}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default FormGroupCapabilities;
