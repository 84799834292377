import React, { ReactElement } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../../../components/Clickable";
import RelationTags from "../../../components/RelationTags";
import { useTranslation } from "react-i18next";
import { CompanyInterface } from "../../../interfaces/CompanyInterface";
import ContentBlock from "../../../components/ContentBlock";
import { PublicProfileBlockTypeType } from "../../../interfaces/PublicProfileBlockInterface";
import AddDefaultProfileBlocks from "../AddDefaultProfileBlocks";
import getAppUrl from "../../../helpers/getAppUrl";

export interface PublicProfileColumn {
  id: string;
  name: string;
  blocks: EditablePublicProfileBlockInterface[];
}

export interface EditablePublicProfileBlockInterface {
  edit: boolean;
  id?: string;
  name: string;
  content: ReactElement<typeof ContentBlock>;
  originalContent: string;
  companyId: string;
  company?: CompanyInterface;
  type: PublicProfileBlockTypeType;
}

const columnHelper = createColumnHelper<PublicProfileColumn>();

interface UsePublicProfileListColumnsInterface {
  columns: Array<ColumnDef<PublicProfileColumn, any>>;
}
const usePublicProfileListColumns =
  (): UsePublicProfileListColumnsInterface => {
    const { t } = useTranslation();

    const columns: Array<ColumnDef<PublicProfileColumn, any>> = [
      columnHelper.accessor("name", {
        header: `${t("PUBLIC_PROFILE.company-name")}`,
        cell: (info) => {
          return (
            <Clickable onClick={info.row.getToggleExpandedHandler()}>
              {info.getValue()}
            </Clickable>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.blocks, {
        id: "blocks",
        header: `${t("PUBLIC_PROFILE.blocks")}`,
        cell: (info) => {
          if (info.getValue() !== undefined) {
            return (
              <RelationTags
                relationsItems={info
                  .getValue()
                  .map((type: EditablePublicProfileBlockInterface) => ({
                    id: type.id,
                    label: type.name,
                  }))}
              />
            );
          }
          return <></>;
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.id, {
        header: `${t("PUBLIC_PROFILE.script")}`,
        cell: (info) => {
          return (
            <code>
              {`
          <script>const companyId="${info.getValue() as string}";</script>
          <script src="${getAppUrl()}/snippet.js" type="module"></script>`}
            </code>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("id", {
        header: "",
        cell: (info) => {
          return <AddDefaultProfileBlocks companyId={info.row.original.id} />;
        },
        footer: (info) => info.column.id,
      }),
    ];

    return { columns };
  };

export default usePublicProfileListColumns;
