import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../../../interfaces/UserInterface";

const columnHelper = createColumnHelper<UserInterface>();

interface UseUserListColumnsInterface {
  columns: Array<ColumnDef<UserInterface, any>>;
}
const useUserListColumns = (): UseUserListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<UserInterface, any>> = [
    columnHelper.accessor("email", {
      header: `${t("USER.email")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.firstName, {
      id: "firstName",
      header: `${t("USER.firstName")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.lastName, {
      id: "lastName",
      header: `${t("USER.lastName")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.phone, {
      id: "phone",
      header: `${t("USER.phone")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.position, {
      id: "position",
      header: `${t("USER.position")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useUserListColumns;
