import { ProjectCapabilityInterface } from "../interfaces/ProjectCapabilityInterface";
import { useTranslation } from "react-i18next";

interface UseCapabilityValuesInterface {
  getCapabilityValue: (
    projectCapability: ProjectCapabilityInterface
  ) => string | null;
}
const getFloatValue = (
  projectCapabilityValue: string
): { min: string | null; max: string | null } | string => {
  const value = JSON.parse(projectCapabilityValue);
  if (typeof value === "number") {
    return projectCapabilityValue;
  }
  if ("min" in value && "max" in value) {
    const min = value.min === "" ? null : value.min;
    const max = value.max === "" ? null : value.max;
    return { min, max };
  }

  return projectCapabilityValue;
};

const useCapabilityValues = (): UseCapabilityValuesInterface => {
  const { t } = useTranslation();

  const getCapabilityValue = (
    projectCapability: ProjectCapabilityInterface
  ): string | null => {
    if (projectCapability.capability === undefined) {
      return typeof projectCapability.value === "string"
        ? projectCapability.value
        : projectCapability.value.name;
    }

    if (projectCapability.value instanceof File) {
      return projectCapability.value.name;
    }
    switch (projectCapability.capability.type) {
      case "FLOAT": {
        const parsedValue = getFloatValue(projectCapability.value);
        if (typeof parsedValue === "string") {
          return parsedValue;
        }
        if (parsedValue.min === null && parsedValue.max === null) {
          return null;
        }
        const minString =
          parsedValue.min === null
            ? ""
            : `${t("CAPABILITY.from")}: ${parsedValue.min} `;
        const maxString =
          parsedValue.max === null
            ? ""
            : `${t("CAPABILITY.to")}: ${parsedValue.max}`;
        return `${minString}, ${maxString}`;
      }
      case "BOOLEAN": {
        return projectCapability.value === "true"
          ? t("CAPABILITY.true")
          : t("CAPABILITY.false");
      }
      case "SELECT": {
        const foundValue =
          projectCapability.capability?.capabilityConfigurations?.find(
            (capabilityConfigurationValue) =>
              capabilityConfigurationValue.id === projectCapability.value
          );
        if (foundValue !== undefined && typeof foundValue.value === "string") {
          return foundValue.value;
        }
        break;
      }
      case "FILE": {
        const foundValue =
          projectCapability.capability?.capabilityConfigurations?.find(
            (capabilityConfigurationValue) =>
              capabilityConfigurationValue.id === projectCapability.value
          );
        if (foundValue === undefined) {
          return projectCapability.value;
        }
        const blob = new Blob([foundValue.value]);
        return window.URL.createObjectURL(blob);
      }
    }

    return projectCapability.value;
  };

  return { getCapabilityValue };
};

export default useCapabilityValues;
