import useApi from "../useApi";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";

interface UseGetProjectCategoryInterface {
  getById: (id: string) => Promise<ProjectCategoryInterface | null>;
}

const useGetProjectCategory = (): UseGetProjectCategoryInterface => {
  const { get } = useApi();

  const getById = async (
    id: string
  ): Promise<ProjectCategoryInterface | null> => {
    const { data, status } = await get(`/project-category/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetProjectCategory;
