import useCreateFile from "./useCreateFile";
import useGetFile from "./useGetFile";
import useDeleteFile from "./useDeleteFile";

interface UseFilesInterface {
  createFile: ({
    file,
  }: {
    file: File;
  }) => Promise<Array<{ fileName: string }>>;
  getFile: (fileName: string) => Promise<Buffer | null>;
  deleteById: (id: string) => Promise<boolean>;
}

const useFiles = (): UseFilesInterface => {
  const { create } = useCreateFile();
  const { getById } = useGetFile();
  const { deleteById: apiDeleteById } = useDeleteFile();

  const createFile = async ({
    file,
  }: {
    file: File;
  }): Promise<Array<{ fileName: string }>> => {
    return await create(file);
  };
  const getFile = async (fileName: string): Promise<Buffer | null> => {
    return await getById(fileName);
  };

  const deleteById = async (id: string): Promise<boolean> => {
    return await apiDeleteById(id);
  };

  return { createFile, getFile, deleteById };
};

export default useFiles;
