import React, { ReactElement, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Form, Layout, MainColumn, StyledContent } from "../components";
import { useTranslation } from "react-i18next";
import Right from "../components/Right";
import CheckIcon from "../icons/CheckIcon";
import useAuthentication from "../api/useAuthentication";

const LoggedOutApp = (): ReactElement => {
  const { t } = useTranslation();
  const { login, isLoggedIn } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate("/");
  }, [isLoggedIn]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Layout>
      <MainColumn>
        <StyledContent>
          <Form
            fields={[
              {
                id: "email",
                label: t("USER.email"),
                type: "email",
              },
              {
                id: "password",
                label: t("USER.password"),
                type: "password",
              },
            ]}
            initialValues={{
              email: "",
              password: "",
            }}
            handleSubmit={(loginData) => {
              setIsSubmitting(true);
              void login({
                email: loginData.email,
                password: loginData.password,
              })
                .then((data) => {
                  if (data === null) {
                    setIsSubmitting(false);
                    return;
                    // todo
                  }
                  setIsSubmitting(false);
                  navigate("/");
                })
                .catch(() => {
                  // todo errors
                  setIsSubmitting(false);
                });
            }}
          >
            <Right>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant={isSubmitting ? "success" : "default"}
                whileTap={{
                  scale: 0.8,
                }}
              >
                {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
              </Button>
            </Right>
          </Form>
          <Outlet />
        </StyledContent>
      </MainColumn>
    </Layout>
  );
};

export default LoggedOutApp;
