import React, { ReactElement } from "react";
import StyledField from "../styles/StyledField";
import StyledLabel from "../styles/StyledLabel";
import { Checkbox } from "../index";

interface InputCheckboxProps {
  label: string;
  checked?: boolean;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
  name: string;
  value?: any;
}

const InputCheckbox = ({
  label,
  checked,
  handleChange,
  handleBlur,
  name,
  value,
}: InputCheckboxProps): ReactElement => {
  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      <Checkbox
        value={value}
        label={label}
        name={name}
        handleChange={handleChange}
        handleBlur={handleBlur}
        checked={checked}
      />
    </StyledField>
  );
};

export default InputCheckbox;
