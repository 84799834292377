import useApi from "../useApi";

interface UseCreateFileInterface {
  create: (file: File) => Promise<any>;
}
const useCreateFile = (): UseCreateFileInterface => {
  const { postForm } = useApi();

  const create = async (file: File): Promise<any> => {
    const files: Array<{ file: File; name: string }> = [
      { file, name: file.name },
    ];
    const { status, data: createdFile } = await postForm(`/file`, {}, files);

    if (status === 200) {
      return createdFile;
    }

    throw new Error("File creation error");
  };

  return { create };
};

export default useCreateFile;
