import React, { ReactElement, useEffect, useState } from "react";
import { LoadingSkeleton } from "../../components";
import useFilterFormGroups from "./useFilterFormGroups";
import useFormGroups from "../../api/formGroups/useFormGroups";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface FilteredFormsProps {
  projectCategoryFilter: ProjectCategoryInterface | null;
  RenderElement: ({
    formGroup,
  }: {
    formGroup: FormGroupInterface;
  }) => ReactElement;
}
const FilteredForms = ({
  projectCategoryFilter,
  RenderElement,
}: FilteredFormsProps): ReactElement => {
  const { getAll, formGroups } = useFormGroups();
  const [status, setStatus] = useState<RequestStatusType>("initial");

  const { filteredFormGroups } = useFilterFormGroups({
    formGroups,
    projectCategoryFilter,
  });

  useEffect(() => {
    if (status !== "initial") {
      return;
    }
    void getAll().then(() => {
      setStatus("success");
    });

    setStatus("loading");
  }, [status]);

  return (
    <>
      {status === "loading" && <LoadingSkeleton />}
      {status === "success" && (
        <>
          {filteredFormGroups.map((formGroup) => (
            <React.Fragment key={formGroup.id}>
              <RenderElement formGroup={formGroup} />
            </React.Fragment>
          ))}
        </>
      )}{" "}
    </>
  );
};

export default FilteredForms;
