import React, { ReactElement } from "react";
import { FormFieldInterface } from "../../components";
import { useTranslation } from "react-i18next";
import MasterDataForm from "./MasterDataForm";

export interface EntityMasterDataConfigurationInterface {
  name: string;
  description: string;
  image: string;
  video: string;
  year: number;
  price: number;
}

interface EntityMasterDataFormProps {
  handleNext: ({
    name,
    description,
  }: EntityMasterDataConfigurationInterface) => void;
  handleChange: ({
    name,
    description,
  }: EntityMasterDataConfigurationInterface) => void;
  handlePrevious: () => void;
  values: EntityMasterDataConfigurationInterface;
}
const EntityMasterDataForm = ({
  handleNext,
  handleChange,
  handlePrevious,
  values,
}: EntityMasterDataFormProps): ReactElement => {
  const { t } = useTranslation();

  const fields: FormFieldInterface[] = [
    {
      id: "name",
      label: t("CREATE_ENTITY.name"),
      type: "text",
    },
    {
      id: "description",
      label: t("CREATE_ENTITY.description"),
      type: "text",
    },
    {
      id: "image",
      label: t("CREATE_ENTITY.image"),
      type: "fileUpload",
    },
    {
      id: "video",
      label: t("CREATE_ENTITY.video"),
      type: "text",
    },
    {
      id: "year",
      label: t("CREATE_ENTITY.year"),
      type: "number",
    },
    {
      id: "price",
      label: t("CREATE_ENTITY.price"),
      type: "number",
    },
  ];

  return (
    <>
      <MasterDataForm<{
        name: string;
        description: string;
        image: string;
        video: string;
        year: number;
        price: number;
      }>
        fields={fields}
        initialValues={{
          name: values.name,
          description: values.description,
          image: values.image,
          video: values.video,
          year: values.year,
          price: values.price,
        }}
        handleNext={async ({
          name,
          description,
          image,
          video,
          year,
          price,
        }): Promise<void> => {
          await handleNext({ name, description, image, video, year, price });
        }}
        handleChange={(formValues) => {
          handleChange(formValues);
        }}
        handlePrevious={handlePrevious}
      />
    </>
  );
};

export default EntityMasterDataForm;
