import { GetState, SetState } from "zustand";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { IdAware } from "../interfaces/IdAware";

export const defaultStateCrud = <CrudType extends IdAware>(
  set: SetState<CrudStateInterface<CrudType>>,
  get: GetState<CrudStateInterface<CrudType>>
): CrudStateInterface<CrudType> => ({
  data: [],
  set: (data: CrudType[]) => set(() => ({ data })),
  add: (dataSet: CrudType | CrudType[]) =>
    set((state) => {
      if (Array.isArray(dataSet)) {
        const updatedData: CrudType[] = [];
        dataSet.forEach((dataSetRecord) => {
          if (
            state.data.find(({ id }) => id === dataSetRecord.id) !== undefined
          ) {
            return;
          }

          updatedData.push(dataSetRecord);
        });

        return {
          data: [...state.data, ...updatedData],
        };
      }

      if (state.data.find(({ id }) => id === dataSet.id) === null) {
        return { data: [...state.data] };
      }

      return {
        data: [...state.data, dataSet],
      };
    }),
  update: ({ id, updatedDataSet }: { id: string; updatedDataSet: CrudType }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSetFromState, index) => {
            if (dataSetFromState.id !== id) {
              return { ...dataSetFromState };
            }

            return {
              ...dataSetFromState,
              ...updatedDataSet,
            };
          }),
        ],
      };
    }),
  remove: (id: string) =>
    set((state) => {
      return {
        data: [...state.data.filter((dataSet) => dataSet.id !== id)],
      };
    }),
  getById: (id: string) =>
    get().data.find(({ id: stateId }) => id === stateId) ?? null,
  getAll: () => get().data,
  gotAll: false,
  setGotAll: () => set(() => ({ gotAll: true })),
});
