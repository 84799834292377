import useApi from "../useApi";

interface UseDeletePublicProfileBlockInterface {
  deleteBlock: (id: string) => Promise<void>;
}

const useDeletePublicProfileBlock =
  (): UseDeletePublicProfileBlockInterface => {
    const { deleteRequest } = useApi();

    const deleteBlock = async (id: string): Promise<void> => {
      const { status } = await deleteRequest(`/public-profile/${id}`);

      if (status === 200) {
        return;
      }

      throw new Error("Public Profile Block creation error");
    };

    return { deleteBlock };
  };

export default useDeletePublicProfileBlock;
