import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

type ColumnSizeType = 1 | 2 | 3 | 4 | "unset" | "shrink";
type AlignType = "left" | "center" | "right";

interface ColumnProps {
  children: ReactNode;
  size?: ColumnSizeType;
  align?: AlignType;
  padding?: string;
}

const StyledColumn = styled.div<{
  columnSize: ColumnSizeType;
  align?: AlignType;
  padding?: string;
}>`
  ${({ columnSize }) =>
    columnSize === 1 ? "flex-basis:  25%; max-width: 25%;" : ""}
  ${({ columnSize }) =>
    columnSize === 2 ? "flex-basis:  50%; max-width: 50%;" : ""}
  ${({ columnSize }) =>
    columnSize === 3 ? "flex-basis:  75%; max-width: 75%;" : ""}
  ${({ columnSize }) =>
    columnSize === 4 ? "flex-basis:  100%; max-width: 100%;" : ""}
  ${({ columnSize }) => (columnSize === "unset" ? "flex: 1;" : "")}
  ${({ columnSize }) => (columnSize === "shrink" ? "flex: 0 1 auto;" : "")}
  
  ${({ align }) => (align !== undefined ? `text-align: ${align};` : "")}
  padding: ${({ padding }) => (padding !== undefined ? padding : "0 1.5rem")};
`;

const Column = ({
  children,
  size = "unset",
  align,
  padding,
}: ColumnProps): ReactElement => {
  return (
    <StyledColumn columnSize={size} align={align} padding={padding}>
      {children}
    </StyledColumn>
  );
};

export default Column;
