import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";
import useProjectCategoryStore from "../../stores/useProjectCategoryStore";
import useGetProjectCategories from "./useGetProjectCategories";
import useGetProjectCategory from "./useGetProjectCategory";

interface UseCapabilitiesInterface {
  getAll: () => Promise<ProjectCategoryInterface[]>;
  getByName: (name: string) => Promise<ProjectCategoryInterface | null>;
  getById: (id: string) => Promise<ProjectCategoryInterface | null>;
  projectCategories: ProjectCategoryInterface[];
}

const useProjectCategories = (): UseCapabilitiesInterface => {
  const projectCategories = useProjectCategoryStore((store) => store.data);
  const add = useProjectCategoryStore((store) => store.add);
  const set = useProjectCategoryStore((store) => store.set);
  const gotAll = useProjectCategoryStore((store) => store.gotAll);
  const stateGetById = useProjectCategoryStore((state) => state.getById);
  const setGotAll = useProjectCategoryStore((store) => store.setGotAll);
  const { getAll: apiGetAll } = useGetProjectCategories();
  const { getById: apiGetById } = useGetProjectCategory();

  const getAll = async (): Promise<ProjectCategoryInterface[]> => {
    if (gotAll) {
      return projectCategories;
    }

    const projectCategoriesFromApi = await apiGetAll();

    setGotAll();
    set(projectCategoriesFromApi);

    return projectCategoriesFromApi;
  };

  const getById = async (
    id: string
  ): Promise<ProjectCategoryInterface | null> => {
    let projectCategory = stateGetById(id);

    if (projectCategory !== null) {
      return projectCategory;
    }

    projectCategory = await apiGetById(id);

    if (projectCategory === null) {
      return null;
    }

    add(projectCategory);
    return projectCategory;
  };

  const getByName = async (
    name: string
  ): Promise<ProjectCategoryInterface | null> => {
    const projectCategory = projectCategories.find(
      (storedProjectCategory) => storedProjectCategory.name === name
    );
    if (projectCategory !== undefined) {
      return projectCategory;
    }

    const projectCategoriesFromApi = await getAll();
    return (
      projectCategoriesFromApi.find(
        (storedProjectCategory) => storedProjectCategory.name === name
      ) ?? null
    );
  };

  return {
    getAll,
    projectCategories,
    getByName,
    getById,
  };
};
export default useProjectCategories;
