import { RequestStatusType } from "../interfaces/RequestStatusType";
import useGetUsers from "./useGetUsers";
import useDeleteUser from "./useDeleteUser";
import { UserInterface } from "../interfaces/UserInterface";
import useUpdateUser from "./useUpdateUser";
import useCreateUser from "./useCreateUser";

interface UseUsersInterface {
  status: RequestStatusType;
  users: UserInterface[];
  deleteById: ({ id }: { id: string }) => Promise<void>;
  update: (user: UserInterface) => Promise<void>;
  create: (user: UserInterface) => Promise<void>;
}

const useUsers = (): UseUsersInterface => {
  const { users, status, removeUser, updateUser, createUser } = useGetUsers();
  const { create: apiCreate } = useCreateUser();
  const { update: apiUpdate } = useUpdateUser();
  const { deleteById: apiDeleteById } = useDeleteUser();

  const deleteById = async ({ id }: { id: string }): Promise<void> => {
    const deleted = await apiDeleteById({ id });
    if (deleted) {
      removeUser({ id });
    }
  };

  const update = async (user: UserInterface): Promise<void> => {
    const updated = await apiUpdate(user);
    if (updated !== null) {
      updateUser(updated);
    }
  };

  const create = async (user: UserInterface): Promise<void> => {
    const created = await apiCreate(user);
    if (created !== null) {
      createUser(created);
    }
  };

  return {
    status,
    users,
    deleteById,
    update,
    create,
  };
};
export default useUsers;
