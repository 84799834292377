import useGetUsers from "./useGetUsers";
import useCreateUser from "./useCreateUser";
import useUserStore from "../../stores/useUserStore";
import { UserInterface } from "../../interfaces/UserInterface";
import useGetUsersByCompanyId from "./useGetUsersByCompanyId";
import useGetUser from "./useGetUserById";
import useGetCurrentUser from "./useGetCurrentUser";
import { useEffect } from "react";
import useUpdateUser from "./useUpdateUser";

interface UseUsersInterface {
  getAll: () => Promise<UserInterface[]>;
  getById: (id: string) => Promise<UserInterface>;
  getCurrentUser: () => Promise<UserInterface>;
  currentUser: UserInterface | null;
  getByCompany: (companyId: string) => Promise<UserInterface[]>;
  create: (user: UserInterface) => Promise<UserInterface>;
  update: (user: UserInterface) => Promise<UserInterface | null>;
}

const useUsers = (): UseUsersInterface => {
  const set = useUserStore((state) => state.set);
  const add = useUserStore((state) => state.add);
  const stateCurrentUser = useUserStore((state) => state.currentUser);
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const gotAll = useUserStore((state) => state.gotAll);
  const stateGetAll = useUserStore((state) => state.getAll);
  const stateUpdate = useUserStore((state) => state.update);
  const stateGetById = useUserStore((state) => state.getById);
  const setGotAll = useUserStore((state) => state.setGotAll);
  const users = useUserStore((state) => state.data);
  const { create: apiCreate } = useCreateUser();
  const { getAll: apiGetAll } = useGetUsers();
  const { getById: apiGetById } = useGetUser();
  const { update: apiUpdate } = useUpdateUser();
  const { getCurrent: apiGetCurrent } = useGetCurrentUser();
  const { getByCompanyId: apiGetByCompanyId } = useGetUsersByCompanyId();

  useEffect(() => {
    if (stateCurrentUser !== null) {
      return;
    }
    void getCurrentUser().then();
  }, [stateCurrentUser]);

  const getAll = async (): Promise<UserInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const projects = await apiGetAll();

    set(projects);
    setGotAll();

    return projects;
  };

  const getById = async (id: string): Promise<UserInterface> => {
    const user = stateGetById(id);
    if (user !== null) {
      return user;
    }

    const userById = await apiGetById(id);
    add(userById);

    return userById;
  };

  const update = async (user: UserInterface): Promise<UserInterface | null> => {
    const updatedUser = await apiUpdate(user);

    if (updatedUser === null || updatedUser.id === undefined) {
      return null;
    }

    stateUpdate({ id: updatedUser.id, updatedDataSet: updatedUser });

    if (stateCurrentUser !== null && user.id === stateCurrentUser.id) {
      setCurrentUser(user);
    }

    return updatedUser;
  };

  const getCurrentUser = async (): Promise<UserInterface> => {
    if (stateCurrentUser !== null) {
      return stateCurrentUser;
    }

    const currentUser = await apiGetCurrent();
    add(currentUser);
    setCurrentUser(currentUser);

    return currentUser;
  };

  const getByCompany = async (companyId: string): Promise<UserInterface[]> => {
    if (gotAll) {
      return users.filter(
        (user) =>
          user.companies !== undefined &&
          user.companies.filter((company) => company.id === companyId).length >
            0
      );
    }
    const usersByCompany = await apiGetByCompanyId(companyId);

    add(usersByCompany);

    return usersByCompany;
  };

  const create = async (company: UserInterface): Promise<UserInterface> => {
    const created = await apiCreate(company);
    if (created !== null) {
      add(created);
    }

    return created;
  };

  return {
    getAll,
    create,
    getByCompany,
    getById,
    getCurrentUser,
    currentUser: stateCurrentUser,
    update,
  };
};
export default useUsers;
