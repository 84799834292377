import React, { ReactElement, useEffect, useState } from "react";
import useCompanies from "../../api/companies/useCompanies";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { Button, Card, Form, Right, Space } from "../../components";
import HeadlineToggle from "../HeadlineToggle";
import { useTranslation } from "react-i18next";
import { FormStatusType } from "../../interfaces/FormStatusType";

interface UpdateCompanyFormProps {
  company: CompanyInterface;
  handleSaved: () => void;
}
const UpdateCompanyForm = ({
  company,
  handleSaved,
}: UpdateCompanyFormProps): ReactElement => {
  const { update } = useCompanies();

  const { t } = useTranslation();
  const [status, setStatus] = useState<FormStatusType>("initial");

  return (
    <Form<{
      name: string;
      address: string;
      country: string;
      email: string;
      phone: string;
      description: string;
    }>
      handleChange={() => setStatus("edited")}
      fields={[
        {
          id: "name",
          type: "text",
          label: t("COMPANY.name"),
        },
        {
          id: "address",
          type: "text",
          label: t("COMPANY.address"),
        },
        {
          id: "country",
          type: "text",
          label: t("COMPANY.country"),
        },
        {
          id: "email",
          type: "email",
          label: t("COMPANY.email"),
        },
        {
          id: "phone",
          type: "phone",
          label: t("COMPANY.phone"),
        },
        {
          id: "description",
          type: "text",
          label: t("COMPANY.description"),
        },
      ]}
      initialValues={{
        name: company.name ?? "",
        address: company.address ?? "",
        country: company.country ?? "",
        email: company.email ?? "",
        phone: company.phone ?? "",
        description: company.description ?? "",
      }}
      handleSubmit={async (updatedCompany) => {
        if (company.id === undefined) {
          return;
        }
        setStatus("loading");
        await update({
          id: company.id,
          address: updatedCompany.address,
          country: updatedCompany.country,
          description: updatedCompany.description,
          email: updatedCompany.email,
          name: updatedCompany.name,
          phone: updatedCompany.phone,
        });
        handleSaved();
        setStatus("success");
      }}
    >
      <Right>
        {status === "success" && (
          <Button variant="success" disabled>
            {t("MESSAGES.successfully-saved")}
          </Button>
        )}
        {status === "edited" && <Button>{t("BUTTONS.save")}</Button>}
        {status === "loading" && (
          <Button disabled>{t("BUTTONS.updating")}</Button>
        )}
      </Right>
    </Form>
  );
};

const UpdateCompany = (): ReactElement => {
  const { getCurrentUsersCompanies } = useCompanies();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }

    void getCurrentUsersCompanies().then((currentUsersCompanies) => {
      setCompanies(currentUsersCompanies);
      setStatus("success");
    });
    setStatus("loading");
  }, [status]);

  return (
    <>
      {companies.map((company) => (
        <React.Fragment key={company.id}>
          <Card>
            <HeadlineToggle
              headline={company.name}
              isInitiallyOpened={companies.length === 1}
            >
              <UpdateCompanyForm
                company={company}
                handleSaved={() => setStatus("initial")}
              />
            </HeadlineToggle>
          </Card>
          <Space size={2} />
        </React.Fragment>
      ))}
    </>
  );
};

export default UpdateCompany;
