import useApi from "../useApi";
import { CapabilityInterface } from "../../interfaces/CapabilityInterface";

interface UseCreateCapabilityInterface {
  create: (capability: CapabilityInterface) => Promise<CapabilityInterface>;
}

const useCreateCapability = (): UseCreateCapabilityInterface => {
  const { post } = useApi();

  const create = async (
    capability: CapabilityInterface
  ): Promise<CapabilityInterface> => {
    const { status, data: createdCapability } = await post(`/capability`, {
      ...capability,
    });

    if (status === 200) {
      return createdCapability;
    }

    throw new Error("Capability creation error");
  };

  return { create };
};

export default useCreateCapability;
