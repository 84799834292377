import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { Card, Checkbox, Column, Row, Space } from "../../components";
import useProjectCategories from "../../api/projectCategories/useProjectCategories";
import useSearchConfiguration from "./hooks/useSearchConfiguration";
import { FormikValues } from "formik";
import MatchingForm from "./MatchingForm";
import SearchResultsWrapper from "./SearchResultsWrapper";
import StyledGroup from "../../components/styles/StyledGroup";
import useHandleSearch from "./hooks/useHandleSearch";

interface MatchingProps {
  handleSaveProject: ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }) => Promise<void> | void;
  isShowSaveProject?: boolean;
  projectId?: string;
}

const Matching = ({
  handleSaveProject,
  isShowSaveProject = true,
  projectId,
}: MatchingProps): ReactElement => {
  const { t } = useTranslation();
  const { getAll } = useProjectCategories();
  const { isEnabled, toggle } = useSearchConfiguration();

  const [projectCategories, setProjectCategories] = useState<
    ProjectCategoriesColumn[]
  >([]);

  const { searchResults, handleSearch, values, files } = useHandleSearch();

  useEffect(() => {
    const getProjectCategories = async (): Promise<void> => {
      const projectCategoriesData = await getAll();

      setProjectCategories(
        projectCategoriesData.map((projectCategory) => ({
          ...projectCategory,
          edit: true,
        }))
      );
    };

    void getProjectCategories();
  }, []);

  // todo outsource
  // todo see ../Entities/hooks/useFormToCapabilityAdapter
  // todo: persist project with these parameters
  // todo: adapt GET /project parameters back to FormikValues (useProjectMatching.matchProjects) to prepopulate form

  const handleSaveSearch = async (): Promise<void> => {
    if (handleSaveProject === undefined) {
      return;
    }

    const enabledCategories: ProjectCategoriesColumn[] = [];
    projectCategories.forEach((projectCategory) => {
      if (!isEnabled(projectCategory.id)) {
        return;
      }
      enabledCategories.push(projectCategory);
    });

    await handleSaveProject({ enabledCategories, formData: values, files });
  };

  return (
    <>
      <Card>
        <StyledGroup>
          {projectCategories.map(({ id, name }) => (
            <React.Fragment key={JSON.stringify(id)}>
              <Checkbox
                name={name}
                checked={isEnabled(id)}
                label={t(`PROJECT_CATEGORIES.${name}`)}
                handleChange={() => toggle(id)}
              />
            </React.Fragment>
          ))}
        </StyledGroup>
      </Card>
      <Space size={2} />
      <Row>
        <Column>
          <MatchingForm
            isSearch
            buttonText={t("BUTTONS.create-search")}
            buttonRedoText={t("BUTTONS.redo-search")}
            toggle={toggle}
            isEnabled={isEnabled}
            projectCategories={projectCategories}
            handleSearch={handleSearch}
            hasSearched={searchResults.searched}
          />
        </Column>
        <SearchResultsWrapper
          isShowSaveProject={isShowSaveProject}
          searchResults={searchResults}
          handleSaveSearch={handleSaveSearch}
          projectId={projectId}
        />
      </Row>
    </>
  );
};

export default Matching;
