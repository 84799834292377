import { useEffect, useState } from "react";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import { CompanyInterface } from "../interfaces/CompanyInterface";
import useApi from "./useApi";

interface UseGetCompaniesInterface {
  companies: CompanyInterface[];
  status: RequestStatusType;
  removeCompany: ({ id }: { id: string }) => void;
  updateCompany: (company: CompanyInterface) => void;
  createCompany: (company: CompanyInterface) => void;
}

const useGetCompanies = (): UseGetCompaniesInterface => {
  const { get } = useApi();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);

  useEffect(() => {
    if (status !== "initial") {
      return;
    }
    setStatus("loading");

    const getCompanies = async (): Promise<void> => {
      const { data, status: responseStatus } = await get(`/company`);
      if (responseStatus !== 200) {
        // todo
        console.log("error");
        return;
      }

      setCompanies(data);
      setStatus("success");
    };

    getCompanies().catch((error) => {
      // todo
      console.log("error", error);
    });

    setStatus("loading");
  }, [status]);

  const updateCompany = (company: CompanyInterface): void => {
    setCompanies(
      companies.map((existingCompany) => {
        if (existingCompany.id !== company.id) {
          return existingCompany;
        }

        return { ...existingCompany, ...company };
      })
    );
  };

  const removeCompany = ({ id }: { id: string }): void => {
    setCompanies(companies.filter((company) => company.id !== id));
  };

  const createCompany = (company: CompanyInterface): void => {
    setCompanies([
      ...companies,
      {
        ...company,
      },
    ]);
  };

  return { companies, status, removeCompany, updateCompany, createCompany };
};

export default useGetCompanies;
