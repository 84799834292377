import React, { ReactElement, useState } from "react";
import { PublicProfileBlockTypeEnum } from "../interfaces/PublicProfileBlockTypeEnum";
import ImageIcon from "../icons/ImageIcon";
import StyledPopover from "./styles/StyledPopover";
import GenericPopover from "./GenericPopover";
import styled from "styled-components";
import getStaticFileUrl from "../helpers/getStaticFileUrl";
import { Clickable } from "./index";

interface ImageProps {
  fileName: string;
  isShowClickable?: boolean;
}

const StyledImage = styled.img`
  max-width: 400px;
  max-height: 400px;
`;

const Image = ({ fileName, isShowClickable }: ImageProps): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <>
      {isShowClickable === true && (
        <GenericPopover
          isPopoverOpen={isPopoverOpen}
          closePopover={() => setIsPopoverOpen(false)}
          positions={["bottom"]}
          triggerElement={
            <>
              <Clickable onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <ImageIcon height="1.75rem" />
              </Clickable>
            </>
          }
        >
          <StyledPopover>
            <StyledImage src={getStaticFileUrl(fileName)} />
          </StyledPopover>
        </GenericPopover>
      )}
      {isShowClickable === false && (
        <StyledImage src={getStaticFileUrl(fileName)} />
      )}
    </>
  );
};

const ContentBlock = ({
  content,
  type,
  isShowImageAs,
}: {
  content: string;
  type: PublicProfileBlockTypeEnum;
  isShowImageAs?: "clickable" | "img";
}): ReactElement => {
  if (type === PublicProfileBlockTypeEnum.HTML) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <Image
      isShowClickable={
        isShowImageAs === undefined || isShowImageAs === "clickable"
      }
      fileName={content}
    />
  );
};

export default ContentBlock;
