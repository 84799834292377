import useApi from "../useApi";
import { CapabilityConfigurationInterface } from "../../interfaces/CapabilityConfigurationInterface";

interface UseCreateCapabilityConfigurationInterface {
  create: (
    capability: CapabilityConfigurationInterface
  ) => Promise<CapabilityConfigurationInterface>;
}

const useCreateCapabilityConfiguration =
  (): UseCreateCapabilityConfigurationInterface => {
    const { post } = useApi();

    const create = async (
      capabilityConfiguration: CapabilityConfigurationInterface
    ): Promise<CapabilityConfigurationInterface> => {
      const { status, data: create } = await post(`/capability-configuration`, {
        ...capabilityConfiguration,
      });

      if (status === 200) {
        return create;
      }

      throw new Error("Capability Configuration creation error");
    };

    return { create };
  };

export default useCreateCapabilityConfiguration;
