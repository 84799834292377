import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Clickable from "../components/Clickable";
import { UserInterface } from "../interfaces/UserInterface";
import RelationTags from "../components/RelationTags";
import { useTranslation } from "react-i18next";
import { CompanyInterface } from "../interfaces/CompanyInterface";

export interface PersonColumn extends UserInterface {
  edit: boolean;
}

const columnHelper = createColumnHelper<PersonColumn>();

interface UseUserListColumnsInterface {
  columns: Array<ColumnDef<PersonColumn, any>>;
}
const useUserListColumns = (): UseUserListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<PersonColumn, any>> = [
    columnHelper.accessor("email", {
      header: `${t("USER.email")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.firstName, {
      id: "firstName",
      header: `${t("USER.firstName")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.lastName, {
      id: "lastName",
      header: `${t("USER.lastName")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.phone, {
      id: "phone",
      header: `${t("USER.phone")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.profile?.position, {
      id: "position",
      header: `${t("USER.position")}`,
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.companies, {
      id: "companies",
      header: `${t("USER.company")}`,
      filterFn: "fuzzy",
      cell: (info) => {
        if (info.getValue() !== undefined) {
          return (
            <RelationTags
              relationsItems={info.getValue().map((type: CompanyInterface) => ({
                id: type.id,
                label: type.name,
              }))}
            />
          );
        }
        return <></>;
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useUserListColumns;
