import React, { ReactElement } from "react";
import { FileUploadInputFieldProps } from "../../interfaces/InputFieldProps";
import StyledField from "../styles/StyledField";
import StyledInputField from "../styles/StyledInputField";
import StyledLabel from "../styles/StyledLabel";

const FileInputField = ({
  label,
  name,
  handleChange,
  handleBlur,
  children,
  disabled,
}: FileUploadInputFieldProps): ReactElement => {
  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      <StyledInputField
        disabled={disabled}
        name={name}
        type="file"
        onChange={(e) => {
          void handleChange(e);
        }}
        onBlur={handleBlur}
      />
      {children}
    </StyledField>
  );
};

export default FileInputField;
