import useApi from "../useApi";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";

interface UseCreateCompanyInterface {
  create: (
    publicProfileBlock: PublicProfileBlockInterface
  ) => Promise<PublicProfileBlockInterface>;
}

const useCreatePublicProfileBlock = (): UseCreateCompanyInterface => {
  const { postForm } = useApi();

  const create = async (
    publicProfileBlock: PublicProfileBlockInterface
  ): Promise<PublicProfileBlockInterface> => {
    if (publicProfileBlock.companyId === undefined) {
      throw new Error(
        "Public Profile Block creation error, no companyId given"
      );
    }
    const { status, data: createdPublicProfileBlock } = await postForm(
      `/public-profile/${publicProfileBlock.companyId}`,
      {
        ...publicProfileBlock,
      }
    );

    if (status === 200) {
      return createdPublicProfileBlock;
    }

    throw new Error("Public Profile Block creation error");
  };

  return { create };
};

export default useCreatePublicProfileBlock;
