import React, { ReactElement, useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import SpecificationSheetPdf from "../../components/SpecificationSheet/SpecificationSheetPdf";
import { useParams } from "react-router-dom";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import useProjects from "../../api/projects/useProjects";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { UserInterface } from "../../interfaces/UserInterface";
import useUsers from "../../api/users/useUsers";
import useFormGroups from "../../api/formGroups/useFormGroups";
import useCapabilityConfigurations from "../../api/capabilityConfigurations/useCapabilityConfigurations";
import useProjectCapabilities from "../../api/projectCapabilities/useProjectCapabilities";

const SpecificationSheet = (): ReactElement => {
  const { projectId } = useParams();
  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [specificationSheetData, setSpecificationSheetData] = useState<{
    project: ProjectInterface;
    user?: UserInterface;
    capabilityConfigurationValues: Array<{
      capabilityConfigurationId: string;
      value: string | Buffer;
    }>;
    appendix: Buffer[] | null;
  } | null>(null);

  const { getById: getProjectById } = useProjects();
  const { getById: getCapabilityConfigurationById } =
    useCapabilityConfigurations();
  const { getFileById: getProjectCapabilityFileById } =
    useProjectCapabilities();
  const { getByCompany } = useUsers();
  const { formGroups, getAll } = useFormGroups();

  const handleSpecificationDataChange = async ({
    user,
    project,
  }: {
    user?: UserInterface;
    project: ProjectInterface;
  }): Promise<void> => {
    const capabilityConfigurationValues: Array<{
      capabilityConfigurationId: string;
      value: string | Buffer;
    }> = [];
    const appendix: Buffer[] = [];
    for (const { capability, value, id } of project.projectCapabilities ?? []) {
      if (
        capability === undefined ||
        (capability.type !== "SELECT" && capability.type !== "FILE") ||
        typeof value !== "string"
      ) {
        continue;
      }

      if (capability.type === "FILE" && id !== undefined) {
        const fileBuffer = await getProjectCapabilityFileById(id);
        if (fileBuffer !== null) {
          // todo redo
          capabilityConfigurationValues.push({
            capabilityConfigurationId: value,
            value: fileBuffer,
          });
          appendix.push(fileBuffer);
        }
        continue;
      }
      // value is SELECT
      const capabilityConfig = await getCapabilityConfigurationById(value);
      if (
        capabilityConfig === null ||
        capabilityConfigurationValues.find(
          (capabilityConfigurationValue) =>
            capabilityConfigurationValue.capabilityConfigurationId ===
            capabilityConfig.id
        ) !== undefined
      ) {
        continue;
      }
      capabilityConfigurationValues.push({
        capabilityConfigurationId: capabilityConfig.id ?? "",
        value: capabilityConfig.value,
      });
    }
    setSpecificationSheetData({
      project,
      user,
      capabilityConfigurationValues,
      appendix,
    });
  };

  useEffect(() => {
    if (status !== "initial" || projectId === undefined) {
      return;
    }

    void getProjectById(projectId).then(async (projectData) => {
      if (projectData === null) {
        setSpecificationSheetData(null);
        return;
      }
      if (projectData.user !== undefined) {
        await handleSpecificationDataChange({
          project: projectData,
          user: projectData.user,
        });
        await getFormGroups();
        return;
      }
      if (projectData.companyId === undefined) {
        return;
      }

      const [user] = (await getByCompany(projectData.companyId)) ?? [null];
      await handleSpecificationDataChange({
        user,
        project: projectData,
      });
      await getFormGroups();
    });
    setStatus("loading");
  }, [projectId]);

  const getFormGroups = async (): Promise<void> => {
    await getAll();
    setStatus("success");
  };

  return (
    <>
      {status === "success" && (
        <div>
          {specificationSheetData?.project.company !== undefined && (
            <>
              <PDFViewer style={{ flex: 1, width: "100vw", height: "100vh" }}>
                <SpecificationSheetPdf
                  capabilityConfigurationValues={
                    specificationSheetData.capabilityConfigurationValues
                  }
                  formGroups={formGroups}
                  project={specificationSheetData.project}
                  company={specificationSheetData.project.company}
                  user={specificationSheetData.user}
                />
              </PDFViewer>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SpecificationSheet;
