import { create } from "zustand";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { UserInterface } from "../interfaces/UserInterface";

interface UserStateInterface {
  currentUser: UserInterface | null;
  setCurrentUser: (user: UserInterface) => void;
}
interface UserState
  extends UserStateInterface,
    CrudStateInterface<UserInterface> {}
const useUserStore = create<UserState>((set, get) => ({
  ...defaultStateCrud<UserInterface>(set, get),
  currentUser: null,
  setCurrentUser: (user: UserInterface) => set(() => ({ currentUser: user })),
}));

export default useUserStore;
