import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import useApi from "../useApi";

interface UseCreateFormGroupInterface {
  create: (formGroup: FormGroupInterface) => Promise<FormGroupInterface>;
}

const useCreateFormGroup = (): UseCreateFormGroupInterface => {
  const { post } = useApi();

  const create = async (
    formGroup: FormGroupInterface
  ): Promise<FormGroupInterface> => {
    const { status, data: createdFormGroup } = await post(`/form-group`, {
      ...formGroup,
    });

    if (status === 200) {
      return createdFormGroup;
    }

    throw new Error("FormGroup creation error");
  };

  return { create };
};

export default useCreateFormGroup;
