import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useFrontends from "../../api/frontends/useFrontends";
import Form, { FormValuesType } from "../../components/Form/Form";
import { Button } from "../../components";
import CheckIcon from "../../icons/CheckIcon";

interface FrontendFormInterface extends FormValuesType {
  name: string;
  listWrapper: string;
  contactForm: string;
  projectWrapper: string;
  imageWrapper: string;
  priceWrapper: string;
  yearWrapper: string;
  specificationWrapperBoolean: string;
  specificationWrapperString: string;
  specificationWrapperSelect: string;
  specificationWrapperFloat: string;
  specificationWrapperFile: string;
}

interface AddFrontendFormProps {
  handleCreated: () => void;
}
const AddFrontendForm = ({
  handleCreated,
}: AddFrontendFormProps): ReactElement => {
  const { t } = useTranslation();
  const { create } = useFrontends();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <Form<FrontendFormInterface>
        fields={[
          { id: "name", label: t("FRONTENDS.name"), type: "text" },
          {
            id: "listWrapper",
            label: t("FRONTENDS.listWrapper"),
            type: "code",
          },
          {
            id: "projectWrapper",
            label: t("FRONTENDS.projectWrapper"),
            type: "code",
          },
          {
            id: "contactForm",
            label: t("FRONTENDS.contactForm"),
            type: "code",
          },
          {
            id: "specificationWrapperBoolean",
            label: t("FRONTENDS.specificationWrapperBoolean"),
            type: "code",
          },
          {
            id: "specificationWrapperString",
            label: t("FRONTENDS.specificationWrapperString"),
            type: "code",
          },
          {
            id: "specificationWrapperSelect",
            label: t("FRONTENDS.specificationWrapperSelect"),
            type: "code",
          },
          {
            id: "specificationWrapperFloat",
            label: t("FRONTENDS.specificationWrapperFloat"),
            type: "code",
          },
          {
            id: "specificationWrapperFile",
            label: t("FRONTENDS.specificationWrapperFile"),
            type: "code",
          },
        ]}
        initialValues={{
          name: "",
          listWrapper: '<ul class="entity">$list</ul>',
          contactForm:
            '<form name="pp-request" data-id="$id"><label for="email">E-Mail-Adresse: *</label><input type="email" name="email" /><br /><label for="firstName">Vorname: *</label><input type="text" name="firstName" /><br /><label for="lastName">Nachname: *</label><input type="text" name="lastName" /><br /><label for="phone">Telefonnummer:</label><input type="phone" name="phone" /><br /><label for="company">Unternehmen:</label><input type="text" name="company" /><br /><label for="message">Nachricht:</label><br /><textarea name="message"></textarea><br /><input type="submit" value="Abschicken" /></form>',
          projectWrapper:
            '<li class="item">$name ($id)</li><ul>$specifications</ul>',
          imageWrapper: "<img src='$value' />",
          priceWrapper: "<u>($price)</u>",
          yearWrapper: "<strong>Baujahr: $value</strong>",
          specificationWrapperBoolean: "<li>$key: $value</li>",
          specificationWrapperFloat: "<li>$key: $value</li>",
          specificationWrapperString: "<li>$key: $value</li>",
          specificationWrapperSelect: "<li>$key: $value</li>",
          specificationWrapperFile: "<img alt='$key' src='$url' />",
        }}
        handleSubmit={async (values) => {
          setIsSubmitting(true);
          await create({ ...values });
          setIsSubmitting(false);
          handleCreated();
        }}
      >
        <Button
          type="submit"
          disabled={isSubmitting}
          variant={isSubmitting ? "success" : "default"}
          whileTap={{
            scale: 0.8,
          }}
        >
          {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
        </Button>
      </Form>
    </>
  );
};

export default AddFrontendForm;
