import React, { ReactElement } from "react";
import "modern-normalize/modern-normalize.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme/theme";
import LoggedInApp from "./modules/LoggedInApp";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as de from "./languages/de.json";
import UserList from "./modules/UserList";
import CompanyList from "./modules/CompanyList";
import PublicProfileList from "./modules/PublicProfiles/PublicProfileList";
import LoggedOutApp from "./modules/LoggedOutApp";
import ProjectCategoriesList from "./modules/ProjectCategoriesList";
import { CapabilitiesList } from "./modules";
import CreateProject from "./modules/Projects/CreateProject";
import Logout from "./modules/Logout";
import Forms from "./modules/Forms/Forms";
import AccountData from "./modules/AccountData/AccountData";
import UpdateCompany from "./modules/Company/UpdateCompany";
import EntityList from "./modules/Entities/EntityList";
import CreateEntity from "./modules/Entities/CreateEntity";
import ProjectList from "./modules/Projects/ProjectList";
import EditEntity from "./modules/Entities/EditEntity";
import SpecificationSheet from "./modules/SpecificationSheet/SpecificationSheet";
import PartnerOverview from "./modules/PartnerOverview/PartnerOverview";
import EditProject from "./modules/Projects/EditProject";
import AccessSpecificationSheet from "./modules/SpecificationSheet/AccessSpecificationSheet";
import PublicProfile from "./modules/PublicProfiles/PublicProfile";
import FrontendList from "./modules/Frontends/FrontendList";

void i18n
  .use(initReactI18next)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      de: {
        translation: {
          ...de,
        },
      },
    },
    lng: "de",
    fallbackLng: "de",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .catch();

function App(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/specifications/:projectId"
            element={<SpecificationSheet />}
          />
          <Route
            path="/specifications/access/:projectId"
            element={<AccessSpecificationSheet />}
          />
          <Route path="/login" element={<LoggedOutApp />} />
          <Route path="/" element={<LoggedInApp />}>
            <Route index element={<PartnerOverview />} />
            <Route path="*" element={<>Under construction.</>} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/create-project" element={<CreateProject />} />
            <Route
              path="/client/users"
              element={<UserList filter="client" />}
            />
            <Route path="/client/projects" element={<ProjectList />} />
            <Route
              path="/packing-machine/users"
              element={<UserList filter="default" />}
            />
            <Route path="/machine/edit/:entityId" element={<EditEntity />} />
            <Route path="/project/edit/:projectId" element={<EditProject />} />
            <Route
              path="/contract-manufacturer/users"
              element={<UserList filter="contractManufacturer" />}
            />
            <Route
              path="/packaging/users"
              element={<UserList filter="packaging" />}
            />
            <Route
              path="/used-machine/users"
              element={<UserList filter="usedMachine" />}
            />
            <Route
              path="/client/companies"
              element={<CompanyList filter="client" />}
            />
            <Route
              path="/packing-machine/companies"
              element={<CompanyList filter="default" />}
            />
            <Route
              path="/packaging/companies"
              element={<CompanyList filter="packaging" />}
            />
            <Route
              path="/contract-manufacturer/companies"
              element={<CompanyList filter="contractManufacturer" />}
            />
            <Route
              path="/used-machine/companies"
              element={<CompanyList filter="usedMachine" />}
            />
            <Route path="/companies" element={<CompanyList />} />
            <Route path="/machines" element={<EntityList />} />
            <Route
              path="/packing-machine/machines"
              element={<EntityList filter="default" />}
            />
            <Route
              path="/contract-manufacturer/machines"
              element={<EntityList filter="contractManufacturer" />}
            />
            <Route
              path="/used-machine/machines"
              element={<EntityList filter="usedMachine" />}
            />
            <Route
              path="/packing-machine/machines/create"
              element={<CreateEntity type={{ name: "default" }} />}
            />
            <Route
              path="/contract-manufacturer/machines/create"
              element={<CreateEntity type={{ name: "contractManufacturer" }} />}
            />
            <Route
              path="/used-machine/machines/create"
              element={<CreateEntity type={{ name: "usedMachine" }} />}
            />
            <Route
              path="/packaging/entities/create"
              element={<CreateEntity type={{ name: "packaging" }} />}
            />
            <Route
              path="/packaging/entities"
              element={<EntityList filter="packaging" />}
            />
            <Route path="/users" element={<UserList />} />
            <Route path="/public-profiles" element={<PublicProfileList />} />
            <Route path="/capabilities" element={<CapabilitiesList />} />
            <Route
              path="/project-categories"
              element={<ProjectCategoriesList />}
            />
            <Route path="/forms" element={<Forms />} />
            <Route path="/account/my-account" element={<AccountData />} />
            <Route path="/account/company" element={<UpdateCompany />} />
            <Route
              path="/profile/company/:companyId"
              element={<PublicProfile />}
            />
            <Route path="/frontends" element={<FrontendList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
