import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface ButtonProps {
  children: ReactNode;
  type?: "submit" | "button";
  disabled?: boolean;
  variant?: "default" | "success" | "light";
  handleClick?: () => void | Promise<void>;
  fullWidth?: boolean;
}

export const StyledButton = styled.button<{
  variant: "default" | "success" | "light";
  fullWidth?: boolean;
}>`
  border: ${({ theme, variant }) =>
    variant === "light" ? `1px solid ${theme.colors.black}` : "0"};
  border-radius: 1rem;
  ${({ theme, variant }) =>
    variant === "success" ? `background: ${theme.colors.success};` : ""}
  ${({ theme, variant }) =>
    variant === "default" ? `background: ${theme.colors.black};` : ""}
  ${({ theme, variant }) =>
    variant === "light" ? `background: ${theme.colors.white};` : ""}
  color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.black : theme.colors.white};
  padding: ${({ variant }) =>
    variant === "light" ? "calc(1rem - 1px) calc(2rem - 1px)" : "1rem 2rem"};
  outline: none;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  ${({ fullWidth }) =>
    fullWidth !== undefined && fullWidth ? "width: 100%;" : ""}

  &:hover {
    ${({ theme, variant }) =>
      variant === "success" ? `background: ${theme.colors["success/80"]};` : ""}
    ${({ theme, variant }) =>
      variant === "default" ? `background: ${theme.colors.darkgray};` : ""}
    ${({ theme, variant }) =>
      variant === "light" ? `background: ${theme.colors["black/5"]};` : ""}
  }
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = "submit",
      disabled = false,
      variant = "default",
      handleClick,
      fullWidth,
    },
    ref
  ): ReactElement => {
    return (
      <StyledButton
        ref={ref}
        type={type}
        disabled={disabled}
        variant={variant}
        onClick={handleClick}
        fullWidth={fullWidth}
      >
        {children}
      </StyledButton>
    );
  }
);
Button.displayName = "Button";

export default motion(Button);
