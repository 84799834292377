import useApi from "../useApi";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";

interface UseRemoveCapabilityFromFormGroupInterface {
  addCapabilityToFormGroup: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId?: string;
  }) => Promise<FormGroupInterface | null>;

  addCapabilityToCapabilityGroup: ({
    formGroupId,
    capabilityId,
    capabilityGroupId,
  }: {
    formGroupId: string;
    capabilityId: string;
    capabilityGroupId: string;
  }) => Promise<FormGroupInterface | null>;
}

const useAddCapabilityToFormGroup =
  (): UseRemoveCapabilityFromFormGroupInterface => {
    const { post } = useApi();

    const addCapabilityToFormGroup = async ({
      formGroupId,
      capabilityId,
      capabilityGroupId,
    }: {
      formGroupId: string;
      capabilityId: string;
      capabilityGroupId?: string;
    }): Promise<FormGroupInterface> => {
      const postData =
        capabilityGroupId !== undefined
          ? {
              capabilityGroupId,
            }
          : {};
      const { data, status } = await post(
        `/form-group/${formGroupId}/${capabilityId}`,
        { ...postData }
      );

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    const addCapabilityToCapabilityGroup = async ({
      formGroupId,
      capabilityId,
      capabilityGroupId,
    }: {
      formGroupId: string;
      capabilityId: string;
      capabilityGroupId: string;
    }): Promise<FormGroupInterface> => {
      const postData =
        capabilityGroupId !== undefined
          ? {
              capabilityGroupId,
            }
          : {};
      const { data, status } = await post(
        `/form-group/${formGroupId}/${capabilityId}`,
        { ...postData }
      );

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { addCapabilityToFormGroup, addCapabilityToCapabilityGroup };
  };

export default useAddCapabilityToFormGroup;
