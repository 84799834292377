import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useProjects from "../../api/projects/useProjects";
import CompanyUserSelection, {
  CompanyUserSelectionConfigurationInterface,
} from "../Projects/CompanyUserSelection";
import { ProjectMasterDataConfigurationInterface } from "../Projects/ProjectMasterDataForm";
import { Button, Card, Headline, Space } from "../../components";
import SetEntityCapabilities from "./SetEntityCapabilities";
import { ProjectCategoriesColumn } from "../../hooks/useProjectCategoriesListColumns";
import { FormikValues } from "formik";
import useFormToProjectCapabilityAdapter from "./hooks/useFormToCapabilityAdapter";
import { ProjectCapabilityInterface } from "../../interfaces/ProjectCapabilityInterface";
import { useNavigate, useParams } from "react-router-dom";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import useProjectCapabilityToFormAdapter from "./hooks/useCapabilityToFormAdapter";
import EntityMasterDataForm from "../Projects/EntityMasterDataForm";

export interface EditEntityConfigurationInterface
  extends CompanyUserSelectionConfigurationInterface,
    ProjectMasterDataConfigurationInterface {
  projectCapabilities: ProjectCapabilityInterface[];
}

const EditEntity = (): ReactElement => {
  const { t } = useTranslation();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { adapt: adaptToProjectCapabilities } =
    useFormToProjectCapabilityAdapter();
  const { adapt: adaptToFormData } = useProjectCapabilityToFormAdapter();
  const { getById: getProjectById, update: updateProject } = useProjects();
  const [status, setStatus] = useState<RequestStatusType>("initial");

  const [initialValues, setInitialValues] = useState<
    | {
        enabledCategories: ProjectCategoriesColumn[];
        formData: FormikValues;
        enabledFieldIds: string[];
      }
    | undefined
  >();
  const [activeStep, setActiveStep] = useState(1);
  const [configuration, setConfiguration] =
    useState<EditEntityConfigurationInterface>({
      companyId: null,
      userId: null,
      name: "",
      description: "",
      projectCapabilities: [],
      price: 0,
      year: 0,
      image: ",",
      video: "",
    });

  useEffect(() => {
    if (entityId === undefined) {
      return;
    }
    void getProjectById(entityId).then(async (entity) => {
      if (entity === null) {
        setStatus("error");
        return;
      }
      setInitialValues(
        await adaptToFormData({
          projectCapabilities: entity.projectCapabilities ?? [],
        })
      );
      setConfiguration({
        companyId: entity.companyId ?? null,
        userId: entity.userId ?? null,
        name: entity.name,
        description: entity.description,
        projectCapabilities: entity.projectCapabilities ?? [],
        price: entity.projectMasterData?.price ?? 0,
        year: entity.projectMasterData?.year ?? 0,
        image: entity.projectMasterData?.image ?? "",
        video: entity.projectMasterData?.video ?? "",
      });
      setStatus("success");
    });
    setStatus("loading");
  }, [entityId]);

  const handleNext = (
    updatedConfiguration:
      | CompanyUserSelectionConfigurationInterface
      | ProjectMasterDataConfigurationInterface
  ): void => {
    setConfiguration({
      ...configuration,
      ...updatedConfiguration,
    });
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = (): void => setActiveStep(activeStep - 1);

  const handleSaveEntity = async ({
    enabledCategories,
    formData,
    files,
  }: {
    enabledCategories: ProjectCategoriesColumn[];
    formData: FormikValues;
    files: Record<string, File | null>;
  }): Promise<void> => {
    if (entityId === undefined) {
      return;
    }
    const projectCapabilities = await adaptToProjectCapabilities({
      formData,
      enabledCategories,
      files,
    });

    setConfiguration({
      ...configuration,
      projectCapabilities,
    });

    if (configuration.companyId === null) {
      return;
    }

    await updateProject({
      id: entityId,
      companyId: configuration.companyId,
      userId: configuration.userId ?? undefined,
      name: configuration.name,
      description: configuration.description,
      categories: enabledCategories.map((category) => ({
        name: category.name,
      })),
      projectMasterData: {
        image: configuration.image,
        video: configuration.video,
        year: configuration.year,
        price: configuration.price,
      },
      projectCapabilities,
    });

    setActiveStep(4);
  };

  return (
    <>
      {status === "success" && (
        <>
          {activeStep === 1 && (
            <>
              <Headline>{t("EDIT_ENTITY.step-1")}</Headline>
              <CompanyUserSelection
                handleNext={handleNext}
                values={{
                  companyId: configuration.companyId,
                  userId: configuration.userId,
                }}
              />
            </>
          )}
          {activeStep === 2 && (
            <>
              <Headline>{t("EDIT_ENTITY.step-2")}</Headline>
              <EntityMasterDataForm
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                values={{
                  name: configuration.name,
                  description: configuration.description,
                  image: configuration.image ?? "",
                  video: configuration.video ?? "",
                  year: configuration.year ?? new Date().getFullYear(),
                  price: configuration.price ?? 0,
                }}
                handleChange={(updatedConfiguration) => {
                  setConfiguration({
                    ...configuration,
                    ...updatedConfiguration,
                  });
                }}
              />
            </>
          )}
          {activeStep === 3 && entityId !== undefined && (
            <>
              <Headline>{t("EDIT_ENTITY.step-3")}</Headline>
              <SetEntityCapabilities
                action="edit"
                initialValues={initialValues}
                handleSaveEntity={handleSaveEntity}
                projectId={entityId}
              />
            </>
          )}
          {activeStep === 4 && (
            <>
              <Card color="success">{t("EDIT_ENTITY.success")}</Card>
              <Space size={2} />
              <Button
                type="button"
                handleClick={() => navigate("/packing-machine/machines")}
              >
                {t("EDIT_ENTITY.back")}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditEntity;
