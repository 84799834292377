import { FrontendInterface } from "../../interfaces/FrontendInterface";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useFrontends from "../../api/frontends/useFrontends";
import Form, { FormValuesType } from "../../components/Form/Form";
import { Button, Headline, Space } from "../../components";
import CheckIcon from "../../icons/CheckIcon";
import getAppUrl from "../../helpers/getAppUrl";

interface FrontendFormInterface extends FormValuesType {
  name: string;
  listWrapper: string;
  projectWrapper: string;
  imageWrapper: string;
  priceWrapper: string;
  yearWrapper: string;
  specificationWrapperBoolean: string;
  specificationWrapperString: string;
  specificationWrapperSelect: string;
  specificationWrapperFloat: string;
  specificationWrapperFile: string;
}

interface EditFrontendFormProps {
  frontend: FrontendInterface;
}

const EditFrontendForm = ({
  frontend,
}: EditFrontendFormProps): ReactElement => {
  const { t } = useTranslation();
  const { update } = useFrontends();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      {frontend.id !== undefined && (
        <>
          <Headline>Code für hinter den Body-Tag:</Headline>
          <code>
            {`<script src="${getAppUrl()}/snippet.js" type="module"></script>`}
          </code>
          <Space size={2} />
          <Headline>Verpackungsmaschinen:</Headline>
          <code>
            {`<div class="pp-market" data-type="default" data-frontend-id="${frontend.id}"></div>`}
          </code>
          <Space size={2} />
          <Headline>Gebrauchtmaschinen:</Headline>
          <code>
            {`<div class="pp-market" data-type="usedMachine" data-frontend-id="${frontend.id}"></div>`}
          </code>
          <Space size={2} />
          <Headline>Packmittel:</Headline>
          <code>
            {`<div class="pp-market" data-type="packaging" data-frontend-id="${frontend.id}"></div>`}
          </code>
        </>
      )}

      <Form<FrontendFormInterface>
        fields={[
          { id: "name", label: t("FRONTENDS.name"), type: "text" },
          {
            id: "listWrapper",
            label: t("FRONTENDS.listWrapper"),
            type: "code",
          },
          {
            id: "projectWrapper",
            label: t("FRONTENDS.projectWrapper"),
            type: "code",
          },
          {
            id: "contactForm",
            label: t("FRONTENDS.contactForm"),
            type: "code",
          },
          {
            id: "imageWrapper",
            label: t("FRONTENDS.imageWrapper"),
            type: "code",
          },
          {
            id: "priceWrapper",
            label: t("FRONTENDS.priceWrapper"),
            type: "code",
          },
          {
            id: "yearWrapper",
            label: t("FRONTENDS.yearWrapper"),
            type: "code",
          },
          {
            id: "specificationWrapperBoolean",
            label: t("FRONTENDS.specificationWrapperBoolean"),
            type: "code",
          },
          {
            id: "specificationWrapperString",
            label: t("FRONTENDS.specificationWrapperString"),
            type: "code",
          },
          {
            id: "specificationWrapperSelect",
            label: t("FRONTENDS.specificationWrapperSelect"),
            type: "code",
          },
          {
            id: "specificationWrapperFloat",
            label: t("FRONTENDS.specificationWrapperFloat"),
            type: "code",
          },
          {
            id: "specificationWrapperFile",
            label: t("FRONTENDS.specificationWrapperFile"),
            type: "code",
          },
        ]}
        initialValues={{
          ...frontend,
        }}
        handleSubmit={async (values) => {
          setIsSubmitting(true);
          await update({ ...frontend, ...values });
          setIsSubmitting(false);
        }}
      >
        <Button
          type="submit"
          disabled={isSubmitting}
          variant={isSubmitting ? "success" : "default"}
          whileTap={{
            scale: 0.8,
          }}
        >
          {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
        </Button>
      </Form>
    </>
  );
};

export default EditFrontendForm;
