import { ProjectInterface } from "../../interfaces/ProjectInterface";
import useProjectsStore from "../../stores/useProjectsStore";
import useGetProjects from "./useGetProjects";
import useCreateProject from "./useCreateProject";
import useGetProjectsByMandate from "./useGetProjectsByMandate";
import useGetProject from "./useGetProject";
import useUpdateProject from "./useUpdateProject";
import useGetProjectsByMandateAndType from "./useGetProjectsByMandateAndType";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";
import { ProjectMandateEnum } from "../../interfaces/ProjectMandateEnum";
import useDeleteProject from "./useDeleteProject";
import useGetProjectsByCompanyId from "./useGetProjectsByCompanyId";

interface UseProjectsInterface {
  getAll: () => Promise<ProjectInterface[]>;
  getAllClientProjects: () => Promise<ProjectInterface[]>;
  getAllEntityProjects: () => Promise<ProjectInterface[]>;
  getById: (id: string) => Promise<ProjectInterface | null>;
  getEntitiesByCompanyId: (companyId: string) => Promise<ProjectInterface[]>;
  create: (project: ProjectInterface) => Promise<ProjectInterface>;
  update: (project: ProjectInterface) => Promise<ProjectInterface | null>;
  updateWhere: (
    where: (
      value: ProjectInterface,
      index: number,
      obj: ProjectInterface[]
    ) => boolean,
    data: (project: ProjectInterface) => any
  ) => Promise<void>;
  projects: ProjectInterface[];
  entityProjects: ProjectInterface[];
  clientProjects: ProjectInterface[];
  getAllEntityProjectsByType: ({
    type,
  }: {
    type: string;
  }) => Promise<ProjectInterface[]>;
  addContract: (projectContract: ProjectContractInterface) => Promise<void>;
  updateContract: (projectContract: ProjectContractInterface) => Promise<void>;
  removeContract: (projectContract: ProjectContractInterface) => Promise<void>;
  deleteById: (id: string) => Promise<boolean>;
}

const useProjects = (): UseProjectsInterface => {
  const set = useProjectsStore((state) => state.set);
  const gotAll = useProjectsStore((state) => state.gotAll);
  const add = useProjectsStore((state) => state.add);
  const stateUpdate = useProjectsStore((state) => state.update);
  const stateGetById = useProjectsStore((state) => state.getById);
  const stateGetAll = useProjectsStore((state) => state.getAll);
  const setGotAll = useProjectsStore((state) => state.setGotAll);
  const projects = useProjectsStore((state) => state.data);
  const stateRemove = useProjectsStore((state) => state.remove);
  const { getAll: apiGetAll } = useGetProjects();
  const { getByMandate } = useGetProjectsByMandate();
  const { getByCompanyId: apiGetByCompanyId } = useGetProjectsByCompanyId();
  const { getByMandateAndType } = useGetProjectsByMandateAndType();
  const { create: apiCreate } = useCreateProject();
  const { update: apiUpdate } = useUpdateProject();
  const { deleteById: apiDeleteById } = useDeleteProject();
  const { getById: apiGetById } = useGetProject();

  const updateWhere = async (
    where: (
      value: ProjectInterface,
      index: number,
      obj: ProjectInterface[]
    ) => boolean,
    data: (project: ProjectInterface) => any
  ): Promise<void> => {
    const foundProject = projects.find(where);

    if (foundProject === undefined) {
      return;
    }

    const updatedProject = { ...foundProject, ...data(foundProject) };
    stateUpdate({ id: updatedProject.id, updatedDataSet: updatedProject });
  };

  const deleteById = async (id: string): Promise<boolean> => {
    const removed = await apiDeleteById(id);

    stateRemove(id);

    return removed;
  };

  const addContract = async (
    projectContract: ProjectContractInterface
  ): Promise<void> => {
    if (projectContract.projectId === undefined) {
      return;
    }
    const project = await getById(projectContract.projectId);
    if (project === null) {
      return;
    }
    stateUpdate({
      id: projectContract.projectId,
      updatedDataSet: {
        ...project,
        projectContracts:
          project.projectContracts === undefined
            ? [projectContract]
            : [...project.projectContracts, projectContract],
      },
    });
  };

  const updateContract = async (
    projectContract: ProjectContractInterface
  ): Promise<void> => {
    if (projectContract.projectId === undefined) {
      return;
    }

    const project = await getById(projectContract.projectId);
    if (project === null) {
      return;
    }
    stateUpdate({
      id: projectContract.projectId,
      updatedDataSet: {
        ...project,
        projectContracts:
          project.projectContracts === undefined
            ? [projectContract]
            : [
                ...project.projectContracts.map((projectContractData) => {
                  if (projectContractData.id !== projectContract.id) {
                    return projectContractData;
                  }
                  return projectContract;
                }),
              ],
      },
    });
  };

  const removeContract = async (
    projectContract: ProjectContractInterface
  ): Promise<void> => {
    if (projectContract.projectId === undefined) {
      return;
    }
    const project = await getById(projectContract.projectId);
    if (project === null) {
      return;
    }
    stateUpdate({
      id: projectContract.projectId,
      updatedDataSet: {
        ...project,
        projectContracts:
          project.projectContracts === undefined
            ? []
            : [
                ...project.projectContracts.filter(
                  (projectContractData) =>
                    projectContractData.id !== projectContract.id
                ),
              ],
      },
    });
  };

  const getAllEntityProjects = async (): Promise<ProjectInterface[]> => {
    if (gotAll) {
      return stateGetAll().filter(
        (project) => project.mandate === ProjectMandateEnum.ENTITY
      );
    }

    const projects = await getByMandate({ mandate: ProjectMandateEnum.ENTITY });
    add(projects);

    return projects;
  };

  const getAllEntityProjectsByType = async ({
    type,
  }: {
    type: string;
  }): Promise<ProjectInterface[]> => {
    if (gotAll) {
      return stateGetAll().filter(
        (project: ProjectInterface) =>
          project.mandate === ProjectMandateEnum.ENTITY &&
          project.types !== undefined &&
          project.types.find((projectType) => projectType.name === type) !==
            undefined
      );
    }

    const projects = await getByMandateAndType({
      mandate: ProjectMandateEnum.ENTITY,
      type,
    });
    add(projects);

    return projects;
  };

  const getAllClientProjects = async (): Promise<ProjectInterface[]> => {
    if (gotAll) {
      return stateGetAll().filter(
        (project) => project.mandate === ProjectMandateEnum.REQUEST
      );
    }

    const projects = await getByMandate({
      mandate: ProjectMandateEnum.REQUEST,
    });
    add(projects);

    return projects;
  };

  const getEntitiesByCompanyId = async (
    companyId: string
  ): Promise<ProjectInterface[]> => {
    if (gotAll) {
      return stateGetAll().filter(
        (project) =>
          project.mandate === ProjectMandateEnum.ENTITY &&
          project.companyId === companyId
      );
    }

    const projects = await apiGetByCompanyId({
      companyId,
      mandate: ProjectMandateEnum.ENTITY,
    });
    add(projects);

    return projects;
  };

  const getAll = async (): Promise<ProjectInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const projects = await apiGetAll();

    set(projects);
    setGotAll();

    return projects;
  };

  const getById = async (id: string): Promise<ProjectInterface | null> => {
    let project = stateGetById(id);

    if (project !== null) {
      return project;
    }

    project = await apiGetById(id);

    if (project === null) {
      return null;
    }

    add(project);
    return project;
  };

  const create = async (
    project: ProjectInterface
  ): Promise<ProjectInterface> => {
    const created = await apiCreate(project);

    if (created !== null) {
      add(created);
    }

    return created;
  };

  const update = async (
    project: ProjectInterface
  ): Promise<ProjectInterface | null> => {
    const updated = await apiUpdate(project);
    if (updated !== null && project.id !== undefined) {
      stateUpdate({ id: project.id, updatedDataSet: updated });
    }

    return updated;
  };

  return {
    getAll,
    getAllClientProjects,
    getAllEntityProjects,
    addContract,
    removeContract,
    updateContract,
    getById,
    create,
    update,
    projects,
    updateWhere,
    deleteById,
    entityProjects: projects.filter((project) => project.mandate === "ENTITY"),
    clientProjects: projects.filter((project) => project.mandate === "REQUEST"),
    getAllEntityProjectsByType,
    getEntitiesByCompanyId,
  };
};
export default useProjects;
