import { CapabilityConfigurationInterface } from "../../interfaces/CapabilityConfigurationInterface";
import useCapabilityConfigurationStore from "../../stores/useCapabilityConfigurationStore";
import useGetCapabilityConfigurations from "./useGetCapabilityConfigurations";
import useCreateCapabilityConfiguration from "./useCreateCapabilityConfiguration";
import useGetCapabilityConfiguration from "./useGetCapabilityConfiguration";

interface UseCapabilityConfigurationsInterface {
  getAll: () => Promise<CapabilityConfigurationInterface[]>;
  getById: (id: string) => Promise<CapabilityConfigurationInterface | null>;
  create: (
    capability: CapabilityConfigurationInterface
  ) => Promise<CapabilityConfigurationInterface>;
}

const useCapabilityConfigurations =
  (): UseCapabilityConfigurationsInterface => {
    const add = useCapabilityConfigurationStore((state) => state.add);
    const set = useCapabilityConfigurationStore((state) => state.set);
    const gotAll = useCapabilityConfigurationStore((state) => state.gotAll);
    const stateGetAll = useCapabilityConfigurationStore(
      (state) => state.getAll
    );
    const stateGetById = useCapabilityConfigurationStore(
      (state) => state.getById
    );
    const setGotAll = useCapabilityConfigurationStore(
      (state) => state.setGotAll
    );
    const { getAll: apiGetAll } = useGetCapabilityConfigurations();
    const { create: apiCreate } = useCreateCapabilityConfiguration();
    const { getById: apiGetById } = useGetCapabilityConfiguration();

    const getAll = async (): Promise<CapabilityConfigurationInterface[]> => {
      if (gotAll) {
        return stateGetAll();
      }
      const capabilityConfigurations = await apiGetAll();

      set(capabilityConfigurations);
      setGotAll();

      return capabilityConfigurations;
    };

    const getById = async (
      id: string
    ): Promise<CapabilityConfigurationInterface | null> => {
      let capabilityConfiguration = stateGetById(id);

      if (capabilityConfiguration !== null) {
        return capabilityConfiguration;
      }

      capabilityConfiguration = await apiGetById(id);

      if (capabilityConfiguration === null) {
        return null;
      }

      add(capabilityConfiguration);
      return capabilityConfiguration;
    };

    const create = async (
      capabilityConfiguration: CapabilityConfigurationInterface
    ): Promise<CapabilityConfigurationInterface> => {
      const created = await apiCreate(capabilityConfiguration);
      if (created !== null) {
        add(created);
      }

      return created;
    };

    return {
      getAll,
      getById,
      create,
    };
  };

export default useCapabilityConfigurations;
