import useAuthentication from "../../../api/useAuthentication";
import hasUserRole from "../../../helpers/hasUserRole";
import useGeneralProjectListColumns from "./useGeneralProjectListColumns";
import { ColumnDef } from "@tanstack/react-table";
import { ProjectInterface } from "../../../interfaces/ProjectInterface";
import usePartnerProjectListColumns from "./usePartnerProjectListColumns";

interface UseProjectsListColumnsInterface {
  columns: Array<ColumnDef<ProjectInterface, any>>;
}

const useProjectsListColumns = (): UseProjectsListColumnsInterface => {
  const { token } = useAuthentication();

  if (hasUserRole({ user: token?.user, role: "ADMIN" })) {
    const { columns } = useGeneralProjectListColumns();

    return { columns };
  }
  if (hasUserRole({ user: token?.user, role: "PARTNER" })) {
    const { columns } = usePartnerProjectListColumns();

    return { columns };
  }

  const { columns } = useGeneralProjectListColumns();
  return { columns };
};

export default useProjectsListColumns;
