import React, { ReactElement, useEffect } from "react";
import useAuthentication from "../api/useAuthentication";
import { redirect } from "react-router-dom";

const Logout = (): ReactElement => {
  const { logout } = useAuthentication();

  useEffect(() => {
    logout();
    redirect("/");
  }, []);
  return <></>;
};

export default Logout;
