import useApi from "../useApi";
import { ProjectCategoryInterface } from "../../interfaces/ProjectCategoryInterface";

interface UseGetCapabilitiesInterface {
  getAll: () => Promise<ProjectCategoryInterface[]>;
}

const useGetProjectCategories = (): UseGetCapabilitiesInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<ProjectCategoryInterface[]> => {
    const { data, status } = await get(`/project-category`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetProjectCategories;
