import styled from "styled-components";

const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 2rem;
`;

export default StyledGroup;
