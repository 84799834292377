import { create } from "zustand";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { PublicProfileBlockInterface } from "../interfaces/PublicProfileBlockInterface";

interface PublicProfileBlockState
  extends CrudStateInterface<PublicProfileBlockInterface> {
  getByCompanyId: (companyId: string) => PublicProfileBlockInterface[];
}
const useCompanyStore = create<PublicProfileBlockState>((set, get) => ({
  ...defaultStateCrud<PublicProfileBlockInterface>(set, get),
  getByCompanyId: (companyId) =>
    get().data.filter(
      ({ companyId: stateCompanyId }) => companyId === stateCompanyId
    ) ?? [],
}));

export default useCompanyStore;
