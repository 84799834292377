import useApi from "../useApi";
import { CompanyInterface } from "../../interfaces/CompanyInterface";

interface UseGetCompanyInterface {
  getById: (id: string) => Promise<CompanyInterface | null>;
}

const useGetCompany = (): UseGetCompanyInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<CompanyInterface | null> => {
    const { data, status } = await get(`/company/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetCompany;
