import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import {
  Button,
  Checkbox,
  Column,
  Headline,
  LoadingSkeleton,
  Row,
  Space,
  StyledLabel,
} from "../../../components";
import useProjectContracts from "../../../api/projectContracts/useProjectContracts";
import useCompanies from "../../../api/companies/useCompanies";
import useUsers from "../../../api/users/useUsers";
import { UserInterface } from "../../../interfaces/UserInterface";
import StyledGroup from "../../../components/styles/StyledGroup";
import getName from "../../../helpers/getName";
import { CompanyInterface } from "../../../interfaces/CompanyInterface";
import { RequestStatusType } from "../../../interfaces/RequestStatusType";
import { CheckIcon } from "../../../icons";
import useSpecificationInvitations from "../../../api/specificationInvitations/useSpecificationInvitations";

interface EditContractStateModalProps {
  isOpen: boolean;
  contractId: string;
  handleOpenChange: (isOpen: boolean) => void;
}

const SendEmailModal = ({
  isOpen,
  contractId,
  handleOpenChange,
}: EditContractStateModalProps): ReactElement => {
  const { t } = useTranslation();
  const { getById: getContractById } = useProjectContracts();
  const { getById: getCompanyById } = useCompanies();
  const { getByCompany: getUsersByCompany } = useUsers();
  const { create: createInvitations } = useSpecificationInvitations();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [users, setUsers] = useState<UserInterface[] | null>(null);
  const [company, setCompany] = useState<CompanyInterface | null>(null);
  const [selection, setSelection] = useState<
    Array<{ id: string; isActivated: boolean; entity: "company" | "user" }>
  >([]);

  useEffect(() => {
    void getContractById(contractId).then((contract) => {
      if (contract === null) {
        setCompany(null);
        setUsers([]);
        return;
      }

      void getCompanyById(contract.companyId).then((company) => {
        if (company === null || company.id === undefined) {
          setCompany(null);
          setUsers([]);
          return;
        }

        setCompany(company);

        void getUsersByCompany(company.id).then((usersByCompany) => {
          setUsers(usersByCompany);
        });
      });
    });
  }, [contractId]);

  const toggleSelection = (entity: "company" | "user", id?: string): void => {
    if (id === undefined) {
      return;
    }
    const foundUserSelection = selection.find(
      (selection) => selection.id === id
    );

    if (foundUserSelection === undefined) {
      setSelection([...selection, { id, isActivated: true, entity }]);
      return;
    }

    setSelection(
      selection.map((selection) => {
        if (selection.id !== id) {
          return selection;
        }
        return { ...selection, isActivated: !selection.isActivated };
      })
    );
  };

  const isSomeUsersActivated = (): boolean => {
    return selection.find((selection) => selection.isActivated) !== undefined;
  };

  const isActivated = (userId?: string): boolean => {
    if (userId === undefined) {
      return false;
    }
    return (
      selection.find(
        (selection) => selection.id === userId && selection.isActivated
      ) !== undefined
    );
  };

  const handleSendClick = async (): Promise<void> => {
    setStatus("loading");
    await createInvitations({
      contractId,
      recipients: selection
        .filter((recipient) => recipient.isActivated)
        .map((recipient) => ({
          id: recipient.id,
          entity: recipient.entity,
        })),
    });

    setStatus("success");
  };

  return (
    <Modal isOpen={isOpen} handleClose={() => handleOpenChange(false)}>
      <Headline>
        {company === null || company.name === undefined
          ? ""
          : `${company.name}: `}
        {t("PROJECT.SEND_MAIL.headline")}
      </Headline>
      {users === null && <LoadingSkeleton />}
      {users !== null && (
        <>
          {status !== "success" && (
            <StyledGroup>
              <StyledLabel>{t("PROJECT.SEND_MAIL.select")}:</StyledLabel>
              {users.map((user) => (
                <React.Fragment key={user.id}>
                  <Checkbox
                    label={getName(user.profile)}
                    name={user.id ?? ""}
                    value={isActivated(user.id)}
                    handleChange={() => toggleSelection("user", user.id)}
                  />
                </React.Fragment>
              ))}
              {company?.email !== undefined && company.email !== "" && (
                <>
                  <Checkbox
                    label={`${t(
                      "PROJECT.SEND_MAIL.company-mail"
                    )} ${`(${company.email})`}`}
                    name="company"
                    value={isActivated(company.id)}
                    handleChange={() => toggleSelection("company", company.id)}
                  />
                </>
              )}
            </StyledGroup>
          )}
          {status === "success" && <>{t("PROJECT.SEND_MAIL.success")}</>}
          <Space size={2} />
          <Row mb="0">
            <Column>
              <Button
                variant={status === "success" ? "default" : "light"}
                fullWidth
                handleClick={() => handleOpenChange(false)}
              >
                {status === "success"
                  ? t("BUTTONS.close")
                  : t("BUTTONS.cancel")}
              </Button>
            </Column>
            {isSomeUsersActivated() && (
              <Column size={status === "success" ? "shrink" : "unset"}>
                <Button
                  fullWidth
                  variant={status === "success" ? "success" : "default"}
                  disabled={status !== "initial"}
                  handleClick={handleSendClick}
                >
                  {status === "loading" && <>{t("BUTTONS.updating")}</>}
                  {status === "initial" && <>{t("PROJECT.SEND_MAIL.send")}</>}
                  {status === "success" && (
                    <>
                      <CheckIcon />
                    </>
                  )}
                </Button>
              </Column>
            )}
          </Row>
        </>
      )}
    </Modal>
  );
};

export default SendEmailModal;
