import React, { ReactElement, useEffect, useState } from "react";
import StyledField from "../styles/StyledField";
import StyledLabel from "../styles/StyledLabel";
import StyledInputField from "../styles/StyledInputField";
import styled from "styled-components";
import { ChevronDownIcon, CloseIcon } from "../../icons";
import { SearchableDataType } from "../../interfaces/SearchableDataType";
import { SearchableDataInterface } from "../../interfaces/SearchableDataInterface";
import { useTranslation } from "react-i18next";

const StyledHorizontalAlignment = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledActionIcon = styled.div`
  cursor: pointer;
`;

const StyledSearchResults = styled.div`
  display: block;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 2rem;
  margin-bottom: 2rem;
`;

const StyledResultList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: ${() => 5 * 3.75}rem;
  overflow: auto;
`;
const StyledResultItem = styled.li<{ selected?: boolean; noEffect?: boolean }>`
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.5s;
  ${({ selected }) =>
    selected === undefined || !selected ? "" : "font-weight: 600;"}

  &:hover {
    ${({ noEffect, theme }) =>
      noEffect !== true ? `background: ${theme.colors["black/5"]};` : ""}
  }
`;

interface SearchInputProps {
  label: string;
  searchData: SearchableDataType;
  selected: SearchableDataInterface | null;
  handleCreate: (label: string) => void | Promise<void>;
  handleSelect: (select: SearchableDataInterface | null) => void;
  createText: string;
}

const SearchInput = ({
  label,
  searchData,
  selected,
  handleCreate,
  handleSelect,
  createText,
}: SearchInputProps): ReactElement => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");
  const [searchResults, setSearchResults] =
    useState<SearchableDataType>(searchData);

  useEffect(() => {
    const filteredResults = searchData.filter(({ label }) => {
      return label.toLowerCase().includes(value.toLowerCase());
    });

    setSearchResults(filteredResults);
  }, [value, searchData]);

  useEffect(() => {
    if (selected === null) {
      return;
    }
    setValue(selected.label);
  }, [selected]);

  // todo decouple
  return (
    <>
      <StyledField mb={selected === null ? "0" : undefined}>
        <StyledLabel>{label}</StyledLabel>
        <StyledHorizontalAlignment>
          <StyledInputField
            type="text"
            name="search"
            disabled={selected !== null}
            onChange={(e) => setValue(e.currentTarget.value)}
            value={value}
          />
          <StyledActionIcon>
            {selected === null && <ChevronDownIcon />}
            {selected !== null && (
              <a onClick={() => handleSelect(null)}>
                <CloseIcon size="16" />
              </a>
            )}
          </StyledActionIcon>
        </StyledHorizontalAlignment>
      </StyledField>
      {selected === null && (
        <StyledSearchResults>
          <StyledResultList>
            {searchResults.length === 0 && value === "" && (
              <StyledResultItem noEffect>
                {t("SEARCH_INPUT.no-results")}
              </StyledResultItem>
            )}
            {searchResults.map((result) => (
              <React.Fragment key={JSON.stringify(result)}>
                <StyledResultItem onClick={() => handleSelect(result)}>
                  {result.label}
                </StyledResultItem>
              </React.Fragment>
            ))}
            {value !== "" && (
              <StyledResultItem
                onClick={() => {
                  void handleCreate(value);
                }}
              >
                {t(createText, { value })}
              </StyledResultItem>
            )}
          </StyledResultList>
        </StyledSearchResults>
      )}
    </>
  );
};

export default SearchInput;
