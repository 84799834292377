import React, { ReactElement, ReactNode } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

const ModalContentStyle = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-shadow: ${({ theme }) => theme.shadows.softer}
    ${({ theme }) => theme.colors["black/5"]};
  outline: none;
  width: 50vw;
`;

const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors["black/20"]};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 2rem;
  z-index: 1031;
`;

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}
const Modal = ({ children, isOpen, handleClose }: ModalProps): ReactElement => {
  return (
    <ReactModal
      onRequestClose={handleClose}
      isOpen={isOpen}
      className="_"
      overlayClassName="_"
      contentElement={(props, children) => (
        <ModalContentStyle {...props}>{children}</ModalContentStyle>
      )}
      overlayElement={(props, contentElement) => (
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
      )}
    >
      <div>{children}</div>
    </ReactModal>
  );
};

export default Modal;
