import React, { ReactElement, ReactNode } from "react";
import {
  Logo,
  Sidebar,
  StyledIconWrapper,
  StyledLogo,
  StyledLogoWrapper,
} from "../components";

interface LeftSidebarProps {
  isShowLeftSidebar: boolean;
  children: ReactNode;
}

const LeftSidebar = ({
  isShowLeftSidebar,
  children,
}: LeftSidebarProps): ReactElement => {
  return (
    <>
      {isShowLeftSidebar && (
        <Sidebar>
          <StyledLogoWrapper>
            <StyledLogo>
              <Logo height="3.75rem" />
            </StyledLogo>
            <StyledIconWrapper />
          </StyledLogoWrapper>
          {children}
        </Sidebar>
      )}
    </>
  );
};

export default LeftSidebar;
