import { ProjectCategoriesColumn } from "../../../hooks/useProjectCategoriesListColumns";
import useProjects from "../../../api/projects/useProjects";
import { CapabilityType } from "../../../interfaces/CapabilityInterface";

export interface RelevantCapability {
  projectCategoryName: string;
  capabilityId: string;
  value: string;
  type: CapabilityType;
  priority: number;
}

interface SearchResultInterface {
  projectId: string;
  matchingCapabilities: RelevantCapability[];
}

interface RelevantPropertiesInterface {
  relevantProjects: SearchResultInterface[];
  relevantCapabilities: RelevantCapability[];
}

// todo: outsource and decouple!
// todo outsource and put logic into api!
// todo: interfaces!
const useProjectMatching = (): {
  matchProjects: ({
    projectCategories,
    values,
  }: {
    projectCategories: ProjectCategoriesColumn[];
    values: Record<string, Record<string, any>>;
  }) => Promise<RelevantPropertiesInterface>;
  getRelevantCapabilities: ({
    projectCategories,
    values,
  }: {
    projectCategories: ProjectCategoriesColumn[];
    values: Record<string, Record<string, any>>;
  }) => RelevantCapability[];
} => {
  const { getAll: getAllProjects } = useProjects();

  // todo:
  // const transformRelevantCapabilities = (
  //   relevantCapabilities: RelevantCapability[]
  // ): Record<string, Record<string, any>> => {
  //   return {};
  // };

  const getRelevantCapabilities = ({
    projectCategories,
    values,
  }: {
    projectCategories: ProjectCategoriesColumn[];
    values: Record<string, Record<string, any>>;
  }): RelevantCapability[] => {
    const relevantCapabilities: RelevantCapability[] = [];

    for (const projectCategory of projectCategories) {
      if (projectCategory.capabilities === undefined) {
        continue;
      }
      const valueKeys = Object.keys(values[projectCategory.name]);
      for (const valueKey of valueKeys) {
        // todo: this is found multiple times, fix
        const [, cleanedValueKey, priority] =
          valueKey.match(/([\w|.]+)\.(\d+)$/) ?? [];
        const capability = projectCategory.capabilities.find(
          (cap) => cap.name === cleanedValueKey
        );
        if (
          capability === undefined ||
          capability.type === undefined ||
          values[projectCategory.name][valueKey] === "" ||
          (values[projectCategory.name][valueKey] instanceof File &&
            values[projectCategory.name][valueKey].size === 0)
        ) {
          continue;
        }

        relevantCapabilities.push({
          priority: priority !== undefined ? +priority : 0,
          projectCategoryName: projectCategory.name,
          type: capability.type,
          capabilityId: capability.id ?? "",
          value: values[projectCategory.name][valueKey],
        });
      }
    }

    return relevantCapabilities;
  };

  const matchProjects = async ({
    projectCategories,
    values,
  }: {
    projectCategories: ProjectCategoriesColumn[];
    values: Record<string, Record<string, any>>;
  }): Promise<RelevantPropertiesInterface> => {
    // 1. find relevant Capabilities
    const relevantCapabilities = getRelevantCapabilities({
      projectCategories,
      values,
    });

    // TODO: redo whole thing, bring into backend
    // 2. find relevant projects
    const relevantProjects: SearchResultInterface[] = [];

    const projects = await getAllProjects();
    relevantCapabilities.forEach((relevantCapability) => {
      projects.forEach((project) => {
        const { categories, projectCapabilities } = project;
        // project has no categories, no projectCapabilities or the category (fill, seal, etc.) doesn't match the search's, the match value is 0
        if (
          categories === undefined ||
          projectCapabilities === undefined ||
          categories.find(
            (category) =>
              category.name === relevantCapability.projectCategoryName
          ) === undefined
        ) {
          return {
            project: { ...project },
            match: 0,
          };
        }

        // project has matching capabilities.
        projectCapabilities.forEach((projectCapability) => {
          if (
            projectCapability.capabilityId !==
              relevantCapability.capabilityId ||
            projectCapability.projectId === undefined
          ) {
            return;
          }
          switch (relevantCapability.type) {
            case "FLOAT": {
              if (typeof projectCapability.value !== "string") {
                return;
              }
              const projectRange = JSON.parse(projectCapability.value);
              if (
                relevantCapability.value < projectRange.min ||
                relevantCapability.value > projectRange.max
              ) {
                return;
              }
              const existingRelevantProject = relevantProjects.find(
                (relevantProject) =>
                  projectCapability.projectId === relevantProject.projectId
              );
              if (existingRelevantProject !== undefined) {
                existingRelevantProject.matchingCapabilities.push({
                  ...relevantCapability,
                });
                return;
              }
              relevantProjects.push({
                projectId: projectCapability.projectId,
                matchingCapabilities: [{ ...relevantCapability }],
              });
              return;
            }

            default: {
              if (
                `${
                  typeof projectCapability.value === "string"
                    ? projectCapability.value
                    : projectCapability.value.name
                }` !== `${relevantCapability.value}`
              ) {
                return;
              }
              const existingRelevantProject = relevantProjects.find(
                (relevantProject) =>
                  projectCapability.projectId === relevantProject.projectId
              );
              if (existingRelevantProject !== undefined) {
                existingRelevantProject.matchingCapabilities.push({
                  ...relevantCapability,
                });
                return;
              }
              relevantProjects.push({
                projectId: projectCapability.projectId,
                matchingCapabilities: [{ ...relevantCapability }],
              });
            }
          }
        });
      });
    });

    return { relevantProjects, relevantCapabilities };
  };

  return {
    matchProjects,
    getRelevantCapabilities,
  };
};
export default useProjectMatching;
