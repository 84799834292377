import { FrontendInterface } from "../../interfaces/FrontendInterface";
import useFrontendsStore from "../../stores/useFrontendsStore";
import useCreateFrontend from "./useCreateFrontend";
import useGetFrontend from "./useGetFrontend";
import useUpdateFrontend from "./useUpdateFrontend";
import useDeleteFrontend from "./useDeleteFrontend";
import useGetFrontends from "./useGetFrontends";

interface UseFrontendsInterface {
  getById: (id: string) => Promise<FrontendInterface | null>;
  create: (frontend: FrontendInterface) => Promise<FrontendInterface>;
  update: (frontend: FrontendInterface) => Promise<FrontendInterface | null>;
  frontends: FrontendInterface[];
  getAll: () => Promise<FrontendInterface[]>;
  deleteById: (id: string) => Promise<boolean>;
}

const useFrontends = (): UseFrontendsInterface => {
  const add = useFrontendsStore((state) => state.add);
  const set = useFrontendsStore((state) => state.set);
  const remove = useFrontendsStore((state) => state.remove);
  const stateUpdate = useFrontendsStore((state) => state.update);
  const stateGetById = useFrontendsStore((state) => state.getById);
  const frontends = useFrontendsStore((state) => state.data);
  const gotAll = useFrontendsStore((state) => state.gotAll);
  const stateGetAll = useFrontendsStore((state) => state.getAll);
  const setGotAll = useFrontendsStore((state) => state.setGotAll);

  const { create: apiCreate } = useCreateFrontend();
  const { deleteById: apiDeleteById } = useDeleteFrontend();
  const { update: apiUpdate } = useUpdateFrontend();
  const { getById: apiGetById } = useGetFrontend();
  const { getAll: apiGetAll } = useGetFrontends();

  const getAll = async (): Promise<FrontendInterface[]> => {
    if (gotAll) {
      return stateGetAll();
    }
    const frontend = await apiGetAll();

    set(frontend);
    setGotAll();

    return frontend;
  };

  const deleteById = async (id: string): Promise<boolean> => {
    const removed = await apiDeleteById(id);

    remove(id);

    return removed;
  };

  const getById = async (id: string): Promise<FrontendInterface | null> => {
    let frontend = stateGetById(id);

    if (frontend !== null) {
      return frontend;
    }

    frontend = await apiGetById(id);

    if (frontend === null) {
      return null;
    }

    add(frontend);
    return frontend;
  };

  const create = async (
    frontend: FrontendInterface
  ): Promise<FrontendInterface> => {
    const created = await apiCreate(frontend);

    if (created === null) {
      return created;
    }

    add(created);

    return created;
  };

  const update = async (
    frontend: FrontendInterface
  ): Promise<FrontendInterface | null> => {
    if (frontend.id === undefined) {
      return null;
    }

    const updatedFrontend = await apiUpdate(frontend);

    if (updatedFrontend === null) {
      return null;
    }

    stateUpdate({
      id: frontend.id,
      updatedDataSet: updatedFrontend,
    });
    return updatedFrontend;
  };

  return {
    getById,
    create,
    deleteById,
    update,
    frontends,
    getAll,
  };
};
export default useFrontends;
