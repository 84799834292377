import useApi from "../useApi";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { ProjectMandateType } from "../../interfaces/ProjectMandateType";

interface UseGetProjectsByMandateInterface {
  getByMandateAndType: ({
    mandate,
    type,
  }: {
    mandate: ProjectMandateType;
    type: string;
  }) => Promise<ProjectInterface[]>;
}

const useGetProjectsByMandateAndType = (): UseGetProjectsByMandateInterface => {
  const { get } = useApi();

  const getByMandateAndType = async ({
    mandate,
    type,
  }: {
    mandate: ProjectMandateType;
    type: string;
  }): Promise<ProjectInterface[]> => {
    const { data, status } = await get(
      `/project/by-mandate/${mandate}/${type}`
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByMandateAndType };
};

export default useGetProjectsByMandateAndType;
