import useApi from "../useApi";
import { ProjectContractInterface } from "../../interfaces/ProjectContractInterface";

interface UseUpdateProjectContractInterface {
  update: (
    projectContract: ProjectContractInterface
  ) => Promise<ProjectContractInterface | null>;
}

const useUpdateProjectContract = (): UseUpdateProjectContractInterface => {
  const { put } = useApi();

  const update = async (
    projectContract: ProjectContractInterface
  ): Promise<ProjectContractInterface | null> => {
    if (projectContract.id === undefined) {
      return null;
    }

    const { data, status } = await put(
      `/project-contract/${projectContract.id}`,
      projectContract
    );

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { update };
};

export default useUpdateProjectContract;
