import useApi from "../useApi";
import { CapabilityConfigurationInterface } from "../../interfaces/CapabilityConfigurationInterface";

interface UseGetCapabilityConfigurationInterface {
  getById: (id: string) => Promise<CapabilityConfigurationInterface | null>;
}

const useGetCapabilityConfiguration =
  (): UseGetCapabilityConfigurationInterface => {
    const { get } = useApi();

    const getById = async (
      id: string
    ): Promise<CapabilityConfigurationInterface | null> => {
      const { data, status } = await get(`/capability-configuration/${id}`);

      if (status !== 200) {
        // todo
        throw new Error("Unknown");
      }

      return data;
    };

    return { getById };
  };

export default useGetCapabilityConfiguration;
