import styled from "styled-components";

const StyledPopover = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: ${(props) => props.theme.shadows.default}
    ${(props) => props.theme.colors.gray};
`;

export default StyledPopover;
