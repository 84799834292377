import React, { ReactElement } from "react";
import { CircledPlusIcon } from "../../icons";
import { Clickable } from "../../components";
import usePublicProfileBlocks from "../../api/publicProfileBlocks/usePublicProfileBlocks";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";
import { PublicProfileBlockTypeEnum } from "../../interfaces/PublicProfileBlockTypeEnum";

interface AddDefaultProfileBlocksProps {
  companyId: string;
}
const AddDefaultProfileBlocks = ({
  companyId,
}: AddDefaultProfileBlocksProps): ReactElement => {
  const { create } = usePublicProfileBlocks();

  const defaultBlocks: PublicProfileBlockInterface[] = [
    {
      name: "Mitarbeiter",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "Besteht weltweit aus ca. 1.120 hochqualifizierten Mitarbeitern. Diese Mitarbeiter sind in 27 Niederlassungen weltweit verteilt.",
    },
    {
      name: "Kontakt",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "<strong>Mosca GmbH</strong></br>Gerd-Mosca-Straße 1</br>69429 Waldbrunn",
    },
    {
      name: "Spezialgebiete",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "+ Umreifungsmaschinen</br>+ Palettenumreifungsanlagen</br>+ Stretchwickler</br>+ Umreifungsbänder</br>+ Gebrauchtmaschinen",
    },
    {
      name: "USP",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "Wir liefern höchstwertige Verpackungssysteme und Verpackungsmaterialien durch die Kombination smarter und innovativer Technologien mit anwendungs basiertem Expertenwissen und einem erstklassigen Kundenservice.</p> <p>Wir streben nach außergewöhnlicher Leistungsfähigkeit durch eine globale Organisation mit einer Unternehmens - kultur, in der wir unsere Mitarbeiter fördern, nachhaltig handeln und unternehmerische Verantwortung zum Wohle aller Interessensgruppen übernehmen.",
    },
    {
      name: "Future",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "VISION: Die weltweit effektivsten und nachhaltigsten Lösungen für ein schützendes und sicherndes Produkthandling.",
    },
    {
      name: "Facts",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "Gegründet: <strong>1966</br></strong>Mitarbeiter: <strong>1.120 St.</br></strong> Umsatz: <strong>180 Mio. €</br></strong> Zertifizierung:</br><strong>DIN EN ISO 5000, DIN ISO 9001</br></strong> Sitz: <strong>Waldbrunn</strong>",
    },
    {
      name: "Kurzbeschreibung",
      type: PublicProfileBlockTypeEnum.HTML,
      content:
        "Zuverlässige Qualität auf ganzer Linie<br>Am Ende eines jeden Produktionsprozesses muss die fertige Ware effizient und funktionell verpackt und für den Transport gesichert werden. Die Technologie am Ende der Prozesskette soll einwandfrei und zuverlässig funktionieren, sodass der gesamte Ablauf nicht ins Stocken gerät und teure Stillstandzeiten erst gar nicht entstehen.<br><br>Hier bietet Mosca die besten End-of-Line Packaging Lösungen: Als traditionelles Familienunternehmen aus dem Odenwald stehen wir weltweit mit über 50 Jahren Erfahrung für Qualität und Zuverlässigkeit im Bereich Umreifungstechnik und Transportgutsicherung. Dank unserer Innovationskraft und unseres Pioniergeistes setzen wir immer wieder neue Standards und stärken unsere Position als Technologieführer in der Umreifungsbranche.<br><br>Wir entwickeln effiziente Umreifungsmaschinen, hochwertige Umreifungsbänder und komplexe Anlagen zur Sicherung Ihrer Transportgüter. Dabei gehen unsere Leistungen weit über ein reines Maschinenportfolio hinaus. Als Systemanbieter begleiten wir unsere Kunden von Beginn an mit einem umfassenden und ganzheitlichen Dienstleistungskonzept, um Kundenanforderungen von Anfang an optimal zu erfüllen. Auch für Ihren Bedarf finden wir die passende Lösung – lernen Sie unsere Produkte und unseren exzellenten Service kennen!",
    },
  ];

  const handleAdd = async (): Promise<void> => {
    for (const defaultBlock of defaultBlocks) {
      await create({
        ...defaultBlock,
        companyId,
      });
    }
  };

  return (
    <Clickable
      onClick={() => {
        void handleAdd().then();
      }}
    >
      <CircledPlusIcon />
    </Clickable>
  );
};

export default AddDefaultProfileBlocks;
