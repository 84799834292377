import React, { ReactElement, useState } from "react";
import { CompanyInterface } from "../../interfaces/CompanyInterface";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import { Button, LoadingSkeleton } from "../../components";
import { useTranslation } from "react-i18next";
import useHasCurrentUserAccessToCompany from "../../hooks/useHasCurrentUserAccessToCompany";
import UnlockModal from "./UnlockModal";
import useUnlockProject from "./hooks/useUnlockProject";

const PartnerCompanyByProjectContract = ({
  project,
  attribute,
}: {
  project: ProjectInterface;
  attribute: keyof CompanyInterface;
}): ReactElement => {
  const { t } = useTranslation();
  const { status, company } = useHasCurrentUserAccessToCompany({
    companyId: project.companyId,
    filterContractState: true,
    projectContracts: project.projectContracts,
  });
  const { handleAccept } = useUnlockProject();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <UnlockModal
        isModalOpen={isModalOpen}
        handleModalOpenChange={setIsModalOpen}
        handleAccept={async (acceptedCompany) => {
          await handleAccept({
            acceptedCompany,
            projectContracts: project.projectContracts,
          });
        }}
        projectContracts={project.projectContracts}
      />
      {status === "success" && (
        <>
          {company !== null ? (
            company[attribute]
          ) : (
            <Button type="button" handleClick={() => setIsModalOpen(true)}>
              {t("PROJECT.UNLOCK.initiate")}
            </Button>
          )}
        </>
      )}
      {status === "loading" && (
        <>
          <LoadingSkeleton />
        </>
      )}
    </>
  );
};

export default PartnerCompanyByProjectContract;
