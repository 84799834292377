import { ProfileInterface } from "../interfaces/ProfileInterface";

const getName = (profile?: ProfileInterface): null | string => {
  if (
    profile === undefined ||
    profile === null ||
    profile.firstName === undefined ||
    profile.lastName === undefined ||
    profile.firstName === null ||
    profile.lastName === null ||
    (profile.firstName === "" && profile.lastName === "")
  ) {
    return null;
  }
  return `${profile.firstName} ${profile.lastName}`;
};

export default getName;
