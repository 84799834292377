import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { ProjectInterface } from "../../../interfaces/ProjectInterface";
import { Clickable } from "../../../components";

export interface EntityColumn extends ProjectInterface {}

const columnHelper = createColumnHelper<EntityColumn>();

interface UseEntitiesListColumnsInterface {
  columns: Array<ColumnDef<EntityColumn, any>>;
}
const useEntitiesListColumns = (): UseEntitiesListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<EntityColumn, any>> = [
    columnHelper.accessor("name", {
      header: `${t("PROJECT.name")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("description", {
      header: `${t("PROJECT.description")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            {info.getValue()}
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useEntitiesListColumns;
