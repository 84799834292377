import { create } from "zustand";
import { defaultStateCrud } from "./defaultStateCrud";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { CapabilityGroupInterface } from "../interfaces/CapabilityGroupInterface";
import { TranslationInterface } from "../interfaces/TranslationInterface";

interface CapabilityGroupState
  extends CrudStateInterface<CapabilityGroupInterface> {
  addTranslation: ({
    capabilityGroupId,
    translation,
  }: {
    capabilityGroupId: string;
    translation: TranslationInterface;
  }) => void;
  updateTranslation: ({
    capabilityGroupId,
    translation,
  }: {
    capabilityGroupId: string;
    translation: TranslationInterface;
  }) => void;
}
const useCapabilityGroupStore = create<CapabilityGroupState>((set, get) => ({
  ...defaultStateCrud<CapabilityGroupInterface>(set, get),
  addTranslation: ({ capabilityGroupId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== capabilityGroupId) {
              return dataSet;
            }
            return {
              ...dataSet,
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation,
                      {
                        ...translation,
                      },
                    ],
            };
          }),
        ],
      };
    }),
  updateTranslation: ({ capabilityGroupId, translation }) =>
    set((state) => {
      return {
        data: [
          ...state.data.map((dataSet) => {
            if (dataSet.id !== capabilityGroupId) {
              return dataSet;
            }
            return {
              ...dataSet,
              translation:
                dataSet.translation === undefined
                  ? [translation]
                  : [
                      ...dataSet.translation.map((stateTranslation) => {
                        if (stateTranslation.id !== translation.id) {
                          return stateTranslation;
                        }
                        return translation;
                      }),
                    ],
            };
          }),
        ],
      };
    }),
}));

export default useCapabilityGroupStore;
