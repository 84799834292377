import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProjects from "../../api/projects/useProjects";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import { Table, TableControlBar } from "../../components";
import PlusIcon from "../../icons/PlusIcon";
import useEntitiesListColumns from "./hooks/useEntitiesListColumns";
import { useTranslation } from "react-i18next";
import { CompanyTypeType } from "../../interfaces/CompanyTypeType";
import { ProjectInterface } from "../../interfaces/ProjectInterface";
import _ from "lodash";

interface EntityListProps {
  filter?: CompanyTypeType;
}

const EntityList = ({ filter }: EntityListProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    entityProjects,
    getAllEntityProjects,
    getAllEntityProjectsByType,
    deleteById,
  } = useProjects();
  const { columns } = useEntitiesListColumns();

  const [status, setStatus] = useState<RequestStatusType>("initial");
  const [filteredEntities, setFilteredEntities] = useState<
    ProjectInterface[] | null
  >(null);

  useEffect(() => {
    if (filter === undefined) {
      void getAllEntityProjects().then(() => {
        setStatus("success");
      });
      setStatus("loading");
      return;
    }

    void getAllEntityProjectsByType({ type: filter }).then(() => {
      setStatus("success");
    });
    setStatus("loading");
  }, [filter]);

  useEffect(() => {
    if (status !== "success") {
      return;
    }
    if (filter === undefined) {
      setFilteredEntities(entityProjects);
      return;
    }

    const filteredEntityProjects = entityProjects.filter((project) =>
      project.types?.find((projectType) => projectType.name === filter)
    );

    if (_.isEqual(filteredEntities, filteredEntityProjects)) {
      return;
    }

    setFilteredEntities(filteredEntityProjects);
  }, [status, filter, entityProjects]);

  const handleCreateClick = (): void => {
    switch (filter) {
      case "default":
        navigate("/packing-machine/machines/create");
        break;
      case "usedMachine":
        navigate("/used-machine/machines/create");
        break;
      case "contractManufacturer":
        navigate("/contract-manufacturer/machines/create");
        break;
      case "packaging":
        navigate("/packaging/entities/create");
        break;
      case "client":
      default:
        break;
    }
  };

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: PlusIcon,
          },
        ]}
        handleAction={handleCreateClick}
      />
      <Table
        data={filteredEntities ?? []}
        isShowMore
        options={[
          {
            label: t("BUTTONS.edit"),
            onClick: (id) => {
              navigate(`/machine/edit/${id}`);
            },
          },
          {
            label: t("BUTTONS.delete"),
            onClick: async (id) => {
              await deleteById(id);
            },
          },
        ]}
        columns={columns}
        isLoading={status === "loading"}
      />
    </>
  );
};

export default EntityList;
