import React, { ReactElement, useEffect, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Clickable } from "../../../components";
import { ProjectContractInterface } from "../../../interfaces/ProjectContractInterface";
import ContractStateTag from "../ContractStateTag";
import useCompanies from "../../../api/companies/useCompanies";
import { CompanyInterface } from "../../../interfaces/CompanyInterface";

export interface ProjectContractListColumn extends ProjectContractInterface {}

const columnHelper = createColumnHelper<ProjectContractListColumn>();

// todo: Company / contract removal
const CompanyColumn = ({ companyId }: { companyId: string }): ReactElement => {
  const { getById } = useCompanies();

  const [company, setCompany] = useState<CompanyInterface | null>(null);

  useEffect(() => {
    void getById(companyId).then((companyData) => {
      setCompany(companyData);
    });
  }, [companyId]);

  if (company === null) {
    return <></>;
  }

  return <>{company.name ?? ""}</>;
};

interface UseProjectsListColumnsInterface {
  columns: Array<ColumnDef<ProjectContractListColumn, any>>;
}
const useProjectContractListColumns = (): UseProjectsListColumnsInterface => {
  const { t } = useTranslation();

  const columns: Array<ColumnDef<ProjectContractListColumn, any>> = [
    columnHelper.accessor("companyId", {
      header: `${t("PROJECT_CONTRACT.company")}`,
      cell: (info) => {
        return (
          <Clickable onClick={info.row.getToggleExpandedHandler()}>
            <CompanyColumn companyId={info.getValue()} />
          </Clickable>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("contractState", {
      header: `${t("PROJECT_CONTRACT.state")}`,
      cell: (info) => {
        return <ContractStateTag state={info.getValue()} />;
      },
      footer: (info) => info.column.id,
    }),
  ];

  return { columns };
};

export default useProjectContractListColumns;
