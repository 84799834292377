import React, { ReactElement, useEffect, useState } from "react";
import TableControlBar from "../../components/TableControlBar";
import PlusIcon from "../../icons/PlusIcon";
import { Button, Card, Space } from "../../components";
import { AnimatePresence } from "framer-motion";
import { CloseIcon } from "../../icons";
import Right from "../../components/Right";
import CheckIcon from "../../icons/CheckIcon";
import ProfileBlockForm from "../PublicProfiles/ProfileBlockForm";
import { useTranslation } from "react-i18next";
import { CapabilityColumn } from "../../hooks/useCapabilitiesListColumns";
import { RequestStatusType } from "../../interfaces/RequestStatusType";
import useCapabilities from "../../api/capabilities/useCapabilities";
import CapabilitiesTable from "./CapabilitiesTable";

const CapabilitiesList = (): ReactElement => {
  const { t } = useTranslation();
  const { getAll } = useCapabilities();

  const [isShowBlockAddForm, setIsShowBlockAddForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [data, setData] = useState<{
    status: RequestStatusType;
    capabilities: CapabilityColumn[];
  }>({ status: "initial", capabilities: [] });

  useEffect(() => {
    const getCapabilities = async (): Promise<void> => {
      const capabilities = await getAll();
      setData({
        status: "success",
        capabilities: capabilities.map((capability) => {
          return { ...capability, edit: true };
        }),
      });
    };

    void getCapabilities();
  }, []);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowBlockAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowBlockAddForm(!isShowBlockAddForm)}
      />
      <AnimatePresence>
        {isShowBlockAddForm && (
          <React.Fragment key={1}>
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <ProfileBlockForm
                initialValues={{
                  id: "",
                  companyId: [],
                  name: "",
                  content: "",
                  type: [],
                }}
                handleSubmit={() => {
                  setIsSubmitting(true);
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </ProfileBlockForm>
            </Card>
            <Space />
          </React.Fragment>
        )}

        <CapabilitiesTable data={data} />
      </AnimatePresence>
    </>
  );
};

export default CapabilitiesList;
