import useApi from "../useApi";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";

interface UseGetPublicProfileBlocksInterface {
  getAll: () => Promise<PublicProfileBlockInterface[]>;
}

const useGetPublicProfileBlocks = (): UseGetPublicProfileBlocksInterface => {
  const { get } = useApi();

  const getAll = async (): Promise<PublicProfileBlockInterface[]> => {
    const { data, status } = await get(`/public-profile`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getAll };
};

export default useGetPublicProfileBlocks;
