import React, { ReactElement, useState } from "react";
import useUsers from "../../api/users/useUsers";
import { Button, Form, Right } from "../../components";
import { useTranslation } from "react-i18next";
import { FormStatusType } from "../../interfaces/FormStatusType";

const AccountData = (): ReactElement => {
  const { t } = useTranslation();
  const { currentUser, update } = useUsers();

  const [status, setStatus] = useState<FormStatusType>("initial");

  if (currentUser === null) {
    return <></>;
  }

  return (
    <>
      <Form<{
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        position: string;
      }>
        handleChange={() => setStatus("edited")}
        fields={[
          {
            id: "email",
            type: "email",
            label: t("USER.email"),
          },
          {
            id: "firstName",
            type: "text",
            label: t("USER.firstName"),
          },
          {
            id: "lastName",
            type: "text",
            label: t("USER.lastName"),
          },
          {
            id: "phone",
            type: "phone",
            label: t("USER.phone"),
          },
          {
            id: "position",
            type: "text",
            label: t("USER.position"),
          },
        ]}
        initialValues={{
          email: currentUser.email,
          firstName: currentUser.profile?.firstName ?? "",
          lastName: currentUser.profile?.lastName ?? "",
          phone: currentUser.profile?.phone ?? "",
          position: currentUser.profile?.position ?? "",
        }}
        handleSubmit={async (updatedUser) => {
          setStatus("loading");
          await update({
            id: currentUser.id,
            email: updatedUser.email,
            profile: {
              phone: updatedUser.phone,
              position: updatedUser.position,
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
            },
          });
          setStatus("success");
        }}
      >
        <Right>
          {status === "success" && (
            <Button variant="success" disabled>
              {t("MESSAGES.successfully-saved")}
            </Button>
          )}
          {status === "edited" && <Button>{t("BUTTONS.save")}</Button>}
          {status === "loading" && (
            <Button disabled>{t("BUTTONS.updating")}</Button>
          )}
        </Right>
      </Form>
    </>
  );
};

export default AccountData;
