import useApi from "../useApi";
import { FrontendInterface } from "../../interfaces/FrontendInterface";

interface UseGetFrontendInterface {
  getById: (id: string) => Promise<FrontendInterface | null>;
}

const useGetFrontend = (): UseGetFrontendInterface => {
  const { get } = useApi();

  const getById = async (id: string): Promise<FrontendInterface | null> => {
    const { data, status } = await get(`/frontend/${id}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getById };
};

export default useGetFrontend;
