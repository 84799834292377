import { Button, Headline, Space, Text } from "../../components";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface UnlockModalContentProps {
  handleCancelClick: () => void;
  handleAcceptClick: () => Promise<void> | void;
}
const UnlockModalContent = ({
  handleCancelClick,
  handleAcceptClick,
}: UnlockModalContentProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Headline>{t("PROJECT.UNLOCK.headline")}</Headline>
      <Text>{t("PROJECT.UNLOCK.notice")}</Text>
      <Space size={2} />
      <Button
        fullWidth
        variant="success"
        type="button"
        handleClick={handleAcceptClick}
      >
        {t("PROJECT.UNLOCK.unlock-button")}
      </Button>
      <Space size={1} />
      <Button
        variant="light"
        handleClick={handleCancelClick}
        fullWidth
        type="button"
      >
        {t("BUTTONS.cancel")}
      </Button>
    </>
  );
};

export default UnlockModalContent;
