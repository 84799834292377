import React, { ReactElement, useEffect, useState } from "react";
import Table from "../components/Table";
import TableControlBar from "../components/TableControlBar";
import PlusIcon from "../icons/PlusIcon";
import { Button, Card, Space } from "../components";
import { AnimatePresence } from "framer-motion";
import { CloseIcon } from "../icons";
import Right from "../components/Right";
import CheckIcon from "../icons/CheckIcon";
import ProfileBlockForm from "./PublicProfiles/ProfileBlockForm";
import { useTranslation } from "react-i18next";
import useProjectCategoriesListColumns, {
  ProjectCategoriesColumn,
} from "../hooks/useProjectCategoriesListColumns";
import { RequestStatusType } from "../interfaces/RequestStatusType";
import useProjectCategories from "../api/projectCategories/useProjectCategories";

const ProjectCategoriesList = (): ReactElement => {
  const { t } = useTranslation();
  const { getAll } = useProjectCategories();
  const { columns } = useProjectCategoriesListColumns();

  const [isShowBlockAddForm, setIsShowBlockAddForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [data, setData] = useState<{
    status: RequestStatusType;
    projectCategories: ProjectCategoriesColumn[];
  }>({ status: "initial", projectCategories: [] });

  useEffect(() => {
    const getProjectCategories = async (): Promise<void> => {
      const projectCategories = await getAll();
      setData({
        status: "success",
        projectCategories: projectCategories.map((projectCategory) => {
          return { ...projectCategory, edit: true };
        }),
      });
    };

    void getProjectCategories();
  }, []);

  return (
    <>
      <TableControlBar
        controls={[
          {
            action: "add",
            element: isShowBlockAddForm ? CloseIcon : PlusIcon,
          },
        ]}
        handleAction={() => setIsShowBlockAddForm(!isShowBlockAddForm)}
      />
      <AnimatePresence>
        {isShowBlockAddForm && (
          <React.Fragment key={1}>
            <Card
              initial={{ y: "-2rem" }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
            >
              <ProfileBlockForm
                initialValues={{
                  id: "",
                  companyId: [],
                  name: "",
                  content: "",
                  type: [],
                }}
                handleSubmit={() => {
                  setIsSubmitting(true);
                }}
              >
                <Right>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant={isSubmitting ? "success" : "default"}
                    whileTap={{
                      scale: 0.8,
                    }}
                  >
                    {!isSubmitting ? t("BUTTONS.save") : <CheckIcon />}
                  </Button>
                </Right>
              </ProfileBlockForm>
            </Card>
            <Space />
          </React.Fragment>
        )}

        <Table
          data={data.projectCategories}
          columns={columns}
          isLoading={data.status !== "success"}
          isShowMore
          getRowCanExpand={() => true}
          renderSubComponent={({ row }) => {
            if (row.original.id === undefined) {
              return <></>;
            }
            return (
              <>
                <Space />
                <Space size={3} />
              </>
            );
          }}
        />
      </AnimatePresence>
    </>
  );
};

export default ProjectCategoriesList;
