import React, { ReactElement, ReactNode, useState } from "react";
import StyledGroupDiv from "../../components/styles/StyledGroupDiv";
import { Column, Headline, Row } from "../../components";
import CircledPlusIcon from "../../icons/CircledPlusIcon";
import CircledMinusIcon from "../../icons/CircledMinusIcon";
import Table from "../../components/Table";
import useCapabilitiesListColumns, {
  CapabilityColumn,
} from "../../hooks/useCapabilitiesListColumns";
import styled from "styled-components";
import { CapabilityGroupCollectionInterface } from "../../interfaces/CapabilityGroupCollectionInterface";
import { useTranslation } from "react-i18next";
import CapabilitySelection from "../CapabilitySelection";
import useFormGroups from "../../api/formGroups/useFormGroups";
import { ShowMoreOptionInterface } from "../../interfaces/ShowMoreOptionInterface";
import AddToCapabilityGroupModal, {
  AddToFormGroupConfig,
} from "../Modals/AddToCapabilityGroupModal";
import CapabilityGroupTranslation from "../Translation/CapabilityGroupTranslation";

const StyledPlusLink = styled.a`
  cursor: pointer;

  svg {
    width: 16px;
  }
`;

const StyledNoWrap = styled.span`
  white-space: nowrap;
`;

interface GroupedCapabilitiesProps {
  capabilityGroup: CapabilityGroupCollectionInterface;
  formGroupId?: string;
}
const GroupedCapabilities = ({
  capabilityGroup,
  formGroupId,
}: GroupedCapabilitiesProps): ReactElement => {
  const { t } = useTranslation();
  const { columns } = useCapabilitiesListColumns();
  const { removeCapabilityFromFormGroup, addCapabilityToFormGroup } =
    useFormGroups();

  const [isAddToFormGroupActivated, setIsAddToFormGroupActivated] =
    useState<boolean>(false);
  const [addToCapabilityConfig, setAddToCapabilityConfig] =
    useState<AddToFormGroupConfig>({
      isActivated: false,
      capabilityId: null,
    });

  const handleRemoval = async (capabilityId: string): Promise<void> => {
    if (formGroupId === undefined) {
      return;
    }
    await removeCapabilityFromFormGroup({
      capabilityId,
      formGroupId,
    });
  };

  const handleSubGroupRemoval = async (capabilityId: string): Promise<void> => {
    if (formGroupId === undefined) {
      return;
    }
    await addCapabilityToFormGroup({
      capabilityId,
      formGroupId,
    });
  };

  const handleAddition = async (capabilityId: string): Promise<void> => {
    if (formGroupId === undefined) {
      return;
    }

    await addCapabilityToFormGroup({
      capabilityId,
      formGroupId,
      capabilityGroupId: capabilityGroup.id,
    });

    setIsAddToFormGroupActivated(false);
  };

  const handleAddToCapabilityGroup = (capabilityId: string): void => {
    setAddToCapabilityConfig({
      capabilityId,
      isActivated: true,
    });
  };

  const options: ShowMoreOptionInterface[] = [
    {
      onClick: handleRemoval,
      label: t("FORMS.GROUPS.remove-capability"),
    },
    {
      onClick: handleAddToCapabilityGroup,
      label: t("FORMS.GROUPS.add-to-new-sub-group"),
    },
  ];
  if (capabilityGroup.id !== undefined) {
    options.push({
      onClick: handleSubGroupRemoval,
      label: t("FORMS.GROUPS.remove-capability-from-sub-group"),
    });
  }

  const GroupWrapper = ({
    children,
  }: {
    children: ReactNode;
  }): ReactElement => {
    return (
      <>
        {capabilityGroup.id === undefined ? (
          <StyledGroupDiv isDull>{children}</StyledGroupDiv>
        ) : (
          <StyledGroupDiv>{children}</StyledGroupDiv>
        )}
      </>
    );
  };

  return (
    <>
      <GroupWrapper>
        <Headline>
          <Row mb="0">
            <Column size="shrink">
              <StyledNoWrap>
                {t(`CAPABILITY_GROUPS.${capabilityGroup.name}`)}{" "}
                {capabilityGroup.id !== undefined && (
                  <CapabilityGroupTranslation
                    capabilityGroup={capabilityGroup}
                  />
                )}
              </StyledNoWrap>
            </Column>
            <Column size="shrink" padding="0">
              <StyledPlusLink
                onClick={() =>
                  setIsAddToFormGroupActivated(!isAddToFormGroupActivated)
                }
              >
                {!isAddToFormGroupActivated ? (
                  <CircledPlusIcon />
                ) : (
                  <CircledMinusIcon />
                )}
              </StyledPlusLink>
            </Column>
          </Row>
        </Headline>
        {formGroupId !== undefined && (
          <AddToCapabilityGroupModal
            formGroupId={formGroupId}
            config={addToCapabilityConfig}
            handleClose={() =>
              setAddToCapabilityConfig({
                isActivated: false,
                capabilityId: null,
              })
            }
          />
        )}
        {isAddToFormGroupActivated && (
          <CapabilitySelection
            initialValue={null}
            handleSelected={async (searchableData) => {
              if (
                searchableData === null ||
                searchableData.value === "createNew"
              ) {
                return;
              }
              void (await handleAddition(searchableData.value));
            }}
          />
        )}
        <Table<CapabilityColumn>
          data={capabilityGroup.capabilities}
          columns={columns}
          isLoading={false}
          isShowMore
          options={options}
        />
      </GroupWrapper>
    </>
  );
};

export default GroupedCapabilities;
