import { create } from "zustand";
import { CrudStateInterface } from "../interfaces/CrudStateInterface";
import { defaultStateCrud } from "./defaultStateCrud";
import { CapabilityConfigurationInterface } from "../interfaces/CapabilityConfigurationInterface";

export interface CapabilityConfigurationsState
  extends CrudStateInterface<CapabilityConfigurationInterface> {}

const useCapabilityConfigurationStore = create<CapabilityConfigurationsState>(
  (set, get) => ({
    ...defaultStateCrud<CapabilityConfigurationInterface>(set, get),
  })
);

export default useCapabilityConfigurationStore;
