import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../components";
import { RelationType } from "../components/Form/Form";

interface CompanyFormProps {
  initialValues: {
    id: string;
    name: string;
    address: string;
    country: string;
    users: RelationType;
  };
  handleSubmit: (values: Record<string, any>) => void;
  children: ReactNode;
}

const CompanyForm = ({
  initialValues,
  handleSubmit,
  children,
}: CompanyFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form
      fields={[
        {
          id: "id",
          label: "id",
          type: "hidden",
        },
        {
          id: "name",
          label: t("COMPANY.name"),
          type: "text",
        },
        {
          id: "address",
          label: t("COMPANY.address"),
          type: "text",
        },
        {
          id: "country",
          label: t("COMPANY.country"),
          type: "text",
        },
        {
          id: "users",
          label: t("COMPANY.users"),
          type: "relation",
        },
      ]}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      {children}
    </Form>
  );
};

export default CompanyForm;
