import { useEffect, useState } from "react";
import { RequestStatusType } from "../../../interfaces/RequestStatusType";
import { CapabilityInterface } from "../../../interfaces/CapabilityInterface";
import useCapabilities from "../../../api/capabilities/useCapabilities";

interface UseCapabilityDataProps {
  id: string;
}

interface DataTypeInterface {
  status: RequestStatusType;
  capability: CapabilityInterface | null;
}

interface UseCapabilityDataInterface {
  data: DataTypeInterface;
}

const useCapabilityData = ({
  id,
}: UseCapabilityDataProps): UseCapabilityDataInterface => {
  const { getById } = useCapabilities();

  const [data, setData] = useState<{
    status: RequestStatusType;
    capability: CapabilityInterface | null;
  }>({ status: "initial", capability: null });

  useEffect(() => {
    if (data.status !== "initial") {
      return;
    }
    const get = async (): Promise<void> => {
      const capability = await getById(id);
      if (capability === null) {
        setData({ status: "error", capability: null });
        return;
      }
      setData({ capability, status: "success" });
    };

    setData({ status: "loading", capability: null });
    void get();
  }, [id, data]);

  return { data };
};
export default useCapabilityData;
