import useApi from "../useApi";
import { PublicProfileBlockInterface } from "../../interfaces/PublicProfileBlockInterface";

interface UseGetPublicProfileBlocksInterface {
  getByCompanyId: (companyId: string) => Promise<PublicProfileBlockInterface[]>;
}

const useGetPublicProfileBlocks = (): UseGetPublicProfileBlocksInterface => {
  const { get } = useApi();

  const getByCompanyId = async (
    companyId: string
  ): Promise<PublicProfileBlockInterface[]> => {
    const { data, status } = await get(`/public-profile/${companyId}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByCompanyId };
};

export default useGetPublicProfileBlocks;
