import {
  FormGroupRequiredType,
  FormGroupRequiredTypeEnum,
} from "../../interfaces/FormGroupRequiredType";
import { FormGroupInterface } from "../../interfaces/FormGroupInterface";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import _ from "lodash";
import {
  Button,
  Checkbox,
  Column,
  Headline,
  Row,
  Space,
  StyledLabel,
} from "../../components";
import SelectField from "../../components/Form/SelectField";
import { ChevronRightIcon, DeleteIcon } from "../../icons";
import useFormGroups from "../../api/formGroups/useFormGroups";
import styled from "styled-components";
import getProjectCategoriesFromFormGroup from "../../helpers/getProjectCategoriesFromFormGroup";
import StyledGroup from "../../components/styles/StyledGroup";
import useProjectCategories from "../../api/projectCategories/useProjectCategories";
import FormGroupTranslation from "../Translation/FormGroupTranslation";

interface FormDataInterface {
  name: string;
  isMandatory: boolean;
  requiredType: FormGroupRequiredType | "";
  projectCategories: {
    [categoryName: string]: boolean;
  };
}

const StyledDeleteLink = styled.a`
  cursor: pointer;
`;

interface FormGroupFormProps {
  formGroup: FormGroupInterface;
  handleCheckCapabilitiesClick: () => void;
}
const FormGroupForm = ({
  formGroup,
  handleCheckCapabilitiesClick,
}: FormGroupFormProps): ReactElement => {
  const { t } = useTranslation();
  const { updateWithProjectCategories, deleteById } = useFormGroups();
  const { projectCategories } = useProjectCategories();

  const [isTouched, setIsTouched] = useState<boolean>(false);

  const activeProjectCategories = getProjectCategoriesFromFormGroup(formGroup);

  const options = Object.keys(FormGroupRequiredTypeEnum);
  const initialValues: FormDataInterface = {
    name: formGroup.name,
    isMandatory: formGroup.isMandatory,
    requiredType: formGroup.requiredType ?? "",
    projectCategories: {},
  };

  projectCategories.forEach((projectCategory) => {
    if (projectCategory.name === undefined) {
      return;
    }
    if (
      activeProjectCategories.find(
        (activeProjectCategory) =>
          activeProjectCategory.name === projectCategory.name
      ) === undefined
    ) {
      initialValues.projectCategories[projectCategory.name] = false;
      return;
    }

    initialValues.projectCategories[projectCategory.name] = true;
  });

  const handleDelete = async (): Promise<void> => {
    if (formGroup.id === undefined) {
      return;
    }

    await deleteById(formGroup.id);
  };

  return (
    <Formik<FormDataInterface>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values) => {
        // todo: provide better solution
        const projectCategoriesToRemove: string[] = [];
        const projectCategoriesToAdd: string[] = [];
        activeProjectCategories.forEach((activeProjectCategory) => {
          if (values.projectCategories[activeProjectCategory.name]) {
            return;
          }
          projectCategoriesToRemove.push(activeProjectCategory.name);
        });
        const projectCategoryValueNames = Object.keys(values.projectCategories);
        projectCategoryValueNames.forEach((projectCategoryName) => {
          if (!values.projectCategories[projectCategoryName]) {
            return;
          }
          if (
            activeProjectCategories.find(
              (activeProjectCategory) =>
                activeProjectCategory.name === projectCategoryName
            ) !== undefined
          ) {
            return;
          }
          projectCategoriesToAdd.push(projectCategoryName);
        });

        await updateWithProjectCategories({
          formGroup: {
            id: formGroup.id,
            name: values.name,
            isMandatory: values.isMandatory,
            requiredType:
              values.requiredType === "" ? null : values.requiredType,
          },
          projectCategoriesToRemove,
          projectCategoriesToAdd,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues,
      }) => {
        useEffect(() => {
          if (_.isEqual(values, initialValues)) {
            setIsTouched(false);
            return;
          }
          setIsTouched(true);
        }, [values, initialValues]);

        const handleTouched = (e: ChangeEvent<any>): void => {
          handleChange(e);
        };

        return (
          <>
            <Row mb="0">
              <Column>
                <Headline>
                  {t([`FORM_GROUPS.${values.name}`, values.name])}{" "}
                  <FormGroupTranslation formGroup={formGroup} />
                </Headline>
              </Column>
              <Column size="shrink">
                <StyledDeleteLink
                  onClick={() => {
                    void handleDelete().then();
                  }}
                >
                  <DeleteIcon />
                </StyledDeleteLink>
              </Column>
            </Row>
            <Checkbox
              label={t("FORMS.GROUPS.is-mandatory")}
              name="isMandatory"
              checked={values.isMandatory}
              handleChange={handleTouched}
            />{" "}
            <br />
            <SelectField
              label={t("FORMS.GROUPS.required-type")}
              name="requiredType"
              handleChange={handleTouched}
              value={values.requiredType}
            >
              <option value="">{t("FORMS.select")}</option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {t(`REQUIRED_TYPES.${option}`)}
                </option>
              ))}
            </SelectField>
            <StyledGroup>
              <StyledLabel>{t("PROJECT_CATEGORIES.title")}:</StyledLabel>
              {projectCategories.map((projectCategory) => (
                <React.Fragment key={projectCategory.id}>
                  <Checkbox
                    label={t(`PROJECT_CATEGORIES.${projectCategory.name}`)}
                    name={`projectCategories.${projectCategory.name}`}
                    handleChange={handleTouched}
                    checked={values.projectCategories[projectCategory.name]}
                    value={values.projectCategories[projectCategory.name]}
                  />
                </React.Fragment>
              ))}
            </StyledGroup>
            <Space size={2} />
            <Row mb="0">
              <Column>
                <Button handleClick={handleCheckCapabilitiesClick}>
                  {t("FORMS.GROUPS.check-capabilities")}{" "}
                  <ChevronRightIcon fill="#ffffff" />
                </Button>
              </Column>
              {isTouched && (
                <Column align="right">
                  <Button variant="success" handleClick={handleSubmit}>
                    {t("BUTTONS.save-changes")}
                  </Button>
                </Column>
              )}
            </Row>
          </>
        );
      }}
    </Formik>
  );
};

export default FormGroupForm;
