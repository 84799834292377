import useApi from "../useApi";
import { UserInterface } from "../../interfaces/UserInterface";

interface UseGetUsersInterface {
  getByCompanyId: (companyId: string) => Promise<UserInterface[]>;
}

const useGetUsersByCompanyId = (): UseGetUsersInterface => {
  const { get } = useApi();

  const getByCompanyId = async (
    companyId: string
  ): Promise<UserInterface[]> => {
    const { data, status } = await get(`/user/by-company/${companyId}`);

    if (status !== 200) {
      // todo
      throw new Error("Unknown");
    }

    return data;
  };

  return { getByCompanyId };
};

export default useGetUsersByCompanyId;
