import { ContractStateType } from "../../../interfaces/ContractStateType";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { Clickable, Headline, Tag } from "../../../components";

interface EditContractStateModalProps {
  isOpen: boolean;
  handleOpenChange: (isOpen: boolean) => void;
  handleStateChange: (state: ContractStateType) => void | Promise<void>;
}

const EditContractStateModal = ({
  isOpen,
  handleOpenChange,
  handleStateChange,
}: EditContractStateModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} handleClose={() => handleOpenChange(false)}>
      <Headline>{t("CONTRACT_STATES.select")}:</Headline>
      <Clickable
        onClick={() => {
          void handleStateChange("OPEN");
        }}
      >
        <Tag variant="danger">{t("CONTRACT_STATES.OPEN")}</Tag>
      </Clickable>
      <br />
      <Clickable
        onClick={() => {
          void handleStateChange("ON_HOLD");
        }}
      >
        <Tag>{t("CONTRACT_STATES.ON_HOLD")}</Tag>
      </Clickable>
      <br />
      <Clickable
        onClick={() => {
          void handleStateChange("CLOSED");
        }}
      >
        <Tag variant="success">{t("CONTRACT_STATES.CLOSED")}</Tag>
      </Clickable>
      <br />
    </Modal>
  );
};

export default EditContractStateModal;
